import {
  ClientAssignment,
  Suggestion,
  SummaryInfluencerScore,
  SummaryConsiderationInfluencer,
  ClientReport
} from "~/types/gen/api";

export const resetState = <S>(state: () => S) => (_state: S) => {
  Object.assign(_state, state());
};

export const convertSummaryInfluencerScore = (
  value: ClientAssignment | Suggestion
): SummaryInfluencerScore &
  SummaryConsiderationInfluencer &
  (
    | Pick<ClientAssignment, "isDataCollectionCompleted">
    | Pick<Suggestion, "isDataCollectionCompleted">) => {
  const {
    totalCost,
    totalCommission,
    totalInfluencerCount,
    totalFollower,
    totalFollow,
    averageEngagementRate,
    averageAffinityRate,
    averageAdvocateScoreRate,
    totalConsiderationInfluencerCount,
    isDataCollectionCompleted
  } = value;
  return {
    totalCost,
    totalCommission,
    totalInfluencerCount,
    totalFollower,
    totalFollow,
    averageEngagementRate,
    averageAffinityRate,
    averageAdvocateScoreRate,
    totalConsiderationInfluencerCount,
    isDataCollectionCompleted
  };
};

export const getReport = (value: ClientReport | null): Object => {
  return {
    totalCost: value ? value.totalCost : null,
    totalCommission: value ? value.totalCommission : null,
    totalFollower: value ? value.totalFollower : null,
    averageEngagementRate: value ? value.averageEngagementRate : null,
    averageAffinityRate: value ? value.averageAffinityRate : null,
    averageAdvocateScoreRate: value ? value.averageAdvocateScoreRate : null,
    isDataCollectionCompleted: value ? value.isDataCollectionCompleted : null,
    totalReachCount: value ? value.totalReachCount : null,
    totalImpressionCount: value ? value.totalImpressionCount : null,
    totalViewCount: value ? value.totalViewCount : null,
    totalLikeCount: value ? value.totalLikeCount : null,
    totalCommentCount: value ? value.totalCommentCount : null,
    totalShareCount: value ? value.totalShareCount : null,
    averageRetentionRate: value ? value.averageRetentionRate : null,
    totalClippingCount: value ? value.totalClippingCount : null,
    totalEcClickCount: value ? value.totalEcClickCount : null,
    totalSalesValue: value ? value.totalSalesValue : null,
    costReachRate: value ? value.costReachRate : null,
    costImpressionRate: value ? value.costImpressionRate : null,
    costViewRate: value ? value.costViewRate : null,
    costEngagementRate: value ? value.costEngagementRate : null,
    costClippingRate: value ? value.costClippingRate : null,
    costEcClickRate: value ? value.costEcClickRate : null,
    costSalesRate: value ? value.costSalesRate : null,
    awarenessScore: value ? value.awarenessScore : null,
    interestScore: value ? value.interestScore : null,
    considerationScore: value ? value.considerationScore : null,
    conversionScore: value ? value.conversionScore : null,
    agencyNote: value ? value.agencyNote : "",
    agencyAttachmentFiles: value ? value.agencyAttachmentFiles : []
  };
};
