import { actionTree, mutationTree } from "nuxt-typed-vuex";
import {
  CreateAgencyAccountMutationRequest,
  CreateAgencyAccountMutationVariables
} from "~/types/gen/api";
import { resetState } from "~/store/utils";

export const state = () => ({});

export const mutations = mutationTree(state, {
  reset: resetState(state)
});

export const actions = actionTree(
  { state },
  {
    async createAgencyAccount(
      _context,
      payload: CreateAgencyAccountMutationVariables
    ): Promise<void> {
      try {
        this.$accessor.presentation.showLoading(null);
        await this.$apiClient.mutate(
          new CreateAgencyAccountMutationRequest(payload)
        );
        const path = `/agency/accounts`;
        this.$router.replace(path);
      } catch (e) {
        this.$accessor.error.showError(e);
      } finally {
        // TODO loadingのPR取り込まれ待ち
        this.$accessor.presentation.dismissLoading();
      }
    }
  }
);
