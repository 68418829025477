import { DateTime, ToRelativeUnit } from "luxon";
import { zeroPadding } from "./number";

export type DateObject = {
  year?: number;
  month?: number;
  day?: number;
};

export const API_DATE_FORMAT = "yyyy-MM-dd";
export const API_TIME_FORMAT = "HH:mm:ss";
export const API_DATETIME_FORMAT = `${API_DATE_FORMAT} ${API_TIME_FORMAT}`;

export function dateString({ year, month, day }: Required<DateObject>) {
  return `${zeroPadding(year)}-${zeroPadding(month)}-${zeroPadding(day)}`;
}

export function dateObject(dateString: string) {
  const array = dateString.split("-");
  return {
    year: +array[0] || undefined,
    month: +array[1] || undefined,
    day: +array[2] || undefined
  };
}

export function isValid(dateString: string) {
  return fromDateOrDateTimeFormat(dateString).isValid;
}

export function fromDateOrDateTimeFormat(dateString: string) {
  let dateTime = DateTime.fromFormat(dateString, API_DATETIME_FORMAT);
  if (dateTime.isValid) {
    return dateTime;
  }
  return DateTime.fromFormat(dateString, API_DATE_FORMAT);
}

export function fromDateTimeFormat(dateString: string) {
  return DateTime.fromFormat(dateString, API_DATETIME_FORMAT);
}

export function fromDateFormat(dateString: string) {
  return DateTime.fromFormat(dateString, API_DATE_FORMAT);
}

// 目的の日付までの日数を返します (return remaining days until certain day)
export function remainingDay(dateString: string): number {
  const to = fromDateTimeFormat(dateString);
  if (!to.isValid) {
    throw new Error("Invalid date " + dateString);
  }
  const diff = to.diffNow("days");
  return diff.days > 0 ? Math.floor(diff.days) : 0;
}

/// SPAD上で使用する標準の期間フォーマットで返します。(return standard duration format in SPAD) e.g) 2019.10.1 ~ 2019.10.2
export function period(from: string, to: string): string {
  return `${formatDate(from)} ~ ${formatDate(to)}`;
}

/// SPAD上で使用する標準の日付フォーマットで返します。 (return standard japanese date format in SPAD) e.g) 2019年10月1日
export function formatLongDate(dateString: string): string {
  return fromDateOrDateTimeFormat(dateString).toFormat("yyyy年MM月dd日");
}

/// SPAD上で使用する標準の日付フォーマットで返します。(return standard date format with dot in SPAD) e.g) 2019.10.1
export function formatDate(dateString: string): string {
  return fromDateOrDateTimeFormat(dateString).toFormat("yyyy.MM.dd");
}

export function formatSlashDate(dateString: string): string {
  return fromDateOrDateTimeFormat(dateString).toFormat("yyyy/MM/dd");
}

export function formatSlashDateTime(dateString: string): string {
  return fromDateOrDateTimeFormat(dateString).toFormat("yyyy/MM/dd HH:mm");
}

export function formatSlashDateTimeSec(dateString: string): string {
  return fromDateOrDateTimeFormat(dateString).toFormat("yyyy/MM/dd HH:mm:ss");
}

export function formatDateTime(dateTimeString: string, lang: string) {
  if (lang === "ja") {
    return fromDateOrDateTimeFormat(dateTimeString).toFormat(
      "yyyy年MM月dd日 HH:mm:ss"
    );
  }
  return fromDateOrDateTimeFormat(dateTimeString).toFormat(
    "yyyy/MM/dd HH:mm:ss"
  );
}

// 過去の時間を相対的に表示します。 e.g) 1時間前, 2日前 (show how long ago it took from the certain time) e.g.) an hour ago, two days ago
export function relativeTime(
  dateTimeString: string
): {
  offset: number;
  unit?: ToRelativeUnit;
} {
  const date = fromDateOrDateTimeFormat(dateTimeString);
  const now = DateTime.local();
  const units: ToRelativeUnit[] = [
    "years",
    "months",
    "days",
    "hours",
    "minutes",
    "seconds"
  ];
  for (let unit of units) {
    const offset = Math.abs(date.diff(now, unit).get(unit));

    if (offset >= 1) {
      return {
        offset: Math.round(offset),
        unit
      };
    }
  }
  return {
    offset: 0
  };
}

/**
 * @param time 現在日時と比較対象の日時 `yyyy-MM-dd HH:mm:ss`
 */
export function greaterThanOrEqualToCurrentDateTime(time: string): boolean {
  const date = fromDateOrDateTimeFormat(time);
  const now = DateTime.local();
  return (
    date.toFormat(API_DATETIME_FORMAT) >= now.toFormat(API_DATETIME_FORMAT)
  );
}

/**
 * @param time 現在日時と比較対象の日時 `yyyy-MM-dd HH:mm:ss`
 */
export function lesserThanCurrentDateTime(time: string): boolean {
  return !greaterThanOrEqualToCurrentDateTime(time);
}

/**
 * @param from `yyyy-MM-dd HH:mm:ss`
 * @param to `yyyy-MM-dd HH:mm:ss`
 */
export function betweenCurrentDateTime(from: string, to: string): boolean {
  const fromDateTime = fromDateOrDateTimeFormat(from).toFormat(
    API_DATETIME_FORMAT
  );
  const toDateTime = fromDateOrDateTimeFormat(to).toFormat(API_DATETIME_FORMAT);
  return (
    lesserThanCurrentDateTime(fromDateTime) &&
    greaterThanOrEqualToCurrentDateTime(toDateTime)
  );
}
