import { actionTree, mutationTree } from "nuxt-typed-vuex";

export interface ToastPayload {
  type: "success" | "warning" | "info" | "error";
  message: String;
  showClose?: Boolean;
}

export interface ToastState {
  isShow: Boolean;
  toastPayload: ToastPayload;
}

export const state = (): ToastState => {
  return {
    isShow: false,
    toastPayload: {
      type: "success",
      message: ""
    }
  };
};

export const mutations = mutationTree(state, {
  set(state, payload: ToastState) {
    state.isShow = payload.isShow;
    state.toastPayload = payload.toastPayload;
  },
  hide(state: ToastState) {
    state.toastPayload = {
      type: "success",
      message: ""
    };
    state.isShow = false;
  }
});

export const actions = actionTree(
  {
    state,
    mutations
  },
  {
    show(context, toastPayload: ToastPayload): void {
      context.commit("set", {
        isShow: true,
        toastPayload
      });
    }
  }
);
