export default {
  title: {
    create_account: "Create User Account"
  },
  label: {
    create_account: "Create"
  },
  message: {
    send_inviting_email:
      "An account setup request will be sent to typed email address."
  }
};
