import { actionTree, mutationTree } from "nuxt-typed-vuex";
import {
  DeleteAgencyAccountMutationRequest,
  DeleteAgencyAccountMutationVariables
} from "~/types/gen/api";
import { resetState } from "~/store/utils";

export const state = () => ({});

export const mutations = mutationTree(state, {
  reset: resetState(state)
});

export const actions = actionTree(
  { state },
  {
    async deleteAgencyAccount(
      _context,
      payload: DeleteAgencyAccountMutationVariables
    ): Promise<void> {
      try {
        this.$accessor.presentation.showLoading(null);
        await this.$apiClient.mutate(
          new DeleteAgencyAccountMutationRequest(payload)
        );
      } catch (e) {
        // TODO エラーメッセージ
      } finally {
        this.$accessor.presentation.dismissLoading();
      }
    }
  }
);
