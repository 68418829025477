import { actionTree, getterTree, mutationTree } from "nuxt-typed-vuex";

export interface PresentationState {
  loadings: LoadingPayload[];
}

export interface LoadingPayload {
  type: LoadingType;
  message: string | null;
}

export type LoadingType = "normal" | "blocking";

export const state = (): PresentationState => {
  return {
    loadings: []
  };
};

export const mutations = mutationTree(state, {
  addLoading(state, payload: LoadingPayload) {
    state.loadings = [...state.loadings, payload];
  },
  removeLoading(state) {
    state.loadings.shift();
  }
});

export const getters = getterTree(state, {
  isLoading(state): boolean {
    return state.loadings.length > 0;
  },
  currentLoading(state): LoadingPayload | null {
    return state.loadings[0] || null;
  }
});

export const actions = actionTree(
  { state, mutations, getters },
  {
    showLoading(context, payload: LoadingPayload | null = null): void {
      context.commit("addLoading", payload || makeDefaultLoading());
    },
    dismissLoading(context): void {
      context.commit("removeLoading");
    }
  }
);

function makeDefaultLoading(): LoadingPayload {
  return {
    type: "normal",
    message: null
  };
}
