export default {
  title: {
    campaign_comments: "Campaign Comments"
  },
  label: {
    pitch: "Pitch",
    order: "Order",
    report: "Report"
  }
};
