import Vue from "vue";

export const FormItem = Vue.extend({
  $_veeValidate: {
    value(): any {
      return this.innerValue;
    }
  },
  name: "FormItem",
  inject: ["$validator"],
  props: {
    name: {
      type: String,
      required: true
    },
    value: {
      type: [String, Boolean],
      default: null
    }
  },
  data: function() {
    return {
      innerValue: null
    };
  },
  computed: {
    error(): boolean {
      return this.$validator.errors.has(this.name);
    }
  },
  watch: {
    value: {
      handler: function(val) {
        this.innerValue = val;
      },
      immediate: true
    }
  }
});

/**
 * Check the component conform to FormItem
 * @param component
 */
export function isFormItem(component: any): component is typeof FormItem {
  return component.$options.name === "FormItem";
}

/**
 * Get child form components
 * @param children
 */
export function getFormItems(children: Vue[]): typeof FormItem[] {
  return children.reduce((res: typeof FormItem[], child) => {
    if (isFormItem(child)) {
      return [...res, child];
    }
    if (child.$children.length > 0) {
      return [...res, ...getFormItems(child.$children)];
    }
    return res;
  }, []);
}
