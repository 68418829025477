














































import Vue from "vue";
import ClientHeader from "~/components/the/header/client-header.vue";
import Sidebar from "~/components/the/sidebar.vue";
import Content from "~/components/the/content.vue";
import ErrorDialog from "~/components/the/error-dialog.vue";
import AppLoading from "~/components/the/app-loading.vue";

export default Vue.extend({
  components: {
    ClientHeader,
    Sidebar,
    Content,
    ErrorDialog,
    AppLoading
  },
  computed: {
    isOwner(): boolean {
      return this.$accessor.clientAccount.isOwner;
    }
  }
});
