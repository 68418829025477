import { actionTree, mutationTree } from "nuxt-typed-vuex";
import {
  UpdateAgencyMutationRequest,
  AgencyQueryVariables,
  AgencyQueryRequest
} from "~/types/gen/api";
import { resetState } from "~/store/utils";

export const state = () => ({
  email: "",
  name: "",
  contact_person: "",
  agencyId: ""
});

export const mutations = mutationTree(state, {
  recieveAgency(state, payload) {
    state.email = payload.ownerAgencyAccount.email;
    state.name = payload.name;
    state.agencyId = payload.id;
    state.contact_person = payload.ownerAgencyAccount.name;
  },
  reset: resetState(state)
});

export const actions = actionTree(
  { state },
  {
    async updateAgency(
      { state },
      { email, name, representative }
    ): Promise<void> {
      if (!state.agencyId) return;
      try {
        this.$accessor.presentation.showLoading(null);
        await this.$apiClient.mutate(
          new UpdateAgencyMutationRequest({
            input: {
              agencyId: state.agencyId,
              name,
              accountName: representative,
              accountEmail: email
            }
          })
        );
        this.$router.replace("/admin/agencies");
      } catch (e) {
        this.$accessor.error.showError(e);
      } finally {
        this.$accessor.presentation.dismissLoading();
      }
    },
    async getAgency(context, payload: AgencyQueryVariables) {
      try {
        this.$accessor.presentation.showLoading(null);
        const req = new AgencyQueryRequest(payload);
        const res = await this.$apiClient.query(req);
        if (res) {
          context.commit("recieveAgency", res.agency);
        }
      } catch (e) {
        this.$accessor.error.showError(e);
      } finally {
        this.$accessor.presentation.dismissLoading();
      }
    }
  }
);
