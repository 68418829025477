import { actionTree, mutationTree, getterTree } from "nuxt-typed-vuex";
import {
  ClientCampaign,
  Suggestion,
  ProposalsQueryRequest,
  ProposalsQueryVariables
} from "~/types/gen/api";
import { resetState } from "~/store/utils";

export type proposalsState = {
  campaign: ClientCampaign | null;
  suggestion: Pick<
    Suggestion,
    "id" | "totalInfluencerCount" | "totalCost" | "createdAt"
  >[];
};

export const state = (): proposalsState => ({
  campaign: null,
  suggestion: []
});

export const mutations = mutationTree(state, {
  recieveAgency(state, payload) {
    state.campaign = payload;
    state.suggestion = payload.agency.suggestions;
  },
  reset: resetState(state)
});

export const getters = getterTree(state, {
  agencyName(state) {
    return (
      state.campaign && state.campaign.agency && state.campaign.agency.name
    );
  },
  campaignName(state) {
    return state.campaign && state.campaign.name;
  }
});

export const actions = actionTree(
  { state },
  {
    async init(context, payload: ProposalsQueryVariables) {
      try {
        this.$accessor.presentation.showLoading(null);
        const req = new ProposalsQueryRequest(payload);
        const res = await this.$apiClient.query(req);
        if (res) {
          context.commit("recieveAgency", res.brand.campaign);
        }
      } catch (e) {
        throw e;
      } finally {
        this.$accessor.presentation.dismissLoading();
      }
    }
  }
);
