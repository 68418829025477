






























import Vue from "vue";
import ClientHeader from "~/components/the/header/client-header.vue";
import Sidebar from "~/components/the/sidebar.vue";
import Content from "~/components/the/content.vue";
import AppLoading from "~/components/the/app-loading.vue";
import ErrorDialog from "~/components/the/error-dialog.vue";
import { Toast } from "./mixins/toast";

export default Vue.extend({
  mixins: [Toast],
  components: {
    ClientHeader,
    Sidebar,
    Content,
    AppLoading,
    ErrorDialog
  },
  computed: {
    brandName(): string {
      return this.$route.params.brand;
    }
  }
});
