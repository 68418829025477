import { actionTree, mutationTree } from "nuxt-typed-vuex";
import {
  UpdateBrandMutationRequest,
  UpdateBrandMutationVariables,
  ClientBrand,
  BrandQueryVariables,
  BrandQueryRequest
} from "~/types/gen/api";
import { resetState } from "~/store/utils";
import { Message } from "element-ui";

export type BrandEditState = {
  brand: ClientBrand | null;
};

export const state = (): BrandEditState => ({
  brand: null
});

export const mutations = mutationTree(state, {
  receiveBrand(state, payload) {
    state.brand = payload;
  },
  reset: resetState(state)
});

export const actions = actionTree(
  {
    state,
    mutations
  },
  {
    async getBrand({ commit }, payload: BrandQueryVariables): Promise<void> {
      try {
        this.$accessor.presentation.showLoading(null);

        const { brand } = await this.$apiClient.query(
          new BrandQueryRequest(payload)
        );
        if (brand) {
          commit("receiveBrand", brand);
        }
      } catch (e) {
        this.$accessor.error.showError(e);
      } finally {
        this.$accessor.presentation.dismissLoading();
      }
    },
    async updateBrand(
      { commit },
      payload: UpdateBrandMutationVariables
    ): Promise<void> {
      try {
        this.$accessor.presentation.showLoading(null);

        const { updateBrand } = await this.$apiClient.mutate(
          new UpdateBrandMutationRequest(payload)
        );
        if (updateBrand) {
          commit("receiveBrand", updateBrand.brand);
          Message(this.$i18n.tc("page.brands_edit.message.success_edit"));
        }
      } catch (e) {
        this.$accessor.error.showError(e);
      } finally {
        this.$accessor.presentation.dismissLoading();
      }
    }
  }
);
