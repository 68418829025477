import gql from "graphql-tag";
import { QueryOptions, MutationOptions } from "apollo-client";
export type Maybe<T> = T | null;
export interface QueryRequest<TResult, TVariable> {
  _result?: TResult;
  options: QueryOptions<TVariable>;
}
export interface MutationRequest<TResult, TVariable> {
  options: MutationOptions<TResult, TVariable>;
}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A datetime string with format `Y-m-d H:i:s`, e.g. `2018-05-23 13:43:32`. */
  DateTime: any;
  /** A uri string in RFC3986 format, e.g. `https:://fisminc.com`. */
  URI: any;
  /** A date string with format `Y-m-d`, e.g. `2011-05-23`. */
  Date: any;
  /** A file upload format */
  Upload: any;
  /** A datetime and timezone string in ISO 8601 format `Y-m-dTH:i:sO`, e.g. `2020-04-20T13:53:12+02:00`. */
  DateTimeTz: any;
};

export type Account = {
  number: Scalars["Int"];
  /** 名前 */
  name?: Maybe<Scalars["String"]>;
  /** メールアドレス */
  email?: Maybe<Scalars["String"]>;
  /** オーナーアカウントかどうか */
  isOwner: Scalars["Boolean"];
  createdAt: Scalars["DateTime"];
  updatedAt: Scalars["DateTime"];
};

export type AddAgenciesToCampaignInput = {
  clientMutationId?: Maybe<Scalars["String"]>;
  campaignId: Scalars["ID"];
  agencyIds: Array<Scalars["ID"]>;
};

export type AddAgenciesToCampaignPayload = {
  __typename?: "AddAgenciesToCampaignPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  campaign: ClientCampaign;
};

export type AddAgencyToCampaignInput = {
  clientMutationId?: Maybe<Scalars["String"]>;
  campaignId: Scalars["ID"];
  agencyId: Scalars["ID"];
};

export type AddAgencyToCampaignPayload = {
  __typename?: "AddAgencyToCampaignPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  campaign: ClientCampaign;
};

export type AddConsiderationInfluencersToConfirmInfluencersInput = {
  clientMutationId?: Maybe<Scalars["String"]>;
  assignmentId: Scalars["ID"];
  /**
   * 発注一覧を表示した時間
   * 最終確認検討リストを取得して、最終確認リストへ移行をさせる前に代理店がファイルをアップロードをしていないかの確認のため
   **/
  assignmentQueryAcquiredAt: Scalars["DateTime"];
};

export type AddConsiderationInfluencersToConfirmInfluencersPayload = {
  __typename?: "AddConsiderationInfluencersToConfirmInfluencersPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  assignment: ClientAssignment;
  /**
   * 最終確認検討リストを取得してから、最終確認リストへ移行をさせる前までに代理店がファイルをアップロードをしてデータが更新をされたかどうか
   * true: 変更ある場合
   * false: 変更なしの場合
   **/
  areConsiderationInfluencersUpdated: Scalars["Boolean"];
};

export type AddInfluencerFromConsiderationInput = {
  clientMutationId?: Maybe<Scalars["String"]>;
  suggestionInfluencerId: Scalars["ID"];
};

export type AddInfluencerFromConsiderationPayload = {
  __typename?: "AddInfluencerFromConsiderationPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  suggestion: Suggestion;
};

export type AdditionalAssignInfluencerConsiderationInput = {
  clientMutationId?: Maybe<Scalars["String"]>;
  assignmentId: Scalars["ID"];
  suggestionInfluencerId: Scalars["ID"];
};

export type AdditionalAssignInfluencerConsiderationPayload = {
  __typename?: "AdditionalAssignInfluencerConsiderationPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  agency: CampaignAgency;
};

export type AdditionalAssignInfluencersToCampaignInput = {
  clientMutationId?: Maybe<Scalars["String"]>;
  assignmentId: Scalars["ID"];
  suggestionId: Scalars["ID"];
};

export type AdditionalAssignInfluencersToCampaignPayload = {
  __typename?: "AdditionalAssignInfluencersToCampaignPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  campaign: ClientAssignment;
};

export type AffinityModel = Node & {
  __typename?: "AffinityModel";
  id: Scalars["ID"];
  number: Scalars["Int"];
  /** アフィニティモデル作成日と作成日ごとにincrementされた数値を加えたname(YYMMDD表記) e.g.210228_1,21022802 */
  name: Scalars["String"];
  description?: Maybe<Scalars["String"]>;
  createdAt: Scalars["DateTime"];
  snapInfluencerEvaluationGroup: SnapInfluencerEvaluationGroup;
  coreModel: CoreModel;
  usedCampaigns?: Maybe<Array<Maybe<UsedCampaign>>>;
  scoredInfluencers?: Maybe<ScoredInfluencerConnection>;
};

export type AffinityModelScoredInfluencersArgs = {
  perPage?: Maybe<Scalars["Int"]>;
  page: Scalars["Int"];
  orderBy?: Maybe<ScoredInfluencerOrderByClause>;
};

export type AffinityModelConnection = Connection & {
  __typename?: "AffinityModelConnection";
  paginatorInfo?: Maybe<PaginatorInfo>;
  edges?: Maybe<Array<Maybe<AffinityModelEdge>>>;
};

export type AffinityModelEdge = Edge & {
  __typename?: "AffinityModelEdge";
  cursor: Scalars["String"];
  node: AffinityModelNode;
};

export type AffinityModelNode = Node & {
  __typename?: "AffinityModelNode";
  id: Scalars["ID"];
  affinityModel: AffinityModel;
};

export type Agency = {
  /** 代理店名 */
  name: Scalars["String"];
};

export type AgencyAccount = Account &
  Node & {
    __typename?: "AgencyAccount";
    id: Scalars["ID"];
    agencyId: Scalars["ID"];
    agencyName?: Maybe<Scalars["String"]>;
    number: Scalars["Int"];
    /** 名前 */
    name: Scalars["String"];
    /** メールアドレス */
    email?: Maybe<Scalars["String"]>;
    /** オーナーアカウントかどうか */
    isOwner: Scalars["Boolean"];
    /** アサインブランドリスト */
    brands: Array<AgencyBrand>;
    /** アサインブランド */
    brand: AgencyBrand;
    createdAt: Scalars["DateTime"];
    updatedAt: Scalars["DateTime"];
  };

export type AgencyAccountBrandArgs = {
  name: Scalars["String"];
};

export type AgencyAccountInfo = Account &
  Node & {
    __typename?: "AgencyAccountInfo";
    id: Scalars["ID"];
    number: Scalars["Int"];
    /** 名前 */
    name?: Maybe<Scalars["String"]>;
    /** メールアドレス */
    email?: Maybe<Scalars["String"]>;
    /** オーナーアカウントかどうか */
    isOwner: Scalars["Boolean"];
    createdAt: Scalars["DateTime"];
    updatedAt: Scalars["DateTime"];
  };

export type AgencyAssignment = Node & {
  __typename?: "AgencyAssignment";
  id: Scalars["ID"];
  /** 発注ステータス */
  status: AssignmentStatus;
  /** 備考 */
  note?: Maybe<Scalars["String"]>;
  /** 添付ファイル */
  attachmentFiles: Array<AttachmentFile>;
  /** キャンペーン */
  campaign: CampaignInfo;
  /** 代理店 */
  agency: AgencyInfo;
  createdAt: Scalars["DateTime"];
  updatedAt: Scalars["DateTime"];
  assignmentInfluencers?: Maybe<AssignmentInfluencerConnection>;
};

export type AgencyAssignmentAssignmentInfluencersArgs = {
  perPage?: Maybe<Scalars["Int"]>;
  page: Scalars["Int"];
};

export type AgencyBrand = Brand &
  Node & {
    __typename?: "AgencyBrand";
    id: Scalars["ID"];
    name: Scalars["String"];
    image: Scalars["URI"];
    divisionName?: Maybe<Scalars["String"]>;
    campaigns?: Maybe<Array<Maybe<AgencyCampaign>>>;
    campaign?: Maybe<AgencyCampaign>;
  };

export type AgencyBrandCampaignArgs = {
  number: Scalars["Int"];
};

export type AgencyCampaign = Campaign &
  Node & {
    __typename?: "AgencyCampaign";
    id: Scalars["ID"];
    number: Scalars["Int"];
    /** キャンペーン名 */
    name: Scalars["String"];
    /** Campaign全体の予算 */
    budget: Scalars["Int"];
    /** 目標指標 */
    indicator: CampaignIndicator;
    /** キャンペーン商品 */
    productItem: ProductItem;
    objectives: Array<ObjectiveType>;
    /** ブランド */
    brandId: Scalars["ID"];
    /** 発注 */
    assignment?: Maybe<AgencyAssignment>;
    /** 備考（クライアントから代理店への共有事項） */
    note?: Maybe<Scalars["String"]>;
    /** 発注をしていない代理店の落選が完了していて、提案フェーズが終了しているかどうか */
    biddingClosed: Scalars["Boolean"];
    /** 投稿開始日 */
    postStartAt: Scalars["DateTime"];
    /** 投稿終了日 */
    postEndAt: Scalars["DateTime"];
    /** 提案開始日時 */
    suggestionStartAt: Scalars["DateTime"];
    /** 提案締め切り日時 */
    suggestionEndAt: Scalars["DateTime"];
    /** 提案の当落状況 */
    suggestionStatus: SuggestionStatus;
    /** レポート */
    report?: Maybe<AgencyReport>;
    /**
     * 提案リスト
     * 追加発注の際に、代理店から採用された提案より後の提案リストを取得したい場合はadditionalSuggestionOnlyをtrueにする
     **/
    suggestions?: Maybe<Array<Maybe<Suggestion>>>;
    /** 添付ファイル */
    attachmentFiles: Array<AttachmentFile>;
    createdAt: Scalars["DateTime"];
    updatedAt: Scalars["DateTime"];
  };

export type AgencyCampaignSuggestionsArgs = {
  additionalSuggestionOnly?: Maybe<Scalars["Boolean"]>;
};

export type AgencyCampaignComment = {
  __typename?: "AgencyCampaignComment";
  campaignId: Scalars["ID"];
  suggestionComments: BiddingComment;
  assignmentComments: BiddingComment;
  reportComments: BiddingComment;
};

export type AgencyChangePasswordInput = {
  clientMutationId?: Maybe<Scalars["String"]>;
  password: Scalars["String"];
  email: Scalars["String"];
  token: Scalars["String"];
};

export type AgencyChangePasswordPayload = {
  __typename?: "AgencyChangePasswordPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type AgencyChangePasswordRequestInput = {
  clientMutationId?: Maybe<Scalars["String"]>;
  email: Scalars["String"];
};

export type AgencyChangePasswordRequestPayload = {
  __typename?: "AgencyChangePasswordRequestPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  requested: Scalars["Boolean"];
};

export type AgencyCredentialPayload = {
  __typename?: "AgencyCredentialPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  token: Scalars["String"];
};

export type AgencyInfo = Agency &
  Node & {
    __typename?: "AgencyInfo";
    id: Scalars["ID"];
    name: Scalars["String"];
  };

export type AgencyLoginInput = {
  clientMutationId?: Maybe<Scalars["String"]>;
  email: Scalars["String"];
  password: Scalars["String"];
};

export type AgencyReport = Report & {
  __typename?: "AgencyReport";
  campaignId: Scalars["ID"];
  /** 添付ファイル */
  attachmentFiles: Array<AttachmentFile>;
  /** レポートインフルエンサーリスト */
  influencers?: Maybe<ReportInfluencerConnection>;
  /** レポートインフルエンサー */
  influencer?: Maybe<ReportInfluencer>;
};

export type AgencyReportInfluencersArgs = {
  perPage?: Maybe<Scalars["Int"]>;
  page: Scalars["Int"];
};

export type AgencyReportInfluencerArgs = {
  influencerId: Scalars["ID"];
};

export type ApproveConfirmedInfluencersInput = {
  clientMutationId?: Maybe<Scalars["String"]>;
  assignmentId: Scalars["ID"];
};

export type ApproveConfirmedInfluencersPayload = {
  __typename?: "ApproveConfirmedInfluencersPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  assignment: ClientAssignment;
};

export type ApproveRequestAvailabilityApprovalInput = {
  clientMutationId?: Maybe<Scalars["String"]>;
  campaignId: Scalars["ID"];
  agencyId: Scalars["ID"];
};

export type ApproveRequestAvailabilityApprovalPayload = {
  __typename?: "ApproveRequestAvailabilityApprovalPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  assignment: ClientAssignment;
};

export type AssignInfluencersToCampaignInput = {
  clientMutationId?: Maybe<Scalars["String"]>;
  suggestionId: Scalars["ID"];
};

export type AssignInfluencersToCampaignPayload = {
  __typename?: "AssignInfluencersToCampaignPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  campaign: ClientCampaign;
};

export type Assignment = {
  /** 発注ステータス */
  status: AssignmentStatus;
  /** キャンペーン */
  campaign: Campaign;
  /** 代理店 */
  agency: AgencyInfo;
  createdAt: Scalars["DateTime"];
  updatedAt: Scalars["DateTime"];
};

export type AssignmentFileUpload = {
  id?: Maybe<Scalars["ID"]>;
  attachmentFile?: Maybe<Scalars["Upload"]>;
};

export type AssignmentInfluencer = BaseInfluencer &
  Node & {
    __typename?: "AssignmentInfluencer";
    id: Scalars["ID"];
    number: Scalars["Int"];
    score?: Maybe<Scalars["Float"]>;
    cost: Scalars["Int"];
    /** インフルエンサー名 */
    name: Scalars["String"];
    /** インフルエンサー画像 */
    image?: Maybe<Scalars["URI"]>;
    /** 各メディアの合計費用 */
    totalCost: Scalars["Int"];
    /** 合計コミッション */
    totalCommission?: Maybe<Scalars["Int"]>;
    /** 各メディアの合計フォロワー数 */
    totalFollowerCount?: Maybe<Scalars["Int"]>;
    /** 各メディアの合計フォロー数 */
    totalFollowCount?: Maybe<Scalars["Int"]>;
    /** 各メディアの合計投稿数 */
    totalPostCount?: Maybe<Scalars["Int"]>;
    /** 各メディアの平均フォロワー上昇率 */
    averageIncreaseRate?: Maybe<Scalars["Float"]>;
    /** 最高ビュー数 */
    maxViewCount?: Maybe<Scalars["Int"]>;
    /** 各メディアの合計平均いいね数 */
    totalLikeCount?: Maybe<Scalars["Float"]>;
    /** 各メディアの合計平均コメント数 */
    totalCommentCount?: Maybe<Scalars["Float"]>;
    /** 各メディアの平均エンゲージメント率 */
    averageEngagement?: Maybe<Scalars["Float"]>;
    /** 各メディアの平均アフィニティスコア */
    averageAffinityScore?: Maybe<Scalars["Float"]>;
    /** 各メディアの平均アフィニティレート */
    averageAffinityRate?: Maybe<Scalars["Float"]>;
    /** 各メディアの平均アドボケイトスコア */
    averageAdvocateScore?: Maybe<Scalars["Float"]>;
    /** SNS情報 */
    socialMedia?: Maybe<SocialMedia>;
    /** メディアごとのインサイト・提案状況 */
    insightsBySocialMedia: Array<AssignmentInfluencerInsightBySocialMedia>;
    /** 提案 */
    suggestion: Suggestion;
    /** 可否 */
    availabilityStatus: AvailabilityStatus;
    /** 最終確認検討中かどうか */
    considerationStatus: AssignmentInfluencerConsiderationStatus;
    /** 最終確認リストに入っているか */
    isClientConfirmed: Scalars["Boolean"];
    /** 過去の同インフルエンサーに対しての発注の履歴 */
    histories: Array<AssignmentInfluencerHistory>;
    note?: Maybe<Scalars["String"]>;
    averageViewCount?: Maybe<Scalars["Float"]>;
    averageLikeCount?: Maybe<Scalars["Float"]>;
    averageCommentCount?: Maybe<Scalars["Float"]>;
    valueCheckStatus: ValueCheckStatus;
    valueCheckDate?: Maybe<Scalars["String"]>;
    /** アカウント存在チェックステータス */
    checkAccountStatus: CheckAccountStatus;
  };

export type AssignmentInfluencerConnection = Connection & {
  __typename?: "AssignmentInfluencerConnection";
  paginatorInfo?: Maybe<PaginatorInfo>;
  edges?: Maybe<Array<Maybe<AssignmentInfluencerEdge>>>;
};

/** client_assignment.assignment_influencers_by_agency.filter_by.assignment_influencer_filter */
export enum AssignmentInfluencerConsiderationStatus {
  Considered = "CONSIDERED",
  NotConsidered = "NOT_CONSIDERED"
}

export type AssignmentInfluencerEdge = Edge & {
  __typename?: "AssignmentInfluencerEdge";
  cursor: Scalars["String"];
  node: AssignmentInfluencerNode;
};

export type AssignmentInfluencerFilter = {
  /** 可否状況ステータス、配列で指定 */
  availabilityStatus?: Maybe<Array<Maybe<AvailabilityStatus>>>;
  /** 最終確認検討中かどうか */
  considerationStatus?: Maybe<AssignmentInfluencerConsiderationStatus>;
  /** 最終確認リストに入っているか */
  isClientConfirmed?: Maybe<Scalars["Boolean"]>;
};

export type AssignmentInfluencerHistory = Node & {
  __typename?: "AssignmentInfluencerHistory";
  id: Scalars["ID"];
  /** 各メディアの合計費用 */
  totalCost: Scalars["Int"];
  /** メディアごとのインサイト・提案状況 */
  insightsBySocialMedia: Array<AssignmentInfluencerInsightBySocialMediaHistory>;
};

export type AssignmentInfluencerInsightBySocialMedia = InfluencerInsightBySocialMedia &
  Node & {
    __typename?: "AssignmentInfluencerInsightBySocialMedia";
    id: Scalars["ID"];
    /** SNS種別 */
    socialMediaType: SocialMediaType;
    /** ユーザーをInstagram上で検索する時に使い、各ユーザーの詳細ページのURLに文字列として含まれているID e.g.)anriworld */
    accountId: Scalars["ID"];
    /** アカウントのURL */
    accountUrl: Scalars["URI"];
    /** メディアごとの費用 */
    cost: Scalars["Int"];
    /** メディアごとのコミッション */
    commission?: Maybe<Scalars["Int"]>;
    /** アフィニティレート */
    affinityRate?: Maybe<Scalars["Float"]>;
    /** アドボケイトスコア */
    advocateScore?: Maybe<Scalars["Float"]>;
    /** 各メディアのアカウント情報（フォロワー数、平均いいね数等）、アフィニティレートをすべて取得完了をしているか、完了している場合はtrue */
    isDataCollectionCompleted: Scalars["Boolean"];
    /** 各メディア上で取得をできる情報 */
    socialMediaInsight?: Maybe<SocialMediaInsight>;
    /** ? */
    affinityUpperThreshold?: Maybe<Scalars["Int"]>;
    /** ? */
    affinityLowerThreshold?: Maybe<Scalars["Int"]>;
    /** アカウント存在チェックステータス */
    checkAccountStatus: CheckAccountStatus;
  };

export type AssignmentInfluencerInsightBySocialMediaHistory = Node & {
  __typename?: "AssignmentInfluencerInsightBySocialMediaHistory";
  id: Scalars["ID"];
  /** SNS種別 */
  socialMediaType: SocialMediaType;
  /** ユーザーをInstagram上で検索する時に使い、各ユーザーの詳細ページのURLに文字列として含まれているID e.g.)anriworld */
  accountId: Scalars["ID"];
  /** メディアごとの費用 */
  cost: Scalars["Int"];
};

export type AssignmentInfluencerNode = Node & {
  __typename?: "AssignmentInfluencerNode";
  id: Scalars["ID"];
  assignmentInfluencer: AssignmentInfluencer;
};

export type AssignmentInfluencerOrderByClause = {
  /** The column that is used for ordering. */
  field: AssignmentInfluencerOrderByColumn;
  /** SNS種別 */
  mediaType?: Maybe<SocialMediaType>;
  /** The direction that is used for ordering. */
  order: SortOrder;
};

/** assignment_influencer_order_by_column */
export enum AssignmentInfluencerOrderByColumn {
  FollowerCount = "FOLLOWER_COUNT",
  IncreaseRate = "INCREASE_RATE",
  Score = "SCORE",
  Cost = "COST",
  AverageLikeCount = "AVERAGE_LIKE_COUNT",
  AverageCommentCount = "AVERAGE_COMMENT_COUNT",
  Engagement = "ENGAGEMENT",
  AffinityScore = "AFFINITY_SCORE",
  AffinityRate = "AFFINITY_RATE",
  AdvocateScore = "ADVOCATE_SCORE",
  ViewCount = "VIEW_COUNT",
  MaxViewCount = "MAX_VIEW_COUNT"
}

export type AssignmentInstagramSummary = SummaryInfluencerScore & {
  __typename?: "AssignmentInstagramSummary";
  /** SNS種別 */
  socialMediaType: SocialMediaType;
  /** 合計コスト */
  totalCost: Scalars["Int"];
  /** 合計コミッション */
  totalCommission?: Maybe<Scalars["Int"]>;
  /** 合計フォロワー数 */
  totalFollower?: Maybe<Scalars["Int"]>;
  /** 合計インフルエンサー数 */
  totalInfluencerCount: Scalars["Int"];
  /** 合計フォロー数 */
  totalFollow?: Maybe<Scalars["Int"]>;
  /** 平均エンゲージメント率 */
  averageEngagementRate?: Maybe<Scalars["Float"]>;
  /** 平均アフィニティスコア */
  averageAffinityScoreRate?: Maybe<Scalars["Float"]>;
  /** 平均アフィニティレート */
  averageAffinityRate?: Maybe<Scalars["Float"]>;
  /** 平均アドボケイトスコア */
  averageAdvocateScoreRate?: Maybe<Scalars["Float"]>;
};

/** assignment_influencer.assignment_status */
export enum AssignmentStatus {
  CheckingAvailability = "CHECKING_AVAILABILITY",
  CheckedAvailability = "CHECKED_AVAILABILITY",
  Approval = "APPROVAL",
  Reject = "REJECT",
  Completed = "COMPLETED"
}

export type AssignmentTiktokSummary = SummaryInfluencerScore & {
  __typename?: "AssignmentTiktokSummary";
  /** SNS種別 */
  socialMediaType: SocialMediaType;
  /** 合計コスト */
  totalCost: Scalars["Int"];
  /** 合計コミッション */
  totalCommission?: Maybe<Scalars["Int"]>;
  /** 合計フォロワー数 */
  totalFollower?: Maybe<Scalars["Int"]>;
  /** 合計インフルエンサー数 */
  totalInfluencerCount: Scalars["Int"];
  /** 合計フォロー数 */
  totalFollow?: Maybe<Scalars["Int"]>;
  /** 平均エンゲージメント率 */
  averageEngagementRate?: Maybe<Scalars["Float"]>;
  /** 平均アフィニティスコア */
  averageAffinityScoreRate?: Maybe<Scalars["Float"]>;
  /** 平均アフィニティレート */
  averageAffinityRate?: Maybe<Scalars["Float"]>;
  /** 平均アドボケイトスコア */
  averageAdvocateScoreRate?: Maybe<Scalars["Float"]>;
};

export type AssignmentTwitterSummary = SummaryInfluencerScore & {
  __typename?: "AssignmentTwitterSummary";
  /** SNS種別 */
  socialMediaType: SocialMediaType;
  /** 合計コスト */
  totalCost: Scalars["Int"];
  /** 合計コミッション */
  totalCommission?: Maybe<Scalars["Int"]>;
  /** 合計フォロワー数 */
  totalFollower?: Maybe<Scalars["Int"]>;
  /** 合計インフルエンサー数 */
  totalInfluencerCount: Scalars["Int"];
  /** 合計フォロー数 */
  totalFollow?: Maybe<Scalars["Int"]>;
  /** 平均エンゲージメント率 */
  averageEngagementRate?: Maybe<Scalars["Float"]>;
  /** 平均アフィニティスコア */
  averageAffinityScoreRate?: Maybe<Scalars["Float"]>;
  /** 平均アフィニティレート */
  averageAffinityRate?: Maybe<Scalars["Float"]>;
  /** 平均アドボケイトスコア */
  averageAdvocateScoreRate?: Maybe<Scalars["Float"]>;
};

export type AssignmentWinningSummary = SummaryInfluencerScore & {
  __typename?: "AssignmentWinningSummary";
  /** 合計コスト */
  totalCost: Scalars["Int"];
  /** 合計コミッション */
  totalCommission?: Maybe<Scalars["Int"]>;
  /** 合計フォロワー数 */
  totalFollower?: Maybe<Scalars["Int"]>;
  /** 合計インフルエンサー数 */
  totalInfluencerCount: Scalars["Int"];
  /** 合計フォロー数 */
  totalFollow?: Maybe<Scalars["Int"]>;
  /** 平均エンゲージメント率 */
  averageEngagementRate?: Maybe<Scalars["Float"]>;
  /** 平均アフィニティレート */
  averageAffinityRate?: Maybe<Scalars["Float"]>;
  /** 平均アドボケイトスコア */
  averageAdvocateScoreRate?: Maybe<Scalars["Float"]>;
};

export type AssignmentYoutubeSummary = SummaryInfluencerScore & {
  __typename?: "AssignmentYoutubeSummary";
  /** SNS種別 */
  socialMediaType: SocialMediaType;
  /** 合計コスト */
  totalCost: Scalars["Int"];
  /** 合計コミッション */
  totalCommission?: Maybe<Scalars["Int"]>;
  /** 合計フォロワー数 */
  totalFollower?: Maybe<Scalars["Int"]>;
  /** 合計インフルエンサー数 */
  totalInfluencerCount: Scalars["Int"];
  /** 合計フォロー数 */
  totalFollow?: Maybe<Scalars["Int"]>;
  /** 平均エンゲージメント率 */
  averageEngagementRate?: Maybe<Scalars["Float"]>;
  /** 平均アフィニティスコア */
  averageAffinityScoreRate?: Maybe<Scalars["Float"]>;
  /** 平均アフィニティレート */
  averageAffinityRate?: Maybe<Scalars["Float"]>;
  /** 平均アドボケイトスコア */
  averageAdvocateScoreRate?: Maybe<Scalars["Float"]>;
};

export type AttachmentFile = {
  __typename?: "AttachmentFile";
  id?: Maybe<Scalars["ID"]>;
  name: Scalars["String"];
  url: Scalars["String"];
};

/** assignment_influencer.availability_status */
export enum AvailabilityStatus {
  Ok = "OK",
  Ng = "NG",
  Confirm = "CONFIRM"
}

export type BaseInfluencer = {
  name: Scalars["String"];
  image?: Maybe<Scalars["URI"]>;
};

export type BiddingComment = {
  __typename?: "BiddingComment";
  unreadCommentCount: Scalars["Int"];
  comments: Array<Comment>;
  draftComment?: Maybe<Comment>;
};

export type Brand = {
  id: Scalars["ID"];
  name: Scalars["String"];
  image: Scalars["URI"];
  divisionName?: Maybe<Scalars["String"]>;
  campaigns?: Maybe<Array<Maybe<Campaign>>>;
  campaign?: Maybe<Campaign>;
};

export type BrandCampaignArgs = {
  number: Scalars["Int"];
};

export type Campaign = {
  id: Scalars["ID"];
  number: Scalars["Int"];
  name: Scalars["String"];
  /** Campaign全体の予算 */
  budget: Scalars["Int"];
  /** ブランド */
  brandId: Scalars["ID"];
  /** 発注をしていない代理店の落選が完了していて、提案フェーズが終了しているかどうか */
  biddingClosed: Scalars["Boolean"];
  /** 投稿開始日 */
  postStartAt: Scalars["DateTime"];
  /** 投稿終了日 */
  postEndAt: Scalars["DateTime"];
  /** 提案開始日時 */
  suggestionStartAt: Scalars["DateTime"];
  /** 提案締め切り日時 */
  suggestionEndAt: Scalars["DateTime"];
  /** 添付ファイル */
  attachmentFiles: Array<AttachmentFile>;
  createdAt: Scalars["DateTime"];
  updatedAt: Scalars["DateTime"];
};

/** campaign_activity_type */
export enum CampaignActivityType {
  SnsPostOnly = "SNS_POST_ONLY",
  Event = "EVENT",
  Gifting = "GIFTING",
  Live = "LIVE"
}

export type CampaignAgency = Agency &
  Node & {
    __typename?: "CampaignAgency";
    id: Scalars["ID"];
    campaignId: Scalars["ID"];
    name: Scalars["String"];
    totalSuggestionCount: Scalars["Int"];
    lastSuggestionAt?: Maybe<Scalars["DateTime"]>;
    /**
     * 提案リスト
     * 追加発注の際に、採用された提案より後の提案リストを取得したい場合はadditionalSuggestionOnlyをtrueにする
     **/
    suggestions?: Maybe<Array<Maybe<Suggestion>>>;
    suggestion?: Maybe<Suggestion>;
    assignment?: Maybe<ClientAssignment>;
    /** 提案の当落状況 */
    suggestionStatus: SuggestionStatus;
  };

export type CampaignAgencySuggestionsArgs = {
  additionalSuggestionOnly?: Maybe<Scalars["Boolean"]>;
};

export type CampaignAgencySuggestionArgs = {
  number: Scalars["Int"];
  considerationOnly?: Maybe<Scalars["Boolean"]>;
  excludeAssignment?: Maybe<Scalars["Boolean"]>;
  additionalConsiderationOnly?: Maybe<Scalars["Boolean"]>;
};

export type CampaignAgencyAssignmentArgs = {
  filterBy?: Maybe<AssignmentInfluencerFilter>;
  includeAdditionalConsiderationSummary?: Maybe<Scalars["Boolean"]>;
  suggestionNumber?: Maybe<Scalars["Int"]>;
};

export type CampaignFileUpload = {
  id?: Maybe<Scalars["ID"]>;
  attachmentFile?: Maybe<Scalars["Upload"]>;
};

export type CampaignIndicator = Node & {
  __typename?: "CampaignIndicator";
  campaignId: Scalars["ID"];
  budget: Scalars["Int"];
  reachKpi: Scalars["Int"];
  instagramIndicator?: Maybe<CampaignInstagramIndicator>;
  twitterIndicator?: Maybe<CampaignTwitterIndicator>;
  youtubeIndicator?: Maybe<CampaignYoutubeIndicator>;
  tiktokIndicator?: Maybe<CampaignTiktokIndicator>;
  id: Scalars["ID"];
};

export type CampaignInfo = Campaign &
  Node & {
    __typename?: "CampaignInfo";
    id: Scalars["ID"];
    number: Scalars["Int"];
    /** キャンペーン名 */
    name: Scalars["String"];
    /** Campaign全体の予算 */
    budget: Scalars["Int"];
    /** キャンペーン商品 */
    productItem: ProductItem;
    /** ブランド */
    brandId: Scalars["ID"];
    /** 発注をしていない代理店の落選が完了していて、提案フェーズが終了しているかどうか */
    biddingClosed: Scalars["Boolean"];
    /** 投稿開始日 */
    postStartAt: Scalars["DateTime"];
    /** 投稿終了日 */
    postEndAt: Scalars["DateTime"];
    /** 提案開始日時 */
    suggestionStartAt: Scalars["DateTime"];
    /** 提案締め切り日時 */
    suggestionEndAt: Scalars["DateTime"];
    objectives: Array<ObjectiveType>;
    /** 添付ファイル */
    attachmentFiles: Array<AttachmentFile>;
    createdAt: Scalars["DateTime"];
    updatedAt: Scalars["DateTime"];
  };

export type CampaignInstagramIndicator = CampaignSocialMediaIndicator &
  Node & {
    __typename?: "CampaignInstagramIndicator";
    id: Scalars["ID"];
    budget?: Maybe<Scalars["Int"]>;
    reachKpi: Scalars["Int"];
    sales?: Maybe<Scalars["Int"]>;
    ecClick?: Maybe<Scalars["Int"]>;
  };

export type CampaignReportRequestInput = {
  divisionName?: Maybe<Scalars["String"]>;
  brandName?: Maybe<Scalars["String"]>;
  campaignName?: Maybe<Scalars["String"]>;
  agencyName?: Maybe<Scalars["String"]>;
  categoryName?: Maybe<Scalars["String"]>;
  snsName?: Maybe<Scalars["String"]>;
  postStartAt?: Maybe<Scalars["String"]>;
  postEndAt?: Maybe<Scalars["String"]>;
};

export type CampaignReportRequestPayload = {
  __typename?: "CampaignReportRequestPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  /** ダウンロードリンク */
  response: Scalars["Boolean"];
};

export type CampaignSocialMediaIndicator = {
  id: Scalars["ID"];
  budget?: Maybe<Scalars["Int"]>;
  reachKpi: Scalars["Int"];
  sales?: Maybe<Scalars["Int"]>;
  ecClick?: Maybe<Scalars["Int"]>;
};

export type CampaignTiktokIndicator = CampaignSocialMediaIndicator &
  Node & {
    __typename?: "CampaignTiktokIndicator";
    id: Scalars["ID"];
    budget?: Maybe<Scalars["Int"]>;
    reachKpi: Scalars["Int"];
    sales?: Maybe<Scalars["Int"]>;
    ecClick?: Maybe<Scalars["Int"]>;
  };

export type CampaignTwitterIndicator = CampaignSocialMediaIndicator &
  Node & {
    __typename?: "CampaignTwitterIndicator";
    id: Scalars["ID"];
    budget?: Maybe<Scalars["Int"]>;
    reachKpi: Scalars["Int"];
    sales?: Maybe<Scalars["Int"]>;
    ecClick?: Maybe<Scalars["Int"]>;
  };

export type CampaignYoutubeIndicator = CampaignSocialMediaIndicator &
  Node & {
    __typename?: "CampaignYoutubeIndicator";
    id: Scalars["ID"];
    budget?: Maybe<Scalars["Int"]>;
    reachKpi: Scalars["Int"];
    sales?: Maybe<Scalars["Int"]>;
    ecClick?: Maybe<Scalars["Int"]>;
  };

/** check_account_status */
export enum CheckAccountStatus {
  Checking = "CHECKING",
  Checked = "CHECKED",
  NotExist = "NOT_EXIST"
}

export type Client = Node & {
  __typename?: "Client";
  id: Scalars["ID"];
  /** クライアント名 */
  name: Scalars["String"];
  createdAt: Scalars["DateTime"];
  updatedAt: Scalars["DateTime"];
};

export type ClientAccount = Account &
  Node & {
    __typename?: "ClientAccount";
    id: Scalars["ID"];
    number: Scalars["Int"];
    name: Scalars["String"];
    email: Scalars["String"];
    isOwner: Scalars["Boolean"];
    brands: Array<ClientBrand>;
    brand: ClientBrand;
    createdAt: Scalars["DateTime"];
    updatedAt: Scalars["DateTime"];
  };

export type ClientAccountBrandArgs = {
  name: Scalars["String"];
};

export type ClientAgency = Agency &
  Node & {
    __typename?: "ClientAgency";
    id: Scalars["ID"];
    name: Scalars["String"];
    ownerAgencyAccount: AgencyAccountInfo;
    agencyAccounts?: Maybe<Array<Maybe<AgencyAccountInfo>>>;
    isLocked: Scalars["Boolean"];
  };

export type ClientAssignment = Assignment &
  SummaryInfluencerScore &
  SummaryConsiderationInfluencer &
  SummaryAssignmentInfluencer &
  Node & {
    __typename?: "ClientAssignment";
    id: Scalars["ID"];
    number: Scalars["Int"];
    /** 全インフルエンサーのアカウント情報、アフィニティレートをすべて取得完了をしているか */
    isDataCollectionCompleted: Scalars["Boolean"];
    /** 合計コスト */
    totalCost: Scalars["Int"];
    /** 合計コミッション */
    totalCommission: Scalars["Int"];
    /** 合計インフルエンサー数 */
    totalInfluencerCount: Scalars["Int"];
    /** 合計フォロワー数 */
    totalFollower?: Maybe<Scalars["Int"]>;
    /** 合計フォロー数 */
    totalFollow?: Maybe<Scalars["Int"]>;
    /** 平均エンゲージメント率 */
    averageEngagementRate?: Maybe<Scalars["Float"]>;
    /** 平均スコア率 */
    averageScoreRate?: Maybe<Scalars["Float"]>;
    /** 平均アフィニティスコア */
    averageAffinityScoreRate?: Maybe<Scalars["Float"]>;
    /** 平均アフィニティレート */
    averageAffinityRate?: Maybe<Scalars["Float"]>;
    /** 平均アドボケイトスコア */
    averageAdvocateScoreRate?: Maybe<Scalars["Float"]>;
    /** 合計検討中インフルエンサー数 */
    totalConsiderationInfluencerCount: Scalars["Int"];
    /** 合計可否OKインフルエンサー数 */
    totalAvailabilityOKInfluencerCount: Scalars["Int"];
    /** 合計可否NGインフルエンサー数 */
    totalAvailabilityNGInfluencerCount: Scalars["Int"];
    /** 合計可否Waitingインフルエンサー数（発注後まだ可否確認がとれていない人） */
    totalAvailabilityWaitingInfluencerCount: Scalars["Int"];
    /** 各メディアごとの発注の概要 */
    summaries: Array<SummaryInfluencerScore>;
    /** 各メディアごとの発注時にInitalのデータ */
    winningSummaries: Array<SummaryInfluencerScore>;
    /** 発注ステータス */
    status: AssignmentStatus;
    /** キャンペーン */
    campaign: CampaignInfo;
    /** 代理店 */
    agency: AgencyInfo;
    /** 発注インフルエンサーリスト */
    assignmentInfluencersByAgency?: Maybe<AssignmentInfluencerConnection>;
    /** 発注インフルエンサー */
    assignmentInfluencerByAgency?: Maybe<AssignmentInfluencer>;
    createdAt: Scalars["DateTime"];
    updatedAt: Scalars["DateTime"];
    /** クライアントがOrderListのページを見た時間 */
    orderListLookedAt: Scalars["DateTime"];
    /** 代理店の備考 */
    agencyNote?: Maybe<Scalars["String"]>;
    /** 代理店がアップロードした添付ファイル(Suggestion) */
    agencyAttachmentFiles: Array<AttachmentFile>;
    /** 代理店がアップロードした添付ファイル(Assignment) */
    agencyAssignmentAttachmentFiles: Array<AttachmentFile>;
    /** 代理店がアップロードした添付ファイル(Report) */
    agencyReportAttachmentFiles: Array<AttachmentFile>;
    /** コメント未読件数 */
    unreadCommentCount: Scalars["Int"];
  };

export type ClientAssignmentSummariesArgs = {
  filterBy?: Maybe<AssignmentInfluencerFilter>;
  includeAdditionalConsiderationSummary?: Maybe<Scalars["Boolean"]>;
  suggestionNumber?: Maybe<Scalars["Int"]>;
};

export type ClientAssignmentAssignmentInfluencersByAgencyArgs = {
  perPage?: Maybe<Scalars["Int"]>;
  page: Scalars["Int"];
  orderBy?: Maybe<AssignmentInfluencerOrderByClause>;
  filterBy?: Maybe<AssignmentInfluencerFilter>;
  influencerName?: Maybe<Scalars["String"]>;
};

export type ClientAssignmentAssignmentInfluencerByAgencyArgs = {
  number: Scalars["Int"];
};

export type ClientBrand = Node & {
  __typename?: "ClientBrand";
  id: Scalars["ID"];
  name: Scalars["String"];
  image: Scalars["URI"];
  description?: Maybe<Scalars["String"]>;
  divisionName?: Maybe<Scalars["String"]>;
  affinityModels?: Maybe<AffinityModelConnection>;
  affinityModel?: Maybe<AffinityModel>;
  influencerEvaluationGroups?: Maybe<InfluencerEvaluationGroupConnection>;
  influencerEvaluationGroup?: Maybe<InfluencerEvaluationGroup>;
  campaigns?: Maybe<Array<Maybe<ClientCampaign>>>;
  campaign?: Maybe<ClientCampaign>;
  productItems: Array<Maybe<ProductItem>>;
};

export type ClientBrandAffinityModelsArgs = {
  perPage?: Maybe<Scalars["Int"]>;
  page: Scalars["Int"];
};

export type ClientBrandAffinityModelArgs = {
  number: Scalars["Int"];
};

export type ClientBrandInfluencerEvaluationGroupsArgs = {
  perPage?: Maybe<Scalars["Int"]>;
  page: Scalars["Int"];
};

export type ClientBrandInfluencerEvaluationGroupArgs = {
  number: Scalars["Int"];
};

export type ClientBrandCampaignArgs = {
  number: Scalars["Int"];
};

export type ClientCampaign = Campaign &
  Node & {
    __typename?: "ClientCampaign";
    id: Scalars["ID"];
    number: Scalars["Int"];
    campaignCode: Scalars["String"];
    /** キャンペーン名 */
    name: Scalars["String"];
    /** Campaign全体の予算 */
    budget: Scalars["Int"];
    /** ? */
    keywordAndHashtags?: Maybe<Scalars["String"]>;
    /** 目標リーチ数 */
    reachKpi: Scalars["Int"];
    /** 目標指標 */
    indicator: CampaignIndicator;
    /** キャンペーン商品 */
    productItem: ProductItem;
    objectives: Array<ObjectiveType>;
    campaignActivityType: CampaignActivityType;
    /** ブランド */
    brandId: Scalars["ID"];
    /** アフィニティモデル */
    affinityModel: AffinityModel;
    /** 備考（クライアントから代理店への共有事項） */
    note?: Maybe<Scalars["String"]>;
    /** 代理店リスト */
    agencies?: Maybe<Array<Maybe<CampaignAgency>>>;
    /** 代理店 */
    agency?: Maybe<CampaignAgency>;
    assignments?: Maybe<Array<Maybe<ClientAssignment>>>;
    /** 発注インフルエンサーリスト */
    assignmentInfluencers?: Maybe<AssignmentInfluencerConnection>;
    /** 発注をしていない代理店の落選が完了していて、提案フェーズが終了しているかどうか */
    biddingClosed: Scalars["Boolean"];
    /** 投稿開始日 */
    postStartAt: Scalars["DateTime"];
    /** 投稿終了日 */
    postEndAt: Scalars["DateTime"];
    /** 提案開始日時 */
    suggestionStartAt: Scalars["DateTime"];
    /** 提案締め切り日時 */
    suggestionEndAt: Scalars["DateTime"];
    /** レポート */
    report?: Maybe<ClientReport>;
    tags?: Maybe<Array<Maybe<Tag>>>;
    /** 添付ファイル */
    attachmentFiles: Array<AttachmentFile>;
    createdAt: Scalars["DateTime"];
    updatedAt: Scalars["DateTime"];
    brandName: Scalars["String"];
    divisionName?: Maybe<Scalars["String"]>;
  };

export type ClientCampaignAgencyArgs = {
  name: Scalars["String"];
};

export type ClientCampaignAssignmentInfluencersArgs = {
  perPage?: Maybe<Scalars["Int"]>;
  page: Scalars["Int"];
};

export type ClientCampaignComment = {
  __typename?: "ClientCampaignComment";
  campaignId: Scalars["ID"];
  totalSuggestionUnreadComment: Scalars["Int"];
  totalAssignmentUnreadComment: Scalars["Int"];
  totalReportUnreadComment: Scalars["Int"];
  suggestionComments: BiddingComment;
  assignmentComments: BiddingComment;
  reportComments: BiddingComment;
};

export type ClientCampaignCommentSuggestionCommentsArgs = {
  agencyName: Scalars["String"];
};

export type ClientCampaignCommentAssignmentCommentsArgs = {
  agencyName: Scalars["String"];
};

export type ClientCampaignCommentReportCommentsArgs = {
  agencyName: Scalars["String"];
};

export type ClientCampaignsFilterDropdownOptions = Node & {
  __typename?: "ClientCampaignsFilterDropdownOptions";
  divisionNames: Array<Maybe<DropdownOption>>;
  brandNames: Array<Maybe<DropdownOption>>;
  campaignNames: Array<Maybe<DropdownOption>>;
  agencyNames: Array<Maybe<DropdownOption>>;
  categoryNames: Array<Maybe<DropdownOption>>;
  snsNames: Array<Maybe<DropdownOption>>;
  id: Scalars["ID"];
};

export type ClientChangePasswordInput = {
  clientMutationId?: Maybe<Scalars["String"]>;
  password: Scalars["String"];
  email: Scalars["String"];
  token: Scalars["String"];
};

export type ClientChangePasswordPayload = {
  __typename?: "ClientChangePasswordPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type ClientChangePasswordRequestInput = {
  clientMutationId?: Maybe<Scalars["String"]>;
  email: Scalars["String"];
};

export type ClientChangePasswordRequestPayload = {
  __typename?: "ClientChangePasswordRequestPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  requested: Scalars["Boolean"];
};

export type ClientCredentialPayload = {
  __typename?: "ClientCredentialPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  token: Scalars["String"];
};

export type ClientLoginInput = {
  clientMutationId?: Maybe<Scalars["String"]>;
  email: Scalars["String"];
  password: Scalars["String"];
};

export type ClientReport = SummaryInfluencerScore &
  Report & {
    __typename?: "ClientReport";
    campaignId: Scalars["ID"];
    /** 全インフルエンサーのアカウント情報、投稿情報、アフィニティレートをすべて取得完了をしているか */
    isDataCollectionCompleted: Scalars["Boolean"];
    /** 合計コスト */
    totalCost: Scalars["Int"];
    /** 合計コミッション */
    totalCommission: Scalars["Int"];
    /** 合計フォロワー数 */
    totalFollower?: Maybe<Scalars["Int"]>;
    /** 合計インフルエンサー数 */
    totalInfluencerCount: Scalars["Int"];
    /** 合計フォロー数 */
    totalFollow?: Maybe<Scalars["Int"]>;
    /** 平均エンゲージメント率 */
    averageEngagementRate?: Maybe<Scalars["Float"]>;
    /** 平均スコア率 */
    averageScoreRate?: Maybe<Scalars["Float"]>;
    /** 平均アフィニティスコア */
    averageAffinityScoreRate?: Maybe<Scalars["Float"]>;
    /** 平均アフィニティレート */
    averageAffinityRate?: Maybe<Scalars["Float"]>;
    /** 平均アドボケイトスコア */
    averageAdvocateScoreRate?: Maybe<Scalars["Float"]>;
    /** 各メディアごとのレポートの概要 */
    summaries: Array<SummaryInfluencerScore>;
    /** 各メディアごとのConfirm時のデータ */
    winningSummaries: Array<SummaryInfluencerScore>;
    /** agency */
    agency: AgencyInfo;
    /** レポートインフルエンサーリスト */
    influencers?: Maybe<ReportInfluencerConnection>;
    /** レポートインフルエンサー */
    influencer?: Maybe<ReportInfluencer>;
    /** 合計リーチ数 */
    totalReachCount?: Maybe<Scalars["Float"]>;
    /** 合計インプレッション数 */
    totalImpressionCount?: Maybe<Scalars["Float"]>;
    /** 合計view数 */
    totalViewCount?: Maybe<Scalars["Float"]>;
    /** 合計いいね数 */
    totalLikeCount?: Maybe<Scalars["Float"]>;
    /** 合計コメント数 */
    totalCommentCount?: Maybe<Scalars["Float"]>;
    /** 合計シェア数 */
    totalShareCount?: Maybe<Scalars["Float"]>;
    /** 平均リテンション率 */
    averageRetentionRate?: Maybe<Scalars["Float"]>;
    /** 合計保存数 */
    totalClippingCount?: Maybe<Scalars["Float"]>;
    /** 合計ECクリック数 */
    totalEcClickCount?: Maybe<Scalars["Float"]>;
    /** キャンペーン売上 */
    totalSalesValue?: Maybe<Scalars["Int"]>;
    /** ? */
    costReachRate?: Maybe<Scalars["Float"]>;
    /** ? */
    costImpressionRate?: Maybe<Scalars["Float"]>;
    /** ? */
    costViewRate?: Maybe<Scalars["Float"]>;
    /** ? */
    costEngagementRate?: Maybe<Scalars["Float"]>;
    /** ? */
    costClippingRate?: Maybe<Scalars["Float"]>;
    /** ? */
    costEcClickRate?: Maybe<Scalars["Float"]>;
    /** ? */
    costSalesRate?: Maybe<Scalars["Float"]>;
    /** ? */
    awarenessScore?: Maybe<Scalars["Float"]>;
    /** ? */
    interestScore?: Maybe<Scalars["Float"]>;
    /** ? */
    considerationScore?: Maybe<Scalars["Float"]>;
    /** ? */
    conversionScore?: Maybe<Scalars["Float"]>;
    /** 代理店の備考 */
    agencyNote?: Maybe<Scalars["String"]>;
    /** 代理店がアップロードした添付ファイル */
    agencyAttachmentFiles: Array<AttachmentFile>;
    /** 代理店がアップロードした添付ファイル(Assignment) */
    agencyAssignmentAttachmentFiles: Array<AttachmentFile>;
    /** 代理店がアップロードした添付ファイル(Report) */
    agencyReportAttachmentFiles: Array<AttachmentFile>;
    /** コメント未読件数 */
    unreadCommentCount: Scalars["Int"];
  };

export type ClientReportInfluencersArgs = {
  perPage?: Maybe<Scalars["Int"]>;
  page: Scalars["Int"];
  orderBy?: Maybe<ReportInfluencerOrderByClause>;
};

export type ClientReportInfluencerArgs = {
  influencerId: Scalars["ID"];
};

export type Comment = {
  __typename?: "Comment";
  id: Scalars["ID"];
  account: CommentAccount;
  readFlag: Scalars["Boolean"];
  sendAt: Scalars["DateTime"];
  comment: Scalars["String"];
};

export type CommentAccount = ClientAccount | AgencyAccount;

export type Connection = {
  paginatorInfo?: Maybe<PaginatorInfo>;
  edges?: Maybe<Array<Maybe<Edge>>>;
};

/** instagram_post_media.content_type */
export enum ContentType {
  Image = "IMAGE",
  Video = "VIDEO"
}

export type CoreModel = Node & {
  __typename?: "CoreModel";
  id: Scalars["ID"];
  to: Scalars["Date"];
  rangeMonth: CoreRangeMonth;
  logic: Logic;
};

/** core_model.core_range_month */
export enum CoreRangeMonth {
  ThreeMonth = "THREE_MONTH",
  SixMonth = "SIX_MONTH",
  TwelveMonth = "TWELVE_MONTH"
}

export type CreateAffinityModelInput = {
  clientMutationId?: Maybe<Scalars["String"]>;
  snapInfluencerEvaluationGroupId: Scalars["ID"];
  to: Scalars["Date"];
  description?: Maybe<Scalars["String"]>;
};

export type CreateAffinityModelPayload = {
  __typename?: "CreateAffinityModelPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  affinityModel: AffinityModel;
};

export type CreateAgencyAccountInput = {
  clientMutationId?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  email: Scalars["String"];
};

export type CreateAgencyAccountPayload = {
  __typename?: "CreateAgencyAccountPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  agencyAccount: AgencyAccount;
};

export type CreateAgencyInput = {
  clientMutationId?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  accountName: Scalars["String"];
  accountEmail: Scalars["String"];
};

export type CreateAgencyPayload = {
  __typename?: "CreateAgencyPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  agency: ClientAgency;
};

export type CreateBrandInput = {
  clientMutationId?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  image: Scalars["Upload"];
  description?: Maybe<Scalars["String"]>;
};

export type CreateBrandPayload = {
  __typename?: "CreateBrandPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  brand: ClientBrand;
};

export type CreateCampaignInput = {
  clientMutationId?: Maybe<Scalars["String"]>;
  title: Scalars["String"];
  campaignActivityType?: Maybe<CampaignActivityType>;
  productItemId: Scalars["ID"];
  objectives?: Maybe<Array<Maybe<ObjectiveType>>>;
  reachKpi?: Maybe<Scalars["Int"]>;
  budget: Scalars["Int"];
  keywordAndHashtags?: Maybe<Scalars["String"]>;
  instagramReachKpi?: Maybe<Scalars["Int"]>;
  twitterReachKpi?: Maybe<Scalars["Int"]>;
  youtubeReachKpi?: Maybe<Scalars["Int"]>;
  tiktokReachKpi?: Maybe<Scalars["Int"]>;
  instagramSales?: Maybe<Scalars["Int"]>;
  twitterSales?: Maybe<Scalars["Int"]>;
  youtubeSales?: Maybe<Scalars["Int"]>;
  tiktokSales?: Maybe<Scalars["Int"]>;
  instagramEcClick?: Maybe<Scalars["Int"]>;
  twitterEcClick?: Maybe<Scalars["Int"]>;
  youtubeEcClick?: Maybe<Scalars["Int"]>;
  tiktokEcClick?: Maybe<Scalars["Int"]>;
  note?: Maybe<Scalars["String"]>;
  postStartAt: Scalars["DateTime"];
  postEndAt: Scalars["DateTime"];
  tagIds?: Maybe<Array<Maybe<Scalars["ID"]>>>;
  /** 提案開始日時 */
  suggestionStartAt: Scalars["DateTime"];
  /** 提案締め切り日時 */
  suggestionEndAt: Scalars["DateTime"];
  affinityModelId: Scalars["ID"];
  attachmentFiles?: Maybe<Array<Maybe<CampaignFileUpload>>>;
};

export type CreateCampaignPayload = {
  __typename?: "CreateCampaignPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  campaign: ClientCampaign;
};

export type CreateClientAccountInput = {
  clientMutationId?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  email: Scalars["String"];
};

export type CreateClientAccountPayload = {
  __typename?: "CreateClientAccountPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  clientAccount: ClientAccount;
};

export type CreateInfluencerEvaluationGroupInput = {
  clientMutationId?: Maybe<Scalars["String"]>;
  brandId: Scalars["ID"];
  title: Scalars["String"];
};

export type CreateInfluencerEvaluationGroupPayload = {
  __typename?: "CreateInfluencerEvaluationGroupPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  influencerEvaluationGroup: InfluencerEvaluationGroup;
};

export type CreateSnapInfluencerEvaluationGroupInput = {
  clientMutationId?: Maybe<Scalars["String"]>;
  influencerEvaluationGroupId: Scalars["ID"];
  title: Scalars["String"];
  description?: Maybe<Scalars["String"]>;
};

export type CreateSnapInfluencerEvaluationGroupPayload = {
  __typename?: "CreateSnapInfluencerEvaluationGroupPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  snapInfluencerEvaluationGroup?: Maybe<SnapInfluencerEvaluationGroup>;
};

export type DeactivateAgencyInput = {
  clientMutationId?: Maybe<Scalars["String"]>;
  agencyId: Scalars["ID"];
};

export type DeactivateAgencyPayload = {
  __typename?: "DeactivateAgencyPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type DeleteAgencyAccountInput = {
  clientMutationId?: Maybe<Scalars["String"]>;
  agencyAccountId: Scalars["ID"];
};

export type DeleteAgencyAccountPayload = {
  __typename?: "DeleteAgencyAccountPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type DeleteCampaignInput = {
  clientMutationId?: Maybe<Scalars["String"]>;
  brandName: Scalars["String"];
  number: Scalars["String"];
};

export type DeleteCampaignPayload = {
  __typename?: "DeleteCampaignPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  deleted: Scalars["Boolean"];
};

export type DeleteClientAccountInput = {
  clientMutationId?: Maybe<Scalars["String"]>;
  clientAccountId: Scalars["ID"];
};

export type DeleteClientAccountPayload = {
  __typename?: "DeleteClientAccountPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type DismissRequestAvailabilityApprovalInput = {
  clientMutationId?: Maybe<Scalars["String"]>;
  campaignId: Scalars["ID"];
  agencyId: Scalars["ID"];
};

export type DismissRequestAvailabilityApprovalPayload = {
  __typename?: "DismissRequestAvailabilityApprovalPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  assignment: ClientAssignment;
};

export type DownloadAgencyReportInfluencersInput = {
  clientMutationId?: Maybe<Scalars["String"]>;
  campaignId: Scalars["ID"];
};

export type DownloadAgencyReportInfluencersPayload = {
  __typename?: "DownloadAgencyReportInfluencersPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  /** ダウンロードリンク */
  url: Scalars["URI"];
};

export type DownloadAssignmentInfluencersInput = {
  clientMutationId?: Maybe<Scalars["String"]>;
  campaignId: Scalars["ID"];
  /**
   * agencyIdがnullの場合は全代理店の一括ダウンロードをできる
   * nullで送られた時に変換できないため、globalIdのディレクティブは利用していない
   **/
  agencyId?: Maybe<Scalars["ID"]>;
  confirmedInfluencersOnly?: Maybe<Scalars["Boolean"]>;
};

export type DownloadAssignmentInfluencersPayload = {
  __typename?: "DownloadAssignmentInfluencersPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  /** ダウンロードリンク */
  url: Scalars["URI"];
};

export type DownloadAssignmentInfluencersToAgencyInput = {
  clientMutationId?: Maybe<Scalars["String"]>;
  campaignId: Scalars["ID"];
  /**
   * 特定の代理店の発注インフルエンサーを取得したい場合はagencyIdを指定
   * 全ての代理店の発注インフルエンサーのデータを取得したい場合はagencyIdをnullで指定
   **/
  agencyId?: Maybe<Scalars["ID"]>;
  /**
   * trueの場合は最終確定リストに入っているインフルエンサーのみが対象
   * falseの場合はcampaignId、agencyIdで指定した対象のインフルエンサー全員が対象
   **/
  confirmedInfluencersOnly?: Maybe<Scalars["Boolean"]>;
};

export type DownloadAssignmentInfluencersToAgencyPayload = {
  __typename?: "DownloadAssignmentInfluencersToAgencyPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  /** ダウンロードリンク */
  url: Scalars["URI"];
};

export type DownloadClientReportInput = {
  clientMutationId?: Maybe<Scalars["String"]>;
  campaignId: Scalars["ID"];
};

export type DownloadClientReportPayload = {
  __typename?: "DownloadClientReportPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  /** ダウンロードリンク */
  url: Scalars["URI"];
};

export type DownloadSuggestionInfluencersInput = {
  clientMutationId?: Maybe<Scalars["String"]>;
  suggestionId: Scalars["ID"];
  /**
   * trueの場合は検討中のインフルエンサーのみが対象になる。
   * falseの場合はsuggestionIdで指定した提案インフルエンサー全員が対象になる。
   **/
  considerationOnly?: Maybe<Scalars["Boolean"]>;
};

export type DownloadSuggestionInfluencersPayload = {
  __typename?: "DownloadSuggestionInfluencersPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  /** ダウンロードリンク */
  url: Scalars["URI"];
};

export type DownloadSuggestionInfluencersToAgencyInput = {
  clientMutationId?: Maybe<Scalars["String"]>;
  suggestionId: Scalars["ID"];
  considerationOnly?: Maybe<Scalars["Boolean"]>;
};

export type DownloadSuggestionInfluencersToAgencyPayload = {
  __typename?: "DownloadSuggestionInfluencersToAgencyPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  /** ダウンロードリンク */
  url: Scalars["URI"];
};

export type DropdownOption = Node & {
  __typename?: "DropdownOption";
  label: Scalars["String"];
  value: Scalars["String"];
  id: Scalars["ID"];
};

export type Edge = {
  cursor: Scalars["String"];
  node: Node;
};

/** influencer_evaluation.evaluation_type */
export enum EvaluationType {
  BeforeEvaluation = "BEFORE_EVALUATION",
  Matched = "MATCHED",
  Considering = "CONSIDERING",
  Unmatched = "UNMATCHED"
}

export type Influencer = Node & {
  __typename?: "Influencer";
  id: Scalars["ID"];
  name: Scalars["String"];
  image?: Maybe<Scalars["URI"]>;
  socialMedia?: Maybe<SocialMedia>;
};

export type InfluencerEvaluation = {
  id: Scalars["ID"];
  number: Scalars["Int"];
  /** アフィニティレート */
  affinityRate: Scalars["Float"];
  evaluation: EvaluationType;
  note?: Maybe<Scalars["String"]>;
};

export type InfluencerEvaluationConnection = Connection & {
  __typename?: "InfluencerEvaluationConnection";
  paginatorInfo?: Maybe<PaginatorInfo>;
  edges?: Maybe<Array<Maybe<InfluencerEvaluationEdge>>>;
};

export type InfluencerEvaluationEdge = Edge & {
  __typename?: "InfluencerEvaluationEdge";
  cursor: Scalars["String"];
  node: InfluencerEvaluationNode;
};

export type InfluencerEvaluationGroup = Node & {
  __typename?: "InfluencerEvaluationGroup";
  id: Scalars["ID"];
  number: Scalars["Int"];
  createdAt: Scalars["DateTime"];
  updatedAt: Scalars["DateTime"];
  title: Scalars["String"];
  socialMediaType: SocialMediaType;
  evaluationCount: Scalars["Int"];
  evaluationMatchedCount: Scalars["Int"];
  evaluationConsideringCount: Scalars["Int"];
  evaluationUnmatchedCount: Scalars["Int"];
  influencerEvaluations?: Maybe<InfluencerEvaluationConnection>;
  influencerEvaluation?: Maybe<MasterInfluencerEvaluation>;
  snapInfluencerEvaluationGroups?: Maybe<
    SnapInfluencerEvaluationGroupConnection
  >;
  snapInfluencerEvaluationGroup?: Maybe<SnapInfluencerEvaluationGroup>;
  postLoadingStatus: PostLoadingStatus;
};

export type InfluencerEvaluationGroupInfluencerEvaluationsArgs = {
  perPage?: Maybe<Scalars["Int"]>;
  page: Scalars["Int"];
  orderBy?: Maybe<InfluencerEvaluationOrderByClause>;
  searchBy?: Maybe<Scalars["String"]>;
  filterBy?: Maybe<EvaluationType>;
};

export type InfluencerEvaluationGroupInfluencerEvaluationArgs = {
  number: Scalars["Int"];
};

export type InfluencerEvaluationGroupSnapInfluencerEvaluationGroupsArgs = {
  perPage?: Maybe<Scalars["Int"]>;
  page: Scalars["Int"];
};

export type InfluencerEvaluationGroupSnapInfluencerEvaluationGroupArgs = {
  number: Scalars["Int"];
};

export type InfluencerEvaluationGroupConnection = Connection & {
  __typename?: "InfluencerEvaluationGroupConnection";
  paginatorInfo?: Maybe<PaginatorInfo>;
  edges?: Maybe<Array<Maybe<InfluencerEvaluationGroupEdge>>>;
};

export type InfluencerEvaluationGroupEdge = Edge & {
  __typename?: "InfluencerEvaluationGroupEdge";
  cursor: Scalars["String"];
  node: InfluencerEvaluationGroupNode;
};

export type InfluencerEvaluationGroupNode = Node & {
  __typename?: "InfluencerEvaluationGroupNode";
  id: Scalars["ID"];
  influencerEvaluationGroup: InfluencerEvaluationGroup;
};

export type InfluencerEvaluationNode = Node & {
  __typename?: "InfluencerEvaluationNode";
  id: Scalars["ID"];
  influencerEvaluation: MasterInfluencerEvaluation;
};

export type InfluencerEvaluationOrderByClause = {
  field: InfluencerEvaluationOrderByColumn;
  order: SortOrder;
};

/** influencer_evaluation_order_by_column */
export enum InfluencerEvaluationOrderByColumn {
  FollowerCount = "FOLLOWER_COUNT",
  IncreaseRate = "INCREASE_RATE",
  Score = "SCORE",
  AffinityRate = "AFFINITY_RATE"
}

export type InfluencerInsightBySocialMedia = {
  /** SNS種別 */
  socialMediaType: SocialMediaType;
  /** メディアごとの費用 */
  cost: Scalars["Int"];
  /** アフィニティレート */
  affinityRate?: Maybe<Scalars["Float"]>;
  /** アドボケイトスコア */
  advocateScore?: Maybe<Scalars["Float"]>;
};

export type InfluencerLoadingStatus = {
  __typename?: "InfluencerLoadingStatus";
  status: LoadingStatus;
};

export type InfluencerSegment = Node & {
  __typename?: "InfluencerSegment";
  id: Scalars["ID"];
  /** セグメント名 */
  name: Scalars["String"];
  /** 最大フォロワー数 */
  maxFollowerCount?: Maybe<Scalars["Int"]>;
  /** 最小フォロワー数 */
  minFollowerCount?: Maybe<Scalars["Int"]>;
  /** フォロワー上昇率 */
  increaseRate?: Maybe<Scalars["Float"]>;
};

export type Instagram = SocialMediaId &
  Node & {
    __typename?: "Instagram";
    id: Scalars["ID"];
    /** ユーザーをInstagram上で検索する時に使い、各ユーザーの詳細ページのURLに文字列として含まれているID e.g.)anriworld */
    accountId: Scalars["String"];
    name: Scalars["String"];
    profileImage?: Maybe<Scalars["URI"]>;
    followerCount?: Maybe<Scalars["Int"]>;
    followCount?: Maybe<Scalars["Int"]>;
    postCount?: Maybe<Scalars["Int"]>;
    increaseRate?: Maybe<Scalars["Float"]>;
    /** 平均いいね数 */
    avgLikeCount?: Maybe<Scalars["Float"]>;
    /** 平均コメント数 */
    avgCommentCount?: Maybe<Scalars["Float"]>;
    /** エンゲージメント率 */
    engagement?: Maybe<Scalars["Float"]>;
    /** フォロワー単価 */
    followerUnitPrice?: Maybe<Scalars["Float"]>;
    /** いいね単価 */
    likeUnitPrice?: Maybe<Scalars["Float"]>;
    /** クロール日時 */
    crawlSucceededAt?: Maybe<Scalars["DateTime"]>;
  };

export type InstagramInsight = {
  __typename?: "InstagramInsight";
  /** フォロワー数 */
  followerCount: Scalars["Int"];
  /** フォロー数 */
  followCount: Scalars["Int"];
  /** 投稿数 */
  postCount: Scalars["Int"];
  /** フォロワー上昇率 */
  increaseRate?: Maybe<Scalars["Float"]>;
  /** 平均いいね数 */
  averageLikeCount: Scalars["Float"];
  /** 平均コメント数 */
  averageCommentCount: Scalars["Float"];
  /** エンゲージメント率 */
  engagement: Scalars["Float"];
  /** クロール日 */
  crawlSucceededAt: Scalars["Date"];
};

export type InstagramPost = Node & {
  __typename?: "InstagramPost";
  id: Scalars["ID"];
  postDate: Scalars["DateTime"];
  caption?: Maybe<Scalars["String"]>;
  hashTags?: Maybe<Array<Maybe<Scalars["String"]>>>;
  thumbnailUrl: Scalars["URI"];
  media: Array<InstagramPostMedia>;
  url: Scalars["URI"];
};

export type InstagramPostInsight = {
  __typename?: "InstagramPostInsight";
  /** フォロワー数 */
  followerCount?: Maybe<Scalars["Int"]>;
  /** フォロー数 */
  followCount?: Maybe<Scalars["Int"]>;
  /** followerIncreaseRate */
  followerIncreaseRate?: Maybe<Scalars["Float"]>;
  /** averageCommentCount */
  averageCommentCount?: Maybe<Scalars["Float"]>;
  /** averageLikeCount */
  averageLikeCount?: Maybe<Scalars["Float"]>;
  /** 累計投稿数 */
  cumulativePostCount?: Maybe<Scalars["Int"]>;
  /** フォロワー上昇率 */
  increaseRate?: Maybe<Scalars["Float"]>;
  /** 該当の投稿に関してのいいね数 */
  likeCount?: Maybe<Scalars["Int"]>;
  /** 該当の投稿に関してのコメント数 */
  commentCount?: Maybe<Scalars["Int"]>;
  /** 該当の投稿に関してのエンゲージメント率 */
  engagement?: Maybe<Scalars["Float"]>;
  /** クロール日（投稿についてのクロール日） */
  crawlSucceededAt?: Maybe<Scalars["Date"]>;
  /** リーチ数 */
  reachCount?: Maybe<Scalars["Int"]>;
  /** インプレッション数 */
  impressionCount?: Maybe<Scalars["Int"]>;
  /** 保存数 */
  clippingCount?: Maybe<Scalars["Int"]>;
  /** ? */
  costReachRate?: Maybe<Scalars["Float"]>;
  /** ? */
  costImpressionRate?: Maybe<Scalars["Float"]>;
  /** ? */
  costEngagementRate?: Maybe<Scalars["Float"]>;
  /** ? */
  costClippingRate?: Maybe<Scalars["Float"]>;
  /** ? */
  awarenessScore?: Maybe<Scalars["Float"]>;
  /** ? */
  interestScore?: Maybe<Scalars["Float"]>;
  /** ? */
  considerationScore?: Maybe<Scalars["Float"]>;
  /** ? */
  conversionScore?: Maybe<Scalars["Float"]>;
};

export type InstagramPostMedia = Node & {
  __typename?: "InstagramPostMedia";
  id: Scalars["ID"];
  contentType: ContentType;
  position: Scalars["Int"];
  instagramMediaUrl: Scalars["URI"];
  instagramMediaThumbnailUrl?: Maybe<Scalars["URI"]>;
  mediaUrl?: Maybe<Scalars["URI"]>;
  mediaThumbnailUrl?: Maybe<Scalars["URI"]>;
};

/** suggestion_influencer.loading_status */
export enum LoadingStatus {
  Complete = "COMPLETE",
  Incomplete = "INCOMPLETE"
}

export type LockAgencyInput = {
  clientMutationId?: Maybe<Scalars["String"]>;
  agencyId: Scalars["ID"];
};

export type LockAgencyPayload = {
  __typename?: "LockAgencyPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  agency: ClientAgency;
};

export type Logic = Node & {
  __typename?: "Logic";
  id: Scalars["ID"];
  name: Scalars["String"];
};

export type MasterInfluencerEvaluation = InfluencerEvaluation &
  Node & {
    __typename?: "MasterInfluencerEvaluation";
    id: Scalars["ID"];
    number: Scalars["Int"];
    /** アフィニティレート */
    affinityRate: Scalars["Float"];
    evaluation: EvaluationType;
    note?: Maybe<Scalars["String"]>;
    snapped: Scalars["Boolean"];
    evaluatedAt?: Maybe<Scalars["DateTime"]>;
    trainingInfluencer: TrainingInfluencer;
  };

export type Mutation = {
  __typename?: "Mutation";
  addConsiderationInfluencersToConfirmInfluencers?: Maybe<
    AddConsiderationInfluencersToConfirmInfluencersPayload
  >;
  toggleConfirmInfluencersConsideration?: Maybe<
    ToggleConfirmInfluencersConsiderationPayload
  >;
  addAgenciesToCampaign?: Maybe<AddAgenciesToCampaignPayload>;
  addAgencyToCampaign?: Maybe<AddAgencyToCampaignPayload>;
  addInfluencerFromConsideration?: Maybe<AddInfluencerFromConsiderationPayload>;
  additionalAssignInfluencerConsideration?: Maybe<
    AdditionalAssignInfluencerConsiderationPayload
  >;
  additionalAssignInfluencersToCampaign?: Maybe<AddAgencyToCampaignPayload>;
  agencyChangePassword?: Maybe<AgencyChangePasswordPayload>;
  agencyChangePasswordRequest?: Maybe<AgencyChangePasswordRequestPayload>;
  agencyLogin?: Maybe<AgencyCredentialPayload>;
  approveConfirmedInfluencers?: Maybe<ApproveConfirmedInfluencersPayload>;
  approveRequestAvailabilityApproval?: Maybe<
    ApproveRequestAvailabilityApprovalPayload
  >;
  assignInfluencersToCampaign?: Maybe<AssignInfluencersToCampaignPayload>;
  campaignReportRequest?: Maybe<CampaignReportRequestPayload>;
  clientChangePassword?: Maybe<ClientChangePasswordPayload>;
  clientChangePasswordRequest?: Maybe<ClientChangePasswordRequestPayload>;
  clientLogin?: Maybe<ClientCredentialPayload>;
  createAffinityModel?: Maybe<CreateAffinityModelPayload>;
  createAgency?: Maybe<CreateAgencyPayload>;
  createAgencyAccount?: Maybe<CreateAgencyAccountPayload>;
  createBrand?: Maybe<CreateBrandPayload>;
  createCampaign?: Maybe<CreateCampaignPayload>;
  createClientAccount?: Maybe<CreateClientAccountPayload>;
  createInfluencerEvaluationGroup?: Maybe<
    CreateInfluencerEvaluationGroupPayload
  >;
  createSnapInfluencerEvaluationGroup?: Maybe<
    CreateSnapInfluencerEvaluationGroupPayload
  >;
  deleteAgencyAccount?: Maybe<DeleteAgencyAccountPayload>;
  deactivateAgency?: Maybe<DeactivateAgencyPayload>;
  deleteCampaign?: Maybe<DeleteCampaignPayload>;
  deleteClientAccount?: Maybe<DeleteClientAccountPayload>;
  dismissRequestAvailabilityApproval?: Maybe<
    DismissRequestAvailabilityApprovalPayload
  >;
  downloadAgencyReportInfluencers?: Maybe<
    DownloadAgencyReportInfluencersPayload
  >;
  downloadAssignmentInfluencers?: Maybe<DownloadAssignmentInfluencersPayload>;
  downloadAssignmentInfluencersToAgency?: Maybe<
    DownloadAssignmentInfluencersToAgencyPayload
  >;
  downloadClientReport?: Maybe<DownloadClientReportPayload>;
  downloadSuggestionInfluencers?: Maybe<DownloadSuggestionInfluencersPayload>;
  downloadSuggestionInfluencersToAgency?: Maybe<
    DownloadSuggestionInfluencersToAgencyPayload
  >;
  lockAgency?: Maybe<LockAgencyPayload>;
  readAssignmentComment?: Maybe<ReadCommentPayload>;
  readReportComment?: Maybe<ReadCommentPayload>;
  readSuggestionComment?: Maybe<ReadCommentPayload>;
  rejectSuggestion?: Maybe<RejectSuggestionPayload>;
  removeAgencyFromCampaign?: Maybe<RemoveAgencyFromCampaignPayload>;
  removeConfirmedInfluencers?: Maybe<RemoveConfirmedInfluencersPayload>;
  removeInfluencerFromConsideration?: Maybe<
    RemoveInfluencerFromConsiderationPayload
  >;
  requestAvailabilityApproval?: Maybe<RequestAvailabilityApprovalPayload>;
  sendAssignmentComment?: Maybe<SendCommentPayload>;
  sendDraftAssignmentComment?: Maybe<SendDraftCommentPayload>;
  sendDraftReportComment?: Maybe<SendDraftCommentPayload>;
  sendDraftSuggestionComment?: Maybe<SendDraftCommentPayload>;
  sendReportComment?: Maybe<SendCommentPayload>;
  sendSuggestionComment?: Maybe<SendCommentPayload>;
  skipToReport?: Maybe<SkipToReportPayload>;
  toggleInfluencersConsideration?: Maybe<ToggleInfluencersConsiderationPayload>;
  updateAgency?: Maybe<UpdateAgencyPayload>;
  updateAgencyAccount?: Maybe<UpdateAgencyAccountPayload>;
  updateBrand?: Maybe<UpdateBrandPayload>;
  updateCampaign?: Maybe<UpdateCampaignPayload>;
  updateClientAccount?: Maybe<UpdateClientAccountPayload>;
  updateInfluencerEvaluation?: Maybe<UpdateInfluencerEvaluationPayload>;
  updateInfluencerEvaluationNote?: Maybe<UpdateInfluencerEvaluationNotePayload>;
  updateInfluencerSegment?: Maybe<UpdateInfluencerSegmentPayload>;
  updateUnevaluatedInfluencer?: Maybe<UpdatePostLoadingStatusPayload>;
  uploadAvailabilityResults?: Maybe<UploadAvailabilityResultsPayload>;
  uploadPostedResults?: Maybe<UploadPostedResultsPayload>;
  uploadSuggestion?: Maybe<UploadSuggestionPayload>;
};

export type MutationAddConsiderationInfluencersToConfirmInfluencersArgs = {
  input: AddConsiderationInfluencersToConfirmInfluencersInput;
};

export type MutationToggleConfirmInfluencersConsiderationArgs = {
  input: ToggleConfirmInfluencersConsiderationInput;
};

export type MutationAddAgenciesToCampaignArgs = {
  input: AddAgenciesToCampaignInput;
};

export type MutationAddAgencyToCampaignArgs = {
  input: AddAgencyToCampaignInput;
};

export type MutationAddInfluencerFromConsiderationArgs = {
  input: AddInfluencerFromConsiderationInput;
};

export type MutationAdditionalAssignInfluencerConsiderationArgs = {
  input: AdditionalAssignInfluencerConsiderationInput;
};

export type MutationAdditionalAssignInfluencersToCampaignArgs = {
  input: AdditionalAssignInfluencersToCampaignInput;
};

export type MutationAgencyChangePasswordArgs = {
  input: AgencyChangePasswordInput;
};

export type MutationAgencyChangePasswordRequestArgs = {
  input: AgencyChangePasswordRequestInput;
};

export type MutationAgencyLoginArgs = {
  input: AgencyLoginInput;
};

export type MutationApproveConfirmedInfluencersArgs = {
  input: ApproveConfirmedInfluencersInput;
};

export type MutationApproveRequestAvailabilityApprovalArgs = {
  input: ApproveRequestAvailabilityApprovalInput;
};

export type MutationAssignInfluencersToCampaignArgs = {
  input: AssignInfluencersToCampaignInput;
};

export type MutationCampaignReportRequestArgs = {
  input: CampaignReportRequestInput;
};

export type MutationClientChangePasswordArgs = {
  input: ClientChangePasswordInput;
};

export type MutationClientChangePasswordRequestArgs = {
  input: ClientChangePasswordRequestInput;
};

export type MutationClientLoginArgs = {
  input: ClientLoginInput;
};

export type MutationCreateAffinityModelArgs = {
  input: CreateAffinityModelInput;
};

export type MutationCreateAgencyArgs = {
  input: CreateAgencyInput;
};

export type MutationCreateAgencyAccountArgs = {
  input: CreateAgencyAccountInput;
};

export type MutationCreateBrandArgs = {
  input: CreateBrandInput;
};

export type MutationCreateCampaignArgs = {
  input: CreateCampaignInput;
};

export type MutationCreateClientAccountArgs = {
  input: CreateClientAccountInput;
};

export type MutationCreateInfluencerEvaluationGroupArgs = {
  input: CreateInfluencerEvaluationGroupInput;
};

export type MutationCreateSnapInfluencerEvaluationGroupArgs = {
  input: CreateSnapInfluencerEvaluationGroupInput;
};

export type MutationDeleteAgencyAccountArgs = {
  input: DeleteAgencyAccountInput;
};

export type MutationDeactivateAgencyArgs = {
  input: DeactivateAgencyInput;
};

export type MutationDeleteCampaignArgs = {
  input: DeleteCampaignInput;
};

export type MutationDeleteClientAccountArgs = {
  input: DeleteClientAccountInput;
};

export type MutationDismissRequestAvailabilityApprovalArgs = {
  input: DismissRequestAvailabilityApprovalInput;
};

export type MutationDownloadAgencyReportInfluencersArgs = {
  input: DownloadAgencyReportInfluencersInput;
};

export type MutationDownloadAssignmentInfluencersArgs = {
  input: DownloadAssignmentInfluencersInput;
};

export type MutationDownloadAssignmentInfluencersToAgencyArgs = {
  input: DownloadAssignmentInfluencersToAgencyInput;
};

export type MutationDownloadClientReportArgs = {
  input: DownloadClientReportInput;
};

export type MutationDownloadSuggestionInfluencersArgs = {
  input: DownloadSuggestionInfluencersInput;
};

export type MutationDownloadSuggestionInfluencersToAgencyArgs = {
  input: DownloadSuggestionInfluencersToAgencyInput;
};

export type MutationLockAgencyArgs = {
  input: LockAgencyInput;
};

export type MutationReadAssignmentCommentArgs = {
  input: ReadAssignmentCommentInput;
};

export type MutationReadReportCommentArgs = {
  input: ReadReportCommentInput;
};

export type MutationReadSuggestionCommentArgs = {
  input: ReadSuggestionCommentInput;
};

export type MutationRejectSuggestionArgs = {
  input: RejectSuggestionInput;
};

export type MutationRemoveAgencyFromCampaignArgs = {
  input: RemoveAgencyFromCampaignInput;
};

export type MutationRemoveConfirmedInfluencersArgs = {
  input: RemoveConfirmedInfluencersInput;
};

export type MutationRemoveInfluencerFromConsiderationArgs = {
  input: RemoveInfluencerFromConsiderationInput;
};

export type MutationRequestAvailabilityApprovalArgs = {
  input: RequestAvailabilityApprovalInput;
};

export type MutationSendAssignmentCommentArgs = {
  input: SendAssignmentCommentInput;
};

export type MutationSendDraftAssignmentCommentArgs = {
  input: SendDraftAssignmentCommentInput;
};

export type MutationSendDraftReportCommentArgs = {
  input: SendDraftReportCommentInput;
};

export type MutationSendDraftSuggestionCommentArgs = {
  input: SendDraftSuggestionCommentInput;
};

export type MutationSendReportCommentArgs = {
  input: SendReportCommentInput;
};

export type MutationSendSuggestionCommentArgs = {
  input: SendSuggestionCommentInput;
};

export type MutationSkipToReportArgs = {
  input: SkipToReportInput;
};

export type MutationToggleInfluencersConsiderationArgs = {
  input: ToggleInfluencersConsiderationInput;
};

export type MutationUpdateAgencyArgs = {
  input: UpdateAgencyInput;
};

export type MutationUpdateAgencyAccountArgs = {
  input: UpdateAgencyAccountInput;
};

export type MutationUpdateBrandArgs = {
  input: UpdateBrandInput;
};

export type MutationUpdateCampaignArgs = {
  input: UpdateCampaignInput;
};

export type MutationUpdateClientAccountArgs = {
  input: UpdateClientAccountInput;
};

export type MutationUpdateInfluencerEvaluationArgs = {
  input: UpdateInfluencerEvaluationInput;
};

export type MutationUpdateInfluencerEvaluationNoteArgs = {
  input: UpdateInfluencerEvaluationNoteInput;
};

export type MutationUpdateInfluencerSegmentArgs = {
  input: UpdateInfluencerSegmentInput;
};

export type MutationUpdateUnevaluatedInfluencerArgs = {
  input: UpdateUnevaluatedInfluencerInput;
};

export type MutationUploadAvailabilityResultsArgs = {
  input: UploadAvailabilityResultsInput;
};

export type MutationUploadPostedResultsArgs = {
  input: UploadPostedResultsInput;
};

export type MutationUploadSuggestionArgs = {
  input: UploadSuggestionInput;
};

/** Node global interface */
export type Node = {
  /** Global identifier that can be used to resolve any Node implementation. */
  id: Scalars["ID"];
};

/** objective_type */
export enum ObjectiveType {
  Awareness = "AWARENESS",
  Interest = "INTEREST",
  Consideration = "CONSIDERATION",
  Conversion = "CONVERSION"
}

/** Allows ordering a list of records. */
export type OrderByClause = {
  /** The column that is used for ordering. */
  field: Scalars["String"];
  /** The direction that is used for ordering. */
  order: SortOrder;
};

/** Pagination information about the corresponding list of items. */
export type PageInfo = {
  __typename?: "PageInfo";
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars["Boolean"];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars["Boolean"];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars["String"]>;
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars["String"]>;
  /** Total number of node in connection. */
  total?: Maybe<Scalars["Int"]>;
  /** Count of nodes in current request. */
  count?: Maybe<Scalars["Int"]>;
  /** Current page of request. */
  currentPage?: Maybe<Scalars["Int"]>;
  /** Last page in connection. */
  lastPage?: Maybe<Scalars["Int"]>;
};

/** Pagination information about the corresponding list of items. */
export type PaginatorInfo = {
  __typename?: "PaginatorInfo";
  /** Total count of available items in the page. */
  count: Scalars["Int"];
  /** Current pagination page. */
  currentPage: Scalars["Int"];
  /** Index of first item in the current page. */
  firstItem?: Maybe<Scalars["Int"]>;
  /** If collection has more pages. */
  hasMorePages: Scalars["Boolean"];
  /** Index of last item in the current page. */
  lastItem?: Maybe<Scalars["Int"]>;
  /** Last page number of the collection. */
  lastPage: Scalars["Int"];
  /** Number of items per page in the collection. */
  perPage: Scalars["Int"];
  /** Total items available in the collection. */
  total: Scalars["Int"];
};

export type PostLoadingStatus = {
  __typename?: "PostLoadingStatus";
  status: LoadingStatus;
};

/** report_influencer.post_status */
export enum PostStatus {
  NotPosted = "NOT_POSTED",
  Complete = "COMPLETE"
}

export type ProductCategory = Node & {
  __typename?: "ProductCategory";
  id: Scalars["ID"];
  /** カテゴリ名 */
  name: Scalars["String"];
  /** 商品サブカテゴリ */
  subCategories?: Maybe<Array<Maybe<ProductSubCategory>>>;
};

export type ProductItem = Node & {
  __typename?: "ProductItem";
  id: Scalars["ID"];
  /** 商品名 */
  name: Scalars["String"];
  /** 商品サブカテゴリ */
  productSubCategory: ProductSubCategory;
};

export type ProductSubCategory = Node & {
  __typename?: "ProductSubCategory";
  id: Scalars["ID"];
  /** カテゴリ名 */
  name: Scalars["String"];
  /** 商品カテゴリID */
  productCategoryId: Scalars["ID"];
};

export type Query = {
  __typename?: "Query";
  affinityModels?: Maybe<Array<Maybe<AffinityModel>>>;
  agencies?: Maybe<Array<ClientAgency>>;
  agency: ClientAgency;
  agencyAccount: AgencyAccount;
  agencyAccountByNumber: AgencyAccount;
  agencyAccounts?: Maybe<Array<AgencyAccount>>;
  agencyCampaignComment?: Maybe<AgencyCampaignComment>;
  brand: ClientBrand;
  clientAccount: ClientAccount;
  clientAccountByNumber: ClientAccount;
  clientAccounts?: Maybe<Array<ClientAccount>>;
  clientCampaignComment: ClientCampaignComment;
  clientCampaigns?: Maybe<Array<ClientCampaign>>;
  clientCampaignsFilterDropdownOptions: ClientCampaignsFilterDropdownOptions;
  influencerEvaluationGroups?: Maybe<Array<Maybe<InfluencerEvaluationGroup>>>;
  influencerSegments?: Maybe<Array<InfluencerSegment>>;
  logics?: Maybe<Array<Logic>>;
  productCategories?: Maybe<Array<ProductCategory>>;
  tags?: Maybe<Array<Maybe<Tag>>>;
  userPostVectors?: Maybe<Array<UserPostVector>>;
  node?: Maybe<Node>;
};

export type QueryAffinityModelsArgs = {
  brandName: Scalars["String"];
};

export type QueryAgencyArgs = {
  name: Scalars["String"];
};

export type QueryAgencyAccountByNumberArgs = {
  number: Scalars["Int"];
};

export type QueryAgencyCampaignCommentArgs = {
  brandName: Scalars["String"];
  campaignNumber: Scalars["Int"];
};

export type QueryBrandArgs = {
  name: Scalars["String"];
};

export type QueryClientAccountByNumberArgs = {
  number: Scalars["Int"];
};

export type QueryClientCampaignCommentArgs = {
  brandName: Scalars["String"];
  campaignNumber: Scalars["Int"];
};

export type QueryClientCampaignsArgs = {
  divisionName?: Maybe<Scalars["String"]>;
  brandName?: Maybe<Scalars["String"]>;
  campaignName?: Maybe<Scalars["String"]>;
  agencyName?: Maybe<Scalars["String"]>;
  categoryName?: Maybe<Scalars["String"]>;
  snsName?: Maybe<Scalars["String"]>;
  postStartAt?: Maybe<Scalars["String"]>;
  postEndAt?: Maybe<Scalars["String"]>;
};

export type QueryInfluencerEvaluationGroupsArgs = {
  brandName: Scalars["String"];
};

export type QueryNodeArgs = {
  id: Scalars["ID"];
};

export type ReadAssignmentCommentInput = {
  clientMutationId?: Maybe<Scalars["String"]>;
  assignmentId: Scalars["ID"];
};

export type ReadCommentPayload = {
  __typename?: "ReadCommentPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type ReadReportCommentInput = {
  clientMutationId?: Maybe<Scalars["String"]>;
  campaignId: Scalars["ID"];
  agencyId: Scalars["ID"];
};

export type ReadSuggestionCommentInput = {
  clientMutationId?: Maybe<Scalars["String"]>;
  campaignId: Scalars["ID"];
  agencyId: Scalars["ID"];
};

export type RejectSuggestionInput = {
  clientMutationId?: Maybe<Scalars["String"]>;
  campaignId: Scalars["ID"];
};

export type RejectSuggestionPayload = {
  __typename?: "RejectSuggestionPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  campaign: ClientCampaign;
};

export type RemoveAgencyFromCampaignInput = {
  campaignId: Scalars["ID"];
  agencyId: Scalars["ID"];
};

export type RemoveAgencyFromCampaignPayload = {
  __typename?: "RemoveAgencyFromCampaignPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  campaign: ClientCampaign;
};

export type RemoveConfirmedInfluencersInput = {
  clientMutationId?: Maybe<Scalars["String"]>;
  assignmentId: Scalars["ID"];
};

export type RemoveConfirmedInfluencersPayload = {
  __typename?: "RemoveConfirmedInfluencersPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  assignment: ClientAssignment;
};

export type RemoveInfluencerFromConsiderationInput = {
  clientMutationId?: Maybe<Scalars["String"]>;
  suggestionInfluencerId: Scalars["ID"];
};

export type RemoveInfluencerFromConsiderationPayload = {
  __typename?: "RemoveInfluencerFromConsiderationPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  suggestion: Suggestion;
};

export type Report = {
  campaignId: Scalars["ID"];
  /** レポートインフルエンサーリスト */
  influencers?: Maybe<ReportInfluencerConnection>;
  /** レポートインフルエンサー */
  influencer?: Maybe<ReportInfluencer>;
};

export type ReportInfluencersArgs = {
  perPage?: Maybe<Scalars["Int"]>;
  page: Scalars["Int"];
};

export type ReportInfluencerArgs = {
  influencerId: Scalars["ID"];
};

export type ReportFileUpload = {
  id?: Maybe<Scalars["ID"]>;
  attachmentFile?: Maybe<Scalars["Upload"]>;
};

export type ReportInfluencer = BaseInfluencer &
  Node & {
    __typename?: "ReportInfluencer";
    id: Scalars["ID"];
    score?: Maybe<Scalars["Float"]>;
    /** 費用 */
    cost: Scalars["Int"];
    /** インフルエンサー名（取得がまだの場合はアカウントIDを表示） */
    name: Scalars["String"];
    /** 合計コミッション */
    totalCommission?: Maybe<Scalars["Int"]>;
    /** インフルエンサー画像 */
    image?: Maybe<Scalars["URI"]>;
    /** 各メディアの合計費用 */
    totalCost: Scalars["Int"];
    /** 合計インフルエンサー費用 */
    totalInfluencerCost: Scalars["Int"];
    /** 各メディアの合計二次利用費 */
    totalCostUsageRight: Scalars["Int"];
    /** 各メディアの合計配送費用 */
    totalProductSendOutCost: Scalars["Int"];
    /** 各メディアの平均アフィニティスコア */
    averageAffinityScore?: Maybe<Scalars["Float"]>;
    /** 各メディアの平均アフィニティレート */
    averageAffinityRate?: Maybe<Scalars["Float"]>;
    /** 各メディアの平均アドボケイトスコア */
    averageAdvocateScore?: Maybe<Scalars["Float"]>;
    /** 各メディアの合計フォロワー数 */
    totalFollowerCount?: Maybe<Scalars["Int"]>;
    /** 各メディアの合計フォロー数 */
    totalFollowCount?: Maybe<Scalars["Int"]>;
    /** 各メディアの合計投稿数 */
    totalPostCount?: Maybe<Scalars["Int"]>;
    /** 各メディアの平均フォロワー上昇率 */
    averageIncreaseRate?: Maybe<Scalars["Float"]>;
    /** maxViewCount */
    maxViewCount?: Maybe<Scalars["Float"]>;
    /** 各メディアの合計平均いいね数 */
    totalLikeCount?: Maybe<Scalars["Float"]>;
    /** 各メディアの合計平均コメント数 */
    totalCommentCount?: Maybe<Scalars["Float"]>;
    /** 各メディアの合計平均シェア数 */
    totalShareCount?: Maybe<Scalars["Float"]>;
    /** 各メディアの合計平均ビュー数 */
    totalViewCount?: Maybe<Scalars["Float"]>;
    /** 各メディアの平均エンゲージメント率 */
    averageEngagement?: Maybe<Scalars["Float"]>;
    /** 各メディアの平均リーチ数 */
    averageReachCount?: Maybe<Scalars["Float"]>;
    /** 各メディアの平均インプレッション数 */
    averageImpressionCount?: Maybe<Scalars["Float"]>;
    /** 各メディアの平均view数 */
    averageViewCount?: Maybe<Scalars["Float"]>;
    averageLikeCount?: Maybe<Scalars["Float"]>;
    averageCommentCount?: Maybe<Scalars["Float"]>;
    /** 各メディアの平均シェア数 */
    averageShareCount?: Maybe<Scalars["Float"]>;
    /** 各メディアの平均リテンション率 */
    averageRetentionRate?: Maybe<Scalars["Float"]>;
    /** 各メディアの平均保存数 */
    averageClippingCount?: Maybe<Scalars["Float"]>;
    /** キャンペーン売上 */
    totalSalesValue?: Maybe<Scalars["Int"]>;
    /** ? */
    costReachRate?: Maybe<Scalars["Float"]>;
    /** ? */
    costImpressionRate?: Maybe<Scalars["Float"]>;
    /** ? */
    costViewRate?: Maybe<Scalars["Float"]>;
    /** ? */
    costEngagementRate?: Maybe<Scalars["Float"]>;
    /** ? */
    costClippingRate?: Maybe<Scalars["Float"]>;
    /** ? */
    costSalesRate?: Maybe<Scalars["Float"]>;
    /** ? */
    awarenessScore?: Maybe<Scalars["Float"]>;
    /** ? */
    interestScore?: Maybe<Scalars["Float"]>;
    /** ? */
    considerationScore?: Maybe<Scalars["Float"]>;
    /** ? */
    conversionScore?: Maybe<Scalars["Float"]>;
    /** SNS情報 */
    socialMedia?: Maybe<SocialMedia>;
    /** 投稿URL */
    postUrl?: Maybe<Scalars["URI"]>;
    /** ステータス */
    status?: Maybe<PostStatus>;
    /** インスタグラム投稿内容 */
    instagramPost?: Maybe<InstagramPost>;
    /** メディアごとのインサイト・提案状況 */
    insightsBySocialMedia: Array<ReportInfluencerInsightBySocialMedia>;
    agency?: Maybe<AgencyInfo>;
    valueCheckStatus: ValueCheckStatus;
    valueCheckDate?: Maybe<Scalars["String"]>;
    /** アカウント存在チェックステータス */
    checkAccountStatus: CheckAccountStatus;
  };

export type ReportInfluencerConnection = Connection & {
  __typename?: "ReportInfluencerConnection";
  paginatorInfo?: Maybe<PaginatorInfo>;
  edges?: Maybe<Array<Maybe<ReportInfluencerEdge>>>;
};

export type ReportInfluencerEdge = Edge & {
  __typename?: "ReportInfluencerEdge";
  cursor: Scalars["String"];
  node: ReportInfluencerNode;
};

export type ReportInfluencerInsightBySocialMedia = InfluencerInsightBySocialMedia &
  Node & {
    __typename?: "ReportInfluencerInsightBySocialMedia";
    id: Scalars["ID"];
    /** 投稿URL */
    postUrl?: Maybe<Scalars["URI"]>;
    /** ステータス */
    status: PostStatus;
    /**
     * 発注のやり取りの段階で元々投稿予定のメディアかどうか。
     * 発注まででやり取りをしていた場合はtrue、ギフティング等でreportアップロードの段階で新規で追加をされた場合はfalse
     * instagramのみ発注段階で投稿予定で、twitterもレポート段階で追加をされた場合はinstagramはtrue、twitterはfalseにする
     **/
    isPlanned: Scalars["Boolean"];
    /** SNS種別 */
    socialMediaType: SocialMediaType;
    /** ユーザーをInstagram上で検索する時に使い、各ユーザーの詳細ページのURLに文字列として含まれているID e.g.)anriworld */
    accountId: Scalars["ID"];
    /** アカウントのURL */
    accountUrl: Scalars["URI"];
    /** メディアごとの費用 */
    cost: Scalars["Int"];
    /** メディアごとのコミッション */
    commission?: Maybe<Scalars["Int"]>;
    /** インフルエンサー費用 */
    influencerCost?: Maybe<Scalars["Int"]>;
    /** 二次利用費 */
    costUsageRight?: Maybe<Scalars["Int"]>;
    /** 二次利用費 */
    productSendOutCost?: Maybe<Scalars["Int"]>;
    /** アフィニティレート */
    affinityRate?: Maybe<Scalars["Float"]>;
    /** アドボケイトスコア */
    advocateScore?: Maybe<Scalars["Float"]>;
    /** 各メディアのアカウント情報（フォロワー数等）、投稿情報（いいね数、コメント数等）、アフィニティレートをすべて取得完了をしているか、完了している場合はtrue */
    isDataCollectionCompleted: Scalars["Boolean"];
    /** 各メディア上で取得をできる情報 */
    socialMediaPostInsight?: Maybe<SocialMediaPostInsight>;
    /** ? */
    affinityUpperThreshold?: Maybe<Scalars["Int"]>;
    /** ? */
    affinityLowerThreshold?: Maybe<Scalars["Int"]>;
    /** アカウント存在チェックステータス */
    checkAccountStatus: CheckAccountStatus;
  };

export type ReportInfluencerNode = Node & {
  __typename?: "ReportInfluencerNode";
  id: Scalars["ID"];
  reportInfluencer: ReportInfluencer;
};

export type ReportInfluencerOrderByClause = {
  /** The column that is used for ordering. */
  field: ReportInfluencerOrderByColumn;
  /** SNS種別 */
  mediaType?: Maybe<SocialMediaType>;
  /** The direction that is used for ordering. */
  order: SortOrder;
};

/** report_influencer_order_by_column */
export enum ReportInfluencerOrderByColumn {
  FollowerCount = "FOLLOWER_COUNT",
  IncreaseRate = "INCREASE_RATE",
  Score = "SCORE",
  Cost = "COST",
  LikeCount = "LIKE_COUNT",
  CommentCount = "COMMENT_COUNT",
  Engagement = "ENGAGEMENT",
  AffinityScore = "AFFINITY_SCORE",
  AffinityRate = "AFFINITY_RATE",
  AdvocateScore = "ADVOCATE_SCORE",
  ViewCount = "VIEW_COUNT",
  MaxViewCount = "MAX_VIEW_COUNT"
}

export type ReportInstagramSummary = SummaryInfluencerScore & {
  __typename?: "ReportInstagramSummary";
  /** SNS種別 */
  socialMediaType: SocialMediaType;
  /** 合計コスト */
  totalCost: Scalars["Int"];
  /** 合計インフルエンサー費用 */
  totalInfluencerCost: Scalars["Int"];
  /** 合計コミッション */
  totalCommission: Scalars["Int"];
  /** 各メディアの合計二次利用費 */
  totalCostUsageRight: Scalars["Int"];
  /** 各メディアの合計配送費用 */
  totalProductSendOutCost: Scalars["Int"];
  /** 合計フォロワー数 */
  totalFollower?: Maybe<Scalars["Int"]>;
  /** 合計インフルエンサー数 */
  totalInfluencerCount: Scalars["Int"];
  /** 合計フォロー数 */
  totalFollow?: Maybe<Scalars["Int"]>;
  /** 平均エンゲージメント率 */
  averageEngagementRate?: Maybe<Scalars["Float"]>;
  /** 平均アフィニティスコア */
  averageAffinityScoreRate?: Maybe<Scalars["Float"]>;
  /** 平均アフィニティレート */
  averageAffinityRate?: Maybe<Scalars["Float"]>;
  /** 平均アドボケイトスコア */
  averageAdvocateScoreRate?: Maybe<Scalars["Float"]>;
  /** 合計リーチ数 */
  totalReachCount?: Maybe<Scalars["Int"]>;
  /** 合計インプレッション数 */
  totalImpressionCount?: Maybe<Scalars["Int"]>;
  /** 合計保存数 */
  totalClippingCount?: Maybe<Scalars["Int"]>;
  /** 合計ECクリック数 */
  totalEcClickCount?: Maybe<Scalars["Int"]>;
  /** 合計いいね数 */
  totalLikeCount?: Maybe<Scalars["Float"]>;
  /** 合計コメント数 */
  totalCommentCount?: Maybe<Scalars["Float"]>;
  /** キャンペーン売上 */
  totalSalesValue?: Maybe<Scalars["Int"]>;
  /** ? */
  costReachRate?: Maybe<Scalars["Float"]>;
  /** ? */
  costImpressionRate?: Maybe<Scalars["Float"]>;
  /** ? */
  costEngagementRate?: Maybe<Scalars["Float"]>;
  /** ? */
  costClippingRate?: Maybe<Scalars["Float"]>;
  /** ? */
  costEcClickRate?: Maybe<Scalars["Float"]>;
  /** ? */
  costSalesRate?: Maybe<Scalars["Float"]>;
  /** ? */
  awarenessScore?: Maybe<Scalars["Float"]>;
  /** ? */
  interestScore?: Maybe<Scalars["Float"]>;
  /** ? */
  considerationScore?: Maybe<Scalars["Float"]>;
  /** ? */
  conversionScore?: Maybe<Scalars["Float"]>;
};

export type ReportPlannedTotalSummary = SummaryInfluencerScore & {
  __typename?: "ReportPlannedTotalSummary";
  /** 合計コスト */
  totalCost: Scalars["Int"];
  /** 合計コミッション */
  totalCommission: Scalars["Int"];
  /** 合計フォロワー数 */
  totalFollower?: Maybe<Scalars["Int"]>;
  /** 合計インフルエンサー数 */
  totalInfluencerCount: Scalars["Int"];
  /** 合計フォロー数 */
  totalFollow?: Maybe<Scalars["Int"]>;
  /** 平均エンゲージメント率 */
  averageEngagementRate?: Maybe<Scalars["Float"]>;
  /** 平均アフィニティレート */
  averageAffinityRate?: Maybe<Scalars["Float"]>;
  /** 平均アドボケイトスコア */
  averageAdvocateScoreRate?: Maybe<Scalars["Float"]>;
};

export type ReportTiktokSummary = SummaryInfluencerScore & {
  __typename?: "ReportTiktokSummary";
  /** SNS種別 */
  socialMediaType: SocialMediaType;
  /** 合計コスト */
  totalCost: Scalars["Int"];
  /** 合計インフルエンサー費用 */
  totalInfluencerCost: Scalars["Int"];
  /** 合計コミッション */
  totalCommission: Scalars["Int"];
  /** 各メディアの合計二次利用費 */
  totalCostUsageRight: Scalars["Int"];
  /** 各メディアの合計配送費用 */
  totalProductSendOutCost: Scalars["Int"];
  /** 合計フォロワー数 */
  totalFollower?: Maybe<Scalars["Int"]>;
  /** 合計インフルエンサー数 */
  totalInfluencerCount: Scalars["Int"];
  /** 合計フォロー数 */
  totalFollow?: Maybe<Scalars["Int"]>;
  /** 平均エンゲージメント率 */
  averageEngagementRate?: Maybe<Scalars["Float"]>;
  /** 平均アフィニティレート */
  averageAffinityRate?: Maybe<Scalars["Float"]>;
  /** 平均アドボケイトスコア */
  averageAdvocateScoreRate?: Maybe<Scalars["Float"]>;
  /** 合計view数 */
  totalViewCount?: Maybe<Scalars["Int"]>;
  /** 合計シェア数 */
  totalShareCount?: Maybe<Scalars["Int"]>;
  /** 平均リテンション率 */
  averageRetentionRate?: Maybe<Scalars["Float"]>;
  /** 合計保存数 */
  totalClippingCount?: Maybe<Scalars["Int"]>;
  /** 合計ECクリック数 */
  totalEcClickCount?: Maybe<Scalars["Int"]>;
  /** 合計いいね数 */
  totalLikeCount?: Maybe<Scalars["Float"]>;
  /** 合計コメント数 */
  totalCommentCount?: Maybe<Scalars["Float"]>;
  /** キャンペーン売上 */
  totalSalesValue?: Maybe<Scalars["Int"]>;
  /** ? */
  costViewRate?: Maybe<Scalars["Float"]>;
  /** ? */
  costEngagementRate?: Maybe<Scalars["Float"]>;
  /** ? */
  costClippingRate?: Maybe<Scalars["Float"]>;
  /** ? */
  costEcClickRate?: Maybe<Scalars["Float"]>;
  /** ? */
  costSalesRate?: Maybe<Scalars["Float"]>;
  /** ? */
  awarenessScore?: Maybe<Scalars["Float"]>;
  /** ? */
  interestScore?: Maybe<Scalars["Float"]>;
  /** ? */
  considerationScore?: Maybe<Scalars["Float"]>;
  /** ? */
  conversionScore?: Maybe<Scalars["Float"]>;
};

export type ReportTwitterSummary = SummaryInfluencerScore & {
  __typename?: "ReportTwitterSummary";
  /** SNS種別 */
  socialMediaType: SocialMediaType;
  /** 合計コスト */
  totalCost: Scalars["Int"];
  /** 合計インフルエンサー費用 */
  totalInfluencerCost: Scalars["Int"];
  /** 合計コミッション */
  totalCommission: Scalars["Int"];
  /** 各メディアの合計二次利用費 */
  totalCostUsageRight: Scalars["Int"];
  /** 各メディアの合計配送費用 */
  totalProductSendOutCost: Scalars["Int"];
  /** 合計フォロワー数 */
  totalFollower?: Maybe<Scalars["Int"]>;
  /** 合計インフルエンサー数 */
  totalInfluencerCount: Scalars["Int"];
  /** 合計フォロー数 */
  totalFollow?: Maybe<Scalars["Int"]>;
  /** 平均エンゲージメント率 */
  averageEngagementRate?: Maybe<Scalars["Float"]>;
  /** 平均アフィニティスコア */
  averageAffinityScoreRate?: Maybe<Scalars["Float"]>;
  /** 平均アフィニティレート */
  averageAffinityRate?: Maybe<Scalars["Float"]>;
  /** 平均アドボケイトスコア */
  averageAdvocateScoreRate?: Maybe<Scalars["Float"]>;
  /** 合計リーチ数 */
  totalReachCount?: Maybe<Scalars["Int"]>;
  /** 合計インプレッション数 */
  totalImpressionCount?: Maybe<Scalars["Int"]>;
  /** 合計シェア数 */
  totalShareCount?: Maybe<Scalars["Int"]>;
  /** 合計保存数 */
  totalClippingCount?: Maybe<Scalars["Int"]>;
  /** 合計ECクリック数 */
  totalEcClickCount?: Maybe<Scalars["Int"]>;
  /** 合計いいね数 */
  totalLikeCount?: Maybe<Scalars["Float"]>;
  /** 合計コメント数 */
  totalCommentCount?: Maybe<Scalars["Float"]>;
  /** キャンペーン売上 */
  totalSalesValue?: Maybe<Scalars["Int"]>;
  /** ? */
  costReachRate?: Maybe<Scalars["Float"]>;
  /** ? */
  costImpressionRate?: Maybe<Scalars["Float"]>;
  /** ? */
  costEngagementRate?: Maybe<Scalars["Float"]>;
  /** ? */
  costClippingRate?: Maybe<Scalars["Float"]>;
  /** ? */
  costEcClickRate?: Maybe<Scalars["Float"]>;
  /** ? */
  costSalesRate?: Maybe<Scalars["Float"]>;
  /** ? */
  awarenessScore?: Maybe<Scalars["Float"]>;
  /** ? */
  interestScore?: Maybe<Scalars["Float"]>;
  /** ? */
  considerationScore?: Maybe<Scalars["Float"]>;
  /** ? */
  conversionScore?: Maybe<Scalars["Float"]>;
};

export type ReportYoutubeSummary = SummaryInfluencerScore & {
  __typename?: "ReportYoutubeSummary";
  /** SNS種別 */
  socialMediaType: SocialMediaType;
  /** 合計コスト */
  totalCost: Scalars["Int"];
  /** 合計インフルエンサー費用 */
  totalInfluencerCost: Scalars["Int"];
  /** 合計コミッション */
  totalCommission: Scalars["Int"];
  /** 各メディアの合計二次利用費 */
  totalCostUsageRight: Scalars["Int"];
  /** 各メディアの合計配送費用 */
  totalProductSendOutCost: Scalars["Int"];
  /** 合計フォロワー数 */
  totalFollower?: Maybe<Scalars["Int"]>;
  /** 合計インフルエンサー数 */
  totalInfluencerCount: Scalars["Int"];
  /** 合計フォロー数 */
  totalFollow?: Maybe<Scalars["Int"]>;
  /** 平均エンゲージメント率 */
  averageEngagementRate?: Maybe<Scalars["Float"]>;
  /** 平均アフィニティレート */
  averageAffinityRate?: Maybe<Scalars["Float"]>;
  /** 平均アドボケイトスコア */
  averageAdvocateScoreRate?: Maybe<Scalars["Float"]>;
  /** 合計view数 */
  totalViewCount?: Maybe<Scalars["Int"]>;
  /** 平均リテンション率 */
  averageRetentionRate?: Maybe<Scalars["Float"]>;
  /** 合計保存数 */
  totalClippingCount?: Maybe<Scalars["Int"]>;
  /** 合計ECクリック数 */
  totalEcClickCount?: Maybe<Scalars["Int"]>;
  /** 合計いいね数 */
  totalLikeCount?: Maybe<Scalars["Float"]>;
  /** 合計コメント数 */
  totalCommentCount?: Maybe<Scalars["Float"]>;
  /** キャンペーン売上 */
  totalSalesValue?: Maybe<Scalars["Int"]>;
  /** ? */
  costViewRate?: Maybe<Scalars["Float"]>;
  /** ? */
  costEngagementRate?: Maybe<Scalars["Float"]>;
  /** ? */
  costClippingRate?: Maybe<Scalars["Float"]>;
  /** ? */
  costEcClickRate?: Maybe<Scalars["Float"]>;
  /** ? */
  costSalesRate?: Maybe<Scalars["Float"]>;
  /** ? */
  awarenessScore?: Maybe<Scalars["Float"]>;
  /** ? */
  interestScore?: Maybe<Scalars["Float"]>;
  /** ? */
  considerationScore?: Maybe<Scalars["Float"]>;
  /** ? */
  conversionScore?: Maybe<Scalars["Float"]>;
};

export type RequestAvailabilityApprovalInput = {
  clientMutationId?: Maybe<Scalars["String"]>;
  campaignId: Scalars["ID"];
};

export type RequestAvailabilityApprovalPayload = {
  __typename?: "RequestAvailabilityApprovalPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  assignment: AgencyAssignment;
};

export type ScoredInfluencer = BaseInfluencer &
  Node & {
    __typename?: "ScoredInfluencer";
    id: Scalars["ID"];
    /** インフルエンサー名 */
    name: Scalars["String"];
    /** インフルエンサー画像 */
    image?: Maybe<Scalars["URI"]>;
    /** SNS情報 */
    socialMedia?: Maybe<SocialMedia>;
    number: Scalars["Int"];
    /** アフィニティレート */
    affinityRate: Scalars["Float"];
    createdAt: Scalars["DateTime"];
    updatedAt: Scalars["DateTime"];
  };

export type ScoredInfluencerConnection = Connection & {
  __typename?: "ScoredInfluencerConnection";
  paginatorInfo?: Maybe<PaginatorInfo>;
  edges?: Maybe<Array<Maybe<ScoredInfluencerEdge>>>;
};

export type ScoredInfluencerEdge = Edge & {
  __typename?: "ScoredInfluencerEdge";
  cursor: Scalars["String"];
  node: ScoredInfluencerNode;
};

export type ScoredInfluencerNode = Node & {
  __typename?: "ScoredInfluencerNode";
  id: Scalars["ID"];
  scoredInfluencer: ScoredInfluencer;
};

export type ScoredInfluencerOrderByClause = {
  /** The column that is used for ordering. */
  field: ScoredInfluencersOrderByColumn;
  /** The direction that is used for ordering. */
  order: SortOrder;
};

/** scored_influencers_order_by_column */
export enum ScoredInfluencersOrderByColumn {
  FollowerCount = "FOLLOWER_COUNT",
  IncreaseRate = "INCREASE_RATE",
  Score = "SCORE",
  AffinityRate = "AFFINITY_RATE"
}

export type SendAssignmentCommentInput = {
  clientMutationId?: Maybe<Scalars["String"]>;
  comment: Scalars["String"];
  assignmentId: Scalars["ID"];
};

export type SendCommentPayload = {
  __typename?: "SendCommentPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  comments: Array<Comment>;
};

export type SendDraftAssignmentCommentInput = {
  clientMutationId?: Maybe<Scalars["String"]>;
  comment: Scalars["String"];
  assignmentId: Scalars["ID"];
};

export type SendDraftCommentPayload = {
  __typename?: "SendDraftCommentPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  comment: Comment;
};

export type SendDraftReportCommentInput = {
  clientMutationId?: Maybe<Scalars["String"]>;
  comment: Scalars["String"];
  campaignId: Scalars["ID"];
  agencyId: Scalars["ID"];
};

export type SendDraftSuggestionCommentInput = {
  clientMutationId?: Maybe<Scalars["String"]>;
  comment: Scalars["String"];
  campaignId: Scalars["ID"];
  agencyId: Scalars["ID"];
};

export type SendReportCommentInput = {
  clientMutationId?: Maybe<Scalars["String"]>;
  comment: Scalars["String"];
  campaignId: Scalars["ID"];
  agencyId: Scalars["ID"];
};

export type SendSuggestionCommentInput = {
  clientMutationId?: Maybe<Scalars["String"]>;
  comment: Scalars["String"];
  campaignId: Scalars["ID"];
  agencyId: Scalars["ID"];
};

export type SkipToReportInput = {
  clientMutationId?: Maybe<Scalars["String"]>;
  campaignId: Scalars["ID"];
  agencyIds: Array<Scalars["ID"]>;
};

export type SkipToReportPayload = {
  __typename?: "SkipToReportPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  campaign: ClientCampaign;
};

export type SnapInfluencerEvaluation = InfluencerEvaluation &
  Node & {
    __typename?: "SnapInfluencerEvaluation";
    id: Scalars["ID"];
    number: Scalars["Int"];
    /** アフィニティレート */
    affinityRate: Scalars["Float"];
    evaluation: EvaluationType;
    note?: Maybe<Scalars["String"]>;
    evaluatedAt: Scalars["DateTime"];
    trainingInfluencer: SnapTrainingInfluencer;
  };

export type SnapInfluencerEvaluationConnection = Connection & {
  __typename?: "SnapInfluencerEvaluationConnection";
  paginatorInfo?: Maybe<PaginatorInfo>;
  edges?: Maybe<Array<SnapInfluencerEvaluationEdge>>;
};

export type SnapInfluencerEvaluationEdge = Edge & {
  __typename?: "SnapInfluencerEvaluationEdge";
  cursor: Scalars["String"];
  node: SnapInfluencerEvaluationNode;
};

export type SnapInfluencerEvaluationGroup = Node & {
  __typename?: "SnapInfluencerEvaluationGroup";
  id: Scalars["ID"];
  number: Scalars["Int"];
  title: Scalars["String"];
  description?: Maybe<Scalars["String"]>;
  socialMediaType: SocialMediaType;
  createdAt: Scalars["DateTime"];
  updatedAt: Scalars["DateTime"];
  evaluationCount: Scalars["Int"];
  evaluationMatchedCount: Scalars["Int"];
  evaluationConsideringCount: Scalars["Int"];
  evaluationUnmatchedCount: Scalars["Int"];
  influencerEvaluationGroup: InfluencerEvaluationGroup;
  snapInfluencerEvaluations?: Maybe<SnapInfluencerEvaluationConnection>;
  snapInfluencerEvaluation?: Maybe<SnapInfluencerEvaluation>;
};

export type SnapInfluencerEvaluationGroupSnapInfluencerEvaluationsArgs = {
  perPage?: Maybe<Scalars["Int"]>;
  page: Scalars["Int"];
};

export type SnapInfluencerEvaluationGroupSnapInfluencerEvaluationArgs = {
  number: Scalars["Int"];
};

export type SnapInfluencerEvaluationGroupConnection = Connection & {
  __typename?: "SnapInfluencerEvaluationGroupConnection";
  paginatorInfo?: Maybe<PaginatorInfo>;
  edges?: Maybe<Array<Maybe<SnapInfluencerEvaluationGroupEdge>>>;
};

export type SnapInfluencerEvaluationGroupEdge = Edge & {
  __typename?: "SnapInfluencerEvaluationGroupEdge";
  cursor: Scalars["String"];
  node: SnapInfluencerEvaluationGroupNode;
};

export type SnapInfluencerEvaluationGroupNode = Node & {
  __typename?: "SnapInfluencerEvaluationGroupNode";
  id: Scalars["ID"];
  snapInfluencerEvaluationGroup: SnapInfluencerEvaluationGroup;
};

export type SnapInfluencerEvaluationNode = Node & {
  __typename?: "SnapInfluencerEvaluationNode";
  id: Scalars["ID"];
  snapInfluencerEvaluation: SnapInfluencerEvaluation;
};

export type SnapTrainingInfluencer = BaseInfluencer &
  Node & {
    __typename?: "SnapTrainingInfluencer";
    id: Scalars["ID"];
    name: Scalars["String"];
    image?: Maybe<Scalars["URI"]>;
    socialMedia?: Maybe<SocialMedia>;
    codes: Array<Scalars["String"]>;
  };

export type SocialMedia = {
  __typename?: "SocialMedia";
  instagram?: Maybe<Instagram>;
};

export type SocialMediaId = {
  /** ユーザーをSocialMedia上で検索する時に使い、各ユーザーの詳細ページのURLに文字列として含まれているID e.g.)anriworld */
  accountId: Scalars["String"];
};

export type SocialMediaInsight =
  | InstagramInsight
  | TwitterInsight
  | YoutubeInsight
  | TiktokInsight;

export type SocialMediaPostInsight =
  | InstagramPostInsight
  | TwitterPostInsight
  | YoutubePostInsight
  | TiktokPostInsight;

/** influencer_evaluation_group.social_media_type */
export enum SocialMediaType {
  Instagram = "INSTAGRAM",
  Twitter = "TWITTER",
  Youtube = "YOUTUBE",
  Tiktok = "TIKTOK"
}

/** The available directions for ordering a list of records. */
export enum SortOrder {
  /** Sort records in ascending order. */
  Asc = "ASC",
  /** Sort records in descending order. */
  Desc = "DESC"
}

export type Suggestion = SummaryInfluencerScore &
  SummaryConsiderationInfluencer &
  Node & {
    __typename?: "Suggestion";
    id: Scalars["ID"];
    number: Scalars["Int"];
    /** 提案日でincrementされたname(YYMMDD表記) e.g.210228_1,210228_2 */
    name: Scalars["String"];
    /** 全インフルエンサーのアカウント情報、アフィニティレートをすべて取得完了をしているか */
    isDataCollectionCompleted: Scalars["Boolean"];
    /** 合計コスト */
    totalCost: Scalars["Int"];
    /** 合計コミッション */
    totalCommission: Scalars["Int"];
    /** 合計インフルエンサー数 */
    totalInfluencerCount: Scalars["Int"];
    /** 合計フォロワー数 */
    totalFollower?: Maybe<Scalars["Int"]>;
    /** 合計フォロー数 */
    totalFollow?: Maybe<Scalars["Int"]>;
    /** 平均エンゲージメント率 */
    averageEngagementRate?: Maybe<Scalars["Float"]>;
    /** 平均スコア率 */
    averageScoreRate?: Maybe<Scalars["Float"]>;
    /** 平均アフィニティスコア */
    averageAffinityScoreRate?: Maybe<Scalars["Float"]>;
    /** 平均アフィニティレート */
    averageAffinityRate?: Maybe<Scalars["Float"]>;
    /** 平均アドボケイトスコア */
    averageAdvocateScoreRate?: Maybe<Scalars["Float"]>;
    /** 合計検討中インフルエンサー数 */
    totalConsiderationInfluencerCount: Scalars["Int"];
    /** 各メディアごとの提案の概要 */
    summaries: Array<SummaryInfluencerScore>;
    /** 代理店 */
    agency: AgencyInfo;
    /** 備考 */
    note?: Maybe<Scalars["String"]>;
    /** 添付ファイル */
    attachmentFiles: Array<AttachmentFile>;
    /** 提案インフルエンサーリスト */
    suggestionInfluencers?: Maybe<SuggestionInfluencerConnection>;
    /** 提案インフルエンサー */
    suggestionInfluencer?: Maybe<SuggestionInfluencer>;
    /** 過去に既にオーダー済みか */
    isOrdered?: Maybe<Scalars["Boolean"]>;
    /** コメント未読件数 */
    unreadCommentCount: Scalars["Int"];
    createdAt: Scalars["DateTime"];
    updatedAt: Scalars["DateTime"];
  };

export type SuggestionSummariesArgs = {
  considerationOnly?: Maybe<Scalars["Boolean"]>;
};

export type SuggestionSuggestionInfluencersArgs = {
  perPage?: Maybe<Scalars["Int"]>;
  page: Scalars["Int"];
  orderBy?: Maybe<SuggestionInfluencerOrderByClause>;
  searchBy?: Maybe<Scalars["String"]>;
  isConsideration?: Maybe<Scalars["Boolean"]>;
  excludeAssignment?: Maybe<Scalars["Boolean"]>;
  isAdditionalConsideration?: Maybe<Scalars["Boolean"]>;
  influencerName?: Maybe<Scalars["String"]>;
};

export type SuggestionSuggestionInfluencerArgs = {
  number: Scalars["Int"];
};

export type SuggestionCostComparison = {
  __typename?: "SuggestionCostComparison";
  /** 同一インフルエンサーの提案の平均費用 */
  averageCost: Scalars["Float"];
  /** 同一インフルエンサーの総提案数 */
  suggestionCount: Scalars["Int"];
  /** 同一インフルエンサーで提案をされた中で何番目に金額が低い提案をしたか */
  costRanking: Scalars["Int"];
};

export type SuggestionInfluencer = Node & {
  __typename?: "SuggestionInfluencer";
  id: Scalars["ID"];
  name: Scalars["String"];
  number: Scalars["Int"];
  /** プロフィール画像 */
  image?: Maybe<Scalars["URI"]>;
  /** SNS種別 */
  socialMediaType: SocialMediaType;
  /** アカウントID */
  accountId?: Maybe<Scalars["String"]>;
  /** 費用 */
  cost: Scalars["Int"];
  /** アフィニティスコア */
  score?: Maybe<Scalars["Float"]>;
  /** 各メディアの合計費用 */
  totalCost: Scalars["Int"];
  /** 各メディアの合計コミッション */
  totalCommission: Scalars["Int"];
  /** 各メディアの平均アフィニティスコア */
  averageAffinityScore?: Maybe<Scalars["Float"]>;
  /** 各メディアの平均アフィニティレート */
  averageAffinityRate?: Maybe<Scalars["Float"]>;
  /** 各メディアの平均アドボケイトスコア */
  averageAdvocateScore?: Maybe<Scalars["Float"]>;
  /** 同一インフルエンサーの提案のトータルでの比較情報 */
  costComparison: SuggestionCostComparison;
  /** 各メディアの合計フォロワー数 */
  totalFollowerCount?: Maybe<Scalars["Int"]>;
  /** 各メディアの合計フォロー数 */
  totalFollowCount?: Maybe<Scalars["Int"]>;
  /** 各メディアの合計投稿数 */
  totalPostCount?: Maybe<Scalars["Int"]>;
  /** 各メディアの平均フォロワー上昇率 */
  averageIncreaseRate?: Maybe<Scalars["Float"]>;
  /** 最高ビュー数 */
  maxViewCount?: Maybe<Scalars["Int"]>;
  /** 各メディアの合計平均いいね数 */
  totalLikeCount?: Maybe<Scalars["Float"]>;
  /** 各メディアの合計平均コメント数 */
  totalCommentCount?: Maybe<Scalars["Float"]>;
  /** 各メディアの合計平均シェア数 */
  totalShareCount?: Maybe<Scalars["Float"]>;
  /** 各メディアの合計平均ビュー数 */
  totalViewCount?: Maybe<Scalars["Float"]>;
  /** 各メディアの平均エンゲージメント率 */
  averageEngagement?: Maybe<Scalars["Float"]>;
  /** 検討中かどうか */
  isConsideration: Scalars["Boolean"];
  /** 追加発注検討中かどうか */
  isAdditionalConsideration: Scalars["Boolean"];
  /** インフルエンサー情報の取得中ステータス */
  loadingStatus: InfluencerLoadingStatus;
  /** SPADインフルエンサー情報 */
  influencer?: Maybe<Influencer>;
  /** メディアごとのインサイト・提案状況 */
  insightsBySocialMedia: Array<SuggestionInfluencerInsightBySocialMedia>;
  createdAt: Scalars["DateTime"];
  updatedAt: Scalars["DateTime"];
  averageReachCount?: Maybe<Scalars["Float"]>;
  /** 各メディアの平均インプレッション数 */
  averageImpressionCount?: Maybe<Scalars["Float"]>;
  /** 各メディアの平均view数 */
  averageViewCount?: Maybe<Scalars["Float"]>;
  averageLikeCount?: Maybe<Scalars["Float"]>;
  averageCommentCount?: Maybe<Scalars["Float"]>;
  /** 各メディアの平均シェア数 */
  averageShareCount?: Maybe<Scalars["Float"]>;
  /** 各メディアの平均リテンション率 */
  averageRetentionRate?: Maybe<Scalars["Float"]>;
  /** 各メディアの平均保存数 */
  averageClippingCount?: Maybe<Scalars["Float"]>;
  /** キャンペーン売上 */
  totalSalesValue?: Maybe<Scalars["Int"]>;
  valueCheckStatus: ValueCheckStatus;
  valueCheckDate?: Maybe<Scalars["String"]>;
  /** アカウント存在チェックステータス */
  checkAccountStatus: CheckAccountStatus;
};

export type SuggestionInfluencerConnection = Connection & {
  __typename?: "SuggestionInfluencerConnection";
  paginatorInfo?: Maybe<PaginatorInfo>;
  edges?: Maybe<Array<Maybe<SuggestionInfluencerEdge>>>;
};

export type SuggestionInfluencerEdge = Edge & {
  __typename?: "SuggestionInfluencerEdge";
  cursor: Scalars["String"];
  node: SuggestionInfluencerNode;
};

export type SuggestionInfluencerInsightBySocialMedia = InfluencerInsightBySocialMedia &
  Node & {
    __typename?: "SuggestionInfluencerInsightBySocialMedia";
    id: Scalars["ID"];
    /** SNS種別 */
    socialMediaType: SocialMediaType;
    /** ユーザーをInstagram上で検索する時に使い、各ユーザーの詳細ページのURLに文字列として含まれているID e.g.)anriworld */
    accountId: Scalars["ID"];
    /** アカウントのURL */
    accountUrl: Scalars["URI"];
    /** メディアごとの費用 */
    cost: Scalars["Int"];
    /** メディアごとのコミッション */
    commission?: Maybe<Scalars["Int"]>;
    /** アフィニティレート */
    affinityRate?: Maybe<Scalars["Float"]>;
    /** アドボケイトスコア */
    advocateScore?: Maybe<Scalars["Float"]>;
    /** 各メディアのアカウント情報（フォロワー数、平均いいね数等）、アフィニティレートをすべて取得完了をしているか、完了している場合はtrue */
    isDataCollectionCompleted: Scalars["Boolean"];
    /** 同一インフルエンサーで同一メディアの提案の比較情報 */
    costComparison?: Maybe<SuggestionCostComparison>;
    /** 各メディア上で取得をできる情報 */
    socialMediaInsight?: Maybe<SocialMediaInsight>;
    /** ? */
    affinityUpperThreshold?: Maybe<Scalars["Int"]>;
    /** ? */
    affinityLowerThreshold?: Maybe<Scalars["Int"]>;
    /** アカウント存在チェックステータス */
    checkAccountStatus: CheckAccountStatus;
  };

export type SuggestionInfluencerNode = Node & {
  __typename?: "SuggestionInfluencerNode";
  id: Scalars["ID"];
  suggestionInfluencer: SuggestionInfluencer;
};

export type SuggestionInfluencerOrderByClause = {
  /** The column that is used for ordering. */
  field: SuggestionInfluencerOrderByColumn;
  mediaType?: Maybe<SocialMediaType>;
  /** The direction that is used for ordering. */
  order: SortOrder;
};

/** suggestion_influencer_order_by_column */
export enum SuggestionInfluencerOrderByColumn {
  FollowerCount = "FOLLOWER_COUNT",
  IncreaseRate = "INCREASE_RATE",
  Score = "SCORE",
  Cost = "COST",
  AverageLikeCount = "AVERAGE_LIKE_COUNT",
  AverageCommentCount = "AVERAGE_COMMENT_COUNT",
  Engagement = "ENGAGEMENT",
  AffinityScore = "AFFINITY_SCORE",
  AffinityRate = "AFFINITY_RATE",
  AdvocateScore = "ADVOCATE_SCORE",
  ViewCount = "VIEW_COUNT",
  MaxViewCount = "MAX_VIEW_COUNT"
}

export type SuggestionInstagramSummary = SummaryInfluencerScore & {
  __typename?: "SuggestionInstagramSummary";
  /** SNS種別 */
  socialMediaType: SocialMediaType;
  /** 合計コスト */
  totalCost: Scalars["Int"];
  /** 合計コミッション */
  totalCommission?: Maybe<Scalars["Int"]>;
  /** 合計フォロワー数 */
  totalFollower?: Maybe<Scalars["Int"]>;
  /** 合計インフルエンサー数 */
  totalInfluencerCount: Scalars["Int"];
  /** 合計フォロー数 */
  totalFollow?: Maybe<Scalars["Int"]>;
  /** 平均エンゲージメント率 */
  averageEngagementRate?: Maybe<Scalars["Float"]>;
  /** 平均アフィニティスコア */
  averageAffinityScoreRate?: Maybe<Scalars["Float"]>;
  /** 平均アフィニティレート */
  averageAffinityRate?: Maybe<Scalars["Float"]>;
  /** 平均アドボケイトスコア */
  averageAdvocateScoreRate?: Maybe<Scalars["Float"]>;
};

/** campaign_agencies.status */
export enum SuggestionStatus {
  NotSuggested = "NOT_SUGGESTED",
  ClientChecking = "CLIENT_CHECKING",
  Winning = "WINNING",
  Rejected = "REJECTED"
}

export type SuggestionTiktokSummary = SummaryInfluencerScore & {
  __typename?: "SuggestionTiktokSummary";
  /** SNS種別 */
  socialMediaType: SocialMediaType;
  /** 合計コスト */
  totalCost: Scalars["Int"];
  /** 合計コミッション */
  totalCommission?: Maybe<Scalars["Int"]>;
  /** 合計フォロワー数 */
  totalFollower?: Maybe<Scalars["Int"]>;
  /** 合計インフルエンサー数 */
  totalInfluencerCount: Scalars["Int"];
  /** 合計フォロー数 */
  totalFollow?: Maybe<Scalars["Int"]>;
  /** 平均エンゲージメント率 */
  averageEngagementRate?: Maybe<Scalars["Float"]>;
  /** 平均アフィニティレート */
  averageAffinityRate?: Maybe<Scalars["Float"]>;
  /** 平均アドボケイトスコア */
  averageAdvocateScoreRate?: Maybe<Scalars["Float"]>;
};

export type SuggestionTwitterSummary = SummaryInfluencerScore & {
  __typename?: "SuggestionTwitterSummary";
  /** SNS種別 */
  socialMediaType: SocialMediaType;
  /** 合計コスト */
  totalCost: Scalars["Int"];
  /** 合計コミッション */
  totalCommission?: Maybe<Scalars["Int"]>;
  /** 合計フォロワー数 */
  totalFollower?: Maybe<Scalars["Int"]>;
  /** 合計インフルエンサー数 */
  totalInfluencerCount: Scalars["Int"];
  /** 合計フォロー数 */
  totalFollow?: Maybe<Scalars["Int"]>;
  /** 平均エンゲージメント率 */
  averageEngagementRate?: Maybe<Scalars["Float"]>;
  /** 平均アフィニティスコア */
  averageAffinityScoreRate?: Maybe<Scalars["Float"]>;
  /** 平均アフィニティレート */
  averageAffinityRate?: Maybe<Scalars["Float"]>;
  /** 平均アドボケイトスコア */
  averageAdvocateScoreRate?: Maybe<Scalars["Float"]>;
};

export type SuggestionYoutubeSummary = SummaryInfluencerScore & {
  __typename?: "SuggestionYoutubeSummary";
  /** SNS種別 */
  socialMediaType: SocialMediaType;
  /** 合計コスト */
  totalCost: Scalars["Int"];
  /** 合計コミッション */
  totalCommission?: Maybe<Scalars["Int"]>;
  /** 合計フォロワー数 */
  totalFollower?: Maybe<Scalars["Int"]>;
  /** 合計インフルエンサー数 */
  totalInfluencerCount: Scalars["Int"];
  /** 合計フォロー数 */
  totalFollow?: Maybe<Scalars["Int"]>;
  /** 平均エンゲージメント率 */
  averageEngagementRate?: Maybe<Scalars["Float"]>;
  /** 平均アフィニティレート */
  averageAffinityRate?: Maybe<Scalars["Float"]>;
  /** 平均アドボケイトスコア */
  averageAdvocateScoreRate?: Maybe<Scalars["Float"]>;
};

export type SummaryAssignmentInfluencer = {
  /** 合計可否OKインフルエンサー数 */
  totalAvailabilityOKInfluencerCount: Scalars["Int"];
  /** 合計可否NGインフルエンサー数 */
  totalAvailabilityNGInfluencerCount: Scalars["Int"];
};

export type SummaryConsiderationInfluencer = {
  /** 合計検討中インフルエンサー数 */
  totalConsiderationInfluencerCount: Scalars["Int"];
};

export type SummaryInfluencerScore = {
  /** 合計コスト */
  totalCost: Scalars["Int"];
  /** 合計コミッション */
  totalCommission?: Maybe<Scalars["Int"]>;
  /** 合計フォロワー数 */
  totalFollower?: Maybe<Scalars["Int"]>;
  /** 合計インフルエンサー数 */
  totalInfluencerCount: Scalars["Int"];
  /** 合計フォロー数 */
  totalFollow?: Maybe<Scalars["Int"]>;
  /** 平均エンゲージメント率 */
  averageEngagementRate?: Maybe<Scalars["Float"]>;
  /** 平均アフィニティレート */
  averageAffinityRate?: Maybe<Scalars["Float"]>;
  /** 平均アドボケイトスコア */
  averageAdvocateScoreRate?: Maybe<Scalars["Float"]>;
};

export type Tag = Node & {
  __typename?: "Tag";
  id: Scalars["ID"];
  name: Scalars["String"];
  createdAt: Scalars["DateTime"];
  updatedAt: Scalars["DateTime"];
};

export type TiktokInsight = {
  __typename?: "TiktokInsight";
  /** フォロワー数 */
  followerCount: Scalars["Int"];
  /** ビュー数 */
  viewCount: Scalars["Int"];
  /** 投稿数 */
  postCount: Scalars["Int"];
  /** 最高ビュー数 */
  maxViewCount?: Maybe<Scalars["Int"]>;
  /** フォロワー上昇率 */
  increaseRate?: Maybe<Scalars["Float"]>;
  /** 平均いいね数 */
  averageLikeCount: Scalars["Float"];
  /** 平均コメント数 */
  averageCommentCount: Scalars["Float"];
  /** エンゲージメント率 */
  engagement: Scalars["Float"];
  /** クロール日 */
  crawlSucceededAt: Scalars["Date"];
};

export type TiktokPostInsight = {
  __typename?: "TiktokPostInsight";
  /** フォロワー数 */
  followerCount?: Maybe<Scalars["Int"]>;
  /** フォロー数 */
  followCount?: Maybe<Scalars["Int"]>;
  /** followerIncreaseRate */
  followerIncreaseRate?: Maybe<Scalars["Float"]>;
  /** maxViewCount */
  maxViewCount?: Maybe<Scalars["Int"]>;
  /** averageCommentCount */
  averageCommentCount?: Maybe<Scalars["Float"]>;
  /** averageLikeCount */
  averageLikeCount?: Maybe<Scalars["Float"]>;
  /** AVG SHARE COUNT */
  averageShareCount?: Maybe<Scalars["Float"]>;
  /** AVG VIEW COUNT */
  averageViewCount?: Maybe<Scalars["Float"]>;
  /** 累計投稿数 */
  cumulativePostCount?: Maybe<Scalars["Int"]>;
  /** フォロワー上昇率 */
  increaseRate?: Maybe<Scalars["Float"]>;
  /** 該当の投稿に関してのいいね数 */
  likeCount?: Maybe<Scalars["Int"]>;
  /** 該当の投稿に関してのコメント数 */
  commentCount?: Maybe<Scalars["Int"]>;
  /** シェア数 */
  shareCount?: Maybe<Scalars["Int"]>;
  /** 該当の投稿に関してのエンゲージメント率 */
  engagement?: Maybe<Scalars["Float"]>;
  /** クロール日 */
  crawlSucceededAt?: Maybe<Scalars["Date"]>;
  /** view数 */
  viewCount?: Maybe<Scalars["Int"]>;
  /** リテンション率 */
  retentionRate?: Maybe<Scalars["Float"]>;
  /** 保存数 */
  clippingCount?: Maybe<Scalars["Int"]>;
  /** ? */
  costViewRate?: Maybe<Scalars["Float"]>;
  /** ? */
  costEngagementRate?: Maybe<Scalars["Float"]>;
  /** ? */
  costClippingRate?: Maybe<Scalars["Float"]>;
  /** ? */
  awarenessScore?: Maybe<Scalars["Float"]>;
  /** ? */
  interestScore?: Maybe<Scalars["Float"]>;
  /** ? */
  considerationScore?: Maybe<Scalars["Float"]>;
  /** ? */
  conversionScore?: Maybe<Scalars["Float"]>;
};

export type ToggleConfirmInfluencersConsiderationInput = {
  clientMutationId?: Maybe<Scalars["String"]>;
  assignmentId: Scalars["ID"];
  /**
   * ・単体のチェックボックスを選択した場合
   * チェックを入れられたのが既に検討中のインフルエンサーであれば除外され、そうでない場合は検討中に追加される。
   * 配列のIDには該当のインフルエンサーのIDのみを送信する
   * ・同じページに既に検討中のインフルエンサーがいる際にALLのチェックボックスを選択した場合
   * 検討リストから同じページのインフルエンサー全員が除外をされる。
   * 配列のIDには同じページ内で既に検討中だったインフルエンサー達のIDを送信する
   * ・同じページに検討リスト入のインフルエンサーが一人もいない際にALLのチェックボックスを選択した場合
   * 同じページにいるインフルエンサー全員が検討リストには追加される。
   * 配列のIDには同じページ内のインフルエンサー全員のIDを送信する
   **/
  assignmentInfluencerIds: Array<Scalars["ID"]>;
};

export type ToggleConfirmInfluencersConsiderationPayload = {
  __typename?: "ToggleConfirmInfluencersConsiderationPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  assignment: ClientAssignment;
};

export type ToggleInfluencersConsiderationInput = {
  clientMutationId?: Maybe<Scalars["String"]>;
  suggestionId: Scalars["ID"];
  /** ToggleConfirmInfluencersConsiderationInputを参照 */
  suggestionInfluencerIds: Array<Scalars["ID"]>;
};

export type ToggleInfluencersConsiderationPayload = {
  __typename?: "ToggleInfluencersConsiderationPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  suggestion: Suggestion;
};

export type TrainingInfluencer = BaseInfluencer &
  Node & {
    __typename?: "TrainingInfluencer";
    id: Scalars["ID"];
    name: Scalars["String"];
    image?: Maybe<Scalars["URI"]>;
    socialMedia?: Maybe<SocialMedia>;
    affinityPosts?: Maybe<Array<InstagramPost>>;
    resentPosts?: Maybe<Array<InstagramPost>>;
    likedPosts?: Maybe<Array<InstagramPost>>;
  };

/** Specify if you want to include or exclude trashed results from a query. */
export enum Trashed {
  /** Only return trashed results. */
  Only = "ONLY",
  /** Return both trashed and non-trashed results. */
  With = "WITH",
  /** Only return non-trashed results. */
  Without = "WITHOUT"
}

export type TwitterInsight = {
  __typename?: "TwitterInsight";
  /** フォロワー数 */
  followerCount: Scalars["Int"];
  /** フォロー数 */
  followCount: Scalars["Int"];
  /** 投稿数 */
  postCount: Scalars["Int"];
  /** フォロワー上昇率 */
  increaseRate?: Maybe<Scalars["Float"]>;
  /** 平均いいね数 */
  averageLikeCount: Scalars["Float"];
  /** 平均リツイート数 */
  averageRetweetCount: Scalars["Float"];
  /** エンゲージメント率 */
  engagement: Scalars["Float"];
  /** クロール日 */
  crawlSucceededAt: Scalars["Date"];
};

export type TwitterPostInsight = {
  __typename?: "TwitterPostInsight";
  /** フォロワー数 */
  followerCount?: Maybe<Scalars["Int"]>;
  /** フォロー数 */
  followCount?: Maybe<Scalars["Int"]>;
  /** followerIncreaseRate */
  followerIncreaseRate?: Maybe<Scalars["Float"]>;
  /** averageCommentCount */
  averageCommentCount?: Maybe<Scalars["Float"]>;
  /** averageLikeCount */
  averageLikeCount?: Maybe<Scalars["Float"]>;
  /** AVG SHARE COUNT */
  averageShareCount?: Maybe<Scalars["Float"]>;
  /** 累計投稿数 */
  cumulativePostCount?: Maybe<Scalars["Int"]>;
  /** フォロワー上昇率 */
  increaseRate?: Maybe<Scalars["Float"]>;
  /** 該当の投稿に関してのいいね数 */
  likeCount?: Maybe<Scalars["Int"]>;
  /** 該当の投稿に関してのリツイート数 */
  retweetCount?: Maybe<Scalars["Int"]>;
  /** 該当の投稿に関してのエンゲージメント率 */
  engagement?: Maybe<Scalars["Float"]>;
  /** クロール日 */
  crawlSucceededAt?: Maybe<Scalars["Date"]>;
  /** リーチ数 */
  reachCount?: Maybe<Scalars["Int"]>;
  /** インプレッション数 */
  impressionCount?: Maybe<Scalars["Int"]>;
  /** シェア数 */
  shareCount?: Maybe<Scalars["Int"]>;
  /** 保存数 */
  clippingCount?: Maybe<Scalars["Int"]>;
  /** ? */
  costReachRate?: Maybe<Scalars["Float"]>;
  /** ? */
  costImpressionRate?: Maybe<Scalars["Float"]>;
  /** ? */
  costEngagementRate?: Maybe<Scalars["Float"]>;
  /** ? */
  costClippingRate?: Maybe<Scalars["Float"]>;
  /** ? */
  awarenessScore?: Maybe<Scalars["Float"]>;
  /** ? */
  interestScore?: Maybe<Scalars["Float"]>;
  /** ? */
  considerationScore?: Maybe<Scalars["Float"]>;
  /** ? */
  conversionScore?: Maybe<Scalars["Float"]>;
};

export type UpdateAgencyAccountInput = {
  clientMutationId?: Maybe<Scalars["String"]>;
  agencyAccountId: Scalars["ID"];
  name: Scalars["String"];
  email: Scalars["String"];
};

export type UpdateAgencyAccountPayload = {
  __typename?: "UpdateAgencyAccountPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  agencyAccount: AgencyAccount;
};

export type UpdateAgencyInput = {
  clientMutationId?: Maybe<Scalars["String"]>;
  agencyId: Scalars["ID"];
  name: Scalars["String"];
  accountName: Scalars["String"];
  accountEmail: Scalars["String"];
};

export type UpdateAgencyPayload = {
  __typename?: "UpdateAgencyPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  agency: ClientAgency;
};

export type UpdateBrandInput = {
  clientMutationId?: Maybe<Scalars["String"]>;
  brandId: Scalars["ID"];
  name: Scalars["String"];
  image?: Maybe<Scalars["Upload"]>;
  description?: Maybe<Scalars["String"]>;
};

export type UpdateBrandPayload = {
  __typename?: "UpdateBrandPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  brand: ClientBrand;
};

export type UpdateCampaignInput = {
  clientMutationId?: Maybe<Scalars["String"]>;
  campaignActivityType: CampaignActivityType;
  campaignId: Scalars["ID"];
  title: Scalars["String"];
  productItemId: Scalars["ID"];
  objectives?: Maybe<Array<Maybe<ObjectiveType>>>;
  reachKpi?: Maybe<Scalars["Int"]>;
  budget: Scalars["Int"];
  keywordAndHashtags?: Maybe<Scalars["String"]>;
  instagramReachKpi?: Maybe<Scalars["Int"]>;
  twitterReachKpi?: Maybe<Scalars["Int"]>;
  youtubeReachKpi?: Maybe<Scalars["Int"]>;
  tiktokReachKpi?: Maybe<Scalars["Int"]>;
  instagramSales?: Maybe<Scalars["Int"]>;
  twitterSales?: Maybe<Scalars["Int"]>;
  youtubeSales?: Maybe<Scalars["Int"]>;
  tiktokSales?: Maybe<Scalars["Int"]>;
  instagramEcClick?: Maybe<Scalars["Int"]>;
  twitterEcClick?: Maybe<Scalars["Int"]>;
  youtubeEcClick?: Maybe<Scalars["Int"]>;
  tiktokEcClick?: Maybe<Scalars["Int"]>;
  note?: Maybe<Scalars["String"]>;
  postStartAt: Scalars["DateTime"];
  postEndAt: Scalars["DateTime"];
  affinityModelId: Scalars["ID"];
  tagIds?: Maybe<Array<Maybe<Scalars["ID"]>>>;
  /** 提案開始日時 */
  suggestionStartAt: Scalars["DateTime"];
  /** 提案締め切り日時 */
  suggestionEndAt: Scalars["DateTime"];
  clientAttachmentFiles?: Maybe<Array<Maybe<CampaignFileUpload>>>;
};

export type UpdateCampaignPayload = {
  __typename?: "UpdateCampaignPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  campaign: ClientCampaign;
};

export type UpdateClientAccountInput = {
  clientMutationId?: Maybe<Scalars["String"]>;
  clientAccountId: Scalars["ID"];
  name: Scalars["String"];
  email: Scalars["String"];
};

export type UpdateClientAccountPayload = {
  __typename?: "UpdateClientAccountPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  clientAccount: ClientAccount;
};

export type UpdateInfluencerEvaluationInput = {
  clientMutationId?: Maybe<Scalars["String"]>;
  influencerEvaluationId: Scalars["ID"];
  evaluation: EvaluationType;
};

export type UpdateInfluencerEvaluationNoteInput = {
  clientMutationId?: Maybe<Scalars["String"]>;
  influencerEvaluationId: Scalars["ID"];
  note: Scalars["String"];
};

export type UpdateInfluencerEvaluationNotePayload = {
  __typename?: "UpdateInfluencerEvaluationNotePayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  influencerEvaluation: MasterInfluencerEvaluation;
};

export type UpdateInfluencerEvaluationPayload = {
  __typename?: "UpdateInfluencerEvaluationPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  influencerEvaluation: MasterInfluencerEvaluation;
};

export type UpdateInfluencerSegmentInput = {
  clientMutationId?: Maybe<Scalars["String"]>;
  influencerSegmentId: Scalars["ID"];
  name: Scalars["String"];
  maxFollowerCount?: Maybe<Scalars["Int"]>;
  minFollowerCount?: Maybe<Scalars["Int"]>;
  increaseRate?: Maybe<Scalars["Float"]>;
};

export type UpdateInfluencerSegmentPayload = {
  __typename?: "UpdateInfluencerSegmentPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  influencerSegment: InfluencerSegment;
};

export type UpdatePostLoadingStatusPayload = {
  __typename?: "UpdatePostLoadingStatusPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  postLoadingStatus: PostLoadingStatus;
};

export type UpdateUnevaluatedInfluencerInput = {
  clientMutationId?: Maybe<Scalars["String"]>;
  influencerEvaluationGroupId: Scalars["ID"];
};

export type UploadAvailabilityResultsInput = {
  clientMutationId?: Maybe<Scalars["String"]>;
  campaignId: Scalars["ID"];
  file: Scalars["Upload"];
  note?: Maybe<Scalars["String"]>;
  attachmentFiles?: Maybe<Array<Maybe<AssignmentFileUpload>>>;
};

export type UploadAvailabilityResultsPayload = {
  __typename?: "UploadAvailabilityResultsPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  assignment: AgencyAssignment;
};

export type UploadPostedResultsInput = {
  clientMutationId?: Maybe<Scalars["String"]>;
  campaignId: Scalars["ID"];
  file: Scalars["Upload"];
  attachmentFiles?: Maybe<Array<Maybe<ReportFileUpload>>>;
};

export type UploadPostedResultsPayload = {
  __typename?: "UploadPostedResultsPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  report: AgencyReport;
};

export type UploadSuggestionInput = {
  clientMutationId?: Maybe<Scalars["String"]>;
  campaignId: Scalars["ID"];
  file: Scalars["Upload"];
  note?: Maybe<Scalars["String"]>;
  attachmentFiles?: Maybe<Array<Maybe<Scalars["Upload"]>>>;
};

export type UploadSuggestionPayload = {
  __typename?: "UploadSuggestionPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  suggestion: Suggestion;
};

export type UsedCampaign = Campaign &
  Node & {
    __typename?: "UsedCampaign";
    id: Scalars["ID"];
    number: Scalars["Int"];
    /** キャンペーン名 */
    name: Scalars["String"];
    /** Campaign全体の予算 */
    budget: Scalars["Int"];
    /** キャンペーン商品 */
    productItem: ProductItem;
    /** ブランド */
    brandId: Scalars["ID"];
    /** 発注インフルエンサーリスト */
    assignmentInfluencers?: Maybe<AssignmentInfluencerConnection>;
    /** 発注をしていない代理店の落選が完了していて、提案フェーズが終了しているかどうか */
    biddingClosed: Scalars["Boolean"];
    /** 投稿開始日 */
    postStartAt: Scalars["DateTime"];
    /** 投稿終了日 */
    postEndAt: Scalars["DateTime"];
    /** 提案開始日時 */
    suggestionStartAt: Scalars["DateTime"];
    /** 提案締め切り日時 */
    suggestionEndAt: Scalars["DateTime"];
    /** レポート */
    report?: Maybe<ClientReport>;
    /** 添付ファイル */
    attachmentFiles: Array<AttachmentFile>;
    createdAt: Scalars["DateTime"];
    updatedAt: Scalars["DateTime"];
  };

export type UsedCampaignAssignmentInfluencersArgs = {
  perPage?: Maybe<Scalars["Int"]>;
  page: Scalars["Int"];
};

export type UserPostVector = Node & {
  __typename?: "UserPostVector";
  id: Scalars["ID"];
  /** ユーザー投稿のvectorの日付 */
  to: Scalars["Date"];
  rangeMonth: CoreRangeMonth;
  influencerCount: Scalars["Int"];
  postCount: Scalars["Int"];
  version: Scalars["String"];
};

/** influencer_value_check_histories.check_status */
export enum ValueCheckStatus {
  NotRequired = "NOT_REQUIRED",
  Required = "REQUIRED",
  Aligned = "ALIGNED",
  NotAligned = "NOT_ALIGNED"
}

export type YoutubeInsight = {
  __typename?: "YoutubeInsight";
  /** フォロワー数 */
  followerCount: Scalars["Int"];
  /** ビュー数 */
  viewCount: Scalars["Int"];
  /** 投稿数 */
  postCount: Scalars["Int"];
  /** 最高ビュー数 */
  maxViewCount?: Maybe<Scalars["Int"]>;
  /** フォロワー上昇率 */
  increaseRate?: Maybe<Scalars["Float"]>;
  /** 平均いいね数 */
  averageLikeCount: Scalars["Float"];
  /** 平均コメント数 */
  averageCommentCount: Scalars["Float"];
  /** エンゲージメント率 */
  engagement: Scalars["Float"];
  /** クロール日 */
  crawlSucceededAt: Scalars["Date"];
};

export type YoutubePostInsight = {
  __typename?: "YoutubePostInsight";
  /** フォロワー数 */
  followerCount?: Maybe<Scalars["Int"]>;
  /** view数 */
  followCount?: Maybe<Scalars["Int"]>;
  /** followerIncreaseRate */
  followerIncreaseRate?: Maybe<Scalars["Float"]>;
  /** maxViewCount */
  maxViewCount?: Maybe<Scalars["Int"]>;
  /** averageCommentCount */
  averageCommentCount?: Maybe<Scalars["Float"]>;
  /** averageLikeCount */
  averageLikeCount?: Maybe<Scalars["Float"]>;
  /** AVG VIEW COUNT */
  averageViewCount?: Maybe<Scalars["Float"]>;
  /** 累計投稿数 */
  cumulativePostCount?: Maybe<Scalars["Int"]>;
  /** フォロワー上昇率 */
  increaseRate?: Maybe<Scalars["Float"]>;
  /** 該当の投稿に関してのいいね数 */
  likeCount?: Maybe<Scalars["Int"]>;
  /** 該当の投稿に関してのコメント数 */
  commentCount?: Maybe<Scalars["Int"]>;
  /** 該当の投稿に関してのエンゲージメント率 */
  engagement?: Maybe<Scalars["Float"]>;
  /** クロール日 */
  crawlSucceededAt?: Maybe<Scalars["Date"]>;
  /** view数 */
  viewCount?: Maybe<Scalars["Int"]>;
  /** リテンション率 */
  retentionRate?: Maybe<Scalars["Float"]>;
  /** 保存数 */
  clippingCount?: Maybe<Scalars["Int"]>;
  /** ? */
  costViewRate?: Maybe<Scalars["Float"]>;
  /** ? */
  costEngagementRate?: Maybe<Scalars["Float"]>;
  /** ? */
  costClippingRate?: Maybe<Scalars["Float"]>;
  /** ? */
  awarenessScore?: Maybe<Scalars["Float"]>;
  /** ? */
  interestScore?: Maybe<Scalars["Float"]>;
  /** ? */
  considerationScore?: Maybe<Scalars["Float"]>;
  /** ? */
  conversionScore?: Maybe<Scalars["Float"]>;
};

export type AssignmentInfluencerFragment = {
  __typename?: "AssignmentInfluencer";
} & Pick<
  AssignmentInfluencer,
  | "id"
  | "number"
  | "name"
  | "image"
  | "cost"
  | "totalCost"
  | "totalCommission"
  | "totalFollowerCount"
  | "totalFollowCount"
  | "totalPostCount"
  | "averageIncreaseRate"
  | "totalLikeCount"
  | "totalCommentCount"
  | "averageEngagement"
  | "averageAffinityScore"
  | "averageAdvocateScore"
  | "maxViewCount"
  | "isClientConfirmed"
  | "considerationStatus"
  | "valueCheckStatus"
  | "checkAccountStatus"
  | "valueCheckDate"
  | "availabilityStatus"
> & {
    histories: Array<
      { __typename?: "AssignmentInfluencerHistory" } & Pick<
        AssignmentInfluencerHistory,
        "id" | "totalCost"
      > & {
          insightsBySocialMedia: Array<
            {
              __typename?: "AssignmentInfluencerInsightBySocialMediaHistory";
            } & AssignmentInfluencerInsightBySocialMediaHistoryFragment
          >;
        }
    >;
    insightsBySocialMedia: Array<
      {
        __typename?: "AssignmentInfluencerInsightBySocialMedia";
      } & AssignmentInfluencerInsightBySocialMediaFragment
    >;
  };

export type AssignmentInstagramSummaryFragment = {
  __typename?: "AssignmentInstagramSummary";
} & Pick<
  AssignmentInstagramSummary,
  | "socialMediaType"
  | "totalCost"
  | "totalCommission"
  | "totalFollower"
  | "totalInfluencerCount"
  | "totalFollow"
  | "averageEngagementRate"
  | "averageAffinityRate"
  | "averageAdvocateScoreRate"
>;

export type AssignmentTwitterSummaryFragment = {
  __typename?: "AssignmentTwitterSummary";
} & Pick<
  AssignmentTwitterSummary,
  | "socialMediaType"
  | "totalCost"
  | "totalCommission"
  | "totalFollower"
  | "totalInfluencerCount"
  | "totalFollow"
  | "averageEngagementRate"
  | "averageAffinityRate"
  | "averageAdvocateScoreRate"
>;

export type AssignmentYoutubeSummaryFragment = {
  __typename?: "AssignmentYoutubeSummary";
} & Pick<
  AssignmentYoutubeSummary,
  | "socialMediaType"
  | "totalCost"
  | "totalCommission"
  | "totalFollower"
  | "totalInfluencerCount"
  | "totalFollow"
  | "averageEngagementRate"
  | "averageAffinityRate"
  | "averageAdvocateScoreRate"
>;

export type AssignmentTiktokSummaryFragment = {
  __typename?: "AssignmentTiktokSummary";
} & Pick<
  AssignmentTiktokSummary,
  | "socialMediaType"
  | "totalCost"
  | "totalCommission"
  | "totalFollower"
  | "totalInfluencerCount"
  | "totalFollow"
  | "averageEngagementRate"
  | "averageAffinityRate"
  | "averageAdvocateScoreRate"
>;

export type AssignmentWinningSummaryFragment = {
  __typename?: "AssignmentWinningSummary";
} & Pick<
  AssignmentWinningSummary,
  | "totalCost"
  | "totalCommission"
  | "totalFollower"
  | "totalInfluencerCount"
  | "totalFollow"
  | "averageEngagementRate"
  | "averageAffinityRate"
  | "averageAdvocateScoreRate"
>;

export type ClientAssignmentFragment = {
  __typename?: "ClientAssignment";
} & Pick<
  ClientAssignment,
  | "id"
  | "number"
  | "totalCost"
  | "totalCommission"
  | "totalInfluencerCount"
  | "totalFollower"
  | "totalFollow"
  | "averageEngagementRate"
  | "averageAffinityRate"
  | "averageAdvocateScoreRate"
  | "totalConsiderationInfluencerCount"
  | "isDataCollectionCompleted"
  | "orderListLookedAt"
  | "status"
  | "agencyNote"
> & {
    agencyAttachmentFiles: Array<
      { __typename?: "AttachmentFile" } & Pick<AttachmentFile, "name" | "url">
    >;
    agencyAssignmentAttachmentFiles: Array<
      { __typename?: "AttachmentFile" } & Pick<AttachmentFile, "name" | "url">
    >;
    agencyReportAttachmentFiles: Array<
      { __typename?: "AttachmentFile" } & Pick<AttachmentFile, "name" | "url">
    >;
    summaries: Array<
      | { __typename?: "Suggestion" }
      | { __typename?: "ClientReport" }
      | { __typename?: "ClientAssignment" }
      | ({
          __typename?: "AssignmentInstagramSummary";
        } & AssignmentInstagramSummaryFragment)
      | ({
          __typename?: "AssignmentTiktokSummary";
        } & AssignmentTiktokSummaryFragment)
      | ({
          __typename?: "AssignmentTwitterSummary";
        } & AssignmentTwitterSummaryFragment)
      | ({
          __typename?: "AssignmentWinningSummary";
        } & AssignmentWinningSummaryFragment)
      | ({
          __typename?: "AssignmentYoutubeSummary";
        } & AssignmentYoutubeSummaryFragment)
      | { __typename?: "ReportInstagramSummary" }
      | { __typename?: "ReportPlannedTotalSummary" }
      | { __typename?: "ReportTiktokSummary" }
      | { __typename?: "ReportTwitterSummary" }
      | { __typename?: "ReportYoutubeSummary" }
      | { __typename?: "SuggestionInstagramSummary" }
      | { __typename?: "SuggestionTiktokSummary" }
      | { __typename?: "SuggestionTwitterSummary" }
      | { __typename?: "SuggestionYoutubeSummary" }
    >;
    winningSummaries: Array<
      | { __typename?: "Suggestion" }
      | { __typename?: "ClientReport" }
      | { __typename?: "ClientAssignment" }
      | ({
          __typename?: "AssignmentInstagramSummary";
        } & AssignmentInstagramSummaryFragment)
      | ({
          __typename?: "AssignmentTiktokSummary";
        } & AssignmentTiktokSummaryFragment)
      | ({
          __typename?: "AssignmentTwitterSummary";
        } & AssignmentTwitterSummaryFragment)
      | { __typename?: "AssignmentWinningSummary" }
      | ({
          __typename?: "AssignmentYoutubeSummary";
        } & AssignmentYoutubeSummaryFragment)
      | { __typename?: "ReportInstagramSummary" }
      | { __typename?: "ReportPlannedTotalSummary" }
      | { __typename?: "ReportTiktokSummary" }
      | { __typename?: "ReportTwitterSummary" }
      | { __typename?: "ReportYoutubeSummary" }
      | { __typename?: "SuggestionInstagramSummary" }
      | { __typename?: "SuggestionTiktokSummary" }
      | { __typename?: "SuggestionTwitterSummary" }
      | { __typename?: "SuggestionYoutubeSummary" }
    >;
    assignmentInfluencersByAgency: Maybe<
      { __typename?: "AssignmentInfluencerConnection" } & {
        paginatorInfo: Maybe<
          { __typename?: "PaginatorInfo" } & Pick<
            PaginatorInfo,
            "total" | "currentPage" | "count" | "perPage"
          >
        >;
        edges: Maybe<
          Array<
            Maybe<
              { __typename?: "AssignmentInfluencerEdge" } & {
                node: { __typename?: "AssignmentInfluencerNode" } & {
                  assignmentInfluencer: {
                    __typename?: "AssignmentInfluencer";
                  } & AssignmentInfluencerFragment;
                };
              }
            >
          >
        >;
      }
    >;
  };

export type AssignmentInfluencerInsightBySocialMediaFragment = {
  __typename?: "AssignmentInfluencerInsightBySocialMedia";
} & Pick<
  AssignmentInfluencerInsightBySocialMedia,
  | "id"
  | "isDataCollectionCompleted"
  | "socialMediaType"
  | "accountId"
  | "accountUrl"
  | "cost"
  | "commission"
  | "affinityRate"
  | "advocateScore"
  | "affinityUpperThreshold"
  | "affinityLowerThreshold"
  | "checkAccountStatus"
> & {
    socialMediaInsight: Maybe<
      | ({ __typename?: "InstagramInsight" } & InstagramInsightFragment)
      | ({ __typename?: "TwitterInsight" } & TwitterInsightFragment)
      | ({ __typename?: "YoutubeInsight" } & YoutubeInsightFragment)
      | ({ __typename?: "TiktokInsight" } & TiktokInsightFragment)
    >;
  };

export type AssignmentInfluencerInsightBySocialMediaHistoryFragment = {
  __typename?: "AssignmentInfluencerInsightBySocialMediaHistory";
} & Pick<
  AssignmentInfluencerInsightBySocialMediaHistory,
  "id" | "socialMediaType" | "accountId" | "cost"
>;

export type ReportInstagramSummaryFragment = {
  __typename?: "ReportInstagramSummary";
} & Pick<
  ReportInstagramSummary,
  | "socialMediaType"
  | "totalCost"
  | "totalFollower"
  | "totalInfluencerCount"
  | "totalFollow"
  | "averageEngagementRate"
  | "averageAffinityRate"
  | "averageAdvocateScoreRate"
  | "totalReachCount"
  | "totalImpressionCount"
  | "totalClippingCount"
  | "totalEcClickCount"
  | "totalCommission"
  | "totalLikeCount"
  | "totalCommentCount"
  | "totalSalesValue"
  | "costReachRate"
  | "costImpressionRate"
  | "costEngagementRate"
  | "costClippingRate"
  | "costEcClickRate"
  | "costSalesRate"
  | "awarenessScore"
  | "interestScore"
  | "considerationScore"
  | "conversionScore"
>;

export type ReportTwitterSummaryFragment = {
  __typename?: "ReportTwitterSummary";
} & Pick<
  ReportTwitterSummary,
  | "socialMediaType"
  | "totalCost"
  | "totalFollower"
  | "totalInfluencerCount"
  | "totalFollow"
  | "averageEngagementRate"
  | "averageAffinityRate"
  | "averageAdvocateScoreRate"
  | "totalReachCount"
  | "totalImpressionCount"
  | "totalShareCount"
  | "totalClippingCount"
  | "totalEcClickCount"
  | "totalCommission"
  | "totalLikeCount"
  | "totalCommentCount"
  | "totalSalesValue"
  | "costReachRate"
  | "costImpressionRate"
  | "costEngagementRate"
  | "costClippingRate"
  | "costEcClickRate"
  | "costSalesRate"
  | "awarenessScore"
  | "interestScore"
  | "considerationScore"
  | "conversionScore"
>;

export type ReportYoutubeSummaryFragment = {
  __typename?: "ReportYoutubeSummary";
} & Pick<
  ReportYoutubeSummary,
  | "socialMediaType"
  | "totalCost"
  | "totalFollower"
  | "totalInfluencerCount"
  | "totalFollow"
  | "averageEngagementRate"
  | "averageAffinityRate"
  | "averageAdvocateScoreRate"
  | "totalViewCount"
  | "averageRetentionRate"
  | "totalClippingCount"
  | "totalEcClickCount"
  | "totalCommission"
  | "totalLikeCount"
  | "totalCommentCount"
  | "totalSalesValue"
  | "costViewRate"
  | "costEngagementRate"
  | "costClippingRate"
  | "costEcClickRate"
  | "costSalesRate"
  | "awarenessScore"
  | "interestScore"
  | "considerationScore"
  | "conversionScore"
>;

export type ReportTiktokSummaryFragment = {
  __typename?: "ReportTiktokSummary";
} & Pick<
  ReportTiktokSummary,
  | "socialMediaType"
  | "totalCost"
  | "totalFollower"
  | "totalInfluencerCount"
  | "totalFollow"
  | "averageEngagementRate"
  | "averageAffinityRate"
  | "averageAdvocateScoreRate"
  | "totalViewCount"
  | "totalShareCount"
  | "averageRetentionRate"
  | "totalClippingCount"
  | "totalEcClickCount"
  | "totalCommission"
  | "totalLikeCount"
  | "totalCommentCount"
  | "totalSalesValue"
  | "costViewRate"
  | "costEngagementRate"
  | "costClippingRate"
  | "costEcClickRate"
  | "costSalesRate"
  | "awarenessScore"
  | "interestScore"
  | "considerationScore"
  | "conversionScore"
>;

export type ReportPlannedTotalSummaryFragment = {
  __typename?: "ReportPlannedTotalSummary";
} & Pick<
  ReportPlannedTotalSummary,
  | "totalCost"
  | "totalCommission"
  | "totalFollower"
  | "totalInfluencerCount"
  | "totalFollow"
  | "averageEngagementRate"
  | "averageAffinityRate"
  | "averageAdvocateScoreRate"
>;

export type InstagramInsightFragment = {
  __typename?: "InstagramInsight";
} & Pick<
  InstagramInsight,
  | "followerCount"
  | "increaseRate"
  | "averageLikeCount"
  | "averageCommentCount"
  | "engagement"
>;

export type TwitterInsightFragment = { __typename?: "TwitterInsight" } & Pick<
  TwitterInsight,
  | "followerCount"
  | "increaseRate"
  | "averageLikeCount"
  | "averageRetweetCount"
  | "engagement"
>;

export type YoutubeInsightFragment = { __typename?: "YoutubeInsight" } & Pick<
  YoutubeInsight,
  | "followerCount"
  | "increaseRate"
  | "averageLikeCount"
  | "averageCommentCount"
  | "engagement"
  | "maxViewCount"
>;

export type TiktokInsightFragment = { __typename?: "TiktokInsight" } & Pick<
  TiktokInsight,
  | "followerCount"
  | "increaseRate"
  | "averageLikeCount"
  | "averageCommentCount"
  | "engagement"
  | "maxViewCount"
>;

export type SuggestionInfluencerFragment = {
  __typename?: "SuggestionInfluencer";
} & Pick<
  SuggestionInfluencer,
  | "id"
  | "name"
  | "number"
  | "image"
  | "totalCost"
  | "totalCommission"
  | "averageAffinityScore"
  | "averageAdvocateScore"
  | "maxViewCount"
  | "valueCheckStatus"
  | "valueCheckDate"
  | "checkAccountStatus"
  | "totalFollowerCount"
  | "totalFollowCount"
  | "totalPostCount"
  | "averageIncreaseRate"
  | "totalLikeCount"
  | "totalCommentCount"
  | "totalShareCount"
  | "totalViewCount"
  | "averageEngagement"
  | "isConsideration"
  | "isAdditionalConsideration"
> & {
    costComparison: { __typename?: "SuggestionCostComparison" } & Pick<
      SuggestionCostComparison,
      "averageCost" | "suggestionCount" | "costRanking"
    >;
    loadingStatus: { __typename?: "InfluencerLoadingStatus" } & Pick<
      InfluencerLoadingStatus,
      "status"
    >;
    insightsBySocialMedia: Array<
      { __typename?: "SuggestionInfluencerInsightBySocialMedia" } & Pick<
        SuggestionInfluencerInsightBySocialMedia,
        | "id"
        | "isDataCollectionCompleted"
        | "socialMediaType"
        | "accountId"
        | "accountUrl"
        | "cost"
        | "commission"
        | "affinityRate"
        | "advocateScore"
        | "affinityUpperThreshold"
        | "affinityLowerThreshold"
        | "checkAccountStatus"
      > & {
          socialMediaInsight: Maybe<
            | ({ __typename?: "InstagramInsight" } & InstagramInsightFragment)
            | ({ __typename?: "TwitterInsight" } & TwitterInsightFragment)
            | ({ __typename?: "YoutubeInsight" } & YoutubeInsightFragment)
            | ({ __typename?: "TiktokInsight" } & TiktokInsightFragment)
          >;
        }
    >;
  };

export type SuggestionInstagramSummaryFragment = {
  __typename?: "SuggestionInstagramSummary";
} & Pick<
  SuggestionInstagramSummary,
  | "socialMediaType"
  | "totalCost"
  | "totalCommission"
  | "totalFollower"
  | "totalInfluencerCount"
  | "totalFollow"
  | "averageEngagementRate"
  | "averageAffinityRate"
  | "averageAdvocateScoreRate"
>;

export type SuggestionTwitterSummaryFragment = {
  __typename?: "SuggestionTwitterSummary";
} & Pick<
  SuggestionTwitterSummary,
  | "socialMediaType"
  | "totalCost"
  | "totalCommission"
  | "totalFollower"
  | "totalInfluencerCount"
  | "totalFollow"
  | "averageEngagementRate"
  | "averageAffinityRate"
  | "averageAdvocateScoreRate"
>;

export type SuggestionYoutubeSummaryFragment = {
  __typename?: "SuggestionYoutubeSummary";
} & Pick<
  SuggestionYoutubeSummary,
  | "socialMediaType"
  | "totalCost"
  | "totalCommission"
  | "totalFollower"
  | "totalInfluencerCount"
  | "totalFollow"
  | "averageEngagementRate"
  | "averageAffinityRate"
  | "averageAdvocateScoreRate"
>;

export type SuggestionTiktokSummaryFragment = {
  __typename?: "SuggestionTiktokSummary";
} & Pick<
  SuggestionTiktokSummary,
  | "socialMediaType"
  | "totalCost"
  | "totalCommission"
  | "totalFollower"
  | "totalInfluencerCount"
  | "totalFollow"
  | "averageEngagementRate"
  | "averageAffinityRate"
  | "averageAdvocateScoreRate"
>;

export type AddAgenciesToCampaignMutationVariables = {
  clientMutationId?: Maybe<Scalars["String"]>;
  campaignId: Scalars["ID"];
  agencyIds: Array<Scalars["ID"]>;
};

export type AddAgenciesToCampaignMutation = { __typename?: "Mutation" } & {
  addAgenciesToCampaign: Maybe<
    { __typename?: "AddAgenciesToCampaignPayload" } & {
      campaign: { __typename?: "ClientCampaign" } & Pick<
        ClientCampaign,
        "id" | "number"
      > & {
          agencies: Maybe<
            Array<
              Maybe<
                { __typename?: "CampaignAgency" } & Pick<
                  CampaignAgency,
                  "id" | "name" | "totalSuggestionCount" | "lastSuggestionAt"
                > & {
                    suggestions: Maybe<
                      Array<
                        Maybe<
                          { __typename?: "Suggestion" } & Pick<
                            Suggestion,
                            "id" | "number"
                          >
                        >
                      >
                    >;
                  }
              >
            >
          >;
        };
    }
  >;
};

export type AddConsiderationInfluencersToConfirmInfluencersMutationVariables = {
  assignmentId: Scalars["ID"];
  assignmentQueryAcquiredAt: Scalars["DateTime"];
};

export type AddConsiderationInfluencersToConfirmInfluencersMutation = {
  __typename?: "Mutation";
} & {
  addConsiderationInfluencersToConfirmInfluencers: Maybe<
    {
      __typename?: "AddConsiderationInfluencersToConfirmInfluencersPayload";
    } & Pick<
      AddConsiderationInfluencersToConfirmInfluencersPayload,
      "areConsiderationInfluencersUpdated"
    >
  >;
};

export type AddInfluencerFromConsiderationMutationVariables = {
  clientMutationId?: Maybe<Scalars["String"]>;
  suggestionInfluencerId: Scalars["ID"];
  influencerNumber: Scalars["Int"];
};

export type AddInfluencerFromConsiderationMutation = {
  __typename?: "Mutation";
} & {
  addInfluencerFromConsideration: Maybe<
    { __typename?: "AddInfluencerFromConsiderationPayload" } & {
      suggestion: { __typename?: "Suggestion" } & Pick<
        Suggestion,
        | "totalCost"
        | "totalCommission"
        | "totalInfluencerCount"
        | "totalFollower"
        | "totalFollow"
        | "averageEngagementRate"
        | "averageAffinityRate"
        | "averageAdvocateScoreRate"
        | "totalConsiderationInfluencerCount"
        | "isDataCollectionCompleted"
      > & {
          summaries: Array<
            | { __typename?: "Suggestion" }
            | { __typename?: "ClientReport" }
            | { __typename?: "ClientAssignment" }
            | { __typename?: "AssignmentInstagramSummary" }
            | { __typename?: "AssignmentTiktokSummary" }
            | { __typename?: "AssignmentTwitterSummary" }
            | { __typename?: "AssignmentWinningSummary" }
            | { __typename?: "AssignmentYoutubeSummary" }
            | { __typename?: "ReportInstagramSummary" }
            | { __typename?: "ReportPlannedTotalSummary" }
            | { __typename?: "ReportTiktokSummary" }
            | { __typename?: "ReportTwitterSummary" }
            | { __typename?: "ReportYoutubeSummary" }
            | ({
                __typename?: "SuggestionInstagramSummary";
              } & SuggestionInstagramSummaryFragment)
            | { __typename?: "SuggestionTiktokSummary" }
            | ({
                __typename?: "SuggestionTwitterSummary";
              } & SuggestionTwitterSummaryFragment)
            | ({
                __typename?: "SuggestionYoutubeSummary";
              } & SuggestionYoutubeSummaryFragment)
          >;
          suggestionInfluencer: Maybe<
            {
              __typename?: "SuggestionInfluencer";
            } & SuggestionInfluencerFragment
          >;
        };
    }
  >;
};

export type AdditionalAssignInfluencerConsiderationMutationVariables = {
  suggestionNumber: Scalars["Int"];
  assignmentId: Scalars["ID"];
  suggestionInfluencerId: Scalars["ID"];
  page: Scalars["Int"];
  perPage?: Maybe<Scalars["Int"]>;
  suggestionOrderBy?: Maybe<SuggestionInfluencerOrderByClause>;
  includeAdditionalConsiderationSummary?: Maybe<Scalars["Boolean"]>;
  orderBy?: Maybe<AssignmentInfluencerOrderByClause>;
  hasAssignmentInfluencers?: Maybe<Scalars["Boolean"]>;
  filterBy?: Maybe<AssignmentInfluencerFilter>;
  influencerName?: Maybe<Scalars["String"]>;
};

export type AdditionalAssignInfluencerConsiderationMutation = {
  __typename?: "Mutation";
} & {
  additionalAssignInfluencerConsideration: Maybe<
    { __typename?: "AdditionalAssignInfluencerConsiderationPayload" } & {
      agency: { __typename?: "CampaignAgency" } & {
        suggestion: Maybe<
          { __typename?: "Suggestion" } & Pick<
            Suggestion,
            | "id"
            | "number"
            | "note"
            | "totalCost"
            | "totalInfluencerCount"
            | "totalConsiderationInfluencerCount"
          > & {
              suggestionInfluencers: Maybe<
                { __typename?: "SuggestionInfluencerConnection" } & {
                  paginatorInfo: Maybe<
                    { __typename?: "PaginatorInfo" } & Pick<
                      PaginatorInfo,
                      "total" | "currentPage" | "count"
                    >
                  >;
                  edges: Maybe<
                    Array<
                      Maybe<
                        { __typename?: "SuggestionInfluencerEdge" } & {
                          node: { __typename?: "SuggestionInfluencerNode" } & {
                            suggestionInfluencer: {
                              __typename?: "SuggestionInfluencer";
                            } & SuggestionInfluencerFragment;
                          };
                        }
                      >
                    >
                  >;
                }
              >;
              attachmentFiles: Array<
                { __typename?: "AttachmentFile" } & Pick<
                  AttachmentFile,
                  "name" | "url"
                >
              >;
            }
        >;
        assignment: Maybe<
          { __typename?: "ClientAssignment" } & ClientAssignmentFragment
        >;
      };
    }
  >;
};

export type AdditionalAssignInfluencersToCampaignMutationVariables = {
  clientMutationId?: Maybe<Scalars["String"]>;
  assignmentId: Scalars["ID"];
  suggestionId: Scalars["ID"];
};

export type AdditionalAssignInfluencersToCampaignMutation = {
  __typename?: "Mutation";
} & {
  additionalAssignInfluencersToCampaign: Maybe<
    { __typename?: "AddAgencyToCampaignPayload" } & {
      campaign: { __typename?: "ClientCampaign" } & Pick<
        ClientCampaign,
        "number"
      >;
    }
  >;
};

export type AgencyChangePasswordMutationVariables = {
  clientMutationId?: Maybe<Scalars["String"]>;
  email: Scalars["String"];
  token: Scalars["String"];
  password: Scalars["String"];
};

export type AgencyChangePasswordMutation = { __typename?: "Mutation" } & {
  agencyChangePassword: Maybe<
    { __typename?: "AgencyChangePasswordPayload" } & Pick<
      AgencyChangePasswordPayload,
      "clientMutationId"
    >
  >;
};

export type AgencyLoginMutationVariables = {
  input: AgencyLoginInput;
};

export type AgencyLoginMutation = { __typename?: "Mutation" } & {
  agencyLogin: Maybe<
    { __typename?: "AgencyCredentialPayload" } & Pick<
      AgencyCredentialPayload,
      "token" | "clientMutationId"
    >
  >;
};

export type ApproveAvailabilityMutationVariables = {
  input: RequestAvailabilityApprovalInput;
};

export type ApproveAvailabilityMutation = { __typename?: "Mutation" } & {
  requestAvailabilityApproval: Maybe<
    { __typename?: "RequestAvailabilityApprovalPayload" } & {
      assignment: { __typename?: "AgencyAssignment" } & Pick<
        AgencyAssignment,
        "id" | "status"
      >;
    }
  >;
};

export type AssignInfluencersToCampaignMutationVariables = {
  clientMutationId?: Maybe<Scalars["String"]>;
  suggestionId: Scalars["ID"];
};

export type AssignInfluencersToCampaignMutation = {
  __typename?: "Mutation";
} & {
  assignInfluencersToCampaign: Maybe<
    { __typename?: "AssignInfluencersToCampaignPayload" } & {
      campaign: { __typename?: "ClientCampaign" } & Pick<
        ClientCampaign,
        "id" | "number"
      >;
    }
  >;
};

export type CampaignReportRequestMutationVariables = {
  input: CampaignReportRequestInput;
};

export type CampaignReportRequestMutation = { __typename?: "Mutation" } & {
  campaignReportRequest: Maybe<
    { __typename?: "CampaignReportRequestPayload" } & Pick<
      CampaignReportRequestPayload,
      "response"
    >
  >;
};

export type ChangePasswordMutationVariables = {
  clientMutationId?: Maybe<Scalars["String"]>;
  email: Scalars["String"];
  token: Scalars["String"];
  password: Scalars["String"];
};

export type ChangePasswordMutation = { __typename?: "Mutation" } & {
  clientChangePassword: Maybe<
    { __typename?: "ClientChangePasswordPayload" } & Pick<
      ClientChangePasswordPayload,
      "clientMutationId"
    >
  >;
};

export type ApproveConfirmedInfluencersMutationVariables = {
  assignmentId: Scalars["ID"];
};

export type ApproveConfirmedInfluencersMutation = {
  __typename?: "Mutation";
} & {
  approveConfirmedInfluencers: Maybe<
    { __typename?: "ApproveConfirmedInfluencersPayload" } & {
      assignment: { __typename?: "ClientAssignment" } & Pick<
        ClientAssignment,
        "number" | "status"
      >;
    }
  >;
};

export type ApproveInfluencerListMutationVariables = {
  id: Scalars["ID"];
  agencyId: Scalars["ID"];
};

export type ApproveInfluencerListMutation = { __typename?: "Mutation" } & {
  approveRequestAvailabilityApproval: Maybe<
    { __typename?: "ApproveRequestAvailabilityApprovalPayload" } & {
      assignment: { __typename?: "ClientAssignment" } & Pick<
        ClientAssignment,
        "number" | "status"
      >;
    }
  >;
};

export type RejectInfluencerListMutationVariables = {
  id: Scalars["ID"];
  agencyId: Scalars["ID"];
};

export type RejectInfluencerListMutation = { __typename?: "Mutation" } & {
  dismissRequestAvailabilityApproval: Maybe<
    { __typename?: "DismissRequestAvailabilityApprovalPayload" } & {
      assignment: { __typename?: "ClientAssignment" } & Pick<
        ClientAssignment,
        "number" | "status"
      >;
    }
  >;
};

export type RemoveConfirmedInfluencersMutationVariables = {
  assignmentId: Scalars["ID"];
};

export type RemoveConfirmedInfluencersMutation = { __typename?: "Mutation" } & {
  removeConfirmedInfluencers: Maybe<
    { __typename?: "RemoveConfirmedInfluencersPayload" } & {
      assignment: { __typename?: "ClientAssignment" } & Pick<
        ClientAssignment,
        "number" | "status"
      >;
    }
  >;
};

export type CreateAffinityModelMutationVariables = {
  clientMutationId?: Maybe<Scalars["String"]>;
  snapInfluencerEvaluationGroupId: Scalars["ID"];
  to: Scalars["Date"];
  description?: Maybe<Scalars["String"]>;
};

export type CreateAffinityModelMutation = { __typename?: "Mutation" } & {
  createAffinityModel: Maybe<
    { __typename?: "CreateAffinityModelPayload" } & Pick<
      CreateAffinityModelPayload,
      "clientMutationId"
    > & {
        affinityModel: { __typename?: "AffinityModel" } & Pick<
          AffinityModel,
          "id" | "number" | "description" | "createdAt"
        > & {
            snapInfluencerEvaluationGroup: {
              __typename?: "SnapInfluencerEvaluationGroup";
            } & Pick<SnapInfluencerEvaluationGroup, "id">;
            coreModel: { __typename?: "CoreModel" } & Pick<
              CoreModel,
              "to" | "rangeMonth"
            > & {
                logic: { __typename?: "Logic" } & Pick<Logic, "id" | "name">;
              };
          };
      }
  >;
};

export type CreateAgencyMutationVariables = {
  input: CreateAgencyInput;
};

export type CreateAgencyMutation = { __typename?: "Mutation" } & {
  createAgency: Maybe<
    { __typename?: "CreateAgencyPayload" } & {
      agency: { __typename?: "ClientAgency" } & Pick<ClientAgency, "name">;
    }
  >;
};

export type CreateCampaignMutationVariables = {
  clientMutationId?: Maybe<Scalars["String"]>;
  title: Scalars["String"];
  objectives?: Maybe<Array<Maybe<ObjectiveType>>>;
  campaignActivityType?: Maybe<CampaignActivityType>;
  productItemId: Scalars["ID"];
  instagramReachKpi?: Maybe<Scalars["Int"]>;
  twitterReachKpi?: Maybe<Scalars["Int"]>;
  youtubeReachKpi?: Maybe<Scalars["Int"]>;
  tiktokReachKpi?: Maybe<Scalars["Int"]>;
  instagramSales?: Maybe<Scalars["Int"]>;
  twitterSales?: Maybe<Scalars["Int"]>;
  youtubeSales?: Maybe<Scalars["Int"]>;
  tiktokSales?: Maybe<Scalars["Int"]>;
  instagramEcClick?: Maybe<Scalars["Int"]>;
  twitterEcClick?: Maybe<Scalars["Int"]>;
  youtubeEcClick?: Maybe<Scalars["Int"]>;
  tiktokEcClick?: Maybe<Scalars["Int"]>;
  note?: Maybe<Scalars["String"]>;
  postStartAt: Scalars["DateTime"];
  postEndAt: Scalars["DateTime"];
  suggestionStartAt: Scalars["DateTime"];
  suggestionEndAt: Scalars["DateTime"];
  affinityModelId: Scalars["ID"];
  budget: Scalars["Int"];
  keywordAndHashtags?: Maybe<Scalars["String"]>;
  attachmentFiles?: Maybe<Array<Maybe<CampaignFileUpload>>>;
};

export type CreateCampaignMutation = { __typename?: "Mutation" } & {
  createCampaign: Maybe<
    { __typename?: "CreateCampaignPayload" } & {
      campaign: { __typename?: "ClientCampaign" } & Pick<ClientCampaign, "id">;
    }
  >;
};

export type CreateClientAccountMutationVariables = {
  input: CreateClientAccountInput;
};

export type CreateClientAccountMutation = { __typename?: "Mutation" } & {
  createClientAccount: Maybe<
    { __typename?: "CreateClientAccountPayload" } & {
      clientAccount: { __typename?: "ClientAccount" } & Pick<
        ClientAccount,
        "id"
      >;
    }
  >;
};

export type CreateInfluencerEvaluationGroupMutationVariables = {
  title: Scalars["String"];
  clientMutationId?: Maybe<Scalars["String"]>;
  brandId: Scalars["ID"];
};

export type CreateInfluencerEvaluationGroupMutation = {
  __typename?: "Mutation";
} & {
  createInfluencerEvaluationGroup: Maybe<
    { __typename?: "CreateInfluencerEvaluationGroupPayload" } & {
      influencerEvaluationGroup: {
        __typename?: "InfluencerEvaluationGroup";
      } & Pick<InfluencerEvaluationGroup, "id" | "number" | "title">;
    }
  >;
};

export type CreateSnapMutationVariables = {
  clientMutationId?: Maybe<Scalars["String"]>;
  influencerEvaluationGroupId: Scalars["ID"];
  title: Scalars["String"];
  description?: Maybe<Scalars["String"]>;
};

export type CreateSnapMutation = { __typename?: "Mutation" } & {
  createSnapInfluencerEvaluationGroup: Maybe<
    { __typename?: "CreateSnapInfluencerEvaluationGroupPayload" } & {
      snapInfluencerEvaluationGroup: Maybe<
        { __typename?: "SnapInfluencerEvaluationGroup" } & Pick<
          SnapInfluencerEvaluationGroup,
          "id" | "number"
        >
      >;
    }
  >;
};

export type CreateAgencyAccountMutationVariables = {
  input: CreateAgencyAccountInput;
};

export type CreateAgencyAccountMutation = { __typename?: "Mutation" } & {
  createAgencyAccount: Maybe<
    { __typename?: "CreateAgencyAccountPayload" } & Pick<
      CreateAgencyAccountPayload,
      "clientMutationId"
    >
  >;
};

export type CreateBrandMutationVariables = {
  input: CreateBrandInput;
};

export type CreateBrandMutation = { __typename?: "Mutation" } & {
  createBrand: Maybe<
    { __typename?: "CreateBrandPayload" } & {
      brand: { __typename?: "ClientBrand" } & Pick<ClientBrand, "id" | "name">;
    }
  >;
};

export type DeactivateAgencyMutationVariables = {
  input: DeactivateAgencyInput;
};

export type DeactivateAgencyMutation = { __typename?: "Mutation" } & {
  deactivateAgency: Maybe<
    { __typename?: "DeactivateAgencyPayload" } & Pick<
      DeactivateAgencyPayload,
      "clientMutationId"
    >
  >;
};

export type DeleteAgencyAccountMutationVariables = {
  input: DeleteAgencyAccountInput;
};

export type DeleteAgencyAccountMutation = { __typename?: "Mutation" } & {
  deleteAgencyAccount: Maybe<
    { __typename?: "DeleteAgencyAccountPayload" } & Pick<
      DeleteAgencyAccountPayload,
      "clientMutationId"
    >
  >;
};

export type DeleteCampaignMutationVariables = {
  input: DeleteCampaignInput;
};

export type DeleteCampaignMutation = { __typename?: "Mutation" } & {
  deleteCampaign: Maybe<
    { __typename?: "DeleteCampaignPayload" } & Pick<
      DeleteCampaignPayload,
      "clientMutationId" | "deleted"
    >
  >;
};

export type DownloadClientReportMutationVariables = {
  campaignId: Scalars["ID"];
};

export type DownloadClientReportMutation = { __typename?: "Mutation" } & {
  downloadClientReport: Maybe<
    { __typename?: "DownloadClientReportPayload" } & Pick<
      DownloadClientReportPayload,
      "url"
    >
  >;
};

export type DownloadAgencyReportInfluencersMutationVariables = {
  clientMutationId?: Maybe<Scalars["String"]>;
  campaignId: Scalars["ID"];
};

export type DownloadAgencyReportInfluencersMutation = {
  __typename?: "Mutation";
} & {
  downloadAgencyReportInfluencers: Maybe<
    { __typename?: "DownloadAgencyReportInfluencersPayload" } & Pick<
      DownloadAgencyReportInfluencersPayload,
      "url"
    >
  >;
};

export type DownloadAssignmentInfluencersToAgencyMutationVariables = {
  clientMutationId?: Maybe<Scalars["String"]>;
  campaignId: Scalars["ID"];
  agencyId?: Maybe<Scalars["ID"]>;
};

export type DownloadAssignmentInfluencersToAgencyMutation = {
  __typename?: "Mutation";
} & {
  downloadAssignmentInfluencersToAgency: Maybe<
    { __typename?: "DownloadAssignmentInfluencersToAgencyPayload" } & Pick<
      DownloadAssignmentInfluencersToAgencyPayload,
      "url"
    >
  >;
};

export type DownloadSuggestionInfluencersToAgencyMutationVariables = {
  clientMutationId?: Maybe<Scalars["String"]>;
  suggestionId: Scalars["ID"];
  considerationOnly?: Maybe<Scalars["Boolean"]>;
};

export type DownloadSuggestionInfluencersToAgencyMutation = {
  __typename?: "Mutation";
} & {
  downloadSuggestionInfluencersToAgency: Maybe<
    { __typename?: "DownloadSuggestionInfluencersToAgencyPayload" } & Pick<
      DownloadSuggestionInfluencersToAgencyPayload,
      "url"
    >
  >;
};

export type DownloadSuggestionInfluencersMutationVariables = {
  clientMutationId?: Maybe<Scalars["String"]>;
  suggestionId: Scalars["ID"];
  considerationOnly?: Maybe<Scalars["Boolean"]>;
};

export type DownloadSuggestionInfluencersMutation = {
  __typename?: "Mutation";
} & {
  downloadSuggestionInfluencers: Maybe<
    { __typename?: "DownloadSuggestionInfluencersPayload" } & Pick<
      DownloadSuggestionInfluencersPayload,
      "url"
    >
  >;
};

export type DownloadAssignmentInfluencersMutationVariables = {
  input: DownloadAssignmentInfluencersInput;
};

export type DownloadAssignmentInfluencersMutation = {
  __typename?: "Mutation";
} & {
  downloadAssignmentInfluencers: Maybe<
    { __typename?: "DownloadAssignmentInfluencersPayload" } & Pick<
      DownloadAssignmentInfluencersPayload,
      "url"
    >
  >;
};

export type UpdateBrandMutationVariables = {
  brandId: Scalars["ID"];
  name: Scalars["String"];
  image?: Maybe<Scalars["Upload"]>;
  description?: Maybe<Scalars["String"]>;
};

export type UpdateBrandMutation = { __typename?: "Mutation" } & {
  updateBrand: Maybe<
    { __typename?: "UpdateBrandPayload" } & {
      brand: { __typename?: "ClientBrand" } & Pick<
        ClientBrand,
        "id" | "name" | "image" | "description"
      >;
    }
  >;
};

export type LoginMutationVariables = {
  input: ClientLoginInput;
};

export type LoginMutation = { __typename?: "Mutation" } & {
  clientLogin: Maybe<
    { __typename?: "ClientCredentialPayload" } & Pick<
      ClientCredentialPayload,
      "token" | "clientMutationId"
    >
  >;
};

export type ReadAssignmentCommentMutationVariables = {
  input: ReadAssignmentCommentInput;
};

export type ReadAssignmentCommentMutation = { __typename?: "Mutation" } & {
  readAssignmentComment: Maybe<
    { __typename?: "ReadCommentPayload" } & Pick<
      ReadCommentPayload,
      "clientMutationId"
    >
  >;
};

export type ReadReportCommentMutationVariables = {
  input: ReadReportCommentInput;
};

export type ReadReportCommentMutation = { __typename?: "Mutation" } & {
  readReportComment: Maybe<
    { __typename?: "ReadCommentPayload" } & Pick<
      ReadCommentPayload,
      "clientMutationId"
    >
  >;
};

export type ReadSuggestionCommentMutationVariables = {
  input: ReadSuggestionCommentInput;
};

export type ReadSuggestionCommentMutation = { __typename?: "Mutation" } & {
  readSuggestionComment: Maybe<
    { __typename?: "ReadCommentPayload" } & Pick<
      ReadCommentPayload,
      "clientMutationId"
    >
  >;
};

export type RejectSuggestionMutationVariables = {
  campaignId: Scalars["ID"];
};

export type RejectSuggestionMutation = { __typename?: "Mutation" } & {
  rejectSuggestion: Maybe<
    { __typename?: "RejectSuggestionPayload" } & {
      campaign: { __typename?: "ClientCampaign" } & Pick<ClientCampaign, "id">;
    }
  >;
};

export type RemoveAgencyFromCampaignMutationVariables = {
  campaignId: Scalars["ID"];
  agencyId: Scalars["ID"];
};

export type RemoveAgencyFromCampaignMutation = { __typename?: "Mutation" } & {
  removeAgencyFromCampaign: Maybe<
    { __typename?: "RemoveAgencyFromCampaignPayload" } & Pick<
      RemoveAgencyFromCampaignPayload,
      "clientMutationId"
    >
  >;
};

export type RemoveInfluencerFromConsiderationMutationVariables = {
  clientMutationId?: Maybe<Scalars["String"]>;
  suggestionInfluencerId: Scalars["ID"];
  influencerNumber: Scalars["Int"];
};

export type RemoveInfluencerFromConsiderationMutation = {
  __typename?: "Mutation";
} & {
  removeInfluencerFromConsideration: Maybe<
    { __typename?: "RemoveInfluencerFromConsiderationPayload" } & {
      suggestion: { __typename?: "Suggestion" } & Pick<
        Suggestion,
        | "totalCost"
        | "totalCommission"
        | "totalInfluencerCount"
        | "totalFollower"
        | "totalFollow"
        | "averageEngagementRate"
        | "averageAdvocateScoreRate"
        | "averageAffinityRate"
        | "totalConsiderationInfluencerCount"
        | "isDataCollectionCompleted"
      > & {
          summaries: Array<
            | { __typename?: "Suggestion" }
            | { __typename?: "ClientReport" }
            | { __typename?: "ClientAssignment" }
            | { __typename?: "AssignmentInstagramSummary" }
            | { __typename?: "AssignmentTiktokSummary" }
            | { __typename?: "AssignmentTwitterSummary" }
            | { __typename?: "AssignmentWinningSummary" }
            | { __typename?: "AssignmentYoutubeSummary" }
            | { __typename?: "ReportInstagramSummary" }
            | { __typename?: "ReportPlannedTotalSummary" }
            | { __typename?: "ReportTiktokSummary" }
            | { __typename?: "ReportTwitterSummary" }
            | { __typename?: "ReportYoutubeSummary" }
            | ({
                __typename?: "SuggestionInstagramSummary";
              } & SuggestionInstagramSummaryFragment)
            | { __typename?: "SuggestionTiktokSummary" }
            | ({
                __typename?: "SuggestionTwitterSummary";
              } & SuggestionTwitterSummaryFragment)
            | ({
                __typename?: "SuggestionYoutubeSummary";
              } & SuggestionYoutubeSummaryFragment)
          >;
          suggestionInfluencer: Maybe<
            {
              __typename?: "SuggestionInfluencer";
            } & SuggestionInfluencerFragment
          >;
        };
    }
  >;
};

export type SendAgencyPasswordResetEmailMutationVariables = {
  clientMutationId?: Maybe<Scalars["String"]>;
  email: Scalars["String"];
};

export type SendAgencyPasswordResetEmailMutation = {
  __typename?: "Mutation";
} & {
  agencyChangePasswordRequest: Maybe<
    { __typename?: "AgencyChangePasswordRequestPayload" } & Pick<
      AgencyChangePasswordRequestPayload,
      "requested"
    >
  >;
};

export type SendAssignmentCommentMutationVariables = {
  input: SendAssignmentCommentInput;
  brandName: Scalars["String"];
};

export type SendAssignmentCommentMutation = { __typename?: "Mutation" } & {
  sendAssignmentComment: Maybe<
    { __typename?: "SendCommentPayload" } & Pick<
      SendCommentPayload,
      "clientMutationId"
    > & {
        comments: Array<
          { __typename?: "Comment" } & Pick<
            Comment,
            "id" | "readFlag" | "sendAt" | "comment"
          > & {
              account:
                | ({ __typename: "ClientAccount" } & Pick<
                    ClientAccount,
                    "name" | "number"
                  > & {
                      brand: { __typename?: "ClientBrand" } & Pick<
                        ClientBrand,
                        "name"
                      >;
                    })
                | ({ __typename: "AgencyAccount" } & Pick<
                    AgencyAccount,
                    "name" | "number"
                  > & {
                      brand: { __typename?: "AgencyBrand" } & Pick<
                        AgencyBrand,
                        "name"
                      >;
                    });
            }
        >;
      }
  >;
};

export type SendDraftAssignmentCommentMutationVariables = {
  input: SendDraftAssignmentCommentInput;
};

export type SendDraftAssignmentCommentMutation = { __typename?: "Mutation" } & {
  sendDraftAssignmentComment: Maybe<
    { __typename?: "SendDraftCommentPayload" } & Pick<
      SendDraftCommentPayload,
      "clientMutationId"
    > & {
        comment: { __typename?: "Comment" } & Pick<
          Comment,
          "id" | "comment" | "sendAt"
        >;
      }
  >;
};

export type SendDraftReportCommentMutationVariables = {
  input: SendDraftReportCommentInput;
};

export type SendDraftReportCommentMutation = { __typename?: "Mutation" } & {
  sendDraftReportComment: Maybe<
    { __typename?: "SendDraftCommentPayload" } & Pick<
      SendDraftCommentPayload,
      "clientMutationId"
    > & {
        comment: { __typename?: "Comment" } & Pick<
          Comment,
          "id" | "comment" | "sendAt"
        >;
      }
  >;
};

export type SendDraftSuggestionCommentMutationVariables = {
  input: SendDraftSuggestionCommentInput;
};

export type SendDraftSuggestionCommentMutation = { __typename?: "Mutation" } & {
  sendDraftSuggestionComment: Maybe<
    { __typename?: "SendDraftCommentPayload" } & Pick<
      SendDraftCommentPayload,
      "clientMutationId"
    > & {
        comment: { __typename?: "Comment" } & Pick<
          Comment,
          "id" | "comment" | "sendAt"
        >;
      }
  >;
};

export type SendClientPasswordResetEmailMutationVariables = {
  clientMutationId?: Maybe<Scalars["String"]>;
  email: Scalars["String"];
};

export type SendClientPasswordResetEmailMutation = {
  __typename?: "Mutation";
} & {
  clientChangePasswordRequest: Maybe<
    { __typename?: "ClientChangePasswordRequestPayload" } & Pick<
      ClientChangePasswordRequestPayload,
      "requested"
    >
  >;
};

export type SendReportCommentMutationVariables = {
  input: SendReportCommentInput;
  brandName: Scalars["String"];
};

export type SendReportCommentMutation = { __typename?: "Mutation" } & {
  sendReportComment: Maybe<
    { __typename?: "SendCommentPayload" } & Pick<
      SendCommentPayload,
      "clientMutationId"
    > & {
        comments: Array<
          { __typename?: "Comment" } & Pick<
            Comment,
            "id" | "readFlag" | "sendAt" | "comment"
          > & {
              account:
                | ({ __typename: "ClientAccount" } & Pick<
                    ClientAccount,
                    "name" | "number"
                  > & {
                      brand: { __typename?: "ClientBrand" } & Pick<
                        ClientBrand,
                        "name"
                      >;
                    })
                | ({ __typename: "AgencyAccount" } & Pick<
                    AgencyAccount,
                    "name" | "number"
                  > & {
                      brand: { __typename?: "AgencyBrand" } & Pick<
                        AgencyBrand,
                        "name"
                      >;
                    });
            }
        >;
      }
  >;
};

export type SendSuggestionCommentMutationVariables = {
  input: SendSuggestionCommentInput;
  brandName: Scalars["String"];
};

export type SendSuggestionCommentMutation = { __typename?: "Mutation" } & {
  sendSuggestionComment: Maybe<
    { __typename?: "SendCommentPayload" } & Pick<
      SendCommentPayload,
      "clientMutationId"
    > & {
        comments: Array<
          { __typename?: "Comment" } & Pick<
            Comment,
            "id" | "readFlag" | "sendAt" | "comment"
          > & {
              account:
                | ({ __typename: "ClientAccount" } & Pick<
                    ClientAccount,
                    "name" | "number"
                  > & {
                      brand: { __typename?: "ClientBrand" } & Pick<
                        ClientBrand,
                        "name"
                      >;
                    })
                | ({ __typename: "AgencyAccount" } & Pick<
                    AgencyAccount,
                    "name" | "number"
                  > & {
                      brand: { __typename?: "AgencyBrand" } & Pick<
                        AgencyBrand,
                        "name"
                      >;
                    });
            }
        >;
      }
  >;
};

export type SkipToReportMutationVariables = {
  campaignId: Scalars["ID"];
  agencyIds: Array<Scalars["ID"]>;
};

export type SkipToReportMutation = { __typename?: "Mutation" } & {
  skipToReport: Maybe<
    { __typename?: "SkipToReportPayload" } & {
      campaign: { __typename?: "ClientCampaign" } & Pick<ClientCampaign, "id">;
    }
  >;
};

export type ToggleConfirmInfluencersConsiderationMutationVariables = {
  assignmentId: Scalars["ID"];
  assignmentInfluencerIds: Array<Scalars["ID"]>;
};

export type ToggleConfirmInfluencersConsiderationMutation = {
  __typename?: "Mutation";
} & {
  toggleConfirmInfluencersConsideration: Maybe<
    { __typename?: "ToggleConfirmInfluencersConsiderationPayload" } & {
      assignment: { __typename?: "ClientAssignment" } & Pick<
        ClientAssignment,
        "id"
      >;
    }
  >;
};

export type ToggleInfluencersConsiderationMutationVariables = {
  suggestionId: Scalars["ID"];
  suggestionInfluencerIds: Array<Scalars["ID"]>;
};

export type ToggleInfluencersConsiderationMutation = {
  __typename?: "Mutation";
} & {
  toggleInfluencersConsideration: Maybe<
    { __typename?: "ToggleInfluencersConsiderationPayload" } & {
      suggestion: { __typename?: "Suggestion" } & Pick<
        Suggestion,
        | "id"
        | "totalCost"
        | "totalCommission"
        | "totalInfluencerCount"
        | "totalFollower"
        | "totalFollow"
        | "averageEngagementRate"
        | "averageAdvocateScoreRate"
        | "averageAffinityRate"
        | "totalConsiderationInfluencerCount"
        | "isDataCollectionCompleted"
      > & {
          summaries: Array<
            | { __typename?: "Suggestion" }
            | { __typename?: "ClientReport" }
            | { __typename?: "ClientAssignment" }
            | { __typename?: "AssignmentInstagramSummary" }
            | { __typename?: "AssignmentTiktokSummary" }
            | { __typename?: "AssignmentTwitterSummary" }
            | { __typename?: "AssignmentWinningSummary" }
            | { __typename?: "AssignmentYoutubeSummary" }
            | { __typename?: "ReportInstagramSummary" }
            | { __typename?: "ReportPlannedTotalSummary" }
            | { __typename?: "ReportTiktokSummary" }
            | { __typename?: "ReportTwitterSummary" }
            | { __typename?: "ReportYoutubeSummary" }
            | ({
                __typename?: "SuggestionInstagramSummary";
              } & SuggestionInstagramSummaryFragment)
            | { __typename?: "SuggestionTiktokSummary" }
            | ({
                __typename?: "SuggestionTwitterSummary";
              } & SuggestionTwitterSummaryFragment)
            | ({
                __typename?: "SuggestionYoutubeSummary";
              } & SuggestionYoutubeSummaryFragment)
          >;
        };
    }
  >;
};

export type UpdateAgencyAccountMutationVariables = {
  input: UpdateAgencyAccountInput;
};

export type UpdateAgencyAccountMutation = { __typename?: "Mutation" } & {
  updateAgencyAccount: Maybe<
    { __typename?: "UpdateAgencyAccountPayload" } & Pick<
      UpdateAgencyAccountPayload,
      "clientMutationId"
    >
  >;
};

export type UpdateAgencyMutationVariables = {
  input: UpdateAgencyInput;
};

export type UpdateAgencyMutation = { __typename?: "Mutation" } & {
  updateAgency: Maybe<
    { __typename?: "UpdateAgencyPayload" } & {
      agency: { __typename?: "ClientAgency" } & Pick<ClientAgency, "name">;
    }
  >;
};

export type UpdateCampaignMutationVariables = {
  clientMutationId?: Maybe<Scalars["String"]>;
  title: Scalars["String"];
  objectives?: Maybe<Array<Maybe<ObjectiveType>>>;
  campaignId: Scalars["ID"];
  campaignActivityType: CampaignActivityType;
  productItemId: Scalars["ID"];
  instagramReachKpi?: Maybe<Scalars["Int"]>;
  twitterReachKpi?: Maybe<Scalars["Int"]>;
  youtubeReachKpi?: Maybe<Scalars["Int"]>;
  tiktokReachKpi?: Maybe<Scalars["Int"]>;
  instagramSales?: Maybe<Scalars["Int"]>;
  twitterSales?: Maybe<Scalars["Int"]>;
  youtubeSales?: Maybe<Scalars["Int"]>;
  tiktokSales?: Maybe<Scalars["Int"]>;
  instagramEcClick?: Maybe<Scalars["Int"]>;
  twitterEcClick?: Maybe<Scalars["Int"]>;
  youtubeEcClick?: Maybe<Scalars["Int"]>;
  tiktokEcClick?: Maybe<Scalars["Int"]>;
  note?: Maybe<Scalars["String"]>;
  postStartAt: Scalars["DateTime"];
  postEndAt: Scalars["DateTime"];
  suggestionStartAt: Scalars["DateTime"];
  suggestionEndAt: Scalars["DateTime"];
  affinityModelId: Scalars["ID"];
  budget: Scalars["Int"];
  keywordAndHashtags?: Maybe<Scalars["String"]>;
  clientAttachmentFiles?: Maybe<Array<Maybe<CampaignFileUpload>>>;
};

export type UpdateCampaignMutation = { __typename?: "Mutation" } & {
  updateCampaign: Maybe<
    { __typename?: "UpdateCampaignPayload" } & {
      campaign: { __typename?: "ClientCampaign" } & Pick<ClientCampaign, "id">;
    }
  >;
};

export type UpdateClientAccountMutationVariables = {
  clientMutationId?: Maybe<Scalars["String"]>;
  clientAccountId: Scalars["ID"];
  name: Scalars["String"];
  email: Scalars["String"];
};

export type UpdateClientAccountMutation = { __typename?: "Mutation" } & {
  updateClientAccount: Maybe<
    { __typename?: "UpdateClientAccountPayload" } & {
      clientAccount: { __typename?: "ClientAccount" } & Pick<
        ClientAccount,
        "id" | "number"
      >;
    }
  >;
};

export type UpdateInfluencerEvaluationNoteMutationVariables = {
  influencerEvaluationId: Scalars["ID"];
  clientMutationId?: Maybe<Scalars["String"]>;
  note: Scalars["String"];
};

export type UpdateInfluencerEvaluationNoteMutation = {
  __typename?: "Mutation";
} & {
  updateInfluencerEvaluationNote: Maybe<
    { __typename?: "UpdateInfluencerEvaluationNotePayload" } & {
      influencerEvaluation: {
        __typename?: "MasterInfluencerEvaluation";
      } & Pick<MasterInfluencerEvaluation, "id" | "note">;
    }
  >;
};

export type UpdateInfluencerEvaluationMutationVariables = {
  influencerEvaluationId: Scalars["ID"];
  clientMutationId?: Maybe<Scalars["String"]>;
  evaluation: EvaluationType;
};

export type UpdateInfluencerEvaluationMutation = { __typename?: "Mutation" } & {
  updateInfluencerEvaluation: Maybe<
    { __typename?: "UpdateInfluencerEvaluationPayload" } & {
      influencerEvaluation: {
        __typename?: "MasterInfluencerEvaluation";
      } & Pick<
        MasterInfluencerEvaluation,
        "id" | "number" | "evaluation" | "evaluatedAt"
      >;
    }
  >;
};

export type UpdateInfluencerSegmentMutationVariables = {
  input: UpdateInfluencerSegmentInput;
};

export type UpdateInfluencerSegmentMutation = { __typename?: "Mutation" } & {
  updateInfluencerSegment: Maybe<
    { __typename?: "UpdateInfluencerSegmentPayload" } & {
      influencerSegment: { __typename?: "InfluencerSegment" } & Pick<
        InfluencerSegment,
        "id"
      >;
    }
  >;
};

export type UpdateUnevaluatedInfluencerMutationVariables = {
  clientMutationId?: Maybe<Scalars["String"]>;
  influencerEvaluationGroupId: Scalars["ID"];
};

export type UpdateUnevaluatedInfluencerMutation = {
  __typename?: "Mutation";
} & {
  updateUnevaluatedInfluencer: Maybe<
    { __typename?: "UpdatePostLoadingStatusPayload" } & {
      postLoadingStatus: { __typename?: "PostLoadingStatus" } & Pick<
        PostLoadingStatus,
        "status"
      >;
    }
  >;
};

export type UploadAvailabilityResultsMutationVariables = {
  input: UploadAvailabilityResultsInput;
};

export type UploadAvailabilityResultsMutation = { __typename?: "Mutation" } & {
  uploadAvailabilityResults: Maybe<
    { __typename?: "UploadAvailabilityResultsPayload" } & {
      assignment: { __typename?: "AgencyAssignment" } & Pick<
        AgencyAssignment,
        "id"
      > & {
          attachmentFiles: Array<
            { __typename?: "AttachmentFile" } & Pick<
              AttachmentFile,
              "id" | "name" | "url"
            >
          >;
          assignmentInfluencers: Maybe<
            { __typename?: "AssignmentInfluencerConnection" } & {
              paginatorInfo: Maybe<
                { __typename?: "PaginatorInfo" } & Pick<
                  PaginatorInfo,
                  "total" | "count" | "currentPage"
                >
              >;
              edges: Maybe<
                Array<
                  Maybe<
                    { __typename?: "AssignmentInfluencerEdge" } & {
                      node: { __typename?: "AssignmentInfluencerNode" } & Pick<
                        AssignmentInfluencerNode,
                        "id"
                      > & {
                          assignmentInfluencer: {
                            __typename?: "AssignmentInfluencer";
                          } & Pick<
                            AssignmentInfluencer,
                            | "id"
                            | "name"
                            | "image"
                            | "availabilityStatus"
                            | "note"
                          >;
                        };
                    }
                  >
                >
              >;
            }
          >;
        };
    }
  >;
};

export type UploadPostedResultsMutationVariables = {
  input: UploadPostedResultsInput;
};

export type UploadPostedResultsMutation = { __typename?: "Mutation" } & {
  uploadPostedResults: Maybe<
    { __typename?: "UploadPostedResultsPayload" } & {
      report: { __typename?: "AgencyReport" } & {
        attachmentFiles: Array<
          { __typename?: "AttachmentFile" } & Pick<
            AttachmentFile,
            "id" | "name" | "url"
          >
        >;
        influencers: Maybe<
          { __typename?: "ReportInfluencerConnection" } & {
            paginatorInfo: Maybe<
              { __typename?: "PaginatorInfo" } & Pick<
                PaginatorInfo,
                "total" | "count" | "currentPage"
              >
            >;
            edges: Maybe<
              Array<
                Maybe<
                  { __typename?: "ReportInfluencerEdge" } & {
                    node: { __typename?: "ReportInfluencerNode" } & {
                      reportInfluencer: {
                        __typename?: "ReportInfluencer";
                      } & Pick<ReportInfluencer, "id" | "name" | "image"> & {
                          insightsBySocialMedia: Array<
                            {
                              __typename?: "ReportInfluencerInsightBySocialMedia";
                            } & Pick<
                              ReportInfluencerInsightBySocialMedia,
                              "status" | "isPlanned" | "postUrl"
                            >
                          >;
                        };
                    };
                  }
                >
              >
            >;
          }
        >;
      };
    }
  >;
};

export type UploadSuggestionMutationVariables = {
  input: UploadSuggestionInput;
};

export type UploadSuggestionMutation = { __typename?: "Mutation" } & {
  uploadSuggestion: Maybe<
    { __typename?: "UploadSuggestionPayload" } & {
      suggestion: { __typename?: "Suggestion" } & Pick<
        Suggestion,
        "id" | "number" | "note" | "createdAt"
      > & {
          attachmentFiles: Array<
            { __typename?: "AttachmentFile" } & Pick<
              AttachmentFile,
              "name" | "url"
            >
          >;
        };
    }
  >;
};

export type AdminClientAccountsQueryVariables = {};

export type AdminClientAccountsQuery = { __typename?: "Query" } & {
  clientAccounts: Maybe<
    Array<
      { __typename?: "ClientAccount" } & Pick<
        ClientAccount,
        "id" | "number" | "name" | "email"
      >
    >
  >;
};

export type AffinityModelCreationQueryVariables = {
  name: Scalars["String"];
  perPage?: Maybe<Scalars["Int"]>;
  page: Scalars["Int"];
};

export type AffinityModelCreationQuery = { __typename?: "Query" } & {
  brand: { __typename?: "ClientBrand" } & Pick<ClientBrand, "id" | "name"> & {
      affinityModels: Maybe<
        { __typename?: "AffinityModelConnection" } & {
          edges: Maybe<
            Array<
              Maybe<
                { __typename?: "AffinityModelEdge" } & {
                  node: { __typename?: "AffinityModelNode" } & {
                    affinityModel: { __typename?: "AffinityModel" } & Pick<
                      AffinityModel,
                      "id"
                    > & {
                        coreModel: { __typename?: "CoreModel" } & Pick<
                          CoreModel,
                          "id" | "rangeMonth"
                        >;
                      };
                  };
                }
              >
            >
          >;
        }
      >;
    };
  logics: Maybe<Array<{ __typename?: "Logic" } & Pick<Logic, "id" | "name">>>;
  userPostVectors: Maybe<
    Array<{ __typename?: "UserPostVector" } & Pick<UserPostVector, "id" | "to">>
  >;
  influencerEvaluationGroups: Maybe<
    Array<
      Maybe<
        { __typename?: "InfluencerEvaluationGroup" } & Pick<
          InfluencerEvaluationGroup,
          "id" | "number" | "title"
        > & {
            snapInfluencerEvaluationGroups: Maybe<
              { __typename?: "SnapInfluencerEvaluationGroupConnection" } & {
                edges: Maybe<
                  Array<
                    Maybe<
                      { __typename?: "SnapInfluencerEvaluationGroupEdge" } & {
                        node: {
                          __typename?: "SnapInfluencerEvaluationGroupNode";
                        } & {
                          snapInfluencerEvaluationGroup: {
                            __typename?: "SnapInfluencerEvaluationGroup";
                          } & Pick<
                            SnapInfluencerEvaluationGroup,
                            "id" | "number" | "title"
                          >;
                        };
                      }
                    >
                  >
                >;
              }
            >;
          }
      >
    >
  >;
};

export type AffinityModelQueryVariables = {
  name: Scalars["String"];
  number: Scalars["Int"];
};

export type AffinityModelQuery = { __typename?: "Query" } & {
  brand: { __typename?: "ClientBrand" } & Pick<ClientBrand, "id" | "name"> & {
      affinityModel: Maybe<
        { __typename?: "AffinityModel" } & Pick<
          AffinityModel,
          "name" | "createdAt" | "description"
        > & {
            snapInfluencerEvaluationGroup: {
              __typename?: "SnapInfluencerEvaluationGroup";
            } & {
              influencerEvaluationGroup: {
                __typename?: "InfluencerEvaluationGroup";
              } & Pick<InfluencerEvaluationGroup, "title">;
            };
            coreModel: { __typename?: "CoreModel" } & Pick<
              CoreModel,
              "to" | "rangeMonth"
            > & {
                logic: { __typename?: "Logic" } & Pick<Logic, "name">;
              };
            usedCampaigns: Maybe<
              Array<
                Maybe<
                  { __typename?: "UsedCampaign" } & Pick<
                    UsedCampaign,
                    "id" | "name"
                  >
                >
              >
            >;
          }
      >;
    };
};

export type AffinityModelsQueryVariables = {
  name: Scalars["String"];
  perPage: Scalars["Int"];
  page: Scalars["Int"];
};

export type AffinityModelsQuery = { __typename?: "Query" } & {
  brand: { __typename?: "ClientBrand" } & Pick<ClientBrand, "id" | "name"> & {
      affinityModels: Maybe<
        { __typename?: "AffinityModelConnection" } & {
          paginatorInfo: Maybe<
            { __typename?: "PaginatorInfo" } & Pick<
              PaginatorInfo,
              "total" | "count" | "currentPage"
            >
          >;
          edges: Maybe<
            Array<
              Maybe<
                { __typename?: "AffinityModelEdge" } & {
                  node: { __typename?: "AffinityModelNode" } & {
                    affinityModel: { __typename?: "AffinityModel" } & Pick<
                      AffinityModel,
                      "id" | "name" | "number" | "description" | "createdAt"
                    >;
                  };
                }
              >
            >
          >;
        }
      >;
    };
};

export type AgenciesQueryVariables = {};

export type AgenciesQuery = { __typename?: "Query" } & {
  agencies: Maybe<
    Array<
      { __typename?: "ClientAgency" } & Pick<ClientAgency, "id" | "name"> & {
          ownerAgencyAccount: { __typename?: "AgencyAccountInfo" } & Pick<
            AgencyAccountInfo,
            "name" | "email"
          >;
        }
    >
  >;
};

export type AgencyAccountQueryVariables = {};

export type AgencyAccountQuery = { __typename?: "Query" } & {
  agencyAccount: { __typename?: "AgencyAccount" } & Pick<
    AgencyAccount,
    "id" | "agencyId" | "agencyName" | "name" | "email" | "isOwner"
  > & {
      brands: Array<
        { __typename?: "AgencyBrand" } & Pick<
          AgencyBrand,
          "id" | "name" | "image"
        >
      >;
    };
};

export type AgencyAccountsQueryVariables = {};

export type AgencyAccountsQuery = { __typename?: "Query" } & {
  agencyAccounts: Maybe<
    Array<
      { __typename?: "AgencyAccount" } & Pick<
        AgencyAccount,
        "id" | "number" | "name" | "email" | "isOwner"
      >
    >
  >;
};

export type AgencyAccountUpdateQueryVariables = {
  number: Scalars["Int"];
};

export type AgencyAccountUpdateQuery = { __typename?: "Query" } & {
  agencyAccountByNumber: { __typename?: "AgencyAccount" } & Pick<
    AgencyAccount,
    "id" | "number" | "name" | "email"
  >;
};

export type AgencyAdditionalSuggestionsQueryVariables = {
  brandName: Scalars["String"];
  campaignNumber: Scalars["Int"];
};

export type AgencyAdditionalSuggestionsQuery = { __typename?: "Query" } & {
  agencyAccount: { __typename?: "AgencyAccount" } & Pick<
    AgencyAccount,
    "id"
  > & {
      brand: { __typename?: "AgencyBrand" } & Pick<AgencyBrand, "id"> & {
          campaign: Maybe<
            { __typename?: "AgencyCampaign" } & Pick<AgencyCampaign, "id"> & {
                suggestions: Maybe<
                  Array<
                    Maybe<
                      { __typename?: "Suggestion" } & Pick<
                        Suggestion,
                        "id" | "createdAt" | "note" | "number"
                      > & {
                          attachmentFiles: Array<
                            { __typename?: "AttachmentFile" } & Pick<
                              AttachmentFile,
                              "id" | "name" | "url"
                            >
                          >;
                        }
                    >
                  >
                >;
              }
          >;
        };
    };
};

export type AgencyAssignmentQueryVariables = {
  brandName: Scalars["String"];
  campaignNumber: Scalars["Int"];
  perPage?: Maybe<Scalars["Int"]>;
  page: Scalars["Int"];
};

export type AgencyAssignmentQuery = { __typename?: "Query" } & {
  agencyAccount: { __typename?: "AgencyAccount" } & Pick<
    AgencyAccount,
    "id"
  > & {
      brand: { __typename?: "AgencyBrand" } & Pick<AgencyBrand, "id"> & {
          campaign: Maybe<
            { __typename?: "AgencyCampaign" } & Pick<AgencyCampaign, "id"> & {
                assignment: Maybe<
                  { __typename?: "AgencyAssignment" } & Pick<
                    AgencyAssignment,
                    "id" | "status"
                  > & {
                      assignmentInfluencers: Maybe<
                        { __typename?: "AssignmentInfluencerConnection" } & {
                          paginatorInfo: Maybe<
                            { __typename?: "PaginatorInfo" } & Pick<
                              PaginatorInfo,
                              "total" | "count" | "currentPage"
                            >
                          >;
                          edges: Maybe<
                            Array<
                              Maybe<
                                { __typename?: "AssignmentInfluencerEdge" } & {
                                  node: {
                                    __typename?: "AssignmentInfluencerNode";
                                  } & {
                                    assignmentInfluencer: {
                                      __typename?: "AssignmentInfluencer";
                                    } & Pick<
                                      AssignmentInfluencer,
                                      | "image"
                                      | "name"
                                      | "availabilityStatus"
                                      | "note"
                                    >;
                                  };
                                }
                              >
                            >
                          >;
                        }
                      >;
                    }
                >;
              }
          >;
        };
    };
};

export type AgencyBrandCampaignsQueryVariables = {
  name: Scalars["String"];
};

export type AgencyBrandCampaignsQuery = { __typename?: "Query" } & {
  agencyAccount: { __typename?: "AgencyAccount" } & Pick<
    AgencyAccount,
    "id"
  > & {
      brand: { __typename?: "AgencyBrand" } & Pick<AgencyBrand, "id"> & {
          campaigns: Maybe<
            Array<
              Maybe<
                { __typename?: "AgencyCampaign" } & Pick<
                  AgencyCampaign,
                  "id" | "name" | "number"
                >
              >
            >
          >;
        };
    };
};

export type AgencyCampaignCommentQueryVariables = {
  brandName: Scalars["String"];
  campaignNumber: Scalars["Int"];
};

export type AgencyCampaignCommentQuery = { __typename?: "Query" } & {
  agencyCampaignComment: Maybe<
    { __typename?: "AgencyCampaignComment" } & Pick<
      AgencyCampaignComment,
      "campaignId"
    > & {
        suggestionComments: { __typename?: "BiddingComment" } & Pick<
          BiddingComment,
          "unreadCommentCount"
        > & {
            comments: Array<
              { __typename?: "Comment" } & Pick<
                Comment,
                "id" | "readFlag" | "sendAt" | "comment"
              > & {
                  account:
                    | ({ __typename: "ClientAccount" } & Pick<
                        ClientAccount,
                        "name" | "number"
                      > & {
                          brand: { __typename?: "ClientBrand" } & Pick<
                            ClientBrand,
                            "name"
                          >;
                        })
                    | ({ __typename: "AgencyAccount" } & Pick<
                        AgencyAccount,
                        "name" | "number"
                      > & {
                          brand: { __typename?: "AgencyBrand" } & Pick<
                            AgencyBrand,
                            "name"
                          >;
                        });
                }
            >;
            draftComment: Maybe<
              { __typename?: "Comment" } & Pick<Comment, "id" | "comment">
            >;
          };
        assignmentComments: { __typename?: "BiddingComment" } & Pick<
          BiddingComment,
          "unreadCommentCount"
        > & {
            comments: Array<
              { __typename?: "Comment" } & Pick<
                Comment,
                "id" | "readFlag" | "sendAt" | "comment"
              > & {
                  account:
                    | ({ __typename: "ClientAccount" } & Pick<
                        ClientAccount,
                        "name" | "number"
                      > & {
                          brand: { __typename?: "ClientBrand" } & Pick<
                            ClientBrand,
                            "name"
                          >;
                        })
                    | ({ __typename: "AgencyAccount" } & Pick<
                        AgencyAccount,
                        "name" | "number"
                      > & {
                          brand: { __typename?: "AgencyBrand" } & Pick<
                            AgencyBrand,
                            "name"
                          >;
                        });
                }
            >;
            draftComment: Maybe<
              { __typename?: "Comment" } & Pick<Comment, "id" | "comment">
            >;
          };
        reportComments: { __typename?: "BiddingComment" } & Pick<
          BiddingComment,
          "unreadCommentCount"
        > & {
            comments: Array<
              { __typename?: "Comment" } & Pick<
                Comment,
                "id" | "readFlag" | "sendAt" | "comment"
              > & {
                  account:
                    | ({ __typename: "ClientAccount" } & Pick<
                        ClientAccount,
                        "name" | "number"
                      > & {
                          brand: { __typename?: "ClientBrand" } & Pick<
                            ClientBrand,
                            "name"
                          >;
                        })
                    | ({ __typename: "AgencyAccount" } & Pick<
                        AgencyAccount,
                        "name" | "number"
                      > & {
                          brand: { __typename?: "AgencyBrand" } & Pick<
                            AgencyBrand,
                            "name"
                          >;
                        });
                }
            >;
            draftComment: Maybe<
              { __typename?: "Comment" } & Pick<Comment, "id" | "comment">
            >;
          };
      }
  >;
};

export type AgencyCampaignDetailQueryVariables = {
  name: Scalars["String"];
  number: Scalars["Int"];
};

export type AgencyCampaignDetailQuery = { __typename?: "Query" } & {
  agencyAccount: { __typename?: "AgencyAccount" } & Pick<
    AgencyAccount,
    "id" | "agencyName"
  > & {
      brand: { __typename?: "AgencyBrand" } & Pick<
        AgencyBrand,
        "id" | "image"
      > & {
          campaign: Maybe<
            { __typename?: "AgencyCampaign" } & Pick<
              AgencyCampaign,
              | "id"
              | "number"
              | "name"
              | "objectives"
              | "budget"
              | "note"
              | "suggestionStatus"
              | "suggestionStartAt"
              | "suggestionEndAt"
              | "postStartAt"
              | "postEndAt"
            > & {
                productItem: { __typename?: "ProductItem" } & Pick<
                  ProductItem,
                  "id" | "name"
                >;
                attachmentFiles: Array<
                  { __typename?: "AttachmentFile" } & Pick<
                    AttachmentFile,
                    "id" | "name" | "url"
                  >
                >;
                indicator: { __typename?: "CampaignIndicator" } & Pick<
                  CampaignIndicator,
                  "id" | "budget" | "reachKpi"
                > & {
                    instagramIndicator: Maybe<
                      { __typename?: "CampaignInstagramIndicator" } & Pick<
                        CampaignInstagramIndicator,
                        "reachKpi"
                      >
                    >;
                    twitterIndicator: Maybe<
                      { __typename?: "CampaignTwitterIndicator" } & Pick<
                        CampaignTwitterIndicator,
                        "reachKpi"
                      >
                    >;
                    youtubeIndicator: Maybe<
                      { __typename?: "CampaignYoutubeIndicator" } & Pick<
                        CampaignYoutubeIndicator,
                        "reachKpi"
                      >
                    >;
                    tiktokIndicator: Maybe<
                      { __typename?: "CampaignTiktokIndicator" } & Pick<
                        CampaignTiktokIndicator,
                        "reachKpi"
                      >
                    >;
                  };
                assignment: Maybe<
                  { __typename?: "AgencyAssignment" } & Pick<
                    AgencyAssignment,
                    "id"
                  > & {
                      attachmentFiles: Array<
                        { __typename?: "AttachmentFile" } & Pick<
                          AttachmentFile,
                          "id" | "name" | "url"
                        >
                      >;
                    }
                >;
                report: Maybe<
                  { __typename?: "AgencyReport" } & Pick<
                    AgencyReport,
                    "campaignId"
                  > & {
                      attachmentFiles: Array<
                        { __typename?: "AttachmentFile" } & Pick<
                          AttachmentFile,
                          "id" | "name" | "url"
                        >
                      >;
                    }
                >;
              }
          >;
        };
    };
};

export type AgencyReportInfluencersQueryVariables = {
  brandName: Scalars["String"];
  campaignNumber: Scalars["Int"];
  page: Scalars["Int"];
  perPage?: Maybe<Scalars["Int"]>;
};

export type AgencyReportInfluencersQuery = { __typename?: "Query" } & {
  agencyAccount: { __typename?: "AgencyAccount" } & Pick<
    AgencyAccount,
    "id"
  > & {
      brand: { __typename?: "AgencyBrand" } & Pick<AgencyBrand, "id"> & {
          campaign: Maybe<
            { __typename?: "AgencyCampaign" } & Pick<AgencyCampaign, "id"> & {
                report: Maybe<
                  { __typename?: "AgencyReport" } & {
                    influencers: Maybe<
                      { __typename?: "ReportInfluencerConnection" } & {
                        paginatorInfo: Maybe<
                          { __typename?: "PaginatorInfo" } & Pick<
                            PaginatorInfo,
                            "total" | "count" | "currentPage"
                          >
                        >;
                        edges: Maybe<
                          Array<
                            Maybe<
                              { __typename?: "ReportInfluencerEdge" } & {
                                node: {
                                  __typename?: "ReportInfluencerNode";
                                } & Pick<ReportInfluencerNode, "id"> & {
                                    reportInfluencer: {
                                      __typename?: "ReportInfluencer";
                                    } & Pick<
                                      ReportInfluencer,
                                      "id" | "name" | "image"
                                    > & {
                                        insightsBySocialMedia: Array<
                                          {
                                            __typename?: "ReportInfluencerInsightBySocialMedia";
                                          } & Pick<
                                            ReportInfluencerInsightBySocialMedia,
                                            | "id"
                                            | "socialMediaType"
                                            | "postUrl"
                                            | "status"
                                            | "isPlanned"
                                          >
                                        >;
                                      };
                                  };
                              }
                            >
                          >
                        >;
                      }
                    >;
                  }
                >;
              }
          >;
        };
    };
};

export type AgencySuggestionsQueryVariables = {
  brandName: Scalars["String"];
  campaignNumber: Scalars["Int"];
};

export type AgencySuggestionsQuery = { __typename?: "Query" } & {
  agencyAccount: { __typename?: "AgencyAccount" } & Pick<
    AgencyAccount,
    "id" | "agencyName"
  > & {
      brand: { __typename?: "AgencyBrand" } & Pick<AgencyBrand, "id"> & {
          campaign: Maybe<
            { __typename?: "AgencyCampaign" } & Pick<AgencyCampaign, "id"> & {
                suggestions: Maybe<
                  Array<
                    Maybe<
                      { __typename?: "Suggestion" } & Pick<
                        Suggestion,
                        "id" | "createdAt" | "note" | "number"
                      > & {
                          attachmentFiles: Array<
                            { __typename?: "AttachmentFile" } & Pick<
                              AttachmentFile,
                              "name" | "url"
                            >
                          >;
                        }
                    >
                  >
                >;
              }
          >;
        };
    };
};

export type AgencyQueryVariables = {
  name: Scalars["String"];
};

export type AgencyQuery = { __typename?: "Query" } & {
  agency: { __typename?: "ClientAgency" } & Pick<
    ClientAgency,
    "id" | "name"
  > & {
      ownerAgencyAccount: { __typename?: "AgencyAccountInfo" } & Pick<
        AgencyAccountInfo,
        "name" | "email"
      >;
    };
};

export type BrandQueryVariables = {
  name: Scalars["String"];
};

export type BrandQuery = { __typename?: "Query" } & {
  brand: { __typename?: "ClientBrand" } & Pick<
    ClientBrand,
    "id" | "name" | "image" | "description"
  >;
};

export type CampaignMenuQueryVariables = {
  name: Scalars["String"];
};

export type CampaignMenuQuery = { __typename?: "Query" } & {
  brand: { __typename?: "ClientBrand" } & Pick<ClientBrand, "id"> & {
      campaigns: Maybe<
        Array<
          Maybe<
            { __typename?: "ClientCampaign" } & Pick<
              ClientCampaign,
              "id" | "name" | "number"
            >
          >
        >
      >;
    };
};

export type CampaignCreationQueryVariables = {
  brandName: Scalars["String"];
};

export type CampaignCreationQuery = { __typename?: "Query" } & {
  brand: { __typename?: "ClientBrand" } & Pick<ClientBrand, "id"> & {
      productItems: Array<
        Maybe<{ __typename?: "ProductItem" } & Pick<ProductItem, "id" | "name">>
      >;
    };
  affinityModels: Maybe<
    Array<
      Maybe<
        { __typename?: "AffinityModel" } & Pick<
          AffinityModel,
          "id" | "name" | "number"
        >
      >
    >
  >;
};

export type CampaignDetailQueryVariables = {
  name: Scalars["String"];
  number: Scalars["Int"];
};

export type CampaignDetailQuery = { __typename?: "Query" } & {
  brand: { __typename?: "ClientBrand" } & Pick<ClientBrand, "id" | "image"> & {
      campaign: Maybe<
        { __typename?: "ClientCampaign" } & Pick<
          ClientCampaign,
          "id" | "number" | "name"
        >
      >;
    };
};

export type CampaignQueryVariables = {
  name: Scalars["String"];
  number: Scalars["Int"];
};

export type CampaignQuery = { __typename?: "Query" } & {
  brand: { __typename?: "ClientBrand" } & Pick<ClientBrand, "id"> & {
      campaign: Maybe<
        { __typename?: "ClientCampaign" } & Pick<
          ClientCampaign,
          | "id"
          | "number"
          | "campaignCode"
          | "name"
          | "budget"
          | "keywordAndHashtags"
          | "objectives"
          | "campaignActivityType"
          | "biddingClosed"
          | "createdAt"
          | "postStartAt"
          | "postEndAt"
          | "suggestionEndAt"
          | "suggestionStartAt"
          | "note"
        > & {
            productItem: { __typename?: "ProductItem" } & Pick<
              ProductItem,
              "id" | "name"
            >;
            affinityModel: { __typename?: "AffinityModel" } & Pick<
              AffinityModel,
              "id" | "name" | "number"
            >;
            indicator: { __typename?: "CampaignIndicator" } & Pick<
              CampaignIndicator,
              "id" | "reachKpi"
            > & {
                instagramIndicator: Maybe<
                  { __typename?: "CampaignInstagramIndicator" } & Pick<
                    CampaignInstagramIndicator,
                    "id" | "budget" | "reachKpi" | "sales" | "ecClick"
                  >
                >;
                twitterIndicator: Maybe<
                  { __typename?: "CampaignTwitterIndicator" } & Pick<
                    CampaignTwitterIndicator,
                    "id" | "budget" | "reachKpi" | "sales" | "ecClick"
                  >
                >;
                youtubeIndicator: Maybe<
                  { __typename?: "CampaignYoutubeIndicator" } & Pick<
                    CampaignYoutubeIndicator,
                    "id" | "budget" | "reachKpi" | "sales" | "ecClick"
                  >
                >;
                tiktokIndicator: Maybe<
                  { __typename?: "CampaignTiktokIndicator" } & Pick<
                    CampaignTiktokIndicator,
                    "id" | "budget" | "reachKpi" | "sales" | "ecClick"
                  >
                >;
              };
            assignments: Maybe<
              Array<
                Maybe<
                  { __typename?: "ClientAssignment" } & Pick<
                    ClientAssignment,
                    "id" | "number" | "createdAt" | "status"
                  > & {
                      agency: { __typename?: "AgencyInfo" } & Pick<
                        AgencyInfo,
                        "id" | "name"
                      >;
                    }
                >
              >
            >;
            attachmentFiles: Array<
              { __typename?: "AttachmentFile" } & Pick<
                AttachmentFile,
                "id" | "name" | "url"
              >
            >;
            agencies: Maybe<
              Array<
                Maybe<
                  { __typename?: "CampaignAgency" } & Pick<
                    CampaignAgency,
                    "id" | "name" | "totalSuggestionCount" | "lastSuggestionAt"
                  > & {
                      suggestions: Maybe<
                        Array<
                          Maybe<
                            { __typename?: "Suggestion" } & Pick<
                              Suggestion,
                              "id" | "number" | "name"
                            >
                          >
                        >
                      >;
                    }
                >
              >
            >;
            report: Maybe<
              { __typename?: "ClientReport" } & Pick<
                ClientReport,
                "campaignId"
              > & {
                  agencyAssignmentAttachmentFiles: Array<
                    { __typename?: "AttachmentFile" } & Pick<
                      AttachmentFile,
                      "name" | "url"
                    >
                  >;
                  agencyReportAttachmentFiles: Array<
                    { __typename?: "AttachmentFile" } & Pick<
                      AttachmentFile,
                      "name" | "url"
                    >
                  >;
                }
            >;
          }
      >;
    };
  agencies: Maybe<
    Array<
      { __typename?: "ClientAgency" } & Pick<
        ClientAgency,
        "id" | "name" | "isLocked"
      >
    >
  >;
};

export type CampaignSideMenuQueryVariables = {
  name: Scalars["String"];
  number: Scalars["Int"];
};

export type CampaignSideMenuQuery = { __typename?: "Query" } & {
  brand: { __typename?: "ClientBrand" } & {
    campaign: Maybe<
      { __typename?: "ClientCampaign" } & Pick<ClientCampaign, "id"> & {
          agencies: Maybe<
            Array<
              Maybe<
                { __typename?: "CampaignAgency" } & Pick<
                  CampaignAgency,
                  "id" | "name" | "totalSuggestionCount" | "lastSuggestionAt"
                > & {
                    suggestions: Maybe<
                      Array<
                        Maybe<
                          { __typename?: "Suggestion" } & Pick<
                            Suggestion,
                            "id" | "number"
                          >
                        >
                      >
                    >;
                  }
              >
            >
          >;
          assignments: Maybe<
            Array<
              Maybe<
                { __typename?: "ClientAssignment" } & Pick<
                  ClientAssignment,
                  "number"
                > & {
                    agency: { __typename?: "AgencyInfo" } & Pick<
                      AgencyInfo,
                      "id" | "name"
                    >;
                  }
              >
            >
          >;
        }
    >;
  };
};

export type CampaignsQueryVariables = {
  name: Scalars["String"];
};

export type CampaignsQuery = { __typename?: "Query" } & {
  brand: { __typename?: "ClientBrand" } & Pick<ClientBrand, "id"> & {
      campaigns: Maybe<
        Array<
          Maybe<
            { __typename?: "ClientCampaign" } & Pick<
              ClientCampaign,
              "id" | "number" | "campaignCode" | "name" | "budget"
            > & {
                indicator: { __typename?: "CampaignIndicator" } & Pick<
                  CampaignIndicator,
                  "id" | "reachKpi"
                > & {
                    instagramIndicator: Maybe<
                      { __typename?: "CampaignInstagramIndicator" } & Pick<
                        CampaignInstagramIndicator,
                        "id" | "budget" | "reachKpi"
                      >
                    >;
                    twitterIndicator: Maybe<
                      { __typename?: "CampaignTwitterIndicator" } & Pick<
                        CampaignTwitterIndicator,
                        "id" | "budget" | "reachKpi"
                      >
                    >;
                    youtubeIndicator: Maybe<
                      { __typename?: "CampaignYoutubeIndicator" } & Pick<
                        CampaignYoutubeIndicator,
                        "id" | "budget" | "reachKpi"
                      >
                    >;
                    tiktokIndicator: Maybe<
                      { __typename?: "CampaignTiktokIndicator" } & Pick<
                        CampaignTiktokIndicator,
                        "id" | "budget" | "reachKpi"
                      >
                    >;
                  };
                productItem: { __typename?: "ProductItem" } & Pick<
                  ProductItem,
                  "id" | "name"
                >;
              }
          >
        >
      >;
    };
};

export type ClientAccountUpdateQueryVariables = {
  number: Scalars["Int"];
};

export type ClientAccountUpdateQuery = { __typename?: "Query" } & {
  clientAccountByNumber: { __typename?: "ClientAccount" } & Pick<
    ClientAccount,
    "id" | "number" | "name" | "email"
  >;
};

export type ClientAccountQueryVariables = {};

export type ClientAccountQuery = { __typename?: "Query" } & {
  clientAccount: { __typename?: "ClientAccount" } & Pick<
    ClientAccount,
    "id" | "name" | "email" | "isOwner" | "number"
  > & {
      brands: Array<
        { __typename?: "ClientBrand" } & Pick<
          ClientBrand,
          "id" | "name" | "image" | "divisionName"
        >
      >;
    };
};

export type ClientCampaignCommentQueryVariables = {
  brandName: Scalars["String"];
  campaignNumber: Scalars["Int"];
  agencyName: Scalars["String"];
};

export type ClientCampaignCommentQuery = { __typename?: "Query" } & {
  clientCampaignComment: { __typename?: "ClientCampaignComment" } & Pick<
    ClientCampaignComment,
    | "campaignId"
    | "totalSuggestionUnreadComment"
    | "totalAssignmentUnreadComment"
    | "totalReportUnreadComment"
  > & {
      suggestionComments: { __typename?: "BiddingComment" } & Pick<
        BiddingComment,
        "unreadCommentCount"
      > & {
          comments: Array<
            { __typename?: "Comment" } & Pick<
              Comment,
              "id" | "readFlag" | "sendAt" | "comment"
            > & {
                account:
                  | ({ __typename: "ClientAccount" } & Pick<
                      ClientAccount,
                      "name" | "number"
                    > & {
                        brand: { __typename?: "ClientBrand" } & Pick<
                          ClientBrand,
                          "name"
                        >;
                      })
                  | ({ __typename: "AgencyAccount" } & Pick<
                      AgencyAccount,
                      "name" | "number"
                    > & {
                        brand: { __typename?: "AgencyBrand" } & Pick<
                          AgencyBrand,
                          "name"
                        >;
                      });
              }
          >;
          draftComment: Maybe<
            { __typename?: "Comment" } & Pick<Comment, "id" | "comment">
          >;
        };
      assignmentComments: { __typename?: "BiddingComment" } & Pick<
        BiddingComment,
        "unreadCommentCount"
      > & {
          comments: Array<
            { __typename?: "Comment" } & Pick<
              Comment,
              "id" | "readFlag" | "sendAt" | "comment"
            > & {
                account:
                  | ({ __typename: "ClientAccount" } & Pick<
                      ClientAccount,
                      "name" | "number"
                    > & {
                        brand: { __typename?: "ClientBrand" } & Pick<
                          ClientBrand,
                          "name"
                        >;
                      })
                  | ({ __typename: "AgencyAccount" } & Pick<
                      AgencyAccount,
                      "name" | "number"
                    > & {
                        brand: { __typename?: "AgencyBrand" } & Pick<
                          AgencyBrand,
                          "name"
                        >;
                      });
              }
          >;
          draftComment: Maybe<
            { __typename?: "Comment" } & Pick<Comment, "id" | "comment">
          >;
        };
      reportComments: { __typename?: "BiddingComment" } & Pick<
        BiddingComment,
        "unreadCommentCount"
      > & {
          comments: Array<
            { __typename?: "Comment" } & Pick<
              Comment,
              "id" | "readFlag" | "sendAt" | "comment"
            > & {
                account:
                  | ({ __typename: "ClientAccount" } & Pick<
                      ClientAccount,
                      "name" | "number"
                    > & {
                        brand: { __typename?: "ClientBrand" } & Pick<
                          ClientBrand,
                          "name"
                        >;
                      })
                  | ({ __typename: "AgencyAccount" } & Pick<
                      AgencyAccount,
                      "name" | "number"
                    > & {
                        brand: { __typename?: "AgencyBrand" } & Pick<
                          AgencyBrand,
                          "name"
                        >;
                      });
              }
          >;
          draftComment: Maybe<
            { __typename?: "Comment" } & Pick<Comment, "id" | "comment">
          >;
        };
    };
};

export type ClientCampaignsFilterDropdownOptionsQueryVariables = {};

export type ClientCampaignsFilterDropdownOptionsQuery = {
  __typename?: "Query";
} & {
  clientCampaignsFilterDropdownOptions: {
    __typename?: "ClientCampaignsFilterDropdownOptions";
  } & {
    divisionNames: Array<
      Maybe<
        { __typename?: "DropdownOption" } & Pick<
          DropdownOption,
          "value" | "label"
        >
      >
    >;
    brandNames: Array<
      Maybe<
        { __typename?: "DropdownOption" } & Pick<
          DropdownOption,
          "value" | "label"
        >
      >
    >;
    campaignNames: Array<
      Maybe<
        { __typename?: "DropdownOption" } & Pick<
          DropdownOption,
          "value" | "label"
        >
      >
    >;
    agencyNames: Array<
      Maybe<
        { __typename?: "DropdownOption" } & Pick<
          DropdownOption,
          "value" | "label"
        >
      >
    >;
    categoryNames: Array<
      Maybe<
        { __typename?: "DropdownOption" } & Pick<
          DropdownOption,
          "value" | "label"
        >
      >
    >;
    snsNames: Array<
      Maybe<
        { __typename?: "DropdownOption" } & Pick<
          DropdownOption,
          "value" | "label"
        >
      >
    >;
  };
};

export type ClientCampaignsQueryVariables = {
  divisionName?: Maybe<Scalars["String"]>;
  brandName?: Maybe<Scalars["String"]>;
  campaignName?: Maybe<Scalars["String"]>;
  agencyName?: Maybe<Scalars["String"]>;
  categoryName?: Maybe<Scalars["String"]>;
  snsName?: Maybe<Scalars["String"]>;
  postStartAt?: Maybe<Scalars["String"]>;
  postEndAt?: Maybe<Scalars["String"]>;
};

export type ClientCampaignsQuery = { __typename?: "Query" } & {
  clientCampaigns: Maybe<
    Array<
      { __typename?: "ClientCampaign" } & Pick<
        ClientCampaign,
        | "id"
        | "name"
        | "number"
        | "brandName"
        | "divisionName"
        | "postStartAt"
        | "postEndAt"
      > & {
          productItem: { __typename?: "ProductItem" } & Pick<
            ProductItem,
            "name"
          >;
          indicator: { __typename?: "CampaignIndicator" } & Pick<
            CampaignIndicator,
            "reachKpi"
          >;
        }
    >
  >;
};

export type ConfirmedInfluencersQueryVariables = {
  name: Scalars["String"];
  number: Scalars["Int"];
  agencyName: Scalars["String"];
  page: Scalars["Int"];
  perPage?: Maybe<Scalars["Int"]>;
  hasAssignmentInfluencers?: Maybe<Scalars["Boolean"]>;
  orderBy?: Maybe<AssignmentInfluencerOrderByClause>;
  influencerName?: Maybe<Scalars["String"]>;
};

export type ConfirmedInfluencersQuery = { __typename?: "Query" } & {
  brand: { __typename?: "ClientBrand" } & Pick<ClientBrand, "id"> & {
      campaign: Maybe<
        { __typename?: "ClientCampaign" } & Pick<
          ClientCampaign,
          "id" | "number"
        > & {
            indicator: { __typename?: "CampaignIndicator" } & Pick<
              CampaignIndicator,
              "id" | "budget" | "reachKpi"
            > & {
                instagramIndicator: Maybe<
                  { __typename?: "CampaignInstagramIndicator" } & Pick<
                    CampaignInstagramIndicator,
                    "reachKpi"
                  >
                >;
                twitterIndicator: Maybe<
                  { __typename?: "CampaignTwitterIndicator" } & Pick<
                    CampaignTwitterIndicator,
                    "reachKpi"
                  >
                >;
                youtubeIndicator: Maybe<
                  { __typename?: "CampaignYoutubeIndicator" } & Pick<
                    CampaignYoutubeIndicator,
                    "reachKpi"
                  >
                >;
                tiktokIndicator: Maybe<
                  { __typename?: "CampaignTiktokIndicator" } & Pick<
                    CampaignTiktokIndicator,
                    "reachKpi"
                  >
                >;
              };
            agency: Maybe<
              { __typename?: "CampaignAgency" } & Pick<
                CampaignAgency,
                "id" | "name"
              > & {
                  assignment: Maybe<
                    { __typename?: "ClientAssignment" } & Pick<
                      ClientAssignment,
                      | "id"
                      | "number"
                      | "totalCost"
                      | "totalCommission"
                      | "totalInfluencerCount"
                      | "totalFollower"
                      | "totalFollow"
                      | "averageEngagementRate"
                      | "averageAffinityRate"
                      | "averageAdvocateScoreRate"
                      | "totalConsiderationInfluencerCount"
                      | "isDataCollectionCompleted"
                      | "status"
                    > & {
                        summaries: Array<
                          | { __typename?: "Suggestion" }
                          | { __typename?: "ClientReport" }
                          | { __typename?: "ClientAssignment" }
                          | ({
                              __typename?: "AssignmentInstagramSummary";
                            } & AssignmentInstagramSummaryFragment)
                          | ({
                              __typename?: "AssignmentTiktokSummary";
                            } & AssignmentTiktokSummaryFragment)
                          | ({
                              __typename?: "AssignmentTwitterSummary";
                            } & AssignmentTwitterSummaryFragment)
                          | ({
                              __typename?: "AssignmentWinningSummary";
                            } & AssignmentWinningSummaryFragment)
                          | ({
                              __typename?: "AssignmentYoutubeSummary";
                            } & AssignmentYoutubeSummaryFragment)
                          | { __typename?: "ReportInstagramSummary" }
                          | { __typename?: "ReportPlannedTotalSummary" }
                          | { __typename?: "ReportTiktokSummary" }
                          | { __typename?: "ReportTwitterSummary" }
                          | { __typename?: "ReportYoutubeSummary" }
                          | { __typename?: "SuggestionInstagramSummary" }
                          | { __typename?: "SuggestionTiktokSummary" }
                          | { __typename?: "SuggestionTwitterSummary" }
                          | { __typename?: "SuggestionYoutubeSummary" }
                        >;
                        winningSummaries: Array<
                          | { __typename?: "Suggestion" }
                          | { __typename?: "ClientReport" }
                          | { __typename?: "ClientAssignment" }
                          | ({
                              __typename?: "AssignmentInstagramSummary";
                            } & AssignmentInstagramSummaryFragment)
                          | ({
                              __typename?: "AssignmentTiktokSummary";
                            } & AssignmentTiktokSummaryFragment)
                          | ({
                              __typename?: "AssignmentTwitterSummary";
                            } & AssignmentTwitterSummaryFragment)
                          | { __typename?: "AssignmentWinningSummary" }
                          | ({
                              __typename?: "AssignmentYoutubeSummary";
                            } & AssignmentYoutubeSummaryFragment)
                          | { __typename?: "ReportInstagramSummary" }
                          | { __typename?: "ReportPlannedTotalSummary" }
                          | { __typename?: "ReportTiktokSummary" }
                          | { __typename?: "ReportTwitterSummary" }
                          | { __typename?: "ReportYoutubeSummary" }
                          | { __typename?: "SuggestionInstagramSummary" }
                          | { __typename?: "SuggestionTiktokSummary" }
                          | { __typename?: "SuggestionTwitterSummary" }
                          | { __typename?: "SuggestionYoutubeSummary" }
                        >;
                        assignmentInfluencersByAgency: Maybe<
                          { __typename?: "AssignmentInfluencerConnection" } & {
                            paginatorInfo: Maybe<
                              { __typename?: "PaginatorInfo" } & Pick<
                                PaginatorInfo,
                                "total" | "currentPage" | "count" | "perPage"
                              >
                            >;
                            edges: Maybe<
                              Array<
                                Maybe<
                                  {
                                    __typename?: "AssignmentInfluencerEdge";
                                  } & {
                                    node: {
                                      __typename?: "AssignmentInfluencerNode";
                                    } & {
                                      assignmentInfluencer: {
                                        __typename?: "AssignmentInfluencer";
                                      } & Pick<
                                        AssignmentInfluencer,
                                        | "id"
                                        | "number"
                                        | "name"
                                        | "image"
                                        | "totalCost"
                                        | "totalCommission"
                                        | "totalFollowerCount"
                                        | "totalFollowCount"
                                        | "totalPostCount"
                                        | "averageIncreaseRate"
                                        | "totalLikeCount"
                                        | "totalCommentCount"
                                        | "averageEngagement"
                                        | "averageAffinityScore"
                                        | "averageAdvocateScore"
                                        | "maxViewCount"
                                        | "valueCheckStatus"
                                        | "valueCheckDate"
                                        | "availabilityStatus"
                                      > & {
                                          insightsBySocialMedia: Array<
                                            {
                                              __typename?: "AssignmentInfluencerInsightBySocialMedia";
                                            } & AssignmentInfluencerInsightBySocialMediaFragment
                                          >;
                                        };
                                    };
                                  }
                                >
                              >
                            >;
                          }
                        >;
                        agencyAttachmentFiles: Array<
                          { __typename?: "AttachmentFile" } & Pick<
                            AttachmentFile,
                            "name" | "url"
                          >
                        >;
                        agencyAssignmentAttachmentFiles: Array<
                          { __typename?: "AttachmentFile" } & Pick<
                            AttachmentFile,
                            "name" | "url"
                          >
                        >;
                        agencyReportAttachmentFiles: Array<
                          { __typename?: "AttachmentFile" } & Pick<
                            AttachmentFile,
                            "name" | "url"
                          >
                        >;
                      }
                  >;
                }
            >;
          }
      >;
    };
};

export type InfluencerEvaluationQueryVariables = {
  name: Scalars["String"];
  number: Scalars["Int"];
  perPage?: Maybe<Scalars["Int"]>;
  page: Scalars["Int"];
  orderBy?: Maybe<InfluencerEvaluationOrderByClause>;
  searchBy?: Maybe<Scalars["String"]>;
  filterBy?: Maybe<EvaluationType>;
};

export type InfluencerEvaluationQuery = { __typename?: "Query" } & {
  influencerSegments: Maybe<
    Array<
      { __typename?: "InfluencerSegment" } & Pick<
        InfluencerSegment,
        "id" | "name" | "maxFollowerCount" | "minFollowerCount" | "increaseRate"
      >
    >
  >;
  brand: { __typename?: "ClientBrand" } & Pick<ClientBrand, "id"> & {
      influencerEvaluationGroup: Maybe<
        { __typename?: "InfluencerEvaluationGroup" } & Pick<
          InfluencerEvaluationGroup,
          | "id"
          | "createdAt"
          | "updatedAt"
          | "evaluationCount"
          | "evaluationMatchedCount"
          | "evaluationConsideringCount"
          | "evaluationUnmatchedCount"
        > & {
            postLoadingStatus: { __typename?: "PostLoadingStatus" } & Pick<
              PostLoadingStatus,
              "status"
            >;
            influencerEvaluations: Maybe<
              { __typename?: "InfluencerEvaluationConnection" } & {
                paginatorInfo: Maybe<
                  { __typename?: "PaginatorInfo" } & Pick<
                    PaginatorInfo,
                    "total" | "count" | "currentPage"
                  >
                >;
                edges: Maybe<
                  Array<
                    Maybe<
                      { __typename?: "InfluencerEvaluationEdge" } & {
                        node: { __typename?: "InfluencerEvaluationNode" } & {
                          influencerEvaluation: {
                            __typename?: "MasterInfluencerEvaluation";
                          } & Pick<
                            MasterInfluencerEvaluation,
                            | "id"
                            | "number"
                            | "affinityRate"
                            | "evaluation"
                            | "note"
                            | "snapped"
                            | "evaluatedAt"
                          > & {
                              trainingInfluencer: {
                                __typename?: "TrainingInfluencer";
                              } & Pick<
                                TrainingInfluencer,
                                "id" | "name" | "image"
                              > & {
                                  socialMedia: Maybe<
                                    { __typename?: "SocialMedia" } & {
                                      instagram: Maybe<
                                        { __typename?: "Instagram" } & Pick<
                                          Instagram,
                                          | "name"
                                          | "profileImage"
                                          | "followerCount"
                                          | "increaseRate"
                                        >
                                      >;
                                    }
                                  >;
                                };
                            };
                        };
                      }
                    >
                  >
                >;
              }
            >;
          }
      >;
    };
};

export type InfluencerEvaluationGroupsQueryVariables = {
  name: Scalars["String"];
  perPage?: Maybe<Scalars["Int"]>;
  page: Scalars["Int"];
};

export type InfluencerEvaluationGroupsQuery = { __typename?: "Query" } & {
  brand: { __typename?: "ClientBrand" } & Pick<ClientBrand, "id"> & {
      influencerEvaluationGroups: Maybe<
        { __typename?: "InfluencerEvaluationGroupConnection" } & {
          paginatorInfo: Maybe<
            { __typename?: "PaginatorInfo" } & Pick<
              PaginatorInfo,
              "total" | "count" | "currentPage"
            >
          >;
          edges: Maybe<
            Array<
              Maybe<
                { __typename?: "InfluencerEvaluationGroupEdge" } & {
                  node: { __typename?: "InfluencerEvaluationGroupNode" } & {
                    influencerEvaluationGroup: {
                      __typename?: "InfluencerEvaluationGroup";
                    } & Pick<
                      InfluencerEvaluationGroup,
                      | "id"
                      | "createdAt"
                      | "updatedAt"
                      | "number"
                      | "evaluationCount"
                      | "evaluationMatchedCount"
                      | "evaluationConsideringCount"
                      | "evaluationUnmatchedCount"
                      | "title"
                    > & {
                        snapInfluencerEvaluationGroups: Maybe<
                          {
                            __typename?: "SnapInfluencerEvaluationGroupConnection";
                          } & {
                            edges: Maybe<
                              Array<
                                Maybe<
                                  {
                                    __typename?: "SnapInfluencerEvaluationGroupEdge";
                                  } & {
                                    node: {
                                      __typename?: "SnapInfluencerEvaluationGroupNode";
                                    } & {
                                      snapInfluencerEvaluationGroup: {
                                        __typename?: "SnapInfluencerEvaluationGroup";
                                      } & Pick<
                                        SnapInfluencerEvaluationGroup,
                                        "id" | "number"
                                      >;
                                    };
                                  }
                                >
                              >
                            >;
                          }
                        >;
                      };
                  };
                }
              >
            >
          >;
        }
      >;
    };
};

export type InfluencerPostsQueryVariables = {
  name: Scalars["String"];
  number: Scalars["Int"];
  evalNum: Scalars["Int"];
};

export type InfluencerPostsQuery = { __typename?: "Query" } & {
  brand: { __typename?: "ClientBrand" } & Pick<ClientBrand, "id"> & {
      influencerEvaluationGroup: Maybe<
        { __typename?: "InfluencerEvaluationGroup" } & Pick<
          InfluencerEvaluationGroup,
          "id"
        > & {
            influencerEvaluation: Maybe<
              { __typename?: "MasterInfluencerEvaluation" } & Pick<
                MasterInfluencerEvaluation,
                "id"
              > & {
                  trainingInfluencer: {
                    __typename?: "TrainingInfluencer";
                  } & Pick<TrainingInfluencer, "id" | "name"> & {
                      affinityPosts: Maybe<
                        Array<
                          { __typename?: "InstagramPost" } & Pick<
                            InstagramPost,
                            "id" | "thumbnailUrl" | "url"
                          >
                        >
                      >;
                      resentPosts: Maybe<
                        Array<
                          { __typename?: "InstagramPost" } & Pick<
                            InstagramPost,
                            "id" | "thumbnailUrl" | "url"
                          >
                        >
                      >;
                      likedPosts: Maybe<
                        Array<
                          { __typename?: "InstagramPost" } & Pick<
                            InstagramPost,
                            "id" | "thumbnailUrl" | "url"
                          >
                        >
                      >;
                    };
                }
            >;
          }
      >;
    };
};

export type InfluencerSegmentsQueryVariables = {};

export type InfluencerSegmentsQuery = { __typename?: "Query" } & {
  influencerSegments: Maybe<
    Array<
      { __typename?: "InfluencerSegment" } & Pick<
        InfluencerSegment,
        "id" | "name" | "maxFollowerCount" | "minFollowerCount" | "increaseRate"
      >
    >
  >;
};

export type AdditionalOrderQueryVariables = {
  name: Scalars["String"];
  number: Scalars["Int"];
  agencyName: Scalars["String"];
  suggestionNumber: Scalars["Int"];
  page: Scalars["Int"];
  perPage?: Maybe<Scalars["Int"]>;
  orderBy?: Maybe<SuggestionInfluencerOrderByClause>;
  isAdditionalConsideration?: Maybe<Scalars["Boolean"]>;
  influencerName?: Maybe<Scalars["String"]>;
};

export type AdditionalOrderQuery = { __typename?: "Query" } & {
  brand: { __typename?: "ClientBrand" } & Pick<ClientBrand, "id"> & {
      campaign: Maybe<
        { __typename?: "ClientCampaign" } & Pick<
          ClientCampaign,
          "id" | "name"
        > & {
            agencies: Maybe<
              Array<
                Maybe<
                  { __typename?: "CampaignAgency" } & Pick<
                    CampaignAgency,
                    "name"
                  >
                >
              >
            >;
            agency: Maybe<
              { __typename?: "CampaignAgency" } & Pick<
                CampaignAgency,
                "id" | "name"
              > & {
                  assignment: Maybe<
                    { __typename?: "ClientAssignment" } & Pick<
                      ClientAssignment,
                      "id"
                    >
                  >;
                  suggestions: Maybe<
                    Array<
                      Maybe<
                        { __typename?: "Suggestion" } & Pick<
                          Suggestion,
                          "number"
                        >
                      >
                    >
                  >;
                  suggestion: Maybe<
                    { __typename?: "Suggestion" } & Pick<
                      Suggestion,
                      | "id"
                      | "number"
                      | "note"
                      | "totalCost"
                      | "totalConsiderationInfluencerCount"
                      | "totalInfluencerCount"
                    > & {
                        suggestionInfluencers: Maybe<
                          { __typename?: "SuggestionInfluencerConnection" } & {
                            paginatorInfo: Maybe<
                              { __typename?: "PaginatorInfo" } & Pick<
                                PaginatorInfo,
                                "total" | "currentPage" | "count" | "perPage"
                              >
                            >;
                            edges: Maybe<
                              Array<
                                Maybe<
                                  {
                                    __typename?: "SuggestionInfluencerEdge";
                                  } & {
                                    node: {
                                      __typename?: "SuggestionInfluencerNode";
                                    } & {
                                      suggestionInfluencer: {
                                        __typename?: "SuggestionInfluencer";
                                      } & SuggestionInfluencerFragment;
                                    };
                                  }
                                >
                              >
                            >;
                          }
                        >;
                        attachmentFiles: Array<
                          { __typename?: "AttachmentFile" } & Pick<
                            AttachmentFile,
                            "name" | "url"
                          >
                        >;
                      }
                  >;
                }
            >;
          }
      >;
    };
};

export type AdditionalProposalListQueryVariables = {
  name: Scalars["String"];
  number: Scalars["Int"];
  agencyName: Scalars["String"];
};

export type AdditionalProposalListQuery = { __typename?: "Query" } & {
  brand: { __typename?: "ClientBrand" } & Pick<ClientBrand, "id"> & {
      campaign: Maybe<
        { __typename?: "ClientCampaign" } & Pick<ClientCampaign, "id"> & {
            agency: Maybe<
              { __typename?: "CampaignAgency" } & Pick<CampaignAgency, "id"> & {
                  suggestions: Maybe<
                    Array<
                      Maybe<
                        { __typename?: "Suggestion" } & Pick<
                          Suggestion,
                          "name" | "number"
                        >
                      >
                    >
                  >;
                }
            >;
          }
      >;
    };
};

export type CurrentTotalQueryVariables = {
  name: Scalars["String"];
  number: Scalars["Int"];
  agencyName: Scalars["String"];
  page: Scalars["Int"];
  perPage?: Maybe<Scalars["Int"]>;
  hasAssignmentInfluencers?: Maybe<Scalars["Boolean"]>;
  includeAdditionalConsiderationSummary?: Maybe<Scalars["Boolean"]>;
  orderBy?: Maybe<AssignmentInfluencerOrderByClause>;
  filterBy?: Maybe<AssignmentInfluencerFilter>;
  suggestionNumber?: Maybe<Scalars["Int"]>;
  influencerName?: Maybe<Scalars["String"]>;
};

export type CurrentTotalQuery = { __typename?: "Query" } & {
  brand: { __typename?: "ClientBrand" } & Pick<ClientBrand, "id"> & {
      campaign: Maybe<
        { __typename?: "ClientCampaign" } & Pick<
          ClientCampaign,
          "id" | "number"
        > & {
            indicator: { __typename?: "CampaignIndicator" } & Pick<
              CampaignIndicator,
              "id" | "budget" | "reachKpi"
            > & {
                instagramIndicator: Maybe<
                  { __typename?: "CampaignInstagramIndicator" } & Pick<
                    CampaignInstagramIndicator,
                    "reachKpi"
                  >
                >;
                twitterIndicator: Maybe<
                  { __typename?: "CampaignTwitterIndicator" } & Pick<
                    CampaignTwitterIndicator,
                    "reachKpi"
                  >
                >;
                youtubeIndicator: Maybe<
                  { __typename?: "CampaignYoutubeIndicator" } & Pick<
                    CampaignYoutubeIndicator,
                    "reachKpi"
                  >
                >;
                tiktokIndicator: Maybe<
                  { __typename?: "CampaignTiktokIndicator" } & Pick<
                    CampaignTiktokIndicator,
                    "reachKpi"
                  >
                >;
              };
            agency: Maybe<
              { __typename?: "CampaignAgency" } & Pick<
                CampaignAgency,
                "id" | "name"
              > & {
                  assignment: Maybe<
                    {
                      __typename?: "ClientAssignment";
                    } & ClientAssignmentFragment
                  >;
                }
            >;
          }
      >;
    };
};

export type InfluencersQueryVariables = {
  name: Scalars["String"];
  number: Scalars["Int"];
  agencyName: Scalars["String"];
  page: Scalars["Int"];
  perPage?: Maybe<Scalars["Int"]>;
  hasAssignmentInfluencers?: Maybe<Scalars["Boolean"]>;
  includeAdditionalConsiderationSummary?: Maybe<Scalars["Boolean"]>;
  orderBy?: Maybe<AssignmentInfluencerOrderByClause>;
  filterBy?: Maybe<AssignmentInfluencerFilter>;
  suggestionNumber?: Maybe<Scalars["Int"]>;
  influencerName?: Maybe<Scalars["String"]>;
};

export type InfluencersQuery = { __typename?: "Query" } & {
  brand: { __typename?: "ClientBrand" } & Pick<ClientBrand, "id"> & {
      campaign: Maybe<
        { __typename?: "ClientCampaign" } & Pick<
          ClientCampaign,
          "id" | "number"
        > & {
            indicator: { __typename?: "CampaignIndicator" } & Pick<
              CampaignIndicator,
              "id" | "budget" | "reachKpi"
            > & {
                instagramIndicator: Maybe<
                  { __typename?: "CampaignInstagramIndicator" } & Pick<
                    CampaignInstagramIndicator,
                    "reachKpi"
                  >
                >;
                twitterIndicator: Maybe<
                  { __typename?: "CampaignTwitterIndicator" } & Pick<
                    CampaignTwitterIndicator,
                    "reachKpi"
                  >
                >;
                youtubeIndicator: Maybe<
                  { __typename?: "CampaignYoutubeIndicator" } & Pick<
                    CampaignYoutubeIndicator,
                    "reachKpi"
                  >
                >;
                tiktokIndicator: Maybe<
                  { __typename?: "CampaignTiktokIndicator" } & Pick<
                    CampaignTiktokIndicator,
                    "reachKpi"
                  >
                >;
              };
            agency: Maybe<
              { __typename?: "CampaignAgency" } & Pick<
                CampaignAgency,
                "id" | "name"
              > & {
                  assignment: Maybe<
                    {
                      __typename?: "ClientAssignment";
                    } & ClientAssignmentFragment
                  >;
                }
            >;
          }
      >;
    };
};

export type OrdersQueryVariables = {
  name: Scalars["String"];
  number: Scalars["Int"];
};

export type OrdersQuery = { __typename?: "Query" } & {
  brand: { __typename?: "ClientBrand" } & Pick<ClientBrand, "id"> & {
      campaign: Maybe<
        { __typename?: "ClientCampaign" } & Pick<
          ClientCampaign,
          "id" | "name"
        > & {
            assignments: Maybe<
              Array<
                Maybe<
                  { __typename?: "ClientAssignment" } & Pick<
                    ClientAssignment,
                    "number" | "createdAt"
                  > & {
                      agency: { __typename?: "AgencyInfo" } & Pick<
                        AgencyInfo,
                        "id" | "name"
                      >;
                    }
                >
              >
            >;
          }
      >;
    };
};

export type ProposalQueryVariables = {
  name: Scalars["String"];
  number: Scalars["Int"];
  agencyName: Scalars["String"];
  suggestionNumber: Scalars["Int"];
  page: Scalars["Int"];
  perPage: Scalars["Int"];
  orderBy?: Maybe<SuggestionInfluencerOrderByClause>;
  searchBy?: Maybe<Scalars["String"]>;
};

export type ProposalQuery = { __typename?: "Query" } & {
  brand: { __typename?: "ClientBrand" } & Pick<ClientBrand, "id"> & {
      campaign: Maybe<
        { __typename?: "ClientCampaign" } & Pick<
          ClientCampaign,
          "id" | "name"
        > & {
            indicator: { __typename?: "CampaignIndicator" } & Pick<
              CampaignIndicator,
              "id" | "budget" | "reachKpi"
            > & {
                instagramIndicator: Maybe<
                  { __typename?: "CampaignInstagramIndicator" } & Pick<
                    CampaignInstagramIndicator,
                    "reachKpi"
                  >
                >;
                twitterIndicator: Maybe<
                  { __typename?: "CampaignTwitterIndicator" } & Pick<
                    CampaignTwitterIndicator,
                    "reachKpi"
                  >
                >;
                youtubeIndicator: Maybe<
                  { __typename?: "CampaignYoutubeIndicator" } & Pick<
                    CampaignYoutubeIndicator,
                    "reachKpi"
                  >
                >;
                tiktokIndicator: Maybe<
                  { __typename?: "CampaignTiktokIndicator" } & Pick<
                    CampaignTiktokIndicator,
                    "reachKpi"
                  >
                >;
              };
            agencies: Maybe<
              Array<
                Maybe<
                  { __typename?: "CampaignAgency" } & Pick<
                    CampaignAgency,
                    "id" | "name"
                  >
                >
              >
            >;
            agency: Maybe<
              { __typename?: "CampaignAgency" } & Pick<
                CampaignAgency,
                "id" | "name"
              > & {
                  suggestions: Maybe<
                    Array<
                      Maybe<
                        { __typename?: "Suggestion" } & Pick<
                          Suggestion,
                          "name" | "number"
                        >
                      >
                    >
                  >;
                  suggestion: Maybe<
                    { __typename?: "Suggestion" } & Pick<
                      Suggestion,
                      | "id"
                      | "name"
                      | "note"
                      | "number"
                      | "isDataCollectionCompleted"
                      | "totalCost"
                      | "totalCommission"
                      | "totalInfluencerCount"
                      | "totalFollower"
                      | "totalFollow"
                      | "averageEngagementRate"
                      | "averageAffinityRate"
                      | "averageAdvocateScoreRate"
                      | "totalConsiderationInfluencerCount"
                      | "isOrdered"
                    > & {
                        attachmentFiles: Array<
                          { __typename?: "AttachmentFile" } & Pick<
                            AttachmentFile,
                            "name" | "url"
                          >
                        >;
                        summaries: Array<
                          | { __typename?: "Suggestion" }
                          | { __typename?: "ClientReport" }
                          | { __typename?: "ClientAssignment" }
                          | { __typename?: "AssignmentInstagramSummary" }
                          | { __typename?: "AssignmentTiktokSummary" }
                          | { __typename?: "AssignmentTwitterSummary" }
                          | { __typename?: "AssignmentWinningSummary" }
                          | { __typename?: "AssignmentYoutubeSummary" }
                          | { __typename?: "ReportInstagramSummary" }
                          | { __typename?: "ReportPlannedTotalSummary" }
                          | { __typename?: "ReportTiktokSummary" }
                          | { __typename?: "ReportTwitterSummary" }
                          | { __typename?: "ReportYoutubeSummary" }
                          | ({
                              __typename?: "SuggestionInstagramSummary";
                            } & SuggestionInstagramSummaryFragment)
                          | ({
                              __typename?: "SuggestionTiktokSummary";
                            } & SuggestionTiktokSummaryFragment)
                          | ({
                              __typename?: "SuggestionTwitterSummary";
                            } & SuggestionTwitterSummaryFragment)
                          | ({
                              __typename?: "SuggestionYoutubeSummary";
                            } & SuggestionYoutubeSummaryFragment)
                        >;
                        suggestionInfluencers: Maybe<
                          { __typename?: "SuggestionInfluencerConnection" } & {
                            paginatorInfo: Maybe<
                              { __typename?: "PaginatorInfo" } & Pick<
                                PaginatorInfo,
                                "total" | "currentPage" | "count" | "perPage"
                              >
                            >;
                            edges: Maybe<
                              Array<
                                Maybe<
                                  {
                                    __typename?: "SuggestionInfluencerEdge";
                                  } & {
                                    node: {
                                      __typename?: "SuggestionInfluencerNode";
                                    } & {
                                      suggestionInfluencer: {
                                        __typename?: "SuggestionInfluencer";
                                      } & SuggestionInfluencerFragment;
                                    };
                                  }
                                >
                              >
                            >;
                          }
                        >;
                      }
                  >;
                  assignment: Maybe<
                    { __typename?: "ClientAssignment" } & Pick<
                      ClientAssignment,
                      "id" | "totalCost"
                    > & {
                        agencyAttachmentFiles: Array<
                          { __typename?: "AttachmentFile" } & Pick<
                            AttachmentFile,
                            "name" | "url"
                          >
                        >;
                        agencyAssignmentAttachmentFiles: Array<
                          { __typename?: "AttachmentFile" } & Pick<
                            AttachmentFile,
                            "name" | "url"
                          >
                        >;
                        agencyReportAttachmentFiles: Array<
                          { __typename?: "AttachmentFile" } & Pick<
                            AttachmentFile,
                            "name" | "url"
                          >
                        >;
                      }
                  >;
                }
            >;
          }
      >;
    };
};

export type ProposalsQueryVariables = {
  name: Scalars["String"];
  number: Scalars["Int"];
  agencyName: Scalars["String"];
};

export type ProposalsQuery = { __typename?: "Query" } & {
  brand: { __typename?: "ClientBrand" } & Pick<ClientBrand, "id"> & {
      campaign: Maybe<
        { __typename?: "ClientCampaign" } & Pick<
          ClientCampaign,
          "id" | "name"
        > & {
            agency: Maybe<
              { __typename?: "CampaignAgency" } & Pick<
                CampaignAgency,
                "id" | "name"
              > & {
                  suggestions: Maybe<
                    Array<
                      Maybe<
                        { __typename?: "Suggestion" } & Pick<
                          Suggestion,
                          | "id"
                          | "number"
                          | "totalCost"
                          | "totalInfluencerCount"
                          | "createdAt"
                        >
                      >
                    >
                  >;
                }
            >;
          }
      >;
    };
};

export type ReportQueryVariables = {
  name: Scalars["String"];
  number: Scalars["Int"];
  perPage?: Maybe<Scalars["Int"]>;
  page: Scalars["Int"];
  orderBy?: Maybe<ReportInfluencerOrderByClause>;
};

export type ReportQuery = { __typename?: "Query" } & {
  brand: { __typename?: "ClientBrand" } & Pick<ClientBrand, "id"> & {
      campaign: Maybe<
        { __typename?: "ClientCampaign" } & Pick<ClientCampaign, "id"> & {
            indicator: { __typename?: "CampaignIndicator" } & Pick<
              CampaignIndicator,
              "id" | "budget" | "reachKpi"
            > & {
                instagramIndicator: Maybe<
                  { __typename?: "CampaignInstagramIndicator" } & Pick<
                    CampaignInstagramIndicator,
                    "reachKpi"
                  >
                >;
                twitterIndicator: Maybe<
                  { __typename?: "CampaignTwitterIndicator" } & Pick<
                    CampaignTwitterIndicator,
                    "reachKpi"
                  >
                >;
                youtubeIndicator: Maybe<
                  { __typename?: "CampaignYoutubeIndicator" } & Pick<
                    CampaignYoutubeIndicator,
                    "reachKpi"
                  >
                >;
                tiktokIndicator: Maybe<
                  { __typename?: "CampaignTiktokIndicator" } & Pick<
                    CampaignTiktokIndicator,
                    "reachKpi"
                  >
                >;
              };
            report: Maybe<
              { __typename?: "ClientReport" } & Pick<
                ClientReport,
                | "totalCost"
                | "totalCommission"
                | "totalInfluencerCount"
                | "totalFollower"
                | "totalFollow"
                | "averageEngagementRate"
                | "averageAffinityRate"
                | "averageAdvocateScoreRate"
                | "isDataCollectionCompleted"
                | "totalReachCount"
                | "totalImpressionCount"
                | "totalViewCount"
                | "totalLikeCount"
                | "totalCommentCount"
                | "totalShareCount"
                | "averageRetentionRate"
                | "totalClippingCount"
                | "totalEcClickCount"
                | "totalSalesValue"
                | "costReachRate"
                | "costImpressionRate"
                | "costViewRate"
                | "costEngagementRate"
                | "costClippingRate"
                | "costEcClickRate"
                | "costSalesRate"
                | "awarenessScore"
                | "interestScore"
                | "considerationScore"
                | "conversionScore"
                | "agencyNote"
              > & {
                  agencyAttachmentFiles: Array<
                    { __typename?: "AttachmentFile" } & Pick<
                      AttachmentFile,
                      "name" | "url"
                    >
                  >;
                  agencyAssignmentAttachmentFiles: Array<
                    { __typename?: "AttachmentFile" } & Pick<
                      AttachmentFile,
                      "name" | "url"
                    >
                  >;
                  agencyReportAttachmentFiles: Array<
                    { __typename?: "AttachmentFile" } & Pick<
                      AttachmentFile,
                      "name" | "url"
                    >
                  >;
                  summaries: Array<
                    | { __typename?: "Suggestion" }
                    | { __typename?: "ClientReport" }
                    | { __typename?: "ClientAssignment" }
                    | { __typename?: "AssignmentInstagramSummary" }
                    | { __typename?: "AssignmentTiktokSummary" }
                    | { __typename?: "AssignmentTwitterSummary" }
                    | { __typename?: "AssignmentWinningSummary" }
                    | { __typename?: "AssignmentYoutubeSummary" }
                    | ({
                        __typename?: "ReportInstagramSummary";
                      } & ReportInstagramSummaryFragment)
                    | ({
                        __typename?: "ReportPlannedTotalSummary";
                      } & ReportPlannedTotalSummaryFragment)
                    | ({
                        __typename?: "ReportTiktokSummary";
                      } & ReportTiktokSummaryFragment)
                    | ({
                        __typename?: "ReportTwitterSummary";
                      } & ReportTwitterSummaryFragment)
                    | ({
                        __typename?: "ReportYoutubeSummary";
                      } & ReportYoutubeSummaryFragment)
                    | { __typename?: "SuggestionInstagramSummary" }
                    | { __typename?: "SuggestionTiktokSummary" }
                    | { __typename?: "SuggestionTwitterSummary" }
                    | { __typename?: "SuggestionYoutubeSummary" }
                  >;
                  winningSummaries: Array<
                    | { __typename?: "Suggestion" }
                    | { __typename?: "ClientReport" }
                    | { __typename?: "ClientAssignment" }
                    | ({
                        __typename?: "AssignmentInstagramSummary";
                      } & AssignmentInstagramSummaryFragment)
                    | ({
                        __typename?: "AssignmentTiktokSummary";
                      } & AssignmentTiktokSummaryFragment)
                    | ({
                        __typename?: "AssignmentTwitterSummary";
                      } & AssignmentTwitterSummaryFragment)
                    | { __typename?: "AssignmentWinningSummary" }
                    | ({
                        __typename?: "AssignmentYoutubeSummary";
                      } & AssignmentYoutubeSummaryFragment)
                    | { __typename?: "ReportInstagramSummary" }
                    | { __typename?: "ReportPlannedTotalSummary" }
                    | { __typename?: "ReportTiktokSummary" }
                    | { __typename?: "ReportTwitterSummary" }
                    | { __typename?: "ReportYoutubeSummary" }
                    | { __typename?: "SuggestionInstagramSummary" }
                    | { __typename?: "SuggestionTiktokSummary" }
                    | { __typename?: "SuggestionTwitterSummary" }
                    | { __typename?: "SuggestionYoutubeSummary" }
                  >;
                  influencers: Maybe<
                    { __typename?: "ReportInfluencerConnection" } & {
                      paginatorInfo: Maybe<
                        { __typename?: "PaginatorInfo" } & Pick<
                          PaginatorInfo,
                          "total" | "currentPage" | "count" | "perPage"
                        >
                      >;
                      edges: Maybe<
                        Array<
                          Maybe<
                            { __typename?: "ReportInfluencerEdge" } & {
                              node: { __typename?: "ReportInfluencerNode" } & {
                                reportInfluencer: {
                                  __typename?: "ReportInfluencer";
                                } & Pick<
                                  ReportInfluencer,
                                  | "id"
                                  | "name"
                                  | "image"
                                  | "totalCost"
                                  | "totalCommission"
                                  | "totalInfluencerCost"
                                  | "totalCostUsageRight"
                                  | "totalProductSendOutCost"
                                  | "averageAffinityScore"
                                  | "averageAdvocateScore"
                                  | "totalFollowerCount"
                                  | "totalFollowCount"
                                  | "totalPostCount"
                                  | "averageIncreaseRate"
                                  | "maxViewCount"
                                  | "totalLikeCount"
                                  | "totalCommentCount"
                                  | "totalShareCount"
                                  | "totalViewCount"
                                  | "averageEngagement"
                                  | "averageReachCount"
                                  | "averageImpressionCount"
                                  | "averageViewCount"
                                  | "averageLikeCount"
                                  | "averageShareCount"
                                  | "averageCommentCount"
                                  | "averageRetentionRate"
                                  | "averageClippingCount"
                                  | "totalSalesValue"
                                  | "costReachRate"
                                  | "costImpressionRate"
                                  | "costViewRate"
                                  | "costEngagementRate"
                                  | "costClippingRate"
                                  | "costSalesRate"
                                  | "valueCheckStatus"
                                  | "valueCheckDate"
                                  | "checkAccountStatus"
                                  | "awarenessScore"
                                  | "interestScore"
                                  | "considerationScore"
                                  | "conversionScore"
                                > & {
                                    agency: Maybe<
                                      { __typename?: "AgencyInfo" } & Pick<
                                        AgencyInfo,
                                        "id" | "name"
                                      >
                                    >;
                                    insightsBySocialMedia: Array<
                                      {
                                        __typename?: "ReportInfluencerInsightBySocialMedia";
                                      } & Pick<
                                        ReportInfluencerInsightBySocialMedia,
                                        | "id"
                                        | "socialMediaType"
                                        | "isDataCollectionCompleted"
                                        | "accountId"
                                        | "accountUrl"
                                        | "cost"
                                        | "commission"
                                        | "influencerCost"
                                        | "costUsageRight"
                                        | "productSendOutCost"
                                        | "affinityRate"
                                        | "advocateScore"
                                        | "status"
                                        | "isPlanned"
                                        | "postUrl"
                                        | "affinityUpperThreshold"
                                        | "affinityLowerThreshold"
                                      > & {
                                          socialMediaPostInsight: Maybe<
                                            | ({
                                                __typename?: "InstagramPostInsight";
                                              } & Pick<
                                                InstagramPostInsight,
                                                | "followerCount"
                                                | "followerIncreaseRate"
                                                | "averageCommentCount"
                                                | "averageLikeCount"
                                                | "increaseRate"
                                                | "likeCount"
                                                | "commentCount"
                                                | "engagement"
                                                | "reachCount"
                                                | "impressionCount"
                                                | "clippingCount"
                                                | "costReachRate"
                                                | "costImpressionRate"
                                                | "costEngagementRate"
                                                | "costClippingRate"
                                                | "awarenessScore"
                                                | "interestScore"
                                                | "considerationScore"
                                                | "conversionScore"
                                              >)
                                            | ({
                                                __typename?: "TwitterPostInsight";
                                              } & Pick<
                                                TwitterPostInsight,
                                                | "followerCount"
                                                | "followerIncreaseRate"
                                                | "averageCommentCount"
                                                | "averageLikeCount"
                                                | "averageShareCount"
                                                | "increaseRate"
                                                | "likeCount"
                                                | "retweetCount"
                                                | "engagement"
                                                | "reachCount"
                                                | "impressionCount"
                                                | "shareCount"
                                                | "clippingCount"
                                                | "costReachRate"
                                                | "costImpressionRate"
                                                | "costEngagementRate"
                                                | "costClippingRate"
                                                | "awarenessScore"
                                                | "interestScore"
                                                | "considerationScore"
                                                | "conversionScore"
                                              >)
                                            | ({
                                                __typename?: "YoutubePostInsight";
                                              } & Pick<
                                                YoutubePostInsight,
                                                | "followerCount"
                                                | "followerIncreaseRate"
                                                | "maxViewCount"
                                                | "averageCommentCount"
                                                | "averageLikeCount"
                                                | "averageViewCount"
                                                | "increaseRate"
                                                | "likeCount"
                                                | "commentCount"
                                                | "engagement"
                                                | "viewCount"
                                                | "retentionRate"
                                                | "clippingCount"
                                                | "costViewRate"
                                                | "costEngagementRate"
                                                | "costClippingRate"
                                                | "awarenessScore"
                                                | "interestScore"
                                                | "considerationScore"
                                                | "conversionScore"
                                              >)
                                            | ({
                                                __typename?: "TiktokPostInsight";
                                              } & Pick<
                                                TiktokPostInsight,
                                                | "followerCount"
                                                | "followerIncreaseRate"
                                                | "maxViewCount"
                                                | "averageCommentCount"
                                                | "averageLikeCount"
                                                | "averageShareCount"
                                                | "averageViewCount"
                                                | "increaseRate"
                                                | "likeCount"
                                                | "commentCount"
                                                | "shareCount"
                                                | "engagement"
                                                | "viewCount"
                                                | "retentionRate"
                                                | "clippingCount"
                                                | "costViewRate"
                                                | "costEngagementRate"
                                                | "costClippingRate"
                                                | "awarenessScore"
                                                | "interestScore"
                                                | "considerationScore"
                                                | "conversionScore"
                                              >)
                                          >;
                                        }
                                    >;
                                  };
                              };
                            }
                          >
                        >
                      >;
                    }
                  >;
                }
            >;
          }
      >;
    };
};

export type SnapshotQueryVariables = {
  name: Scalars["String"];
  number: Scalars["Int"];
  snapNum: Scalars["Int"];
  perPage?: Maybe<Scalars["Int"]>;
  page: Scalars["Int"];
};

export type SnapshotQuery = { __typename?: "Query" } & {
  influencerSegments: Maybe<
    Array<
      { __typename?: "InfluencerSegment" } & Pick<
        InfluencerSegment,
        "id" | "name" | "maxFollowerCount" | "minFollowerCount" | "increaseRate"
      >
    >
  >;
  brand: { __typename?: "ClientBrand" } & Pick<ClientBrand, "id"> & {
      influencerEvaluationGroup: Maybe<
        { __typename?: "InfluencerEvaluationGroup" } & Pick<
          InfluencerEvaluationGroup,
          "id" | "number"
        > & {
            snapInfluencerEvaluationGroup: Maybe<
              { __typename?: "SnapInfluencerEvaluationGroup" } & Pick<
                SnapInfluencerEvaluationGroup,
                "id" | "number"
              > & {
                  snapInfluencerEvaluations: Maybe<
                    { __typename?: "SnapInfluencerEvaluationConnection" } & {
                      paginatorInfo: Maybe<
                        { __typename?: "PaginatorInfo" } & Pick<
                          PaginatorInfo,
                          "total" | "count" | "currentPage"
                        >
                      >;
                      edges: Maybe<
                        Array<
                          { __typename?: "SnapInfluencerEvaluationEdge" } & {
                            node: {
                              __typename?: "SnapInfluencerEvaluationNode";
                            } & Pick<SnapInfluencerEvaluationNode, "id"> & {
                                snapInfluencerEvaluation: {
                                  __typename?: "SnapInfluencerEvaluation";
                                } & Pick<
                                  SnapInfluencerEvaluation,
                                  | "id"
                                  | "number"
                                  | "affinityRate"
                                  | "note"
                                  | "evaluation"
                                  | "evaluatedAt"
                                > & {
                                    trainingInfluencer: {
                                      __typename?: "SnapTrainingInfluencer";
                                    } & Pick<
                                      SnapTrainingInfluencer,
                                      "id" | "name" | "image" | "codes"
                                    > & {
                                        socialMedia: Maybe<
                                          { __typename?: "SocialMedia" } & {
                                            instagram: Maybe<
                                              {
                                                __typename?: "Instagram";
                                              } & Pick<
                                                Instagram,
                                                | "id"
                                                | "followerCount"
                                                | "increaseRate"
                                              >
                                            >;
                                          }
                                        >;
                                      };
                                  };
                              };
                          }
                        >
                      >;
                    }
                  >;
                }
            >;
          }
      >;
    };
};

export type SnapshotsQueryVariables = {
  name: Scalars["String"];
  number: Scalars["Int"];
  perPage?: Maybe<Scalars["Int"]>;
  page: Scalars["Int"];
};

export type SnapshotsQuery = { __typename?: "Query" } & {
  brand: { __typename?: "ClientBrand" } & Pick<ClientBrand, "id"> & {
      influencerEvaluationGroup: Maybe<
        { __typename?: "InfluencerEvaluationGroup" } & Pick<
          InfluencerEvaluationGroup,
          "id"
        > & {
            snapInfluencerEvaluationGroups: Maybe<
              { __typename?: "SnapInfluencerEvaluationGroupConnection" } & {
                paginatorInfo: Maybe<
                  { __typename?: "PaginatorInfo" } & Pick<
                    PaginatorInfo,
                    "total" | "count" | "currentPage"
                  >
                >;
                edges: Maybe<
                  Array<
                    Maybe<
                      { __typename?: "SnapInfluencerEvaluationGroupEdge" } & {
                        node: {
                          __typename?: "SnapInfluencerEvaluationGroupNode";
                        } & {
                          snapInfluencerEvaluationGroup: {
                            __typename?: "SnapInfluencerEvaluationGroup";
                          } & Pick<
                            SnapInfluencerEvaluationGroup,
                            | "title"
                            | "description"
                            | "id"
                            | "number"
                            | "evaluationCount"
                            | "evaluationMatchedCount"
                            | "evaluationUnmatchedCount"
                            | "evaluationConsideringCount"
                            | "createdAt"
                            | "updatedAt"
                          >;
                        };
                      }
                    >
                  >
                >;
              }
            >;
          }
      >;
    };
};

export const AssignmentInstagramSummaryFragmentFragmentDoc = gql`
  fragment AssignmentInstagramSummaryFragment on AssignmentInstagramSummary {
    socialMediaType
    totalCost
    totalCommission
    totalFollower
    totalInfluencerCount
    totalFollow
    averageEngagementRate
    averageAffinityRate
    averageAdvocateScoreRate
  }
`;
export const AssignmentTwitterSummaryFragmentFragmentDoc = gql`
  fragment AssignmentTwitterSummaryFragment on AssignmentTwitterSummary {
    socialMediaType
    totalCost
    totalCommission
    totalFollower
    totalInfluencerCount
    totalFollow
    averageEngagementRate
    averageAffinityRate
    averageAdvocateScoreRate
  }
`;
export const AssignmentYoutubeSummaryFragmentFragmentDoc = gql`
  fragment AssignmentYoutubeSummaryFragment on AssignmentYoutubeSummary {
    socialMediaType
    totalCost
    totalCommission
    totalFollower
    totalInfluencerCount
    totalFollow
    averageEngagementRate
    averageAffinityRate
    averageAdvocateScoreRate
  }
`;
export const AssignmentTiktokSummaryFragmentFragmentDoc = gql`
  fragment AssignmentTiktokSummaryFragment on AssignmentTiktokSummary {
    socialMediaType
    totalCost
    totalCommission
    totalFollower
    totalInfluencerCount
    totalFollow
    averageEngagementRate
    averageAffinityRate
    averageAdvocateScoreRate
  }
`;
export const AssignmentWinningSummaryFragmentFragmentDoc = gql`
  fragment AssignmentWinningSummaryFragment on AssignmentWinningSummary {
    totalCost
    totalCommission
    totalFollower
    totalInfluencerCount
    totalFollow
    averageEngagementRate
    averageAffinityRate
    averageAdvocateScoreRate
  }
`;
export const AssignmentInfluencerInsightBySocialMediaHistoryFragmentFragmentDoc = gql`
  fragment AssignmentInfluencerInsightBySocialMediaHistoryFragment on AssignmentInfluencerInsightBySocialMediaHistory {
    id
    socialMediaType
    accountId
    cost
  }
`;
export const InstagramInsightFragmentFragmentDoc = gql`
  fragment InstagramInsightFragment on InstagramInsight {
    followerCount
    increaseRate
    averageLikeCount
    averageCommentCount
    engagement
  }
`;
export const TwitterInsightFragmentFragmentDoc = gql`
  fragment TwitterInsightFragment on TwitterInsight {
    followerCount
    increaseRate
    averageLikeCount
    averageRetweetCount
    engagement
  }
`;
export const YoutubeInsightFragmentFragmentDoc = gql`
  fragment YoutubeInsightFragment on YoutubeInsight {
    followerCount
    increaseRate
    averageLikeCount
    averageCommentCount
    engagement
    maxViewCount
  }
`;
export const TiktokInsightFragmentFragmentDoc = gql`
  fragment TiktokInsightFragment on TiktokInsight {
    followerCount
    increaseRate
    averageLikeCount
    averageCommentCount
    engagement
    maxViewCount
  }
`;
export const AssignmentInfluencerInsightBySocialMediaFragmentFragmentDoc = gql`
  fragment AssignmentInfluencerInsightBySocialMediaFragment on AssignmentInfluencerInsightBySocialMedia {
    id
    isDataCollectionCompleted
    socialMediaType
    accountId
    accountUrl
    cost
    commission
    affinityRate
    advocateScore
    affinityUpperThreshold
    affinityLowerThreshold
    checkAccountStatus
    socialMediaInsight {
      ... on InstagramInsight {
        ...InstagramInsightFragment
      }
      ... on TwitterInsight {
        ...TwitterInsightFragment
      }
      ... on YoutubeInsight {
        ...YoutubeInsightFragment
      }
      ... on TiktokInsight {
        ...TiktokInsightFragment
      }
    }
  }
  ${InstagramInsightFragmentFragmentDoc}
  ${TwitterInsightFragmentFragmentDoc}
  ${YoutubeInsightFragmentFragmentDoc}
  ${TiktokInsightFragmentFragmentDoc}
`;
export const AssignmentInfluencerFragmentFragmentDoc = gql`
  fragment AssignmentInfluencerFragment on AssignmentInfluencer {
    id
    number
    name
    image
    cost
    totalCost
    totalCommission
    totalFollowerCount
    totalFollowCount
    totalPostCount
    averageIncreaseRate
    totalLikeCount
    totalCommentCount
    averageEngagement
    averageAffinityScore
    averageAdvocateScore
    maxViewCount
    isClientConfirmed
    considerationStatus
    valueCheckStatus
    checkAccountStatus
    valueCheckDate
    histories {
      id
      totalCost
      insightsBySocialMedia {
        ... on AssignmentInfluencerInsightBySocialMediaHistory {
          ...AssignmentInfluencerInsightBySocialMediaHistoryFragment
        }
      }
    }
    insightsBySocialMedia {
      ... on AssignmentInfluencerInsightBySocialMedia {
        ...AssignmentInfluencerInsightBySocialMediaFragment
      }
    }
    availabilityStatus
  }
  ${AssignmentInfluencerInsightBySocialMediaHistoryFragmentFragmentDoc}
  ${AssignmentInfluencerInsightBySocialMediaFragmentFragmentDoc}
`;
export const ClientAssignmentFragmentFragmentDoc = gql`
  fragment ClientAssignmentFragment on ClientAssignment {
    id
    number
    totalCost
    totalCommission
    totalInfluencerCount
    totalFollower
    totalFollow
    averageEngagementRate
    averageAffinityRate
    averageAdvocateScoreRate
    totalConsiderationInfluencerCount
    isDataCollectionCompleted
    orderListLookedAt
    status
    agencyNote
    agencyAttachmentFiles {
      name
      url
    }
    agencyAssignmentAttachmentFiles {
      name
      url
    }
    agencyReportAttachmentFiles {
      name
      url
    }
    summaries(
      filterBy: $filterBy
      includeAdditionalConsiderationSummary: $includeAdditionalConsiderationSummary
      suggestionNumber: $suggestionNumber
    ) {
      ... on AssignmentInstagramSummary {
        ...AssignmentInstagramSummaryFragment
      }
      ... on AssignmentTwitterSummary {
        ...AssignmentTwitterSummaryFragment
      }
      ... on AssignmentYoutubeSummary {
        ...AssignmentYoutubeSummaryFragment
      }
      ... on AssignmentTiktokSummary {
        ...AssignmentTiktokSummaryFragment
      }
      ... on AssignmentWinningSummary {
        ...AssignmentWinningSummaryFragment
      }
    }
    winningSummaries {
      ... on AssignmentInstagramSummary {
        ...AssignmentInstagramSummaryFragment
      }
      ... on AssignmentTwitterSummary {
        ...AssignmentTwitterSummaryFragment
      }
      ... on AssignmentYoutubeSummary {
        ...AssignmentYoutubeSummaryFragment
      }
      ... on AssignmentTiktokSummary {
        ...AssignmentTiktokSummaryFragment
      }
    }
    assignmentInfluencersByAgency(
      page: $page
      perPage: $perPage
      orderBy: $orderBy
      filterBy: $filterBy
      influencerName: $influencerName
    ) @include(if: $hasAssignmentInfluencers) {
      paginatorInfo {
        total
        currentPage
        count
        perPage
      }
      edges {
        node {
          assignmentInfluencer {
            ... on AssignmentInfluencer {
              ...AssignmentInfluencerFragment
            }
          }
        }
      }
    }
  }
  ${AssignmentInstagramSummaryFragmentFragmentDoc}
  ${AssignmentTwitterSummaryFragmentFragmentDoc}
  ${AssignmentYoutubeSummaryFragmentFragmentDoc}
  ${AssignmentTiktokSummaryFragmentFragmentDoc}
  ${AssignmentWinningSummaryFragmentFragmentDoc}
  ${AssignmentInfluencerFragmentFragmentDoc}
`;
export const ReportInstagramSummaryFragmentFragmentDoc = gql`
  fragment ReportInstagramSummaryFragment on ReportInstagramSummary {
    socialMediaType
    totalCost
    totalFollower
    totalInfluencerCount
    totalFollow
    averageEngagementRate
    averageAffinityRate
    averageAdvocateScoreRate
    totalReachCount
    totalImpressionCount
    totalClippingCount
    totalEcClickCount
    totalCommission
    totalLikeCount
    totalCommentCount
    totalSalesValue
    costReachRate
    costImpressionRate
    costEngagementRate
    costClippingRate
    costEcClickRate
    costSalesRate
    awarenessScore
    interestScore
    considerationScore
    conversionScore
  }
`;
export const ReportTwitterSummaryFragmentFragmentDoc = gql`
  fragment ReportTwitterSummaryFragment on ReportTwitterSummary {
    socialMediaType
    totalCost
    totalFollower
    totalInfluencerCount
    totalFollow
    averageEngagementRate
    averageAffinityRate
    averageAdvocateScoreRate
    totalReachCount
    totalImpressionCount
    totalShareCount
    totalClippingCount
    totalEcClickCount
    totalCommission
    totalLikeCount
    totalCommentCount
    totalSalesValue
    costReachRate
    costImpressionRate
    costEngagementRate
    costClippingRate
    costEcClickRate
    costSalesRate
    awarenessScore
    interestScore
    considerationScore
    conversionScore
  }
`;
export const ReportYoutubeSummaryFragmentFragmentDoc = gql`
  fragment ReportYoutubeSummaryFragment on ReportYoutubeSummary {
    socialMediaType
    totalCost
    totalFollower
    totalInfluencerCount
    totalFollow
    averageEngagementRate
    averageAffinityRate
    averageAdvocateScoreRate
    totalViewCount
    averageRetentionRate
    totalClippingCount
    totalEcClickCount
    totalCommission
    totalLikeCount
    totalCommentCount
    totalSalesValue
    costViewRate
    costEngagementRate
    costClippingRate
    costEcClickRate
    costSalesRate
    awarenessScore
    interestScore
    considerationScore
    conversionScore
  }
`;
export const ReportTiktokSummaryFragmentFragmentDoc = gql`
  fragment ReportTiktokSummaryFragment on ReportTiktokSummary {
    socialMediaType
    totalCost
    totalFollower
    totalInfluencerCount
    totalFollow
    averageEngagementRate
    averageAffinityRate
    averageAdvocateScoreRate
    totalViewCount
    totalShareCount
    averageRetentionRate
    totalClippingCount
    totalEcClickCount
    totalCommission
    totalLikeCount
    totalCommentCount
    totalSalesValue
    costViewRate
    costEngagementRate
    costClippingRate
    costEcClickRate
    costSalesRate
    awarenessScore
    interestScore
    considerationScore
    conversionScore
  }
`;
export const ReportPlannedTotalSummaryFragmentFragmentDoc = gql`
  fragment ReportPlannedTotalSummaryFragment on ReportPlannedTotalSummary {
    totalCost
    totalCommission
    totalFollower
    totalInfluencerCount
    totalFollow
    averageEngagementRate
    averageAffinityRate
    averageAdvocateScoreRate
  }
`;
export const SuggestionInfluencerFragmentFragmentDoc = gql`
  fragment SuggestionInfluencerFragment on SuggestionInfluencer {
    id
    name
    number
    image
    totalCost
    totalCommission
    averageAffinityScore
    averageAdvocateScore
    maxViewCount
    valueCheckStatus
    valueCheckDate
    checkAccountStatus
    costComparison {
      averageCost
      suggestionCount
      costRanking
    }
    totalFollowerCount
    totalFollowCount
    totalPostCount
    averageIncreaseRate
    maxViewCount
    totalLikeCount
    totalCommentCount
    totalShareCount
    totalViewCount
    averageEngagement
    isConsideration
    isAdditionalConsideration
    loadingStatus {
      status
    }
    insightsBySocialMedia {
      id
      isDataCollectionCompleted
      socialMediaType
      accountId
      accountUrl
      cost
      commission
      affinityRate
      advocateScore
      affinityUpperThreshold
      affinityLowerThreshold
      checkAccountStatus
      socialMediaInsight {
        ... on InstagramInsight {
          ...InstagramInsightFragment
        }
        ... on TwitterInsight {
          ...TwitterInsightFragment
        }
        ... on YoutubeInsight {
          ...YoutubeInsightFragment
        }
        ... on TiktokInsight {
          ...TiktokInsightFragment
        }
      }
    }
  }
  ${InstagramInsightFragmentFragmentDoc}
  ${TwitterInsightFragmentFragmentDoc}
  ${YoutubeInsightFragmentFragmentDoc}
  ${TiktokInsightFragmentFragmentDoc}
`;
export const SuggestionInstagramSummaryFragmentFragmentDoc = gql`
  fragment SuggestionInstagramSummaryFragment on SuggestionInstagramSummary {
    socialMediaType
    totalCost
    totalCommission
    totalFollower
    totalInfluencerCount
    totalFollow
    averageEngagementRate
    averageAffinityRate
    averageAdvocateScoreRate
  }
`;
export const SuggestionTwitterSummaryFragmentFragmentDoc = gql`
  fragment SuggestionTwitterSummaryFragment on SuggestionTwitterSummary {
    socialMediaType
    totalCost
    totalCommission
    totalFollower
    totalInfluencerCount
    totalFollow
    averageEngagementRate
    averageAffinityRate
    averageAdvocateScoreRate
  }
`;
export const SuggestionYoutubeSummaryFragmentFragmentDoc = gql`
  fragment SuggestionYoutubeSummaryFragment on SuggestionYoutubeSummary {
    socialMediaType
    totalCost
    totalCommission
    totalFollower
    totalInfluencerCount
    totalFollow
    averageEngagementRate
    averageAffinityRate
    averageAdvocateScoreRate
  }
`;
export const SuggestionTiktokSummaryFragmentFragmentDoc = gql`
  fragment SuggestionTiktokSummaryFragment on SuggestionTiktokSummary {
    socialMediaType
    totalCost
    totalCommission
    totalFollower
    totalInfluencerCount
    totalFollow
    averageEngagementRate
    averageAffinityRate
    averageAdvocateScoreRate
  }
`;
export const AddAgenciesToCampaignDocument = gql`
  mutation AddAgenciesToCampaign(
    $clientMutationId: String
    $campaignId: ID!
    $agencyIds: [ID!]!
  ) {
    addAgenciesToCampaign(
      input: {
        clientMutationId: $clientMutationId
        campaignId: $campaignId
        agencyIds: $agencyIds
      }
    ) {
      campaign {
        id
        number
        agencies {
          id
          name
          totalSuggestionCount
          lastSuggestionAt
          suggestions {
            id
            number
          }
        }
      }
    }
  }
`;
export class AddAgenciesToCampaignMutationRequest
  implements
    MutationRequest<
      AddAgenciesToCampaignMutation,
      AddAgenciesToCampaignMutationVariables
    > {
  options: MutationOptions<
    AddAgenciesToCampaignMutation,
    AddAgenciesToCampaignMutationVariables
  >;
  constructor(variables: AddAgenciesToCampaignMutationVariables) {
    this.options = {
      mutation: AddAgenciesToCampaignDocument,
      variables
    };
  }
}
export const AddConsiderationInfluencersToConfirmInfluencersDocument = gql`
  mutation AddConsiderationInfluencersToConfirmInfluencers(
    $assignmentId: ID!
    $assignmentQueryAcquiredAt: DateTime!
  ) {
    addConsiderationInfluencersToConfirmInfluencers(
      input: {
        assignmentId: $assignmentId
        assignmentQueryAcquiredAt: $assignmentQueryAcquiredAt
      }
    ) {
      areConsiderationInfluencersUpdated
    }
  }
`;
export class AddConsiderationInfluencersToConfirmInfluencersMutationRequest
  implements
    MutationRequest<
      AddConsiderationInfluencersToConfirmInfluencersMutation,
      AddConsiderationInfluencersToConfirmInfluencersMutationVariables
    > {
  options: MutationOptions<
    AddConsiderationInfluencersToConfirmInfluencersMutation,
    AddConsiderationInfluencersToConfirmInfluencersMutationVariables
  >;
  constructor(
    variables: AddConsiderationInfluencersToConfirmInfluencersMutationVariables
  ) {
    this.options = {
      mutation: AddConsiderationInfluencersToConfirmInfluencersDocument,
      variables
    };
  }
}
export const AddInfluencerFromConsiderationDocument = gql`
  mutation AddInfluencerFromConsideration(
    $clientMutationId: String
    $suggestionInfluencerId: ID!
    $influencerNumber: Int!
  ) {
    addInfluencerFromConsideration(
      input: {
        clientMutationId: $clientMutationId
        suggestionInfluencerId: $suggestionInfluencerId
      }
    ) {
      suggestion {
        totalCost
        totalCommission
        totalInfluencerCount
        totalFollower
        totalFollow
        averageEngagementRate
        averageAffinityRate
        averageAdvocateScoreRate
        totalConsiderationInfluencerCount
        isDataCollectionCompleted
        summaries {
          ... on SuggestionInstagramSummary {
            ...SuggestionInstagramSummaryFragment
          }
          ... on SuggestionTwitterSummary {
            ...SuggestionTwitterSummaryFragment
          }
          ... on SuggestionYoutubeSummary {
            ...SuggestionYoutubeSummaryFragment
          }
        }
        suggestionInfluencer(number: $influencerNumber) {
          ...SuggestionInfluencerFragment
        }
      }
    }
  }
  ${SuggestionInstagramSummaryFragmentFragmentDoc}
  ${SuggestionTwitterSummaryFragmentFragmentDoc}
  ${SuggestionYoutubeSummaryFragmentFragmentDoc}
  ${SuggestionInfluencerFragmentFragmentDoc}
`;
export class AddInfluencerFromConsiderationMutationRequest
  implements
    MutationRequest<
      AddInfluencerFromConsiderationMutation,
      AddInfluencerFromConsiderationMutationVariables
    > {
  options: MutationOptions<
    AddInfluencerFromConsiderationMutation,
    AddInfluencerFromConsiderationMutationVariables
  >;
  constructor(variables: AddInfluencerFromConsiderationMutationVariables) {
    this.options = {
      mutation: AddInfluencerFromConsiderationDocument,
      variables
    };
  }
}
export const AdditionalAssignInfluencerConsiderationDocument = gql`
  mutation AdditionalAssignInfluencerConsideration(
    $suggestionNumber: Int!
    $assignmentId: ID!
    $suggestionInfluencerId: ID!
    $page: Int!
    $perPage: Int
    $suggestionOrderBy: SuggestionInfluencerOrderByClause
    $includeAdditionalConsiderationSummary: Boolean = true
    $orderBy: AssignmentInfluencerOrderByClause
    $hasAssignmentInfluencers: Boolean = false
    $filterBy: AssignmentInfluencerFilter = {
      availabilityStatus: [OK, CONFIRM]
    }
    $influencerName: String
  ) {
    additionalAssignInfluencerConsideration(
      input: {
        assignmentId: $assignmentId
        suggestionInfluencerId: $suggestionInfluencerId
      }
    ) {
      agency {
        suggestion(
          number: $suggestionNumber
          excludeAssignment: true
          additionalConsiderationOnly: true
        ) {
          id
          number
          note
          totalCost
          totalInfluencerCount
          totalConsiderationInfluencerCount
          suggestionInfluencers(
            page: $page
            perPage: $perPage
            excludeAssignment: true
            orderBy: $suggestionOrderBy
          ) {
            paginatorInfo {
              total
              currentPage
              count
            }
            edges {
              node {
                suggestionInfluencer {
                  ...SuggestionInfluencerFragment
                }
              }
            }
          }
          attachmentFiles {
            name
            url
          }
        }
        assignment(
          filterBy: $filterBy
          includeAdditionalConsiderationSummary: $includeAdditionalConsiderationSummary
          suggestionNumber: $suggestionNumber
        ) {
          ... on ClientAssignment {
            ...ClientAssignmentFragment
          }
        }
      }
    }
  }
  ${SuggestionInfluencerFragmentFragmentDoc}
  ${ClientAssignmentFragmentFragmentDoc}
`;
export class AdditionalAssignInfluencerConsiderationMutationRequest
  implements
    MutationRequest<
      AdditionalAssignInfluencerConsiderationMutation,
      AdditionalAssignInfluencerConsiderationMutationVariables
    > {
  options: MutationOptions<
    AdditionalAssignInfluencerConsiderationMutation,
    AdditionalAssignInfluencerConsiderationMutationVariables
  >;
  constructor(
    variables: AdditionalAssignInfluencerConsiderationMutationVariables
  ) {
    this.options = {
      mutation: AdditionalAssignInfluencerConsiderationDocument,
      variables
    };
  }
}
export const AdditionalAssignInfluencersToCampaignDocument = gql`
  mutation AdditionalAssignInfluencersToCampaign(
    $clientMutationId: String
    $assignmentId: ID!
    $suggestionId: ID!
  ) {
    additionalAssignInfluencersToCampaign(
      input: {
        clientMutationId: $clientMutationId
        assignmentId: $assignmentId
        suggestionId: $suggestionId
      }
    ) {
      campaign {
        number
      }
    }
  }
`;
export class AdditionalAssignInfluencersToCampaignMutationRequest
  implements
    MutationRequest<
      AdditionalAssignInfluencersToCampaignMutation,
      AdditionalAssignInfluencersToCampaignMutationVariables
    > {
  options: MutationOptions<
    AdditionalAssignInfluencersToCampaignMutation,
    AdditionalAssignInfluencersToCampaignMutationVariables
  >;
  constructor(
    variables: AdditionalAssignInfluencersToCampaignMutationVariables
  ) {
    this.options = {
      mutation: AdditionalAssignInfluencersToCampaignDocument,
      variables
    };
  }
}
export const AgencyChangePasswordDocument = gql`
  mutation AgencyChangePassword(
    $clientMutationId: String
    $email: String!
    $token: String!
    $password: String!
  ) {
    agencyChangePassword(
      input: {
        clientMutationId: $clientMutationId
        email: $email
        token: $token
        password: $password
      }
    ) {
      clientMutationId
    }
  }
`;
export class AgencyChangePasswordMutationRequest
  implements
    MutationRequest<
      AgencyChangePasswordMutation,
      AgencyChangePasswordMutationVariables
    > {
  options: MutationOptions<
    AgencyChangePasswordMutation,
    AgencyChangePasswordMutationVariables
  >;
  constructor(variables: AgencyChangePasswordMutationVariables) {
    this.options = {
      mutation: AgencyChangePasswordDocument,
      variables
    };
  }
}
export const AgencyLoginDocument = gql`
  mutation AgencyLogin($input: AgencyLoginInput!) {
    agencyLogin(input: $input) {
      token
      clientMutationId
    }
  }
`;
export class AgencyLoginMutationRequest
  implements
    MutationRequest<AgencyLoginMutation, AgencyLoginMutationVariables> {
  options: MutationOptions<AgencyLoginMutation, AgencyLoginMutationVariables>;
  constructor(variables: AgencyLoginMutationVariables) {
    this.options = {
      mutation: AgencyLoginDocument,
      variables
    };
  }
}
export const ApproveAvailabilityDocument = gql`
  mutation ApproveAvailability($input: RequestAvailabilityApprovalInput!) {
    requestAvailabilityApproval(input: $input) {
      assignment {
        id
        status
      }
    }
  }
`;
export class ApproveAvailabilityMutationRequest
  implements
    MutationRequest<
      ApproveAvailabilityMutation,
      ApproveAvailabilityMutationVariables
    > {
  options: MutationOptions<
    ApproveAvailabilityMutation,
    ApproveAvailabilityMutationVariables
  >;
  constructor(variables: ApproveAvailabilityMutationVariables) {
    this.options = {
      mutation: ApproveAvailabilityDocument,
      variables
    };
  }
}
export const AssignInfluencersToCampaignDocument = gql`
  mutation AssignInfluencersToCampaign(
    $clientMutationId: String
    $suggestionId: ID!
  ) {
    assignInfluencersToCampaign(
      input: {
        clientMutationId: $clientMutationId
        suggestionId: $suggestionId
      }
    ) {
      campaign {
        id
        number
      }
    }
  }
`;
export class AssignInfluencersToCampaignMutationRequest
  implements
    MutationRequest<
      AssignInfluencersToCampaignMutation,
      AssignInfluencersToCampaignMutationVariables
    > {
  options: MutationOptions<
    AssignInfluencersToCampaignMutation,
    AssignInfluencersToCampaignMutationVariables
  >;
  constructor(variables: AssignInfluencersToCampaignMutationVariables) {
    this.options = {
      mutation: AssignInfluencersToCampaignDocument,
      variables
    };
  }
}
export const CampaignReportRequestDocument = gql`
  mutation CampaignReportRequest($input: CampaignReportRequestInput!) {
    campaignReportRequest(input: $input) {
      response
    }
  }
`;
export class CampaignReportRequestMutationRequest
  implements
    MutationRequest<
      CampaignReportRequestMutation,
      CampaignReportRequestMutationVariables
    > {
  options: MutationOptions<
    CampaignReportRequestMutation,
    CampaignReportRequestMutationVariables
  >;
  constructor(variables: CampaignReportRequestMutationVariables) {
    this.options = {
      mutation: CampaignReportRequestDocument,
      variables
    };
  }
}
export const ChangePasswordDocument = gql`
  mutation ChangePassword(
    $clientMutationId: String
    $email: String!
    $token: String!
    $password: String!
  ) {
    clientChangePassword(
      input: {
        clientMutationId: $clientMutationId
        email: $email
        token: $token
        password: $password
      }
    ) {
      clientMutationId
    }
  }
`;
export class ChangePasswordMutationRequest
  implements
    MutationRequest<ChangePasswordMutation, ChangePasswordMutationVariables> {
  options: MutationOptions<
    ChangePasswordMutation,
    ChangePasswordMutationVariables
  >;
  constructor(variables: ChangePasswordMutationVariables) {
    this.options = {
      mutation: ChangePasswordDocument,
      variables
    };
  }
}
export const ApproveConfirmedInfluencersDocument = gql`
  mutation ApproveConfirmedInfluencers($assignmentId: ID!) {
    approveConfirmedInfluencers(input: { assignmentId: $assignmentId }) {
      assignment {
        number
        status
      }
    }
  }
`;
export class ApproveConfirmedInfluencersMutationRequest
  implements
    MutationRequest<
      ApproveConfirmedInfluencersMutation,
      ApproveConfirmedInfluencersMutationVariables
    > {
  options: MutationOptions<
    ApproveConfirmedInfluencersMutation,
    ApproveConfirmedInfluencersMutationVariables
  >;
  constructor(variables: ApproveConfirmedInfluencersMutationVariables) {
    this.options = {
      mutation: ApproveConfirmedInfluencersDocument,
      variables
    };
  }
}
export const ApproveInfluencerListDocument = gql`
  mutation ApproveInfluencerList($id: ID!, $agencyId: ID!) {
    approveRequestAvailabilityApproval(
      input: { campaignId: $id, agencyId: $agencyId }
    ) {
      assignment {
        number
        status
      }
    }
  }
`;
export class ApproveInfluencerListMutationRequest
  implements
    MutationRequest<
      ApproveInfluencerListMutation,
      ApproveInfluencerListMutationVariables
    > {
  options: MutationOptions<
    ApproveInfluencerListMutation,
    ApproveInfluencerListMutationVariables
  >;
  constructor(variables: ApproveInfluencerListMutationVariables) {
    this.options = {
      mutation: ApproveInfluencerListDocument,
      variables
    };
  }
}
export const RejectInfluencerListDocument = gql`
  mutation RejectInfluencerList($id: ID!, $agencyId: ID!) {
    dismissRequestAvailabilityApproval(
      input: { campaignId: $id, agencyId: $agencyId }
    ) {
      assignment {
        number
        status
      }
    }
  }
`;
export class RejectInfluencerListMutationRequest
  implements
    MutationRequest<
      RejectInfluencerListMutation,
      RejectInfluencerListMutationVariables
    > {
  options: MutationOptions<
    RejectInfluencerListMutation,
    RejectInfluencerListMutationVariables
  >;
  constructor(variables: RejectInfluencerListMutationVariables) {
    this.options = {
      mutation: RejectInfluencerListDocument,
      variables
    };
  }
}
export const RemoveConfirmedInfluencersDocument = gql`
  mutation RemoveConfirmedInfluencers($assignmentId: ID!) {
    removeConfirmedInfluencers(input: { assignmentId: $assignmentId }) {
      assignment {
        number
        status
      }
    }
  }
`;
export class RemoveConfirmedInfluencersMutationRequest
  implements
    MutationRequest<
      RemoveConfirmedInfluencersMutation,
      RemoveConfirmedInfluencersMutationVariables
    > {
  options: MutationOptions<
    RemoveConfirmedInfluencersMutation,
    RemoveConfirmedInfluencersMutationVariables
  >;
  constructor(variables: RemoveConfirmedInfluencersMutationVariables) {
    this.options = {
      mutation: RemoveConfirmedInfluencersDocument,
      variables
    };
  }
}
export const CreateAffinityModelDocument = gql`
  mutation createAffinityModel(
    $clientMutationId: String
    $snapInfluencerEvaluationGroupId: ID!
    $to: Date!
    $description: String
  ) {
    createAffinityModel(
      input: {
        clientMutationId: $clientMutationId
        snapInfluencerEvaluationGroupId: $snapInfluencerEvaluationGroupId
        to: $to
        description: $description
      }
    ) {
      clientMutationId
      affinityModel {
        id
        number
        description
        createdAt
        snapInfluencerEvaluationGroup {
          id
        }
        coreModel {
          to
          rangeMonth
          logic {
            id
            name
          }
        }
      }
    }
  }
`;
export class CreateAffinityModelMutationRequest
  implements
    MutationRequest<
      CreateAffinityModelMutation,
      CreateAffinityModelMutationVariables
    > {
  options: MutationOptions<
    CreateAffinityModelMutation,
    CreateAffinityModelMutationVariables
  >;
  constructor(variables: CreateAffinityModelMutationVariables) {
    this.options = {
      mutation: CreateAffinityModelDocument,
      variables
    };
  }
}
export const CreateAgencyDocument = gql`
  mutation CreateAgency($input: CreateAgencyInput!) {
    createAgency(input: $input) {
      agency {
        name
      }
    }
  }
`;
export class CreateAgencyMutationRequest
  implements
    MutationRequest<CreateAgencyMutation, CreateAgencyMutationVariables> {
  options: MutationOptions<CreateAgencyMutation, CreateAgencyMutationVariables>;
  constructor(variables: CreateAgencyMutationVariables) {
    this.options = {
      mutation: CreateAgencyDocument,
      variables
    };
  }
}
export const CreateCampaignDocument = gql`
  mutation createCampaign(
    $clientMutationId: String
    $title: String!
    $objectives: [ObjectiveType]
    $campaignActivityType: CampaignActivityType
    $productItemId: ID!
    $instagramReachKpi: Int
    $twitterReachKpi: Int
    $youtubeReachKpi: Int
    $tiktokReachKpi: Int
    $instagramSales: Int
    $twitterSales: Int
    $youtubeSales: Int
    $tiktokSales: Int
    $instagramEcClick: Int
    $twitterEcClick: Int
    $youtubeEcClick: Int
    $tiktokEcClick: Int
    $note: String
    $postStartAt: DateTime!
    $postEndAt: DateTime!
    $suggestionStartAt: DateTime!
    $suggestionEndAt: DateTime!
    $affinityModelId: ID!
    $budget: Int!
    $keywordAndHashtags: String
    $attachmentFiles: [CampaignFileUpload]
  ) {
    createCampaign(
      input: {
        clientMutationId: $clientMutationId
        title: $title
        objectives: $objectives
        campaignActivityType: $campaignActivityType
        productItemId: $productItemId
        instagramReachKpi: $instagramReachKpi
        twitterReachKpi: $twitterReachKpi
        youtubeReachKpi: $youtubeReachKpi
        tiktokReachKpi: $tiktokReachKpi
        instagramSales: $instagramSales
        twitterSales: $twitterSales
        youtubeSales: $youtubeSales
        tiktokSales: $tiktokSales
        instagramEcClick: $instagramEcClick
        twitterEcClick: $twitterEcClick
        youtubeEcClick: $youtubeEcClick
        tiktokEcClick: $tiktokEcClick
        note: $note
        postStartAt: $postStartAt
        postEndAt: $postEndAt
        suggestionStartAt: $suggestionStartAt
        suggestionEndAt: $suggestionEndAt
        affinityModelId: $affinityModelId
        budget: $budget
        keywordAndHashtags: $keywordAndHashtags
        attachmentFiles: $attachmentFiles
      }
    ) {
      campaign {
        id
      }
    }
  }
`;
export class CreateCampaignMutationRequest
  implements
    MutationRequest<CreateCampaignMutation, CreateCampaignMutationVariables> {
  options: MutationOptions<
    CreateCampaignMutation,
    CreateCampaignMutationVariables
  >;
  constructor(variables: CreateCampaignMutationVariables) {
    this.options = {
      mutation: CreateCampaignDocument,
      variables
    };
  }
}
export const CreateClientAccountDocument = gql`
  mutation CreateClientAccount($input: CreateClientAccountInput!) {
    createClientAccount(input: $input) {
      clientAccount {
        id
      }
    }
  }
`;
export class CreateClientAccountMutationRequest
  implements
    MutationRequest<
      CreateClientAccountMutation,
      CreateClientAccountMutationVariables
    > {
  options: MutationOptions<
    CreateClientAccountMutation,
    CreateClientAccountMutationVariables
  >;
  constructor(variables: CreateClientAccountMutationVariables) {
    this.options = {
      mutation: CreateClientAccountDocument,
      variables
    };
  }
}
export const CreateInfluencerEvaluationGroupDocument = gql`
  mutation createInfluencerEvaluationGroup(
    $title: String!
    $clientMutationId: String
    $brandId: ID!
  ) {
    createInfluencerEvaluationGroup(
      input: {
        title: $title
        clientMutationId: $clientMutationId
        brandId: $brandId
      }
    ) {
      influencerEvaluationGroup {
        id
        number
        title
      }
    }
  }
`;
export class CreateInfluencerEvaluationGroupMutationRequest
  implements
    MutationRequest<
      CreateInfluencerEvaluationGroupMutation,
      CreateInfluencerEvaluationGroupMutationVariables
    > {
  options: MutationOptions<
    CreateInfluencerEvaluationGroupMutation,
    CreateInfluencerEvaluationGroupMutationVariables
  >;
  constructor(variables: CreateInfluencerEvaluationGroupMutationVariables) {
    this.options = {
      mutation: CreateInfluencerEvaluationGroupDocument,
      variables
    };
  }
}
export const CreateSnapDocument = gql`
  mutation CreateSnap(
    $clientMutationId: String
    $influencerEvaluationGroupId: ID!
    $title: String!
    $description: String
  ) {
    createSnapInfluencerEvaluationGroup(
      input: {
        clientMutationId: $clientMutationId
        influencerEvaluationGroupId: $influencerEvaluationGroupId
        title: $title
        description: $description
      }
    ) {
      snapInfluencerEvaluationGroup {
        id
        number
      }
    }
  }
`;
export class CreateSnapMutationRequest
  implements MutationRequest<CreateSnapMutation, CreateSnapMutationVariables> {
  options: MutationOptions<CreateSnapMutation, CreateSnapMutationVariables>;
  constructor(variables: CreateSnapMutationVariables) {
    this.options = {
      mutation: CreateSnapDocument,
      variables
    };
  }
}
export const CreateAgencyAccountDocument = gql`
  mutation CreateAgencyAccount($input: CreateAgencyAccountInput!) {
    createAgencyAccount(input: $input) {
      clientMutationId
    }
  }
`;
export class CreateAgencyAccountMutationRequest
  implements
    MutationRequest<
      CreateAgencyAccountMutation,
      CreateAgencyAccountMutationVariables
    > {
  options: MutationOptions<
    CreateAgencyAccountMutation,
    CreateAgencyAccountMutationVariables
  >;
  constructor(variables: CreateAgencyAccountMutationVariables) {
    this.options = {
      mutation: CreateAgencyAccountDocument,
      variables
    };
  }
}
export const CreateBrandDocument = gql`
  mutation CreateBrand($input: CreateBrandInput!) {
    createBrand(input: $input) {
      brand {
        id
        name
      }
    }
  }
`;
export class CreateBrandMutationRequest
  implements
    MutationRequest<CreateBrandMutation, CreateBrandMutationVariables> {
  options: MutationOptions<CreateBrandMutation, CreateBrandMutationVariables>;
  constructor(variables: CreateBrandMutationVariables) {
    this.options = {
      mutation: CreateBrandDocument,
      variables
    };
  }
}
export const DeactivateAgencyDocument = gql`
  mutation DeactivateAgency($input: DeactivateAgencyInput!) {
    deactivateAgency(input: $input) {
      clientMutationId
    }
  }
`;
export class DeactivateAgencyMutationRequest
  implements
    MutationRequest<
      DeactivateAgencyMutation,
      DeactivateAgencyMutationVariables
    > {
  options: MutationOptions<
    DeactivateAgencyMutation,
    DeactivateAgencyMutationVariables
  >;
  constructor(variables: DeactivateAgencyMutationVariables) {
    this.options = {
      mutation: DeactivateAgencyDocument,
      variables
    };
  }
}
export const DeleteAgencyAccountDocument = gql`
  mutation DeleteAgencyAccount($input: DeleteAgencyAccountInput!) {
    deleteAgencyAccount(input: $input) {
      clientMutationId
    }
  }
`;
export class DeleteAgencyAccountMutationRequest
  implements
    MutationRequest<
      DeleteAgencyAccountMutation,
      DeleteAgencyAccountMutationVariables
    > {
  options: MutationOptions<
    DeleteAgencyAccountMutation,
    DeleteAgencyAccountMutationVariables
  >;
  constructor(variables: DeleteAgencyAccountMutationVariables) {
    this.options = {
      mutation: DeleteAgencyAccountDocument,
      variables
    };
  }
}
export const DeleteCampaignDocument = gql`
  mutation DeleteCampaign($input: DeleteCampaignInput!) {
    deleteCampaign(input: $input) {
      clientMutationId
      deleted
    }
  }
`;
export class DeleteCampaignMutationRequest
  implements
    MutationRequest<DeleteCampaignMutation, DeleteCampaignMutationVariables> {
  options: MutationOptions<
    DeleteCampaignMutation,
    DeleteCampaignMutationVariables
  >;
  constructor(variables: DeleteCampaignMutationVariables) {
    this.options = {
      mutation: DeleteCampaignDocument,
      variables
    };
  }
}
export const DownloadClientReportDocument = gql`
  mutation DownloadClientReport($campaignId: ID!) {
    downloadClientReport(input: { campaignId: $campaignId }) {
      url
    }
  }
`;
export class DownloadClientReportMutationRequest
  implements
    MutationRequest<
      DownloadClientReportMutation,
      DownloadClientReportMutationVariables
    > {
  options: MutationOptions<
    DownloadClientReportMutation,
    DownloadClientReportMutationVariables
  >;
  constructor(variables: DownloadClientReportMutationVariables) {
    this.options = {
      mutation: DownloadClientReportDocument,
      variables
    };
  }
}
export const DownloadAgencyReportInfluencersDocument = gql`
  mutation DownloadAgencyReportInfluencers(
    $clientMutationId: String
    $campaignId: ID!
  ) {
    downloadAgencyReportInfluencers(
      input: { clientMutationId: $clientMutationId, campaignId: $campaignId }
    ) {
      url
    }
  }
`;
export class DownloadAgencyReportInfluencersMutationRequest
  implements
    MutationRequest<
      DownloadAgencyReportInfluencersMutation,
      DownloadAgencyReportInfluencersMutationVariables
    > {
  options: MutationOptions<
    DownloadAgencyReportInfluencersMutation,
    DownloadAgencyReportInfluencersMutationVariables
  >;
  constructor(variables: DownloadAgencyReportInfluencersMutationVariables) {
    this.options = {
      mutation: DownloadAgencyReportInfluencersDocument,
      variables
    };
  }
}
export const DownloadAssignmentInfluencersToAgencyDocument = gql`
  mutation DownloadAssignmentInfluencersToAgency(
    $clientMutationId: String
    $campaignId: ID!
    $agencyId: ID
  ) {
    downloadAssignmentInfluencersToAgency(
      input: {
        clientMutationId: $clientMutationId
        campaignId: $campaignId
        agencyId: $agencyId
      }
    ) {
      url
    }
  }
`;
export class DownloadAssignmentInfluencersToAgencyMutationRequest
  implements
    MutationRequest<
      DownloadAssignmentInfluencersToAgencyMutation,
      DownloadAssignmentInfluencersToAgencyMutationVariables
    > {
  options: MutationOptions<
    DownloadAssignmentInfluencersToAgencyMutation,
    DownloadAssignmentInfluencersToAgencyMutationVariables
  >;
  constructor(
    variables: DownloadAssignmentInfluencersToAgencyMutationVariables
  ) {
    this.options = {
      mutation: DownloadAssignmentInfluencersToAgencyDocument,
      variables
    };
  }
}
export const DownloadSuggestionInfluencersToAgencyDocument = gql`
  mutation DownloadSuggestionInfluencersToAgency(
    $clientMutationId: String
    $suggestionId: ID!
    $considerationOnly: Boolean
  ) {
    downloadSuggestionInfluencersToAgency(
      input: {
        clientMutationId: $clientMutationId
        suggestionId: $suggestionId
        considerationOnly: $considerationOnly
      }
    ) {
      url
    }
  }
`;
export class DownloadSuggestionInfluencersToAgencyMutationRequest
  implements
    MutationRequest<
      DownloadSuggestionInfluencersToAgencyMutation,
      DownloadSuggestionInfluencersToAgencyMutationVariables
    > {
  options: MutationOptions<
    DownloadSuggestionInfluencersToAgencyMutation,
    DownloadSuggestionInfluencersToAgencyMutationVariables
  >;
  constructor(
    variables: DownloadSuggestionInfluencersToAgencyMutationVariables
  ) {
    this.options = {
      mutation: DownloadSuggestionInfluencersToAgencyDocument,
      variables
    };
  }
}
export const DownloadSuggestionInfluencersDocument = gql`
  mutation DownloadSuggestionInfluencers(
    $clientMutationId: String
    $suggestionId: ID!
    $considerationOnly: Boolean
  ) {
    downloadSuggestionInfluencers(
      input: {
        clientMutationId: $clientMutationId
        suggestionId: $suggestionId
        considerationOnly: $considerationOnly
      }
    ) {
      url
    }
  }
`;
export class DownloadSuggestionInfluencersMutationRequest
  implements
    MutationRequest<
      DownloadSuggestionInfluencersMutation,
      DownloadSuggestionInfluencersMutationVariables
    > {
  options: MutationOptions<
    DownloadSuggestionInfluencersMutation,
    DownloadSuggestionInfluencersMutationVariables
  >;
  constructor(variables: DownloadSuggestionInfluencersMutationVariables) {
    this.options = {
      mutation: DownloadSuggestionInfluencersDocument,
      variables
    };
  }
}
export const DownloadAssignmentInfluencersDocument = gql`
  mutation DownloadAssignmentInfluencers(
    $input: DownloadAssignmentInfluencersInput!
  ) {
    downloadAssignmentInfluencers(input: $input) {
      url
    }
  }
`;
export class DownloadAssignmentInfluencersMutationRequest
  implements
    MutationRequest<
      DownloadAssignmentInfluencersMutation,
      DownloadAssignmentInfluencersMutationVariables
    > {
  options: MutationOptions<
    DownloadAssignmentInfluencersMutation,
    DownloadAssignmentInfluencersMutationVariables
  >;
  constructor(variables: DownloadAssignmentInfluencersMutationVariables) {
    this.options = {
      mutation: DownloadAssignmentInfluencersDocument,
      variables
    };
  }
}
export const UpdateBrandDocument = gql`
  mutation updateBrand(
    $brandId: ID!
    $name: String!
    $image: Upload
    $description: String
  ) {
    updateBrand(
      input: {
        brandId: $brandId
        name: $name
        image: $image
        description: $description
      }
    ) {
      brand {
        id
        name
        image
        description
      }
    }
  }
`;
export class UpdateBrandMutationRequest
  implements
    MutationRequest<UpdateBrandMutation, UpdateBrandMutationVariables> {
  options: MutationOptions<UpdateBrandMutation, UpdateBrandMutationVariables>;
  constructor(variables: UpdateBrandMutationVariables) {
    this.options = {
      mutation: UpdateBrandDocument,
      variables
    };
  }
}
export const LoginDocument = gql`
  mutation Login($input: ClientLoginInput!) {
    clientLogin(input: $input) {
      token
      clientMutationId
    }
  }
`;
export class LoginMutationRequest
  implements MutationRequest<LoginMutation, LoginMutationVariables> {
  options: MutationOptions<LoginMutation, LoginMutationVariables>;
  constructor(variables: LoginMutationVariables) {
    this.options = {
      mutation: LoginDocument,
      variables
    };
  }
}
export const ReadAssignmentCommentDocument = gql`
  mutation ReadAssignmentComment($input: ReadAssignmentCommentInput!) {
    readAssignmentComment(input: $input) {
      clientMutationId
    }
  }
`;
export class ReadAssignmentCommentMutationRequest
  implements
    MutationRequest<
      ReadAssignmentCommentMutation,
      ReadAssignmentCommentMutationVariables
    > {
  options: MutationOptions<
    ReadAssignmentCommentMutation,
    ReadAssignmentCommentMutationVariables
  >;
  constructor(variables: ReadAssignmentCommentMutationVariables) {
    this.options = {
      mutation: ReadAssignmentCommentDocument,
      variables
    };
  }
}
export const ReadReportCommentDocument = gql`
  mutation ReadReportComment($input: ReadReportCommentInput!) {
    readReportComment(input: $input) {
      clientMutationId
    }
  }
`;
export class ReadReportCommentMutationRequest
  implements
    MutationRequest<
      ReadReportCommentMutation,
      ReadReportCommentMutationVariables
    > {
  options: MutationOptions<
    ReadReportCommentMutation,
    ReadReportCommentMutationVariables
  >;
  constructor(variables: ReadReportCommentMutationVariables) {
    this.options = {
      mutation: ReadReportCommentDocument,
      variables
    };
  }
}
export const ReadSuggestionCommentDocument = gql`
  mutation ReadSuggestionComment($input: ReadSuggestionCommentInput!) {
    readSuggestionComment(input: $input) {
      clientMutationId
    }
  }
`;
export class ReadSuggestionCommentMutationRequest
  implements
    MutationRequest<
      ReadSuggestionCommentMutation,
      ReadSuggestionCommentMutationVariables
    > {
  options: MutationOptions<
    ReadSuggestionCommentMutation,
    ReadSuggestionCommentMutationVariables
  >;
  constructor(variables: ReadSuggestionCommentMutationVariables) {
    this.options = {
      mutation: ReadSuggestionCommentDocument,
      variables
    };
  }
}
export const RejectSuggestionDocument = gql`
  mutation RejectSuggestion($campaignId: ID!) {
    rejectSuggestion(input: { campaignId: $campaignId }) {
      campaign {
        id
      }
    }
  }
`;
export class RejectSuggestionMutationRequest
  implements
    MutationRequest<
      RejectSuggestionMutation,
      RejectSuggestionMutationVariables
    > {
  options: MutationOptions<
    RejectSuggestionMutation,
    RejectSuggestionMutationVariables
  >;
  constructor(variables: RejectSuggestionMutationVariables) {
    this.options = {
      mutation: RejectSuggestionDocument,
      variables
    };
  }
}
export const RemoveAgencyFromCampaignDocument = gql`
  mutation RemoveAgencyFromCampaign($campaignId: ID!, $agencyId: ID!) {
    removeAgencyFromCampaign(
      input: { campaignId: $campaignId, agencyId: $agencyId }
    ) {
      clientMutationId
    }
  }
`;
export class RemoveAgencyFromCampaignMutationRequest
  implements
    MutationRequest<
      RemoveAgencyFromCampaignMutation,
      RemoveAgencyFromCampaignMutationVariables
    > {
  options: MutationOptions<
    RemoveAgencyFromCampaignMutation,
    RemoveAgencyFromCampaignMutationVariables
  >;
  constructor(variables: RemoveAgencyFromCampaignMutationVariables) {
    this.options = {
      mutation: RemoveAgencyFromCampaignDocument,
      variables
    };
  }
}
export const RemoveInfluencerFromConsiderationDocument = gql`
  mutation RemoveInfluencerFromConsideration(
    $clientMutationId: String
    $suggestionInfluencerId: ID!
    $influencerNumber: Int!
  ) {
    removeInfluencerFromConsideration(
      input: {
        clientMutationId: $clientMutationId
        suggestionInfluencerId: $suggestionInfluencerId
      }
    ) {
      suggestion {
        totalCost
        totalCommission
        totalInfluencerCount
        totalFollower
        totalFollow
        averageEngagementRate
        averageAdvocateScoreRate
        averageAffinityRate
        totalConsiderationInfluencerCount
        isDataCollectionCompleted
        summaries {
          ... on SuggestionInstagramSummary {
            ...SuggestionInstagramSummaryFragment
          }
          ... on SuggestionTwitterSummary {
            ...SuggestionTwitterSummaryFragment
          }
          ... on SuggestionYoutubeSummary {
            ...SuggestionYoutubeSummaryFragment
          }
        }
        suggestionInfluencer(number: $influencerNumber) {
          ...SuggestionInfluencerFragment
        }
      }
    }
  }
  ${SuggestionInstagramSummaryFragmentFragmentDoc}
  ${SuggestionTwitterSummaryFragmentFragmentDoc}
  ${SuggestionYoutubeSummaryFragmentFragmentDoc}
  ${SuggestionInfluencerFragmentFragmentDoc}
`;
export class RemoveInfluencerFromConsiderationMutationRequest
  implements
    MutationRequest<
      RemoveInfluencerFromConsiderationMutation,
      RemoveInfluencerFromConsiderationMutationVariables
    > {
  options: MutationOptions<
    RemoveInfluencerFromConsiderationMutation,
    RemoveInfluencerFromConsiderationMutationVariables
  >;
  constructor(variables: RemoveInfluencerFromConsiderationMutationVariables) {
    this.options = {
      mutation: RemoveInfluencerFromConsiderationDocument,
      variables
    };
  }
}
export const SendAgencyPasswordResetEmailDocument = gql`
  mutation SendAgencyPasswordResetEmail(
    $clientMutationId: String
    $email: String!
  ) {
    agencyChangePasswordRequest(
      input: { clientMutationId: $clientMutationId, email: $email }
    ) {
      requested
    }
  }
`;
export class SendAgencyPasswordResetEmailMutationRequest
  implements
    MutationRequest<
      SendAgencyPasswordResetEmailMutation,
      SendAgencyPasswordResetEmailMutationVariables
    > {
  options: MutationOptions<
    SendAgencyPasswordResetEmailMutation,
    SendAgencyPasswordResetEmailMutationVariables
  >;
  constructor(variables: SendAgencyPasswordResetEmailMutationVariables) {
    this.options = {
      mutation: SendAgencyPasswordResetEmailDocument,
      variables
    };
  }
}
export const SendAssignmentCommentDocument = gql`
  mutation SendAssignmentComment(
    $input: SendAssignmentCommentInput!
    $brandName: String!
  ) {
    sendAssignmentComment(input: $input) {
      clientMutationId
      comments {
        id
        account {
          ... on ClientAccount {
            __typename
            name
            number
            brand(name: $brandName) {
              name
            }
          }
          ... on AgencyAccount {
            __typename
            name
            number
            brand(name: $brandName) {
              name
            }
          }
        }
        readFlag
        sendAt
        comment
      }
    }
  }
`;
export class SendAssignmentCommentMutationRequest
  implements
    MutationRequest<
      SendAssignmentCommentMutation,
      SendAssignmentCommentMutationVariables
    > {
  options: MutationOptions<
    SendAssignmentCommentMutation,
    SendAssignmentCommentMutationVariables
  >;
  constructor(variables: SendAssignmentCommentMutationVariables) {
    this.options = {
      mutation: SendAssignmentCommentDocument,
      variables
    };
  }
}
export const SendDraftAssignmentCommentDocument = gql`
  mutation SendDraftAssignmentComment(
    $input: SendDraftAssignmentCommentInput!
  ) {
    sendDraftAssignmentComment(input: $input) {
      clientMutationId
      comment {
        id
        comment
        sendAt
      }
    }
  }
`;
export class SendDraftAssignmentCommentMutationRequest
  implements
    MutationRequest<
      SendDraftAssignmentCommentMutation,
      SendDraftAssignmentCommentMutationVariables
    > {
  options: MutationOptions<
    SendDraftAssignmentCommentMutation,
    SendDraftAssignmentCommentMutationVariables
  >;
  constructor(variables: SendDraftAssignmentCommentMutationVariables) {
    this.options = {
      mutation: SendDraftAssignmentCommentDocument,
      variables
    };
  }
}
export const SendDraftReportCommentDocument = gql`
  mutation SendDraftReportComment($input: SendDraftReportCommentInput!) {
    sendDraftReportComment(input: $input) {
      clientMutationId
      comment {
        id
        comment
        sendAt
      }
    }
  }
`;
export class SendDraftReportCommentMutationRequest
  implements
    MutationRequest<
      SendDraftReportCommentMutation,
      SendDraftReportCommentMutationVariables
    > {
  options: MutationOptions<
    SendDraftReportCommentMutation,
    SendDraftReportCommentMutationVariables
  >;
  constructor(variables: SendDraftReportCommentMutationVariables) {
    this.options = {
      mutation: SendDraftReportCommentDocument,
      variables
    };
  }
}
export const SendDraftSuggestionCommentDocument = gql`
  mutation SendDraftSuggestionComment(
    $input: SendDraftSuggestionCommentInput!
  ) {
    sendDraftSuggestionComment(input: $input) {
      clientMutationId
      comment {
        id
        comment
        sendAt
      }
    }
  }
`;
export class SendDraftSuggestionCommentMutationRequest
  implements
    MutationRequest<
      SendDraftSuggestionCommentMutation,
      SendDraftSuggestionCommentMutationVariables
    > {
  options: MutationOptions<
    SendDraftSuggestionCommentMutation,
    SendDraftSuggestionCommentMutationVariables
  >;
  constructor(variables: SendDraftSuggestionCommentMutationVariables) {
    this.options = {
      mutation: SendDraftSuggestionCommentDocument,
      variables
    };
  }
}
export const SendClientPasswordResetEmailDocument = gql`
  mutation SendClientPasswordResetEmail(
    $clientMutationId: String
    $email: String!
  ) {
    clientChangePasswordRequest(
      input: { clientMutationId: $clientMutationId, email: $email }
    ) {
      requested
    }
  }
`;
export class SendClientPasswordResetEmailMutationRequest
  implements
    MutationRequest<
      SendClientPasswordResetEmailMutation,
      SendClientPasswordResetEmailMutationVariables
    > {
  options: MutationOptions<
    SendClientPasswordResetEmailMutation,
    SendClientPasswordResetEmailMutationVariables
  >;
  constructor(variables: SendClientPasswordResetEmailMutationVariables) {
    this.options = {
      mutation: SendClientPasswordResetEmailDocument,
      variables
    };
  }
}
export const SendReportCommentDocument = gql`
  mutation SendReportComment(
    $input: SendReportCommentInput!
    $brandName: String!
  ) {
    sendReportComment(input: $input) {
      clientMutationId
      comments {
        id
        account {
          ... on ClientAccount {
            __typename
            name
            number
            brand(name: $brandName) {
              name
            }
          }
          ... on AgencyAccount {
            __typename
            name
            number
            brand(name: $brandName) {
              name
            }
          }
        }
        readFlag
        sendAt
        comment
      }
    }
  }
`;
export class SendReportCommentMutationRequest
  implements
    MutationRequest<
      SendReportCommentMutation,
      SendReportCommentMutationVariables
    > {
  options: MutationOptions<
    SendReportCommentMutation,
    SendReportCommentMutationVariables
  >;
  constructor(variables: SendReportCommentMutationVariables) {
    this.options = {
      mutation: SendReportCommentDocument,
      variables
    };
  }
}
export const SendSuggestionCommentDocument = gql`
  mutation SendSuggestionComment(
    $input: SendSuggestionCommentInput!
    $brandName: String!
  ) {
    sendSuggestionComment(input: $input) {
      clientMutationId
      comments {
        id
        account {
          ... on ClientAccount {
            __typename
            name
            number
            brand(name: $brandName) {
              name
            }
          }
          ... on AgencyAccount {
            __typename
            name
            number
            brand(name: $brandName) {
              name
            }
          }
        }
        readFlag
        sendAt
        comment
      }
    }
  }
`;
export class SendSuggestionCommentMutationRequest
  implements
    MutationRequest<
      SendSuggestionCommentMutation,
      SendSuggestionCommentMutationVariables
    > {
  options: MutationOptions<
    SendSuggestionCommentMutation,
    SendSuggestionCommentMutationVariables
  >;
  constructor(variables: SendSuggestionCommentMutationVariables) {
    this.options = {
      mutation: SendSuggestionCommentDocument,
      variables
    };
  }
}
export const SkipToReportDocument = gql`
  mutation SkipToReport($campaignId: ID!, $agencyIds: [ID!]!) {
    skipToReport(input: { campaignId: $campaignId, agencyIds: $agencyIds }) {
      campaign {
        id
      }
    }
  }
`;
export class SkipToReportMutationRequest
  implements
    MutationRequest<SkipToReportMutation, SkipToReportMutationVariables> {
  options: MutationOptions<SkipToReportMutation, SkipToReportMutationVariables>;
  constructor(variables: SkipToReportMutationVariables) {
    this.options = {
      mutation: SkipToReportDocument,
      variables
    };
  }
}
export const ToggleConfirmInfluencersConsiderationDocument = gql`
  mutation ToggleConfirmInfluencersConsideration(
    $assignmentId: ID!
    $assignmentInfluencerIds: [ID!]!
  ) {
    toggleConfirmInfluencersConsideration(
      input: {
        assignmentId: $assignmentId
        assignmentInfluencerIds: $assignmentInfluencerIds
      }
    ) {
      assignment {
        id
      }
    }
  }
`;
export class ToggleConfirmInfluencersConsiderationMutationRequest
  implements
    MutationRequest<
      ToggleConfirmInfluencersConsiderationMutation,
      ToggleConfirmInfluencersConsiderationMutationVariables
    > {
  options: MutationOptions<
    ToggleConfirmInfluencersConsiderationMutation,
    ToggleConfirmInfluencersConsiderationMutationVariables
  >;
  constructor(
    variables: ToggleConfirmInfluencersConsiderationMutationVariables
  ) {
    this.options = {
      mutation: ToggleConfirmInfluencersConsiderationDocument,
      variables
    };
  }
}
export const ToggleInfluencersConsiderationDocument = gql`
  mutation ToggleInfluencersConsideration(
    $suggestionId: ID!
    $suggestionInfluencerIds: [ID!]!
  ) {
    toggleInfluencersConsideration(
      input: {
        suggestionId: $suggestionId
        suggestionInfluencerIds: $suggestionInfluencerIds
      }
    ) {
      suggestion {
        id
        totalCost
        totalCommission
        totalInfluencerCount
        totalFollower
        totalFollow
        averageEngagementRate
        averageAdvocateScoreRate
        averageAffinityRate
        totalConsiderationInfluencerCount
        isDataCollectionCompleted
        summaries {
          ... on SuggestionInstagramSummary {
            ...SuggestionInstagramSummaryFragment
          }
          ... on SuggestionTwitterSummary {
            ...SuggestionTwitterSummaryFragment
          }
          ... on SuggestionYoutubeSummary {
            ...SuggestionYoutubeSummaryFragment
          }
        }
      }
    }
  }
  ${SuggestionInstagramSummaryFragmentFragmentDoc}
  ${SuggestionTwitterSummaryFragmentFragmentDoc}
  ${SuggestionYoutubeSummaryFragmentFragmentDoc}
`;
export class ToggleInfluencersConsiderationMutationRequest
  implements
    MutationRequest<
      ToggleInfluencersConsiderationMutation,
      ToggleInfluencersConsiderationMutationVariables
    > {
  options: MutationOptions<
    ToggleInfluencersConsiderationMutation,
    ToggleInfluencersConsiderationMutationVariables
  >;
  constructor(variables: ToggleInfluencersConsiderationMutationVariables) {
    this.options = {
      mutation: ToggleInfluencersConsiderationDocument,
      variables
    };
  }
}
export const UpdateAgencyAccountDocument = gql`
  mutation UpdateAgencyAccount($input: UpdateAgencyAccountInput!) {
    updateAgencyAccount(input: $input) {
      clientMutationId
    }
  }
`;
export class UpdateAgencyAccountMutationRequest
  implements
    MutationRequest<
      UpdateAgencyAccountMutation,
      UpdateAgencyAccountMutationVariables
    > {
  options: MutationOptions<
    UpdateAgencyAccountMutation,
    UpdateAgencyAccountMutationVariables
  >;
  constructor(variables: UpdateAgencyAccountMutationVariables) {
    this.options = {
      mutation: UpdateAgencyAccountDocument,
      variables
    };
  }
}
export const UpdateAgencyDocument = gql`
  mutation UpdateAgency($input: UpdateAgencyInput!) {
    updateAgency(input: $input) {
      agency {
        name
      }
    }
  }
`;
export class UpdateAgencyMutationRequest
  implements
    MutationRequest<UpdateAgencyMutation, UpdateAgencyMutationVariables> {
  options: MutationOptions<UpdateAgencyMutation, UpdateAgencyMutationVariables>;
  constructor(variables: UpdateAgencyMutationVariables) {
    this.options = {
      mutation: UpdateAgencyDocument,
      variables
    };
  }
}
export const UpdateCampaignDocument = gql`
  mutation UpdateCampaign(
    $clientMutationId: String
    $title: String!
    $objectives: [ObjectiveType]
    $campaignId: ID!
    $campaignActivityType: CampaignActivityType!
    $productItemId: ID!
    $instagramReachKpi: Int
    $twitterReachKpi: Int
    $youtubeReachKpi: Int
    $tiktokReachKpi: Int
    $instagramSales: Int
    $twitterSales: Int
    $youtubeSales: Int
    $tiktokSales: Int
    $instagramEcClick: Int
    $twitterEcClick: Int
    $youtubeEcClick: Int
    $tiktokEcClick: Int
    $note: String
    $postStartAt: DateTime!
    $postEndAt: DateTime!
    $suggestionStartAt: DateTime!
    $suggestionEndAt: DateTime!
    $affinityModelId: ID!
    $budget: Int!
    $keywordAndHashtags: String
    $clientAttachmentFiles: [CampaignFileUpload]
  ) {
    updateCampaign(
      input: {
        clientMutationId: $clientMutationId
        title: $title
        objectives: $objectives
        campaignId: $campaignId
        campaignActivityType: $campaignActivityType
        productItemId: $productItemId
        instagramReachKpi: $instagramReachKpi
        twitterReachKpi: $twitterReachKpi
        youtubeReachKpi: $youtubeReachKpi
        tiktokReachKpi: $tiktokReachKpi
        instagramSales: $instagramSales
        twitterSales: $twitterSales
        youtubeSales: $youtubeSales
        tiktokSales: $tiktokSales
        instagramEcClick: $instagramEcClick
        twitterEcClick: $twitterEcClick
        youtubeEcClick: $youtubeEcClick
        tiktokEcClick: $tiktokEcClick
        note: $note
        postStartAt: $postStartAt
        postEndAt: $postEndAt
        suggestionStartAt: $suggestionStartAt
        suggestionEndAt: $suggestionEndAt
        affinityModelId: $affinityModelId
        budget: $budget
        keywordAndHashtags: $keywordAndHashtags
        clientAttachmentFiles: $clientAttachmentFiles
      }
    ) {
      campaign {
        id
      }
    }
  }
`;
export class UpdateCampaignMutationRequest
  implements
    MutationRequest<UpdateCampaignMutation, UpdateCampaignMutationVariables> {
  options: MutationOptions<
    UpdateCampaignMutation,
    UpdateCampaignMutationVariables
  >;
  constructor(variables: UpdateCampaignMutationVariables) {
    this.options = {
      mutation: UpdateCampaignDocument,
      variables
    };
  }
}
export const UpdateClientAccountDocument = gql`
  mutation UpdateClientAccount(
    $clientMutationId: String
    $clientAccountId: ID!
    $name: String!
    $email: String!
  ) {
    updateClientAccount(
      input: {
        clientMutationId: $clientMutationId
        clientAccountId: $clientAccountId
        name: $name
        email: $email
      }
    ) {
      clientAccount {
        id
        number
      }
    }
  }
`;
export class UpdateClientAccountMutationRequest
  implements
    MutationRequest<
      UpdateClientAccountMutation,
      UpdateClientAccountMutationVariables
    > {
  options: MutationOptions<
    UpdateClientAccountMutation,
    UpdateClientAccountMutationVariables
  >;
  constructor(variables: UpdateClientAccountMutationVariables) {
    this.options = {
      mutation: UpdateClientAccountDocument,
      variables
    };
  }
}
export const UpdateInfluencerEvaluationNoteDocument = gql`
  mutation updateInfluencerEvaluationNote(
    $influencerEvaluationId: ID!
    $clientMutationId: String
    $note: String!
  ) {
    updateInfluencerEvaluationNote(
      input: {
        influencerEvaluationId: $influencerEvaluationId
        clientMutationId: $clientMutationId
        note: $note
      }
    ) {
      influencerEvaluation {
        id
        note
      }
    }
  }
`;
export class UpdateInfluencerEvaluationNoteMutationRequest
  implements
    MutationRequest<
      UpdateInfluencerEvaluationNoteMutation,
      UpdateInfluencerEvaluationNoteMutationVariables
    > {
  options: MutationOptions<
    UpdateInfluencerEvaluationNoteMutation,
    UpdateInfluencerEvaluationNoteMutationVariables
  >;
  constructor(variables: UpdateInfluencerEvaluationNoteMutationVariables) {
    this.options = {
      mutation: UpdateInfluencerEvaluationNoteDocument,
      variables
    };
  }
}
export const UpdateInfluencerEvaluationDocument = gql`
  mutation updateInfluencerEvaluation(
    $influencerEvaluationId: ID!
    $clientMutationId: String
    $evaluation: EvaluationType!
  ) {
    updateInfluencerEvaluation(
      input: {
        influencerEvaluationId: $influencerEvaluationId
        clientMutationId: $clientMutationId
        evaluation: $evaluation
      }
    ) {
      influencerEvaluation {
        id
        number
        evaluation
        evaluatedAt
      }
    }
  }
`;
export class UpdateInfluencerEvaluationMutationRequest
  implements
    MutationRequest<
      UpdateInfluencerEvaluationMutation,
      UpdateInfluencerEvaluationMutationVariables
    > {
  options: MutationOptions<
    UpdateInfluencerEvaluationMutation,
    UpdateInfluencerEvaluationMutationVariables
  >;
  constructor(variables: UpdateInfluencerEvaluationMutationVariables) {
    this.options = {
      mutation: UpdateInfluencerEvaluationDocument,
      variables
    };
  }
}
export const UpdateInfluencerSegmentDocument = gql`
  mutation UpdateInfluencerSegment($input: UpdateInfluencerSegmentInput!) {
    updateInfluencerSegment(input: $input) {
      influencerSegment {
        id
      }
    }
  }
`;
export class UpdateInfluencerSegmentMutationRequest
  implements
    MutationRequest<
      UpdateInfluencerSegmentMutation,
      UpdateInfluencerSegmentMutationVariables
    > {
  options: MutationOptions<
    UpdateInfluencerSegmentMutation,
    UpdateInfluencerSegmentMutationVariables
  >;
  constructor(variables: UpdateInfluencerSegmentMutationVariables) {
    this.options = {
      mutation: UpdateInfluencerSegmentDocument,
      variables
    };
  }
}
export const UpdateUnevaluatedInfluencerDocument = gql`
  mutation UpdateUnevaluatedInfluencer(
    $clientMutationId: String
    $influencerEvaluationGroupId: ID!
  ) {
    updateUnevaluatedInfluencer(
      input: {
        clientMutationId: $clientMutationId
        influencerEvaluationGroupId: $influencerEvaluationGroupId
      }
    ) {
      postLoadingStatus {
        status
      }
    }
  }
`;
export class UpdateUnevaluatedInfluencerMutationRequest
  implements
    MutationRequest<
      UpdateUnevaluatedInfluencerMutation,
      UpdateUnevaluatedInfluencerMutationVariables
    > {
  options: MutationOptions<
    UpdateUnevaluatedInfluencerMutation,
    UpdateUnevaluatedInfluencerMutationVariables
  >;
  constructor(variables: UpdateUnevaluatedInfluencerMutationVariables) {
    this.options = {
      mutation: UpdateUnevaluatedInfluencerDocument,
      variables
    };
  }
}
export const UploadAvailabilityResultsDocument = gql`
  mutation UploadAvailabilityResults($input: UploadAvailabilityResultsInput!) {
    uploadAvailabilityResults(input: $input) {
      assignment {
        id
        attachmentFiles {
          id
          name
          url
        }
        assignmentInfluencers(page: 1, perPage: 7) {
          paginatorInfo {
            total
            count
            currentPage
          }
          edges {
            node {
              id
              assignmentInfluencer {
                id
                name
                image
                availabilityStatus
                note
              }
            }
          }
        }
      }
    }
  }
`;
export class UploadAvailabilityResultsMutationRequest
  implements
    MutationRequest<
      UploadAvailabilityResultsMutation,
      UploadAvailabilityResultsMutationVariables
    > {
  options: MutationOptions<
    UploadAvailabilityResultsMutation,
    UploadAvailabilityResultsMutationVariables
  >;
  constructor(variables: UploadAvailabilityResultsMutationVariables) {
    this.options = {
      mutation: UploadAvailabilityResultsDocument,
      variables
    };
  }
}
export const UploadPostedResultsDocument = gql`
  mutation UploadPostedResults($input: UploadPostedResultsInput!) {
    uploadPostedResults(input: $input) {
      report {
        attachmentFiles {
          id
          name
          url
        }
        influencers(page: 1, perPage: 7) {
          paginatorInfo {
            total
            count
            currentPage
          }
          edges {
            node {
              reportInfluencer {
                id
                name
                image
                insightsBySocialMedia {
                  status
                  isPlanned
                  postUrl
                }
              }
            }
          }
        }
      }
    }
  }
`;
export class UploadPostedResultsMutationRequest
  implements
    MutationRequest<
      UploadPostedResultsMutation,
      UploadPostedResultsMutationVariables
    > {
  options: MutationOptions<
    UploadPostedResultsMutation,
    UploadPostedResultsMutationVariables
  >;
  constructor(variables: UploadPostedResultsMutationVariables) {
    this.options = {
      mutation: UploadPostedResultsDocument,
      variables
    };
  }
}
export const UploadSuggestionDocument = gql`
  mutation UploadSuggestion($input: UploadSuggestionInput!) {
    uploadSuggestion(input: $input) {
      suggestion {
        id
        number
        note
        attachmentFiles {
          name
          url
        }
        createdAt
      }
    }
  }
`;
export class UploadSuggestionMutationRequest
  implements
    MutationRequest<
      UploadSuggestionMutation,
      UploadSuggestionMutationVariables
    > {
  options: MutationOptions<
    UploadSuggestionMutation,
    UploadSuggestionMutationVariables
  >;
  constructor(variables: UploadSuggestionMutationVariables) {
    this.options = {
      mutation: UploadSuggestionDocument,
      variables
    };
  }
}
export const AdminClientAccountsDocument = gql`
  query adminClientAccounts {
    clientAccounts {
      id
      number
      name
      email
    }
  }
`;
export class AdminClientAccountsQueryRequest
  implements
    QueryRequest<AdminClientAccountsQuery, AdminClientAccountsQueryVariables> {
  _result?: AdminClientAccountsQuery;
  options: QueryOptions<AdminClientAccountsQueryVariables>;
  constructor(variables: AdminClientAccountsQueryVariables) {
    this.options = {
      query: AdminClientAccountsDocument,
      variables
    };
  }
}
export const AffinityModelCreationDocument = gql`
  query AffinityModelCreation($name: String!, $perPage: Int, $page: Int!) {
    brand(name: $name) {
      id
      name
      affinityModels(perPage: $perPage, page: $page) {
        edges {
          node {
            affinityModel {
              id
              coreModel {
                id
                rangeMonth
              }
            }
          }
        }
      }
    }
    logics {
      id
      name
    }
    userPostVectors {
      id
      to
    }
    influencerEvaluationGroups(brandName: $name) {
      id
      number
      title
      snapInfluencerEvaluationGroups(page: $page) {
        edges {
          node {
            snapInfluencerEvaluationGroup {
              id
              number
              title
            }
          }
        }
      }
    }
  }
`;
export class AffinityModelCreationQueryRequest
  implements
    QueryRequest<
      AffinityModelCreationQuery,
      AffinityModelCreationQueryVariables
    > {
  _result?: AffinityModelCreationQuery;
  options: QueryOptions<AffinityModelCreationQueryVariables>;
  constructor(variables: AffinityModelCreationQueryVariables) {
    this.options = {
      query: AffinityModelCreationDocument,
      variables
    };
  }
}
export const AffinityModelDocument = gql`
  query AffinityModel($name: String!, $number: Int!) {
    brand(name: $name) {
      id
      name
      affinityModel(number: $number) {
        name
        createdAt
        description
        snapInfluencerEvaluationGroup {
          influencerEvaluationGroup {
            title
          }
        }
        coreModel {
          to
          rangeMonth
          logic {
            name
          }
        }
        usedCampaigns {
          id
          name
        }
      }
    }
  }
`;
export class AffinityModelQueryRequest
  implements QueryRequest<AffinityModelQuery, AffinityModelQueryVariables> {
  _result?: AffinityModelQuery;
  options: QueryOptions<AffinityModelQueryVariables>;
  constructor(variables: AffinityModelQueryVariables) {
    this.options = {
      query: AffinityModelDocument,
      variables
    };
  }
}
export const AffinityModelsDocument = gql`
  query AffinityModels($name: String!, $perPage: Int!, $page: Int!) {
    brand(name: $name) {
      id
      name
      affinityModels(perPage: $perPage, page: $page) {
        paginatorInfo {
          total
          count
          currentPage
        }
        edges {
          node {
            affinityModel {
              id
              name
              number
              description
              createdAt
            }
          }
        }
      }
    }
  }
`;
export class AffinityModelsQueryRequest
  implements QueryRequest<AffinityModelsQuery, AffinityModelsQueryVariables> {
  _result?: AffinityModelsQuery;
  options: QueryOptions<AffinityModelsQueryVariables>;
  constructor(variables: AffinityModelsQueryVariables) {
    this.options = {
      query: AffinityModelsDocument,
      variables
    };
  }
}
export const AgenciesDocument = gql`
  query Agencies {
    agencies {
      id
      name
      ownerAgencyAccount {
        name
        email
      }
    }
  }
`;
export class AgenciesQueryRequest
  implements QueryRequest<AgenciesQuery, AgenciesQueryVariables> {
  _result?: AgenciesQuery;
  options: QueryOptions<AgenciesQueryVariables>;
  constructor(variables: AgenciesQueryVariables) {
    this.options = {
      query: AgenciesDocument,
      variables
    };
  }
}
export const AgencyAccountDocument = gql`
  query AgencyAccount {
    agencyAccount {
      id
      agencyId
      agencyName
      name
      email
      isOwner
      brands {
        id
        name
        image
      }
    }
  }
`;
export class AgencyAccountQueryRequest
  implements QueryRequest<AgencyAccountQuery, AgencyAccountQueryVariables> {
  _result?: AgencyAccountQuery;
  options: QueryOptions<AgencyAccountQueryVariables>;
  constructor(variables: AgencyAccountQueryVariables) {
    this.options = {
      query: AgencyAccountDocument,
      variables
    };
  }
}
export const AgencyAccountsDocument = gql`
  query AgencyAccounts {
    agencyAccounts {
      id
      number
      name
      email
      isOwner
    }
  }
`;
export class AgencyAccountsQueryRequest
  implements QueryRequest<AgencyAccountsQuery, AgencyAccountsQueryVariables> {
  _result?: AgencyAccountsQuery;
  options: QueryOptions<AgencyAccountsQueryVariables>;
  constructor(variables: AgencyAccountsQueryVariables) {
    this.options = {
      query: AgencyAccountsDocument,
      variables
    };
  }
}
export const AgencyAccountUpdateDocument = gql`
  query AgencyAccountUpdate($number: Int!) {
    agencyAccountByNumber(number: $number) {
      id
      number
      name
      email
    }
  }
`;
export class AgencyAccountUpdateQueryRequest
  implements
    QueryRequest<AgencyAccountUpdateQuery, AgencyAccountUpdateQueryVariables> {
  _result?: AgencyAccountUpdateQuery;
  options: QueryOptions<AgencyAccountUpdateQueryVariables>;
  constructor(variables: AgencyAccountUpdateQueryVariables) {
    this.options = {
      query: AgencyAccountUpdateDocument,
      variables
    };
  }
}
export const AgencyAdditionalSuggestionsDocument = gql`
  query AgencyAdditionalSuggestions(
    $brandName: String!
    $campaignNumber: Int!
  ) {
    agencyAccount {
      id
      brand(name: $brandName) {
        id
        campaign(number: $campaignNumber) {
          id
          suggestions(additionalSuggestionOnly: true) {
            id
            createdAt
            note
            number
            attachmentFiles {
              id
              name
              url
            }
          }
        }
      }
    }
  }
`;
export class AgencyAdditionalSuggestionsQueryRequest
  implements
    QueryRequest<
      AgencyAdditionalSuggestionsQuery,
      AgencyAdditionalSuggestionsQueryVariables
    > {
  _result?: AgencyAdditionalSuggestionsQuery;
  options: QueryOptions<AgencyAdditionalSuggestionsQueryVariables>;
  constructor(variables: AgencyAdditionalSuggestionsQueryVariables) {
    this.options = {
      query: AgencyAdditionalSuggestionsDocument,
      variables
    };
  }
}
export const AgencyAssignmentDocument = gql`
  query AgencyAssignment(
    $brandName: String!
    $campaignNumber: Int!
    $perPage: Int
    $page: Int!
  ) {
    agencyAccount {
      id
      brand(name: $brandName) {
        id
        campaign(number: $campaignNumber) {
          id
          assignment {
            id
            status
            assignmentInfluencers(perPage: $perPage, page: $page) {
              paginatorInfo {
                total
                count
                currentPage
              }
              edges {
                node {
                  assignmentInfluencer {
                    image
                    name
                    availabilityStatus
                    note
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
export class AgencyAssignmentQueryRequest
  implements
    QueryRequest<AgencyAssignmentQuery, AgencyAssignmentQueryVariables> {
  _result?: AgencyAssignmentQuery;
  options: QueryOptions<AgencyAssignmentQueryVariables>;
  constructor(variables: AgencyAssignmentQueryVariables) {
    this.options = {
      query: AgencyAssignmentDocument,
      variables
    };
  }
}
export const AgencyBrandCampaignsDocument = gql`
  query AgencyBrandCampaigns($name: String!) {
    agencyAccount {
      id
      brand(name: $name) {
        id
        campaigns {
          id
          name
          number
        }
      }
    }
  }
`;
export class AgencyBrandCampaignsQueryRequest
  implements
    QueryRequest<
      AgencyBrandCampaignsQuery,
      AgencyBrandCampaignsQueryVariables
    > {
  _result?: AgencyBrandCampaignsQuery;
  options: QueryOptions<AgencyBrandCampaignsQueryVariables>;
  constructor(variables: AgencyBrandCampaignsQueryVariables) {
    this.options = {
      query: AgencyBrandCampaignsDocument,
      variables
    };
  }
}
export const AgencyCampaignCommentDocument = gql`
  query AgencyCampaignComment($brandName: String!, $campaignNumber: Int!) {
    agencyCampaignComment(
      brandName: $brandName
      campaignNumber: $campaignNumber
    ) {
      campaignId
      suggestionComments {
        unreadCommentCount
        comments {
          id
          account {
            ... on ClientAccount {
              __typename
              name
              number
              brand(name: $brandName) {
                name
              }
            }
            ... on AgencyAccount {
              __typename
              name
              number
              brand(name: $brandName) {
                name
              }
            }
          }
          readFlag
          sendAt
          comment
        }
        draftComment {
          id
          comment
        }
      }
      assignmentComments {
        unreadCommentCount
        comments {
          id
          account {
            ... on ClientAccount {
              __typename
              name
              number
              brand(name: $brandName) {
                name
              }
            }
            ... on AgencyAccount {
              __typename
              name
              number
              brand(name: $brandName) {
                name
              }
            }
          }
          readFlag
          sendAt
          comment
        }
        draftComment {
          id
          comment
        }
      }
      reportComments {
        unreadCommentCount
        comments {
          id
          account {
            ... on ClientAccount {
              __typename
              name
              number
              brand(name: $brandName) {
                name
              }
            }
            ... on AgencyAccount {
              __typename
              name
              number
              brand(name: $brandName) {
                name
              }
            }
          }
          readFlag
          sendAt
          comment
        }
        draftComment {
          id
          comment
        }
      }
    }
  }
`;
export class AgencyCampaignCommentQueryRequest
  implements
    QueryRequest<
      AgencyCampaignCommentQuery,
      AgencyCampaignCommentQueryVariables
    > {
  _result?: AgencyCampaignCommentQuery;
  options: QueryOptions<AgencyCampaignCommentQueryVariables>;
  constructor(variables: AgencyCampaignCommentQueryVariables) {
    this.options = {
      query: AgencyCampaignCommentDocument,
      variables
    };
  }
}
export const AgencyCampaignDetailDocument = gql`
  query AgencyCampaignDetail($name: String!, $number: Int!) {
    agencyAccount {
      id
      agencyName
      brand(name: $name) {
        id
        image
        campaign(number: $number) {
          id
          number
          name
          objectives
          productItem {
            id
            name
          }
          budget
          attachmentFiles {
            id
            name
            url
          }
          note
          indicator {
            id
            budget
            reachKpi
            instagramIndicator {
              reachKpi
            }
            twitterIndicator {
              reachKpi
            }
            youtubeIndicator {
              reachKpi
            }
            tiktokIndicator {
              reachKpi
            }
          }
          suggestionStatus
          suggestionStartAt
          suggestionEndAt
          postStartAt
          postEndAt
          assignment {
            id
            attachmentFiles {
              id
              name
              url
            }
          }
          report {
            campaignId
            attachmentFiles {
              id
              name
              url
            }
          }
        }
      }
    }
  }
`;
export class AgencyCampaignDetailQueryRequest
  implements
    QueryRequest<
      AgencyCampaignDetailQuery,
      AgencyCampaignDetailQueryVariables
    > {
  _result?: AgencyCampaignDetailQuery;
  options: QueryOptions<AgencyCampaignDetailQueryVariables>;
  constructor(variables: AgencyCampaignDetailQueryVariables) {
    this.options = {
      query: AgencyCampaignDetailDocument,
      variables
    };
  }
}
export const AgencyReportInfluencersDocument = gql`
  query AgencyReportInfluencers(
    $brandName: String!
    $campaignNumber: Int!
    $page: Int!
    $perPage: Int
  ) {
    agencyAccount {
      id
      brand(name: $brandName) {
        id
        campaign(number: $campaignNumber) {
          id
          report {
            influencers(page: $page, perPage: $perPage) {
              paginatorInfo {
                total
                count
                currentPage
              }
              edges {
                node {
                  id
                  reportInfluencer {
                    id
                    name
                    image
                    insightsBySocialMedia {
                      id
                      socialMediaType
                      postUrl
                      status
                      isPlanned
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
export class AgencyReportInfluencersQueryRequest
  implements
    QueryRequest<
      AgencyReportInfluencersQuery,
      AgencyReportInfluencersQueryVariables
    > {
  _result?: AgencyReportInfluencersQuery;
  options: QueryOptions<AgencyReportInfluencersQueryVariables>;
  constructor(variables: AgencyReportInfluencersQueryVariables) {
    this.options = {
      query: AgencyReportInfluencersDocument,
      variables
    };
  }
}
export const AgencySuggestionsDocument = gql`
  query AgencySuggestions($brandName: String!, $campaignNumber: Int!) {
    agencyAccount {
      id
      agencyName
      brand(name: $brandName) {
        id
        campaign(number: $campaignNumber) {
          id
          suggestions {
            id
            createdAt
            note
            number
            attachmentFiles {
              name
              url
            }
          }
        }
      }
    }
  }
`;
export class AgencySuggestionsQueryRequest
  implements
    QueryRequest<AgencySuggestionsQuery, AgencySuggestionsQueryVariables> {
  _result?: AgencySuggestionsQuery;
  options: QueryOptions<AgencySuggestionsQueryVariables>;
  constructor(variables: AgencySuggestionsQueryVariables) {
    this.options = {
      query: AgencySuggestionsDocument,
      variables
    };
  }
}
export const AgencyDocument = gql`
  query Agency($name: String!) {
    agency(name: $name) {
      id
      name
      ownerAgencyAccount {
        name
        email
      }
    }
  }
`;
export class AgencyQueryRequest
  implements QueryRequest<AgencyQuery, AgencyQueryVariables> {
  _result?: AgencyQuery;
  options: QueryOptions<AgencyQueryVariables>;
  constructor(variables: AgencyQueryVariables) {
    this.options = {
      query: AgencyDocument,
      variables
    };
  }
}
export const BrandDocument = gql`
  query Brand($name: String!) {
    brand(name: $name) {
      id
      name
      image
      description
    }
  }
`;
export class BrandQueryRequest
  implements QueryRequest<BrandQuery, BrandQueryVariables> {
  _result?: BrandQuery;
  options: QueryOptions<BrandQueryVariables>;
  constructor(variables: BrandQueryVariables) {
    this.options = {
      query: BrandDocument,
      variables
    };
  }
}
export const CampaignMenuDocument = gql`
  query CampaignMenu($name: String!) {
    brand(name: $name) {
      id
      campaigns {
        id
        name
        number
      }
    }
  }
`;
export class CampaignMenuQueryRequest
  implements QueryRequest<CampaignMenuQuery, CampaignMenuQueryVariables> {
  _result?: CampaignMenuQuery;
  options: QueryOptions<CampaignMenuQueryVariables>;
  constructor(variables: CampaignMenuQueryVariables) {
    this.options = {
      query: CampaignMenuDocument,
      variables
    };
  }
}
export const CampaignCreationDocument = gql`
  query CampaignCreation($brandName: String!) {
    brand(name: $brandName) {
      id
      productItems {
        id
        name
      }
    }
    affinityModels(brandName: $brandName) {
      id
      name
      number
    }
  }
`;
export class CampaignCreationQueryRequest
  implements
    QueryRequest<CampaignCreationQuery, CampaignCreationQueryVariables> {
  _result?: CampaignCreationQuery;
  options: QueryOptions<CampaignCreationQueryVariables>;
  constructor(variables: CampaignCreationQueryVariables) {
    this.options = {
      query: CampaignCreationDocument,
      variables
    };
  }
}
export const CampaignDetailDocument = gql`
  query CampaignDetail($name: String!, $number: Int!) {
    brand(name: $name) {
      id
      image
      campaign(number: $number) {
        id
        number
        name
      }
    }
  }
`;
export class CampaignDetailQueryRequest
  implements QueryRequest<CampaignDetailQuery, CampaignDetailQueryVariables> {
  _result?: CampaignDetailQuery;
  options: QueryOptions<CampaignDetailQueryVariables>;
  constructor(variables: CampaignDetailQueryVariables) {
    this.options = {
      query: CampaignDetailDocument,
      variables
    };
  }
}
export const CampaignDocument = gql`
  query Campaign($name: String!, $number: Int!) {
    brand(name: $name) {
      id
      campaign(number: $number) {
        id
        number
        campaignCode
        name
        budget
        keywordAndHashtags
        objectives
        campaignActivityType
        productItem {
          id
          name
        }
        affinityModel {
          id
          name
          number
        }
        indicator {
          id
          reachKpi
          instagramIndicator {
            id
            budget
            reachKpi
            sales
            ecClick
          }
          twitterIndicator {
            id
            budget
            reachKpi
            sales
            ecClick
          }
          youtubeIndicator {
            id
            budget
            reachKpi
            sales
            ecClick
          }
          tiktokIndicator {
            id
            budget
            reachKpi
            sales
            ecClick
          }
        }
        assignments {
          id
          number
          createdAt
          status
          agency {
            id
            name
          }
        }
        biddingClosed
        createdAt
        postStartAt
        postEndAt
        suggestionEndAt
        suggestionStartAt
        attachmentFiles {
          id
          name
          url
        }
        note
        agencies {
          id
          name
          totalSuggestionCount
          lastSuggestionAt
          suggestions {
            id
            number
            name
          }
        }
        report {
          campaignId
          agencyAssignmentAttachmentFiles {
            name
            url
          }
          agencyReportAttachmentFiles {
            name
            url
          }
        }
      }
    }
    agencies {
      id
      name
      isLocked
    }
  }
`;
export class CampaignQueryRequest
  implements QueryRequest<CampaignQuery, CampaignQueryVariables> {
  _result?: CampaignQuery;
  options: QueryOptions<CampaignQueryVariables>;
  constructor(variables: CampaignQueryVariables) {
    this.options = {
      query: CampaignDocument,
      variables
    };
  }
}
export const CampaignSideMenuDocument = gql`
  query CampaignSideMenu($name: String!, $number: Int!) {
    brand(name: $name) {
      campaign(number: $number) {
        id
        agencies {
          id
          name
          totalSuggestionCount
          lastSuggestionAt
          suggestions {
            id
            number
          }
        }
        assignments {
          number
          agency {
            id
            name
          }
        }
      }
    }
  }
`;
export class CampaignSideMenuQueryRequest
  implements
    QueryRequest<CampaignSideMenuQuery, CampaignSideMenuQueryVariables> {
  _result?: CampaignSideMenuQuery;
  options: QueryOptions<CampaignSideMenuQueryVariables>;
  constructor(variables: CampaignSideMenuQueryVariables) {
    this.options = {
      query: CampaignSideMenuDocument,
      variables
    };
  }
}
export const CampaignsDocument = gql`
  query Campaigns($name: String!) {
    brand(name: $name) {
      id
      campaigns {
        id
        number
        campaignCode
        name
        budget
        indicator {
          id
          reachKpi
          instagramIndicator {
            id
            budget
            reachKpi
          }
          twitterIndicator {
            id
            budget
            reachKpi
          }
          youtubeIndicator {
            id
            budget
            reachKpi
          }
          tiktokIndicator {
            id
            budget
            reachKpi
          }
        }
        productItem {
          id
          name
        }
      }
    }
  }
`;
export class CampaignsQueryRequest
  implements QueryRequest<CampaignsQuery, CampaignsQueryVariables> {
  _result?: CampaignsQuery;
  options: QueryOptions<CampaignsQueryVariables>;
  constructor(variables: CampaignsQueryVariables) {
    this.options = {
      query: CampaignsDocument,
      variables
    };
  }
}
export const ClientAccountUpdateDocument = gql`
  query ClientAccountUpdate($number: Int!) {
    clientAccountByNumber(number: $number) {
      id
      number
      name
      email
    }
  }
`;
export class ClientAccountUpdateQueryRequest
  implements
    QueryRequest<ClientAccountUpdateQuery, ClientAccountUpdateQueryVariables> {
  _result?: ClientAccountUpdateQuery;
  options: QueryOptions<ClientAccountUpdateQueryVariables>;
  constructor(variables: ClientAccountUpdateQueryVariables) {
    this.options = {
      query: ClientAccountUpdateDocument,
      variables
    };
  }
}
export const ClientAccountDocument = gql`
  query ClientAccount {
    clientAccount {
      id
      name
      email
      isOwner
      number
      brands {
        id
        name
        image
        divisionName
      }
    }
  }
`;
export class ClientAccountQueryRequest
  implements QueryRequest<ClientAccountQuery, ClientAccountQueryVariables> {
  _result?: ClientAccountQuery;
  options: QueryOptions<ClientAccountQueryVariables>;
  constructor(variables: ClientAccountQueryVariables) {
    this.options = {
      query: ClientAccountDocument,
      variables
    };
  }
}
export const ClientCampaignCommentDocument = gql`
  query ClientCampaignComment(
    $brandName: String!
    $campaignNumber: Int!
    $agencyName: String!
  ) {
    clientCampaignComment(
      brandName: $brandName
      campaignNumber: $campaignNumber
    ) {
      campaignId
      totalSuggestionUnreadComment
      totalAssignmentUnreadComment
      totalReportUnreadComment
      suggestionComments(agencyName: $agencyName) {
        unreadCommentCount
        comments {
          id
          account {
            ... on ClientAccount {
              __typename
              name
              number
              brand(name: $brandName) {
                name
              }
            }
            ... on AgencyAccount {
              __typename
              name
              number
              brand(name: $brandName) {
                name
              }
            }
          }
          readFlag
          sendAt
          comment
        }
        draftComment {
          id
          comment
        }
      }
      assignmentComments(agencyName: $agencyName) {
        unreadCommentCount
        comments {
          id
          account {
            ... on ClientAccount {
              __typename
              name
              number
              brand(name: $brandName) {
                name
              }
            }
            ... on AgencyAccount {
              __typename
              name
              number
              brand(name: $brandName) {
                name
              }
            }
          }
          readFlag
          sendAt
          comment
        }
        draftComment {
          id
          comment
        }
      }
      reportComments(agencyName: $agencyName) {
        unreadCommentCount
        comments {
          id
          account {
            ... on ClientAccount {
              __typename
              name
              number
              brand(name: $brandName) {
                name
              }
            }
            ... on AgencyAccount {
              __typename
              name
              number
              brand(name: $brandName) {
                name
              }
            }
          }
          readFlag
          sendAt
          comment
        }
        draftComment {
          id
          comment
        }
      }
    }
  }
`;
export class ClientCampaignCommentQueryRequest
  implements
    QueryRequest<
      ClientCampaignCommentQuery,
      ClientCampaignCommentQueryVariables
    > {
  _result?: ClientCampaignCommentQuery;
  options: QueryOptions<ClientCampaignCommentQueryVariables>;
  constructor(variables: ClientCampaignCommentQueryVariables) {
    this.options = {
      query: ClientCampaignCommentDocument,
      variables
    };
  }
}
export const ClientCampaignsFilterDropdownOptionsDocument = gql`
  query ClientCampaignsFilterDropdownOptions {
    clientCampaignsFilterDropdownOptions {
      divisionNames {
        value
        label
      }
      brandNames {
        value
        label
      }
      campaignNames {
        value
        label
      }
      agencyNames {
        value
        label
      }
      categoryNames {
        value
        label
      }
      snsNames {
        value
        label
      }
    }
  }
`;
export class ClientCampaignsFilterDropdownOptionsQueryRequest
  implements
    QueryRequest<
      ClientCampaignsFilterDropdownOptionsQuery,
      ClientCampaignsFilterDropdownOptionsQueryVariables
    > {
  _result?: ClientCampaignsFilterDropdownOptionsQuery;
  options: QueryOptions<ClientCampaignsFilterDropdownOptionsQueryVariables>;
  constructor(variables: ClientCampaignsFilterDropdownOptionsQueryVariables) {
    this.options = {
      query: ClientCampaignsFilterDropdownOptionsDocument,
      variables
    };
  }
}
export const ClientCampaignsDocument = gql`
  query ClientCampaigns(
    $divisionName: String
    $brandName: String
    $campaignName: String
    $agencyName: String
    $categoryName: String
    $snsName: String
    $postStartAt: String
    $postEndAt: String
  ) {
    clientCampaigns(
      divisionName: $divisionName
      brandName: $brandName
      campaignName: $campaignName
      agencyName: $agencyName
      categoryName: $categoryName
      snsName: $snsName
      postStartAt: $postStartAt
      postEndAt: $postEndAt
    ) {
      id
      name
      number
      brandName
      divisionName
      postStartAt
      postEndAt
      productItem {
        name
      }
      indicator {
        reachKpi
      }
    }
  }
`;
export class ClientCampaignsQueryRequest
  implements QueryRequest<ClientCampaignsQuery, ClientCampaignsQueryVariables> {
  _result?: ClientCampaignsQuery;
  options: QueryOptions<ClientCampaignsQueryVariables>;
  constructor(variables: ClientCampaignsQueryVariables) {
    this.options = {
      query: ClientCampaignsDocument,
      variables
    };
  }
}
export const ConfirmedInfluencersDocument = gql`
  query ConfirmedInfluencers(
    $name: String!
    $number: Int!
    $agencyName: String!
    $page: Int!
    $perPage: Int
    $hasAssignmentInfluencers: Boolean = true
    $orderBy: AssignmentInfluencerOrderByClause
    $influencerName: String
  ) {
    brand(name: $name) {
      id
      campaign(number: $number) {
        id
        number
        indicator {
          id
          budget
          reachKpi
          instagramIndicator {
            reachKpi
          }
          twitterIndicator {
            reachKpi
          }
          youtubeIndicator {
            reachKpi
          }
          tiktokIndicator {
            reachKpi
          }
        }
        agency(name: $agencyName) {
          id
          name
          assignment(filterBy: { isClientConfirmed: true }) {
            id
            number
            totalCost
            totalCommission
            totalInfluencerCount
            totalFollower
            totalFollow
            averageEngagementRate
            averageAffinityRate
            averageAdvocateScoreRate
            totalConsiderationInfluencerCount
            isDataCollectionCompleted
            summaries(filterBy: { isClientConfirmed: true }) {
              ... on AssignmentInstagramSummary {
                ...AssignmentInstagramSummaryFragment
              }
              ... on AssignmentTwitterSummary {
                ...AssignmentTwitterSummaryFragment
              }
              ... on AssignmentYoutubeSummary {
                ...AssignmentYoutubeSummaryFragment
              }
              ... on AssignmentTiktokSummary {
                ...AssignmentTiktokSummaryFragment
              }
              ... on AssignmentWinningSummary {
                ...AssignmentWinningSummaryFragment
              }
            }
            winningSummaries {
              ... on AssignmentInstagramSummary {
                ...AssignmentInstagramSummaryFragment
              }
              ... on AssignmentTwitterSummary {
                ...AssignmentTwitterSummaryFragment
              }
              ... on AssignmentYoutubeSummary {
                ...AssignmentYoutubeSummaryFragment
              }
              ... on AssignmentTiktokSummary {
                ...AssignmentTiktokSummaryFragment
              }
            }
            assignmentInfluencersByAgency(
              page: $page
              perPage: $perPage
              orderBy: $orderBy
              influencerName: $influencerName
              filterBy: { isClientConfirmed: true }
            ) @include(if: $hasAssignmentInfluencers) {
              paginatorInfo {
                total
                currentPage
                count
                perPage
              }
              edges {
                node {
                  assignmentInfluencer {
                    id
                    number
                    name
                    image
                    totalCost
                    totalCommission
                    totalFollowerCount
                    totalFollowCount
                    totalPostCount
                    averageIncreaseRate
                    totalLikeCount
                    totalCommentCount
                    averageEngagement
                    averageAffinityScore
                    averageAdvocateScore
                    maxViewCount
                    valueCheckStatus
                    valueCheckDate
                    insightsBySocialMedia {
                      ... on AssignmentInfluencerInsightBySocialMedia {
                        ...AssignmentInfluencerInsightBySocialMediaFragment
                      }
                    }
                    availabilityStatus
                  }
                }
              }
            }
            status
            agencyAttachmentFiles {
              name
              url
            }
            agencyAssignmentAttachmentFiles {
              name
              url
            }
            agencyReportAttachmentFiles {
              name
              url
            }
          }
        }
      }
    }
  }
  ${AssignmentInstagramSummaryFragmentFragmentDoc}
  ${AssignmentTwitterSummaryFragmentFragmentDoc}
  ${AssignmentYoutubeSummaryFragmentFragmentDoc}
  ${AssignmentTiktokSummaryFragmentFragmentDoc}
  ${AssignmentWinningSummaryFragmentFragmentDoc}
  ${AssignmentInfluencerInsightBySocialMediaFragmentFragmentDoc}
`;
export class ConfirmedInfluencersQueryRequest
  implements
    QueryRequest<
      ConfirmedInfluencersQuery,
      ConfirmedInfluencersQueryVariables
    > {
  _result?: ConfirmedInfluencersQuery;
  options: QueryOptions<ConfirmedInfluencersQueryVariables>;
  constructor(variables: ConfirmedInfluencersQueryVariables) {
    this.options = {
      query: ConfirmedInfluencersDocument,
      variables
    };
  }
}
export const InfluencerEvaluationDocument = gql`
  query InfluencerEvaluation(
    $name: String!
    $number: Int!
    $perPage: Int
    $page: Int!
    $orderBy: InfluencerEvaluationOrderByClause
    $searchBy: String
    $filterBy: EvaluationType
  ) {
    influencerSegments {
      id
      name
      maxFollowerCount
      minFollowerCount
      increaseRate
    }
    brand(name: $name) {
      id
      influencerEvaluationGroup(number: $number) {
        id
        createdAt
        updatedAt
        evaluationCount
        evaluationMatchedCount
        evaluationConsideringCount
        evaluationUnmatchedCount
        postLoadingStatus {
          status
        }
        influencerEvaluations(
          perPage: $perPage
          page: $page
          orderBy: $orderBy
          searchBy: $searchBy
          filterBy: $filterBy
        ) {
          paginatorInfo {
            total
            count
            currentPage
          }
          edges {
            node {
              influencerEvaluation {
                id
                number
                affinityRate
                evaluation
                note
                snapped
                evaluatedAt
                trainingInfluencer {
                  id
                  name
                  image
                  socialMedia {
                    instagram {
                      name
                      profileImage
                      followerCount
                      increaseRate
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
export class InfluencerEvaluationQueryRequest
  implements
    QueryRequest<
      InfluencerEvaluationQuery,
      InfluencerEvaluationQueryVariables
    > {
  _result?: InfluencerEvaluationQuery;
  options: QueryOptions<InfluencerEvaluationQueryVariables>;
  constructor(variables: InfluencerEvaluationQueryVariables) {
    this.options = {
      query: InfluencerEvaluationDocument,
      variables
    };
  }
}
export const InfluencerEvaluationGroupsDocument = gql`
  query InfluencerEvaluationGroups($name: String!, $perPage: Int, $page: Int!) {
    brand(name: $name) {
      id
      influencerEvaluationGroups(perPage: $perPage, page: $page) {
        paginatorInfo {
          total
          count
          currentPage
        }
        edges {
          node {
            influencerEvaluationGroup {
              id
              createdAt
              updatedAt
              number
              evaluationCount
              evaluationMatchedCount
              evaluationConsideringCount
              evaluationUnmatchedCount
              title
              snapInfluencerEvaluationGroups(perPage: $perPage, page: $page) {
                edges {
                  node {
                    snapInfluencerEvaluationGroup {
                      id
                      number
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
export class InfluencerEvaluationGroupsQueryRequest
  implements
    QueryRequest<
      InfluencerEvaluationGroupsQuery,
      InfluencerEvaluationGroupsQueryVariables
    > {
  _result?: InfluencerEvaluationGroupsQuery;
  options: QueryOptions<InfluencerEvaluationGroupsQueryVariables>;
  constructor(variables: InfluencerEvaluationGroupsQueryVariables) {
    this.options = {
      query: InfluencerEvaluationGroupsDocument,
      variables
    };
  }
}
export const InfluencerPostsDocument = gql`
  query InfluencerPosts($name: String!, $number: Int!, $evalNum: Int!) {
    brand(name: $name) {
      id
      influencerEvaluationGroup(number: $number) {
        id
        influencerEvaluation(number: $evalNum) {
          id
          trainingInfluencer {
            id
            name
            affinityPosts {
              id
              thumbnailUrl
              url
            }
            resentPosts {
              id
              thumbnailUrl
              url
            }
            likedPosts {
              id
              thumbnailUrl
              url
            }
          }
        }
      }
    }
  }
`;
export class InfluencerPostsQueryRequest
  implements QueryRequest<InfluencerPostsQuery, InfluencerPostsQueryVariables> {
  _result?: InfluencerPostsQuery;
  options: QueryOptions<InfluencerPostsQueryVariables>;
  constructor(variables: InfluencerPostsQueryVariables) {
    this.options = {
      query: InfluencerPostsDocument,
      variables
    };
  }
}
export const InfluencerSegmentsDocument = gql`
  query InfluencerSegments {
    influencerSegments {
      id
      name
      maxFollowerCount
      minFollowerCount
      increaseRate
    }
  }
`;
export class InfluencerSegmentsQueryRequest
  implements
    QueryRequest<InfluencerSegmentsQuery, InfluencerSegmentsQueryVariables> {
  _result?: InfluencerSegmentsQuery;
  options: QueryOptions<InfluencerSegmentsQueryVariables>;
  constructor(variables: InfluencerSegmentsQueryVariables) {
    this.options = {
      query: InfluencerSegmentsDocument,
      variables
    };
  }
}
export const AdditionalOrderDocument = gql`
  query AdditionalOrder(
    $name: String!
    $number: Int!
    $agencyName: String!
    $suggestionNumber: Int!
    $page: Int!
    $perPage: Int
    $orderBy: SuggestionInfluencerOrderByClause
    $isAdditionalConsideration: Boolean
    $influencerName: String
  ) {
    brand(name: $name) {
      id
      campaign(number: $number) {
        id
        name
        agencies {
          name
        }
        agency(name: $agencyName) {
          id
          name
          assignment {
            id
          }
          suggestions(additionalSuggestionOnly: true) {
            number
          }
          suggestion(
            number: $suggestionNumber
            excludeAssignment: true
            additionalConsiderationOnly: true
          ) {
            id
            number
            note
            totalCost
            totalConsiderationInfluencerCount
            totalInfluencerCount
            suggestionInfluencers(
              page: $page
              perPage: $perPage
              excludeAssignment: true
              orderBy: $orderBy
              isAdditionalConsideration: $isAdditionalConsideration
              influencerName: $influencerName
            ) {
              paginatorInfo {
                total
                currentPage
                count
                perPage
              }
              edges {
                node {
                  suggestionInfluencer {
                    ...SuggestionInfluencerFragment
                  }
                }
              }
            }
            attachmentFiles {
              name
              url
            }
          }
        }
      }
    }
  }
  ${SuggestionInfluencerFragmentFragmentDoc}
`;
export class AdditionalOrderQueryRequest
  implements QueryRequest<AdditionalOrderQuery, AdditionalOrderQueryVariables> {
  _result?: AdditionalOrderQuery;
  options: QueryOptions<AdditionalOrderQueryVariables>;
  constructor(variables: AdditionalOrderQueryVariables) {
    this.options = {
      query: AdditionalOrderDocument,
      variables
    };
  }
}
export const AdditionalProposalListDocument = gql`
  query AdditionalProposalList(
    $name: String!
    $number: Int!
    $agencyName: String!
  ) {
    brand(name: $name) {
      id
      campaign(number: $number) {
        id
        agency(name: $agencyName) {
          id
          suggestions(additionalSuggestionOnly: true) {
            name
            number
          }
        }
      }
    }
  }
`;
export class AdditionalProposalListQueryRequest
  implements
    QueryRequest<
      AdditionalProposalListQuery,
      AdditionalProposalListQueryVariables
    > {
  _result?: AdditionalProposalListQuery;
  options: QueryOptions<AdditionalProposalListQueryVariables>;
  constructor(variables: AdditionalProposalListQueryVariables) {
    this.options = {
      query: AdditionalProposalListDocument,
      variables
    };
  }
}
export const CurrentTotalDocument = gql`
  query currentTotal(
    $name: String!
    $number: Int!
    $agencyName: String!
    $page: Int!
    $perPage: Int
    $hasAssignmentInfluencers: Boolean = false
    $includeAdditionalConsiderationSummary: Boolean = true
    $orderBy: AssignmentInfluencerOrderByClause
    $filterBy: AssignmentInfluencerFilter = { considerationStatus: CONSIDERED }
    $suggestionNumber: Int
    $influencerName: String
  ) {
    brand(name: $name) {
      id
      campaign(number: $number) {
        id
        number
        indicator {
          id
          budget
          reachKpi
          instagramIndicator {
            reachKpi
          }
          twitterIndicator {
            reachKpi
          }
          youtubeIndicator {
            reachKpi
          }
          tiktokIndicator {
            reachKpi
          }
        }
        agency(name: $agencyName) {
          id
          name
          assignment(
            includeAdditionalConsiderationSummary: $includeAdditionalConsiderationSummary
            filterBy: $filterBy
            suggestionNumber: $suggestionNumber
          ) {
            ... on ClientAssignment {
              ...ClientAssignmentFragment
            }
          }
        }
      }
    }
  }
  ${ClientAssignmentFragmentFragmentDoc}
`;
export class CurrentTotalQueryRequest
  implements QueryRequest<CurrentTotalQuery, CurrentTotalQueryVariables> {
  _result?: CurrentTotalQuery;
  options: QueryOptions<CurrentTotalQueryVariables>;
  constructor(variables: CurrentTotalQueryVariables) {
    this.options = {
      query: CurrentTotalDocument,
      variables
    };
  }
}
export const InfluencersDocument = gql`
  query Influencers(
    $name: String!
    $number: Int!
    $agencyName: String!
    $page: Int!
    $perPage: Int
    $hasAssignmentInfluencers: Boolean = true
    $includeAdditionalConsiderationSummary: Boolean = false
    $orderBy: AssignmentInfluencerOrderByClause
    $filterBy: AssignmentInfluencerFilter
    $suggestionNumber: Int
    $influencerName: String
  ) {
    brand(name: $name) {
      id
      campaign(number: $number) {
        id
        number
        indicator {
          id
          budget
          reachKpi
          instagramIndicator {
            reachKpi
          }
          twitterIndicator {
            reachKpi
          }
          youtubeIndicator {
            reachKpi
          }
          tiktokIndicator {
            reachKpi
          }
        }
        agency(name: $agencyName) {
          id
          name
          assignment(
            includeAdditionalConsiderationSummary: $includeAdditionalConsiderationSummary
            filterBy: $filterBy
          ) {
            ... on ClientAssignment {
              ...ClientAssignmentFragment
            }
          }
        }
      }
    }
  }
  ${ClientAssignmentFragmentFragmentDoc}
`;
export class InfluencersQueryRequest
  implements QueryRequest<InfluencersQuery, InfluencersQueryVariables> {
  _result?: InfluencersQuery;
  options: QueryOptions<InfluencersQueryVariables>;
  constructor(variables: InfluencersQueryVariables) {
    this.options = {
      query: InfluencersDocument,
      variables
    };
  }
}
export const OrdersDocument = gql`
  query Orders($name: String!, $number: Int!) {
    brand(name: $name) {
      id
      campaign(number: $number) {
        id
        name
        assignments {
          number
          createdAt
          agency {
            id
            name
          }
        }
      }
    }
  }
`;
export class OrdersQueryRequest
  implements QueryRequest<OrdersQuery, OrdersQueryVariables> {
  _result?: OrdersQuery;
  options: QueryOptions<OrdersQueryVariables>;
  constructor(variables: OrdersQueryVariables) {
    this.options = {
      query: OrdersDocument,
      variables
    };
  }
}
export const ProposalDocument = gql`
  query Proposal(
    $name: String!
    $number: Int!
    $agencyName: String!
    $suggestionNumber: Int!
    $page: Int!
    $perPage: Int!
    $orderBy: SuggestionInfluencerOrderByClause
    $searchBy: String
  ) {
    brand(name: $name) {
      id
      campaign(number: $number) {
        id
        name
        indicator {
          id
          budget
          reachKpi
          instagramIndicator {
            reachKpi
          }
          twitterIndicator {
            reachKpi
          }
          youtubeIndicator {
            reachKpi
          }
          tiktokIndicator {
            reachKpi
          }
        }
        agencies {
          id
          name
        }
        agency(name: $agencyName) {
          id
          name
          suggestions {
            name
            number
          }
          suggestion(number: $suggestionNumber) {
            id
            name
            note
            attachmentFiles {
              name
              url
            }
            number
            isDataCollectionCompleted
            totalCost
            totalCommission
            totalInfluencerCount
            totalFollower
            totalFollow
            averageEngagementRate
            averageAffinityRate
            averageAdvocateScoreRate
            totalConsiderationInfluencerCount
            summaries {
              ... on SuggestionInstagramSummary {
                ...SuggestionInstagramSummaryFragment
              }
              ... on SuggestionTwitterSummary {
                ...SuggestionTwitterSummaryFragment
              }
              ... on SuggestionYoutubeSummary {
                ...SuggestionYoutubeSummaryFragment
              }
              ... on SuggestionTiktokSummary {
                ...SuggestionTiktokSummaryFragment
              }
            }
            suggestionInfluencers(
              page: $page
              perPage: $perPage
              orderBy: $orderBy
              searchBy: $searchBy
            ) {
              paginatorInfo {
                total
                currentPage
                count
                perPage
              }
              edges {
                node {
                  suggestionInfluencer {
                    ...SuggestionInfluencerFragment
                  }
                }
              }
            }
            isOrdered
          }
          assignment {
            id
            totalCost
            agencyAttachmentFiles {
              name
              url
            }
            agencyAssignmentAttachmentFiles {
              name
              url
            }
            agencyReportAttachmentFiles {
              name
              url
            }
          }
        }
      }
    }
  }
  ${SuggestionInstagramSummaryFragmentFragmentDoc}
  ${SuggestionTwitterSummaryFragmentFragmentDoc}
  ${SuggestionYoutubeSummaryFragmentFragmentDoc}
  ${SuggestionTiktokSummaryFragmentFragmentDoc}
  ${SuggestionInfluencerFragmentFragmentDoc}
`;
export class ProposalQueryRequest
  implements QueryRequest<ProposalQuery, ProposalQueryVariables> {
  _result?: ProposalQuery;
  options: QueryOptions<ProposalQueryVariables>;
  constructor(variables: ProposalQueryVariables) {
    this.options = {
      query: ProposalDocument,
      variables
    };
  }
}
export const ProposalsDocument = gql`
  query Proposals($name: String!, $number: Int!, $agencyName: String!) {
    brand(name: $name) {
      id
      campaign(number: $number) {
        id
        name
        agency(name: $agencyName) {
          id
          name
          suggestions {
            id
            number
            totalCost
            totalInfluencerCount
            createdAt
          }
        }
      }
    }
  }
`;
export class ProposalsQueryRequest
  implements QueryRequest<ProposalsQuery, ProposalsQueryVariables> {
  _result?: ProposalsQuery;
  options: QueryOptions<ProposalsQueryVariables>;
  constructor(variables: ProposalsQueryVariables) {
    this.options = {
      query: ProposalsDocument,
      variables
    };
  }
}
export const ReportDocument = gql`
  query Report(
    $name: String!
    $number: Int!
    $perPage: Int
    $page: Int!
    $orderBy: ReportInfluencerOrderByClause
  ) {
    brand(name: $name) {
      id
      campaign(number: $number) {
        id
        indicator {
          id
          budget
          reachKpi
          instagramIndicator {
            reachKpi
          }
          twitterIndicator {
            reachKpi
          }
          youtubeIndicator {
            reachKpi
          }
          tiktokIndicator {
            reachKpi
          }
        }
        report {
          totalCost
          totalCommission
          totalInfluencerCount
          totalFollower
          totalFollow
          averageEngagementRate
          averageAffinityRate
          averageAdvocateScoreRate
          isDataCollectionCompleted
          totalReachCount
          totalImpressionCount
          totalViewCount
          totalLikeCount
          totalCommentCount
          totalShareCount
          averageRetentionRate
          totalClippingCount
          totalEcClickCount
          totalSalesValue
          costReachRate
          costImpressionRate
          costViewRate
          costEngagementRate
          costClippingRate
          costEcClickRate
          costSalesRate
          awarenessScore
          interestScore
          considerationScore
          conversionScore
          agencyNote
          agencyAttachmentFiles {
            name
            url
          }
          agencyAssignmentAttachmentFiles {
            name
            url
          }
          agencyReportAttachmentFiles {
            name
            url
          }
          summaries {
            ... on ReportInstagramSummary {
              ...ReportInstagramSummaryFragment
            }
            ... on ReportTwitterSummary {
              ...ReportTwitterSummaryFragment
            }
            ... on ReportYoutubeSummary {
              ...ReportYoutubeSummaryFragment
            }
            ... on ReportTiktokSummary {
              ...ReportTiktokSummaryFragment
            }
            ... on ReportPlannedTotalSummary {
              ...ReportPlannedTotalSummaryFragment
            }
          }
          winningSummaries {
            ... on AssignmentInstagramSummary {
              ...AssignmentInstagramSummaryFragment
            }
            ... on AssignmentTwitterSummary {
              ...AssignmentTwitterSummaryFragment
            }
            ... on AssignmentYoutubeSummary {
              ...AssignmentYoutubeSummaryFragment
            }
            ... on AssignmentTiktokSummary {
              ...AssignmentTiktokSummaryFragment
            }
          }
          influencers(perPage: $perPage, page: $page, orderBy: $orderBy) {
            paginatorInfo {
              total
              currentPage
              count
              perPage
            }
            edges {
              node {
                reportInfluencer {
                  agency {
                    id
                    name
                  }
                  id
                  name
                  image
                  totalCost
                  totalCommission
                  totalInfluencerCost
                  totalCostUsageRight
                  totalProductSendOutCost
                  averageAffinityScore
                  averageAdvocateScore
                  totalFollowerCount
                  totalFollowCount
                  totalPostCount
                  averageIncreaseRate
                  maxViewCount
                  totalLikeCount
                  totalCommentCount
                  totalShareCount
                  totalViewCount
                  averageEngagement
                  averageReachCount
                  averageImpressionCount
                  averageViewCount
                  averageLikeCount
                  averageShareCount
                  averageCommentCount
                  averageRetentionRate
                  averageClippingCount
                  totalSalesValue
                  costReachRate
                  costImpressionRate
                  costViewRate
                  costEngagementRate
                  costClippingRate
                  costSalesRate
                  valueCheckStatus
                  valueCheckDate
                  checkAccountStatus
                  awarenessScore
                  interestScore
                  considerationScore
                  conversionScore
                  insightsBySocialMedia {
                    id
                    socialMediaType
                    isDataCollectionCompleted
                    accountId
                    accountUrl
                    cost
                    commission
                    influencerCost
                    costUsageRight
                    productSendOutCost
                    affinityRate
                    advocateScore
                    status
                    isPlanned
                    postUrl
                    affinityUpperThreshold
                    affinityLowerThreshold
                    socialMediaPostInsight {
                      ... on InstagramPostInsight {
                        followerCount
                        followerIncreaseRate
                        averageCommentCount
                        averageLikeCount
                        increaseRate
                        likeCount
                        commentCount
                        engagement
                        reachCount
                        impressionCount
                        clippingCount
                        costReachRate
                        costImpressionRate
                        costEngagementRate
                        costClippingRate
                        awarenessScore
                        interestScore
                        considerationScore
                        conversionScore
                      }
                      ... on TwitterPostInsight {
                        followerCount
                        followerIncreaseRate
                        averageCommentCount
                        averageLikeCount
                        averageShareCount
                        increaseRate
                        likeCount
                        retweetCount
                        engagement
                        reachCount
                        impressionCount
                        shareCount
                        clippingCount
                        costReachRate
                        costImpressionRate
                        costEngagementRate
                        costClippingRate
                        awarenessScore
                        interestScore
                        considerationScore
                        conversionScore
                      }
                      ... on YoutubePostInsight {
                        followerCount
                        followerIncreaseRate
                        maxViewCount
                        averageCommentCount
                        averageLikeCount
                        averageViewCount
                        increaseRate
                        likeCount
                        commentCount
                        engagement
                        viewCount
                        retentionRate
                        clippingCount
                        costViewRate
                        costEngagementRate
                        costClippingRate
                        awarenessScore
                        interestScore
                        considerationScore
                        conversionScore
                      }
                      ... on TiktokPostInsight {
                        followerCount
                        followerIncreaseRate
                        maxViewCount
                        averageCommentCount
                        averageLikeCount
                        averageShareCount
                        averageViewCount
                        increaseRate
                        likeCount
                        commentCount
                        shareCount
                        engagement
                        viewCount
                        retentionRate
                        clippingCount
                        costViewRate
                        costEngagementRate
                        costClippingRate
                        awarenessScore
                        interestScore
                        considerationScore
                        conversionScore
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  ${ReportInstagramSummaryFragmentFragmentDoc}
  ${ReportTwitterSummaryFragmentFragmentDoc}
  ${ReportYoutubeSummaryFragmentFragmentDoc}
  ${ReportTiktokSummaryFragmentFragmentDoc}
  ${ReportPlannedTotalSummaryFragmentFragmentDoc}
  ${AssignmentInstagramSummaryFragmentFragmentDoc}
  ${AssignmentTwitterSummaryFragmentFragmentDoc}
  ${AssignmentYoutubeSummaryFragmentFragmentDoc}
  ${AssignmentTiktokSummaryFragmentFragmentDoc}
`;
export class ReportQueryRequest
  implements QueryRequest<ReportQuery, ReportQueryVariables> {
  _result?: ReportQuery;
  options: QueryOptions<ReportQueryVariables>;
  constructor(variables: ReportQueryVariables) {
    this.options = {
      query: ReportDocument,
      variables
    };
  }
}
export const SnapshotDocument = gql`
  query Snapshot(
    $name: String!
    $number: Int!
    $snapNum: Int!
    $perPage: Int
    $page: Int!
  ) {
    influencerSegments {
      id
      name
      maxFollowerCount
      minFollowerCount
      increaseRate
    }
    brand(name: $name) {
      id
      influencerEvaluationGroup(number: $number) {
        id
        number
        snapInfluencerEvaluationGroup(number: $snapNum) {
          id
          number
          snapInfluencerEvaluations(page: $page, perPage: $perPage) {
            paginatorInfo {
              total
              count
              currentPage
            }
            edges {
              node {
                id
                snapInfluencerEvaluation {
                  id
                  number
                  affinityRate
                  note
                  evaluation
                  evaluatedAt
                  trainingInfluencer {
                    id
                    name
                    image
                    socialMedia {
                      instagram {
                        id
                        followerCount
                        increaseRate
                      }
                    }
                    codes
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
export class SnapshotQueryRequest
  implements QueryRequest<SnapshotQuery, SnapshotQueryVariables> {
  _result?: SnapshotQuery;
  options: QueryOptions<SnapshotQueryVariables>;
  constructor(variables: SnapshotQueryVariables) {
    this.options = {
      query: SnapshotDocument,
      variables
    };
  }
}
export const SnapshotsDocument = gql`
  query Snapshots($name: String!, $number: Int!, $perPage: Int, $page: Int!) {
    brand(name: $name) {
      id
      influencerEvaluationGroup(number: $number) {
        id
        snapInfluencerEvaluationGroups(perPage: $perPage, page: $page) {
          paginatorInfo {
            total
            count
            currentPage
          }
          edges {
            node {
              snapInfluencerEvaluationGroup {
                title
                description
                id
                number
                evaluationCount
                evaluationMatchedCount
                evaluationUnmatchedCount
                evaluationConsideringCount
                createdAt
                updatedAt
              }
            }
          }
        }
      }
    }
  }
`;
export class SnapshotsQueryRequest
  implements QueryRequest<SnapshotsQuery, SnapshotsQueryVariables> {
  _result?: SnapshotsQuery;
  options: QueryOptions<SnapshotsQueryVariables>;
  constructor(variables: SnapshotsQueryVariables) {
    this.options = {
      query: SnapshotsDocument,
      variables
    };
  }
}
