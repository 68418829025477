









import Vue from "vue";
import ErrorDialog from "~/components/the/error-dialog.vue";
import AppLoading from "~/components/the/app-loading.vue";
import PasswordExpiredErrorDialog from "~/components/the/password-expired-error-dialog.vue";

export default Vue.extend({
  components: {
    ErrorDialog,
    AppLoading,
    PasswordExpiredErrorDialog
  }
});
