import { actionTree, mutationTree } from "nuxt-typed-vuex";
import {
  UpdateAgencyAccountMutationRequest,
  UpdateAgencyAccountMutationVariables,
  AgencyAccountUpdateQueryRequest,
  AgencyAccountUpdateQueryVariables,
  AgencyAccount
} from "~/types/gen/api";
import { resetState } from "~/store/utils";
import { Message } from "element-ui";

type AgencyAccountState = {
  agencyAccount: Pick<AgencyAccount, "id" | "number" | "email" | "name">;
};

export const state = (): AgencyAccountState => ({
  agencyAccount: {
    id: "",
    number: 0,
    email: "",
    name: ""
  }
});

export const mutations = mutationTree(state, {
  recieveAgencyAccount(state, payload) {
    state.agencyAccount = payload;
  },
  reset: resetState(state)
});

export const actions = actionTree(
  { state },
  {
    async getAgencyAccount(
      context,
      payload: AgencyAccountUpdateQueryVariables
    ) {
      const req = new AgencyAccountUpdateQueryRequest(payload);
      const res = await this.$apiClient.query(req);
      if (res) {
        context.commit("recieveAgencyAccount", res.agencyAccountByNumber);
      }
    },
    async updateAgencyAccount(
      _context,
      payload: UpdateAgencyAccountMutationVariables
    ): Promise<void> {
      try {
        this.$accessor.presentation.showLoading(null);
        const req = new UpdateAgencyAccountMutationRequest(payload);
        const res = await this.$apiClient.mutate(req);
        if (res) {
          this.$router.replace(`/agency/accounts`);
          Message(
            this.$i18n.tc("page.agent_account_edit.message.success_edit")
          );
        }
      } catch (e) {
        this.$accessor.error.showError(e);
      } finally {
        // TODO loadingのPR取り込まれ待ち
        this.$accessor.presentation.dismissLoading();
      }
    }
  }
);
