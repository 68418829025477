import {
  Maybe,
  ClientBrand,
  ClientCampaign,
  ClientAssignment,
  CampaignAgency,
  Suggestion,
  Connection,
  SuggestionInfluencerConnection,
  SummaryInfluencerScore,
  AssignmentInfluencerConnection,
  AssignmentStatus,
  ClientReport,
  ReportInfluencerConnection
} from "~/types/gen/api";

export default class BrandResponse {
  private brand: ClientBrand | null;

  constructor(brand: ClientBrand | null) {
    this.brand = brand;
  }

  getListState(list: Connection | null | undefined): Connection {
    return {
      paginatorInfo: list ? list.paginatorInfo : null,
      edges: list ? list.edges : null
    };
  }

  get campaign(): ClientCampaign | null | undefined {
    return this.brand ? this.brand.campaign : null;
  }

  get campaignAssignments(): Maybe<ClientAssignment>[] | null | undefined {
    return this.campaign ? this.campaign.assignments : null;
  }

  get agency(): CampaignAgency | null | undefined {
    return this.campaign ? this.campaign.agency : null;
  }

  get agencies(): Maybe<Array<Maybe<CampaignAgency>>> | null | undefined {
    return this.campaign ? this.campaign.agencies : null;
  }

  get agencySuggestion(): Suggestion | null | undefined {
    return this.agency ? this.agency.suggestion : null;
  }

  get agencySuggestions(): Maybe<Array<Maybe<Suggestion>>> | null | undefined {
    return this.agency ? this.agency.suggestions : null;
  }

  get agencySuggestionSummaries(): SummaryInfluencerScore[] | null {
    return this.agencySuggestion ? this.agencySuggestion.summaries : null;
  }

  get agencySuggestionInfluencers():
    | SuggestionInfluencerConnection
    | null
    | undefined {
    if (this.agencySuggestion) {
      const { suggestionInfluencers } = this.agencySuggestion;
      return suggestionInfluencers ? suggestionInfluencers : null;
    } else {
      return null;
    }
  }

  get agencyAssignment(): Maybe<ClientAssignment> | null | undefined {
    return this.agency ? this.agency.assignment : null;
  }

  get agencyAssignmentSummaries(): SummaryInfluencerScore[] | null {
    return this.agencyAssignment ? this.agencyAssignment.summaries : null;
  }

  get agencyAssignmentWinningSummaries(): SummaryInfluencerScore[] | null {
    return this.agencyAssignment
      ? this.agencyAssignment.winningSummaries
      : null;
  }

  get agencyStatus(): AssignmentStatus | null {
    return this.agencyAssignment ? this.agencyAssignment.status : null;
  }

  get agencyAssignmentInfluencers():
    | AssignmentInfluencerConnection
    | null
    | undefined {
    if (this.agencyAssignment) {
      const { assignmentInfluencersByAgency } = this.agencyAssignment;
      return assignmentInfluencersByAgency
        ? assignmentInfluencersByAgency
        : null;
    } else {
      return null;
    }
  }

  get report(): Maybe<ClientReport> | null | undefined {
    return this.campaign ? this.campaign.report : null;
  }

  get reportInfluencers():
    | Maybe<ReportInfluencerConnection>
    | null
    | undefined {
    return this.report ? this.report.influencers : null;
  }

  get reportSummaries(): SummaryInfluencerScore[] | null {
    return this.report ? this.report.summaries : null;
  }

  get reportWinningSummaries(): SummaryInfluencerScore[] | null {
    return this.report ? this.report.winningSummaries : null;
  }
}
