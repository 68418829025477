import { ApolloError } from "apollo-client";
import { GraphQLError } from "graphql";
import { APIErrorExtensions } from "./types";
// import { i18n } from "../plugins/i18n/index";

export interface APIErrorObject extends Error {
  name: string;
  status: number;
  message: string;
  debugMessage?: string;
}

export class APIPasswordExpiredError implements APIErrorObject {
  name: string = "APIPasswordExpiredError";

  constructor(
    public status: number,
    public message: string,
    public debugMessage?: string
  ) {}
}

/*
 * APIError
 * normalize error response from api
 */
export class APIError implements APIErrorObject {
  name: string = "APIError";

  constructor(
    public status: number,
    public message: string,
    public debugMessage?: string
  ) {}

  static fromGraphQLErrors(errors: GraphQLError[]) {
    // Has information
    if (errors.length > 0 && errors[0].extensions) {
      const extensions = errors[0].extensions! as APIErrorExtensions;
      const status = extensions.code || 500;
      const debugMessage = errors[0].debugMessage;

      if (extensions.category) {
        switch (extensions.category) {
          case "custom":
            return this.getProperError(errors);
          case "validation":
            const message = Object.keys(extensions.validation)
              .map(key => extensions.validation[key])
              .reduce((acc, error) => {
                return [...acc, ...error];
              }, [])
              .join("\n");
            return new APIError(status, message, debugMessage);
        }
      }

      if (status) {
        switch (status) {
          case 401:
            return new APIError(
              status,
              //TODO: i18n.t("common.error.status_code.401") as string,
              "認証に失敗しました",
              debugMessage
            );
          default:
            // Error message is not user fiendly
            return new APIError(
              status,
              //TODO: i18n.t("common.error.status_code.500") as string,
              "原因不明のエラーが発生しました",
              debugMessage
            );
        }
      }
    }

    // No information
    return new APIError(500, "", "Unknown Error");
  }

  static fromApolloError(error: ApolloError): APIError {
    // FIXME:
    // error.networkError has { result: GraphQLErrors[]; }
    // but typings is not
    const networkError: any | undefined = error.networkError;
    if (networkError && networkError.result && networkError.result.errors) {
      return <APIError>this.fromGraphQLErrors(networkError.result.errors);
    }
    if (error.graphQLErrors) {
      return <APIError>(
        this.fromGraphQLErrors(error.graphQLErrors as GraphQLError[])
      );
    }
    return new APIError(500, "Unknown error");
  }

  static getProperError(errors: GraphQLError[]) {
    const extensions = errors[0].extensions! as APIErrorExtensions;
    const status = extensions.code || 500;
    const debugMessage = errors[0].debugMessage;
    const message = errors[0].message;

    if (message === "パスワードの有効期限が切れてます。" && status === 401) {
      return new APIPasswordExpiredError(status, message, debugMessage);
    }

    return new APIError(status, message, debugMessage);
  }

  public get asObject(): APIError.AsObject {
    return {
      name: this.name,
      status: this.status,
      message: this.message,
      debugMessage: this.debugMessage
    };
  }
}

export namespace APIError {
  export interface AsObject extends Error {
    name: string;
    status: number;
    message: string;
    debugMessage?: string;
  }
}

export namespace APIPasswordExpiredError {
  export interface AsObject extends Error {
    name: string;
    status: number;
    message: string;
    debugMessage?: string;
  }
}
