import { actionTree, mutationTree, getterTree } from "nuxt-typed-vuex";
import {
  ClientCampaign,
  PaginatorInfo,
  ProposalQueryRequest,
  ProposalQueryVariables,
  AssignInfluencersToCampaignMutationRequest,
  AssignInfluencersToCampaignMutationVariables,
  SuggestionInfluencer,
  ClientBrand,
  SuggestionInfluencerEdge,
  SummaryInfluencerScore,
  SummaryConsiderationInfluencer,
  CampaignIndicator,
  DownloadSuggestionInfluencersMutationRequest,
  DownloadSuggestionInfluencersMutationVariables,
  Suggestion,
  ToggleInfluencersConsiderationMutationVariables,
  ToggleInfluencersConsiderationMutationRequest
} from "~/types/gen/api";
import { downloadFileByURL } from "~/utils/downloadFileByURL";
import { resetState, convertSummaryInfluencerScore } from "~/store/utils";
import BrandResponse from "~/store/responses/brandResponse";

export type proposalState = {
  campaign: ClientCampaign | null;
  suggestionInfluencers: SuggestionInfluencer[] | null;
  pager: Pick<PaginatorInfo, "total" | "count" | "currentPage" | "perPage">;
  totalInfluencerNum: number;
  totalCost: number;
  campaignIndicator: CampaignIndicator | null;
  summaryInfluencerScore: SummaryInfluencerScore &
    SummaryConsiderationInfluencer;
  currentTotal: SummaryInfluencerScore;
  summaries: SummaryInfluencerScore[];
  winningSummaries: SummaryInfluencerScore[];
  suggestion: Suggestion | null;
};

export const state = (): proposalState => ({
  campaign: null,
  suggestionInfluencers: null,
  pager: {
    total: 0,
    count: 0,
    currentPage: 1,
    perPage: 10
  },
  totalInfluencerNum: 0,
  totalCost: 0,
  campaignIndicator: null,
  summaryInfluencerScore: {
    totalCost: 0,
    totalCommission: 0,
    totalInfluencerCount: 0,
    totalFollower: 0,
    totalFollow: 0,
    averageEngagementRate: 0,
    averageAffinityRate: 0,
    totalConsiderationInfluencerCount: 0
  },
  currentTotal: {
    totalCost: 0,
    totalCommission: 0,
    totalInfluencerCount: 0,
    totalFollower: 0,
    totalFollow: 0,
    averageEngagementRate: 0,
    averageAffinityRate: 0
  },
  summaries: [],
  winningSummaries: [],
  suggestion: null
});

export const mutations = mutationTree(state, {
  receiveCampaignIndicator(state, payload) {
    state.campaignIndicator = payload;
  },
  receiveCampaign(state, payload) {
    state.campaign = payload;
    state.totalInfluencerNum =
      payload.agency.suggestion &&
      payload.agency.suggestion.totalConsiderationInfluencerCount
        ? payload.agency.suggestion.totalConsiderationInfluencerCount
        : 0;
    state.totalCost =
      payload.agency.suggestion && payload.agency.suggestion.totalCost
        ? payload.agency.suggestion.totalCost
        : 0;
  },
  receiveSuggestionInfluencers(state, payload) {
    if (payload) {
      state.suggestionInfluencers = payload.map(
        (v: SuggestionInfluencerEdge) => v.node!.suggestionInfluencer
      );
    }
  },
  receivePaginatorInfo(state, payload) {
    state.pager = payload;
  },
  receiveSummaries(state, payload) {
    state.summaries = payload;
  },
  receiveSuggestionInfluencer(state, payload) {
    const { suggestionInfluencer } = payload.suggestionInfluencer;
    if (state.suggestionInfluencers) {
      state.suggestionInfluencers.splice(
        payload.index,
        1,
        suggestionInfluencer
      );
    }
  },
  receiveCurrentTotal(state, payload) {
    if (payload) {
      state.currentTotal = convertSummaryInfluencerScore(payload);
    }
  },
  receiveSummaryInfluencerScore(state, payload) {
    if (payload) {
      // totalUnevaluatedInfluencerCountを利用する
      let summaryScore = convertSummaryInfluencerScore(payload);
      // 現状の仕組みがsummaryScore = InitialPlanになっているため、部分的にリセット
      summaryScore.totalCost = 0;
      summaryScore.totalFollower = null;
      summaryScore.averageEngagementRate = null;
      summaryScore.averageAffinityRate = null;
      summaryScore.averageAdvocateScoreRate = null;
      state.summaryInfluencerScore = summaryScore;
    }
  },
  receiveSuggestion(state, payload) {
    state.suggestion = payload;
  },
  changeAllSuggestionInfluencersConsiderationStatus(state, payload) {
    if (state.suggestionInfluencers) {
      state.suggestionInfluencers.forEach((v: SuggestionInfluencer) => {
        v.isConsideration = payload.isConsideration;
      });
    }
  },
  receiveOrderInfo(state, payload) {
    state.totalInfluencerNum =
      payload.suggestion && payload.suggestion.totalConsiderationInfluencerCount
        ? payload.suggestion.totalConsiderationInfluencerCount
        : 0;
    state.totalCost =
      payload.suggestion && payload.suggestion.totalCost
        ? payload.suggestion.totalCost
        : 0;
  },
  changeSuggestionInfluencerConsiderationStatus(
    state,
    payload: {
      index: number;
      isConsideration: boolean;
    }
  ) {
    if (state.suggestionInfluencers) {
      state.suggestionInfluencers[payload.index].isConsideration =
        payload.isConsideration;
    }
  },
  reset: resetState(state)
});

export const getters = getterTree(state, {
  agencyName(state) {
    return (
      state.campaign && state.campaign.agency && state.campaign.agency.name
    );
  },
  campaignName(state) {
    return state.campaign && state.campaign.name;
  },
  agencies(state) {
    return state.campaign && state.campaign.agencies;
  },
  suggestions(state) {
    return (
      state.campaign &&
      state.campaign.agency &&
      state.campaign.agency.suggestions!.map((v: any) => {
        return {
          label: v.name,
          value: v.number
        };
      })
    );
  }
});

export const actions = actionTree(
  { state },
  {
    async init(context, payload: ProposalQueryVariables) {
      try {
        this.$accessor.presentation.showLoading(null);
        const req = new ProposalQueryRequest(payload);
        const res = await this.$apiClient.query(req);
        const brandRes = new BrandResponse(res.brand as ClientBrand);
        const { getListState } = brandRes;
        if (res) {
          const { edges, paginatorInfo } = getListState(
            brandRes.agencySuggestionInfluencers
          );
          context.commit("receiveCampaign", brandRes.campaign);
          context.commit("receiveSuggestionInfluencers", edges);
          context.commit("receivePaginatorInfo", paginatorInfo);
          context.commit(
            "receiveSummaries",
            brandRes.agencySuggestionSummaries
          );
          context.commit("receiveCurrentTotal", brandRes.agencySuggestion);
          context.commit(
            "receiveSummaryInfluencerScore",
            brandRes.agencySuggestion
          );
          context.commit(
            "receiveCampaignIndicator",
            brandRes.campaign!.indicator
          );
          context.commit("receiveSuggestion", brandRes.agencySuggestion);
        }
      } catch (e) {
        this.$accessor.error.showError(e);
      } finally {
        this.$accessor.presentation.dismissLoading();
      }
    },
    async orderInfluencers(
      _,
      payload: AssignInfluencersToCampaignMutationVariables
    ) {
      try {
        this.$accessor.presentation.showLoading(null);
        const req = new AssignInfluencersToCampaignMutationRequest(payload);
        const res = await this.$apiClient.mutate(req);
        if (res) {
          const { brand, id, name } = this.$router.currentRoute.params;
          this.$router.replace(`/${brand}/campaigns/${id}/orders/${name}`);
        }
      } catch (e) {
        this.$accessor.error.showError(e);
      } finally {
        this.$accessor.presentation.dismissLoading();
      }
    },
    async downloadAllInfluencers(
      _,
      payload: DownloadSuggestionInfluencersMutationVariables
    ) {
      try {
        this.$accessor.presentation.showLoading(null);
        const req = new DownloadSuggestionInfluencersMutationRequest(payload);
        const res = await this.$apiClient.mutate(req);
        if (res.downloadSuggestionInfluencers!.url) {
          downloadFileByURL(res.downloadSuggestionInfluencers!.url);
        }
      } catch (e) {
        this.$accessor.error.showError(e);
      } finally {
        this.$accessor.presentation.dismissLoading();
      }
    },
    async toggleAllInfluencerCheckbox(
      context,
      payload: {
        nextConsiderationStatus: boolean;
        variables: ToggleInfluencersConsiderationMutationVariables;
      }
    ) {
      try {
        this.$accessor.presentation.showLoading(null);
        const req = new ToggleInfluencersConsiderationMutationRequest(
          payload.variables
        );
        const res = await this.$apiClient.mutate(req);
        if (res) {
          context.commit("changeAllSuggestionInfluencersConsiderationStatus", {
            isConsideration: payload.nextConsiderationStatus
          });
          context.commit(
            "receiveOrderInfo",
            res.toggleInfluencersConsideration
          );
          context.commit(
            "receiveSummaries",
            res.toggleInfluencersConsideration!.suggestion.summaries
          );
          context.commit(
            "receiveCurrentTotal",
            res.toggleInfluencersConsideration!.suggestion
          );
          context.commit(
            "receiveSummaryInfluencerScore",
            res.toggleInfluencersConsideration!.suggestion
          );
        }
      } catch (e) {
        this.$accessor.error.showError(e);
      } finally {
        this.$accessor.presentation.dismissLoading();
      }
    },
    async toggleRowInfluencerCheckbox(
      context,
      payload: {
        index: number;
        nextConsiderationStatus: boolean;
        variables: ToggleInfluencersConsiderationMutationVariables;
      }
    ) {
      try {
        this.$accessor.presentation.showLoading(null);
        const req = new ToggleInfluencersConsiderationMutationRequest(
          payload.variables
        );
        const res = await this.$apiClient.mutate(req);
        if (res) {
          context.commit("changeSuggestionInfluencerConsiderationStatus", {
            index: payload.index,
            isConsideration: payload.nextConsiderationStatus
          });
          context.commit(
            "receiveOrderInfo",
            res.toggleInfluencersConsideration
          );
          context.commit(
            "receiveSummaries",
            res.toggleInfluencersConsideration!.suggestion.summaries
          );
          context.commit(
            "receiveCurrentTotal",
            res.toggleInfluencersConsideration!.suggestion
          );
          context.commit(
            "receiveSummaryInfluencerScore",
            res.toggleInfluencersConsideration!.suggestion
          );
        }
      } catch (e) {
        this.$accessor.error.showError(e);
      } finally {
        this.$accessor.presentation.dismissLoading();
      }
    }
  }
);
