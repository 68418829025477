import Vue from "vue";
import VeeValidate, { Validator } from "vee-validate";
import en from "~/plugins/i18n/locales/en/validation";
import { Context } from "@nuxt/types";
import { rules } from "./rules";

export default function(context: Context) {
  Vue.use(VeeValidate, {
    inject: false,
    i18n: context.app.i18n,
    dictionary: {
      en
    }
  });

  (Object.keys(rules) as (keyof typeof rules)[]).forEach(ruleName =>
    Validator.extend(ruleName, rules[ruleName])
  );
}
