import { actionTree, mutationTree } from "nuxt-typed-vuex";
import { APIError } from "~/network/api-error";

export type ErrorState = {
  error: Error | null;
};

export const state = (): ErrorState => ({
  error: null
});

export const mutations = mutationTree(state, {
  setError(state, payload: APIError | Error) {
    if (payload instanceof APIError) {
      state.error = payload.asObject;
    } else {
      state.error = {
        name: payload.name,
        message: payload.message
      };
    }
  },
  removeError(state) {
    state.error = null;
  }
});

export const actions = actionTree(
  { state, mutations },
  {
    showError(context, payload: APIError | Error): void {
      context.commit("setError", payload);
    },
    dismissError(context): void {
      context.commit("removeError");
    }
  }
);
