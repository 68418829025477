import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _5e0335d8 = () => interopDefault(import('../src/pages/agency-login.vue' /* webpackChunkName: "pages/agency-login" */))
const _334bfa85 = () => interopDefault(import('../src/pages/agency-password-reset/index.vue' /* webpackChunkName: "pages/agency-password-reset/index" */))
const _9e713d7c = () => interopDefault(import('../src/pages/brands/index.vue' /* webpackChunkName: "pages/brands/index" */))
const _53334383 = () => interopDefault(import('../src/pages/campaigns/index.vue' /* webpackChunkName: "pages/campaigns/index" */))
const _08bf6502 = () => interopDefault(import('../src/pages/client-password-reset/index.vue' /* webpackChunkName: "pages/client-password-reset/index" */))
const _63163af4 = () => interopDefault(import('../src/pages/login.vue' /* webpackChunkName: "pages/login" */))
const _cef9d44e = () => interopDefault(import('../src/pages/admin/agencies/index.vue' /* webpackChunkName: "pages/admin/agencies/index" */))
const _1e9ae148 = () => interopDefault(import('../src/pages/admin/client-accounts/index.vue' /* webpackChunkName: "pages/admin/client-accounts/index" */))
const _69ec8416 = () => interopDefault(import('../src/pages/admin/influencer-pyramid/index.vue' /* webpackChunkName: "pages/admin/influencer-pyramid/index" */))
const _628efc54 = () => interopDefault(import('../src/pages/agency-password-reset/verify.vue' /* webpackChunkName: "pages/agency-password-reset/verify" */))
const _4f0b74d0 = () => interopDefault(import('../src/pages/agency/accounts/index.vue' /* webpackChunkName: "pages/agency/accounts/index" */))
const _0b223ef6 = () => interopDefault(import('../src/pages/agency/brands/index.vue' /* webpackChunkName: "pages/agency/brands/index" */))
const _e04ddc88 = () => interopDefault(import('../src/pages/brands/create.vue' /* webpackChunkName: "pages/brands/create" */))
const _10ee8e1c = () => interopDefault(import('../src/pages/client-password-reset/verify.vue' /* webpackChunkName: "pages/client-password-reset/verify" */))
const _72f03f86 = () => interopDefault(import('../src/pages/debug/ui/index.vue' /* webpackChunkName: "pages/debug/ui/index" */))
const _c0d81ff6 = () => interopDefault(import('../src/pages/admin/agencies/create.vue' /* webpackChunkName: "pages/admin/agencies/create" */))
const _4714db14 = () => interopDefault(import('../src/pages/admin/client-accounts/create.vue' /* webpackChunkName: "pages/admin/client-accounts/create" */))
const _8bd12024 = () => interopDefault(import('../src/pages/agency/accounts/create.vue' /* webpackChunkName: "pages/agency/accounts/create" */))
const _335362fe = () => interopDefault(import('../src/pages/admin/agencies/component/agency-form.vue' /* webpackChunkName: "pages/admin/agencies/component/agency-form" */))
const _1890d07a = () => interopDefault(import('../src/pages/admin/agencies/_agencyId/edit.vue' /* webpackChunkName: "pages/admin/agencies/_agencyId/edit" */))
const _7ccfb1de = () => interopDefault(import('../src/pages/admin/client-accounts/_clientAccountId/edit.vue' /* webpackChunkName: "pages/admin/client-accounts/_clientAccountId/edit" */))
const _209428c0 = () => interopDefault(import('../src/pages/agency/accounts/_accountNumber/edit.vue' /* webpackChunkName: "pages/agency/accounts/_accountNumber/edit" */))
const _79cc38d6 = () => interopDefault(import('../src/pages/agency/brands/_brandName/campaigns/index.vue' /* webpackChunkName: "pages/agency/brands/_brandName/campaigns/index" */))
const _74e54158 = () => interopDefault(import('../src/pages/agency/accounts/_accountNumber/component/agency-account-form.vue' /* webpackChunkName: "pages/agency/accounts/_accountNumber/component/agency-account-form" */))
const _0b551bc2 = () => interopDefault(import('../src/pages/agency/brands/_brandName/campaigns/_campaignNumber/index.vue' /* webpackChunkName: "pages/agency/brands/_brandName/campaigns/_campaignNumber/index" */))
const _36348dd6 = () => interopDefault(import('../src/pages/brands/_brandName/edit.vue' /* webpackChunkName: "pages/brands/_brandName/edit" */))
const _04cd3f6f = () => interopDefault(import('../src/pages/index.vue' /* webpackChunkName: "pages/index" */))
const _06f88b9e = () => interopDefault(import('../src/pages/_brand/affinity-models/index.vue' /* webpackChunkName: "pages/_brand/affinity-models/index" */))
const _357f4c52 = () => interopDefault(import('../src/pages/_brand/campaigns/index.vue' /* webpackChunkName: "pages/_brand/campaigns/index" */))
const _4cacf364 = () => interopDefault(import('../src/pages/_brand/influencer-evaluations/index.vue' /* webpackChunkName: "pages/_brand/influencer-evaluations/index" */))
const _00659a40 = () => interopDefault(import('../src/pages/_brand/affinity-models/create.vue' /* webpackChunkName: "pages/_brand/affinity-models/create" */))
const _9d0e7db0 = () => interopDefault(import('../src/pages/_brand/affinity-models/utils.ts' /* webpackChunkName: "pages/_brand/affinity-models/utils" */))
const _bbc2eea8 = () => interopDefault(import('../src/pages/_brand/campaigns/create.vue' /* webpackChunkName: "pages/_brand/campaigns/create" */))
const _7905cc18 = () => interopDefault(import('../src/pages/_brand/campaigns/utils.ts' /* webpackChunkName: "pages/_brand/campaigns/utils" */))
const _f988e3a0 = () => interopDefault(import('../src/pages/_brand/influencer-evaluations/create.vue' /* webpackChunkName: "pages/_brand/influencer-evaluations/create" */))
const _3c65b078 = () => interopDefault(import('../src/pages/_brand/influencer-evaluations/utils.ts' /* webpackChunkName: "pages/_brand/influencer-evaluations/utils" */))
const _726552b7 = () => interopDefault(import('../src/pages/_brand/affinity-models/_number/index.vue' /* webpackChunkName: "pages/_brand/affinity-models/_number/index" */))
const _0855ee3d = () => interopDefault(import('../src/pages/_brand/campaigns/_id/index.vue' /* webpackChunkName: "pages/_brand/campaigns/_id/index" */))
const _8eca6b8e = () => interopDefault(import('../src/pages/_brand/influencer-evaluations/_id/index.vue' /* webpackChunkName: "pages/_brand/influencer-evaluations/_id/index" */))
const _6980f96f = () => interopDefault(import('../src/pages/_brand/campaigns/_id/edit.vue' /* webpackChunkName: "pages/_brand/campaigns/_id/edit" */))
const _29e2fee6 = () => interopDefault(import('../src/pages/_brand/campaigns/_id/orders/index.vue' /* webpackChunkName: "pages/_brand/campaigns/_id/orders/index" */))
const _7c081c1c = () => interopDefault(import('../src/pages/_brand/campaigns/_id/report/index.vue' /* webpackChunkName: "pages/_brand/campaigns/_id/report/index" */))
const _4f7b4c99 = () => interopDefault(import('../src/pages/_brand/influencer-evaluations/_id/snapshots/index.vue' /* webpackChunkName: "pages/_brand/influencer-evaluations/_id/snapshots/index" */))
const _48be10a8 = () => interopDefault(import('../src/pages/_brand/influencer-evaluations/_id/uploading.vue' /* webpackChunkName: "pages/_brand/influencer-evaluations/_id/uploading" */))
const _101628ea = () => interopDefault(import('../src/pages/_brand/campaigns/_id/report/utils.ts' /* webpackChunkName: "pages/_brand/campaigns/_id/report/utils" */))
const _395fd467 = () => interopDefault(import('../src/pages/_brand/campaigns/_id/comments/components/utils.ts' /* webpackChunkName: "pages/_brand/campaigns/_id/comments/components/utils" */))
const _05d2c514 = () => interopDefault(import('../src/pages/_brand/campaigns/_id/comments/order/_name/index.vue' /* webpackChunkName: "pages/_brand/campaigns/_id/comments/order/_name/index" */))
const _7692c376 = () => interopDefault(import('../src/pages/_brand/campaigns/_id/comments/proposal/_name/index.vue' /* webpackChunkName: "pages/_brand/campaigns/_id/comments/proposal/_name/index" */))
const _17b73538 = () => interopDefault(import('../src/pages/_brand/campaigns/_id/comments/report/_name/index.vue' /* webpackChunkName: "pages/_brand/campaigns/_id/comments/report/_name/index" */))
const _7f45e288 = () => interopDefault(import('../src/pages/_brand/campaigns/_id/agency-proposals/_name/index.vue' /* webpackChunkName: "pages/_brand/campaigns/_id/agency-proposals/_name/index" */))
const _1f4255e8 = () => interopDefault(import('../src/pages/_brand/campaigns/_id/orders/_name/index.vue' /* webpackChunkName: "pages/_brand/campaigns/_id/orders/_name/index" */))
const _11a17fd6 = () => interopDefault(import('../src/pages/_brand/influencer-evaluations/_id/posts/_number/index.vue' /* webpackChunkName: "pages/_brand/influencer-evaluations/_id/posts/_number/index" */))
const _36ef60ee = () => interopDefault(import('../src/pages/_brand/influencer-evaluations/_id/snapshots/_snapId/index.vue' /* webpackChunkName: "pages/_brand/influencer-evaluations/_id/snapshots/_snapId/index" */))
const _5b6c8556 = () => interopDefault(import('../src/pages/_brand/campaigns/_id/orders/_name/confirm.vue' /* webpackChunkName: "pages/_brand/campaigns/_id/orders/_name/confirm" */))
const _e484da8e = () => interopDefault(import('../src/pages/_brand/campaigns/_id/orders/_name/index-utils.ts' /* webpackChunkName: "pages/_brand/campaigns/_id/orders/_name/index-utils" */))
const _26c85056 = () => interopDefault(import('../src/pages/_brand/campaigns/_id/agency-proposals/_name/_number/index.vue' /* webpackChunkName: "pages/_brand/campaigns/_id/agency-proposals/_name/_number/index" */))
const _1aa0dbde = () => interopDefault(import('../src/pages/_brand/campaigns/_id/agency-proposals/_name/_number/utils.ts' /* webpackChunkName: "pages/_brand/campaigns/_id/agency-proposals/_name/_number/utils" */))

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/agency-login",
    component: _5e0335d8,
    name: "agency-login"
  }, {
    path: "/agency-password-reset",
    component: _334bfa85,
    name: "agency-password-reset"
  }, {
    path: "/brands",
    component: _9e713d7c,
    name: "brands"
  }, {
    path: "/campaigns",
    component: _53334383,
    name: "campaigns"
  }, {
    path: "/client-password-reset",
    component: _08bf6502,
    name: "client-password-reset"
  }, {
    path: "/login",
    component: _63163af4,
    name: "login"
  }, {
    path: "/admin/agencies",
    component: _cef9d44e,
    name: "admin-agencies"
  }, {
    path: "/admin/client-accounts",
    component: _1e9ae148,
    name: "admin-client-accounts"
  }, {
    path: "/admin/influencer-pyramid",
    component: _69ec8416,
    name: "admin-influencer-pyramid"
  }, {
    path: "/agency-password-reset/verify",
    component: _628efc54,
    name: "agency-password-reset-verify"
  }, {
    path: "/agency/accounts",
    component: _4f0b74d0,
    name: "agency-accounts"
  }, {
    path: "/agency/brands",
    component: _0b223ef6,
    name: "agency-brands"
  }, {
    path: "/brands/create",
    component: _e04ddc88,
    name: "brands-create"
  }, {
    path: "/client-password-reset/verify",
    component: _10ee8e1c,
    name: "client-password-reset-verify"
  }, {
    path: "/debug/ui",
    component: _72f03f86,
    name: "debug-ui"
  }, {
    path: "/admin/agencies/create",
    component: _c0d81ff6,
    name: "admin-agencies-create"
  }, {
    path: "/admin/client-accounts/create",
    component: _4714db14,
    name: "admin-client-accounts-create"
  }, {
    path: "/agency/accounts/create",
    component: _8bd12024,
    name: "agency-accounts-create"
  }, {
    path: "/admin/agencies/component/agency-form",
    component: _335362fe,
    name: "admin-agencies-component-agency-form"
  }, {
    path: "/admin/agencies/:agencyId/edit",
    component: _1890d07a,
    name: "admin-agencies-agencyId-edit"
  }, {
    path: "/admin/client-accounts/:clientAccountId?/edit",
    component: _7ccfb1de,
    name: "admin-client-accounts-clientAccountId-edit"
  }, {
    path: "/agency/accounts/:accountNumber/edit",
    component: _209428c0,
    name: "agency-accounts-accountNumber-edit"
  }, {
    path: "/agency/brands/:brandName/campaigns",
    component: _79cc38d6,
    name: "agency-brands-brandName-campaigns"
  }, {
    path: "/agency/accounts/:accountNumber/component/agency-account-form",
    component: _74e54158,
    name: "agency-accounts-accountNumber-component-agency-account-form"
  }, {
    path: "/agency/brands/:brandName/campaigns/:campaignNumber",
    component: _0b551bc2,
    name: "agency-brands-brandName-campaigns-campaignNumber"
  }, {
    path: "/brands/:brandName/edit",
    component: _36348dd6,
    name: "brands-brandName-edit"
  }, {
    path: "/",
    component: _04cd3f6f,
    name: "index"
  }, {
    path: "/:brand/affinity-models",
    component: _06f88b9e,
    name: "brand-affinity-models"
  }, {
    path: "/:brand/campaigns",
    component: _357f4c52,
    name: "brand-campaigns"
  }, {
    path: "/:brand/influencer-evaluations",
    component: _4cacf364,
    name: "brand-influencer-evaluations"
  }, {
    path: "/:brand/affinity-models/create",
    component: _00659a40,
    name: "brand-affinity-models-create"
  }, {
    path: "/:brand/affinity-models/utils",
    component: _9d0e7db0,
    name: "brand-affinity-models-utils"
  }, {
    path: "/:brand/campaigns/create",
    component: _bbc2eea8,
    name: "brand-campaigns-create"
  }, {
    path: "/:brand/campaigns/utils",
    component: _7905cc18,
    name: "brand-campaigns-utils"
  }, {
    path: "/:brand/influencer-evaluations/create",
    component: _f988e3a0,
    name: "brand-influencer-evaluations-create"
  }, {
    path: "/:brand/influencer-evaluations/utils",
    component: _3c65b078,
    name: "brand-influencer-evaluations-utils"
  }, {
    path: "/:brand/affinity-models/:number?",
    component: _726552b7,
    name: "brand-affinity-models-number"
  }, {
    path: "/:brand/campaigns/:id",
    component: _0855ee3d,
    name: "brand-campaigns-id"
  }, {
    path: "/:brand/influencer-evaluations/:id?",
    component: _8eca6b8e,
    name: "brand-influencer-evaluations-id"
  }, {
    path: "/:brand/campaigns/:id/edit",
    component: _6980f96f,
    name: "brand-campaigns-id-edit"
  }, {
    path: "/:brand/campaigns/:id/orders",
    component: _29e2fee6,
    name: "brand-campaigns-id-orders"
  }, {
    path: "/:brand/campaigns/:id/report",
    component: _7c081c1c,
    name: "brand-campaigns-id-report"
  }, {
    path: "/:brand/influencer-evaluations/:id?/snapshots",
    component: _4f7b4c99,
    name: "brand-influencer-evaluations-id-snapshots"
  }, {
    path: "/:brand/influencer-evaluations/:id?/uploading",
    component: _48be10a8,
    name: "brand-influencer-evaluations-id-uploading"
  }, {
    path: "/:brand/campaigns/:id/report/utils",
    component: _101628ea,
    name: "brand-campaigns-id-report-utils"
  }, {
    path: "/:brand/campaigns/:id/comments/components/utils",
    component: _395fd467,
    name: "brand-campaigns-id-comments-components-utils"
  }, {
    path: "/:brand/campaigns/:id/comments/order/:name?",
    component: _05d2c514,
    name: "brand-campaigns-id-comments-order-name"
  }, {
    path: "/:brand/campaigns/:id/comments/proposal/:name?",
    component: _7692c376,
    name: "brand-campaigns-id-comments-proposal-name"
  }, {
    path: "/:brand/campaigns/:id/comments/report/:name?",
    component: _17b73538,
    name: "brand-campaigns-id-comments-report-name"
  }, {
    path: "/:brand/campaigns/:id/agency-proposals/:name?",
    component: _7f45e288,
    name: "brand-campaigns-id-agency-proposals-name"
  }, {
    path: "/:brand/campaigns/:id/orders/:name",
    component: _1f4255e8,
    name: "brand-campaigns-id-orders-name"
  }, {
    path: "/:brand/influencer-evaluations/:id?/posts/:number",
    component: _11a17fd6,
    name: "brand-influencer-evaluations-id-posts-number"
  }, {
    path: "/:brand/influencer-evaluations/:id?/snapshots/:snapId",
    component: _36ef60ee,
    name: "brand-influencer-evaluations-id-snapshots-snapId"
  }, {
    path: "/:brand/campaigns/:id/orders/:name/confirm",
    component: _5b6c8556,
    name: "brand-campaigns-id-orders-name-confirm"
  }, {
    path: "/:brand/campaigns/:id/orders/:name/index-utils",
    component: _e484da8e,
    name: "brand-campaigns-id-orders-name-index-utils"
  }, {
    path: "/:brand/campaigns/:id/agency-proposals/:name?/:number?",
    component: _26c85056,
    name: "brand-campaigns-id-agency-proposals-name-number"
  }, {
    path: "/:brand/campaigns/:id/agency-proposals/:name?/:number?/utils",
    component: _1aa0dbde,
    name: "brand-campaigns-id-agency-proposals-name-number-utils"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
