import { Context } from "@nuxt/types";

// 高さを変えるURL
const blackList = [
  "brand-campaigns-id-agency-proposals-name-number",
  "brand-campaigns-id-orders-name",
  "brand-campaigns-id-orders-name-confirm"
];

function changeBottomElm(route: any, elm: any) {
  if (blackList.includes(route.name)) {
    elm.style.bottom = "97px";
  } else {
    elm.style.bottom = "0";
  }
}

export default async function supportButton(context: Context) {
  if (process.server) return;
  setTimeout(() => {
    const button = window.document.getElementById("launcher");
    if (!button) return;
    changeBottomElm(context.route, button);

    // サポートボタンを押すと、ボタンの高さが戻るのでアニメーションエンドを検知
    button.addEventListener("transitionend", () => {
      changeBottomElm(context.route, button);
    });
  }, 500);
}
