import {
  AgencyAccount,
  AgencyAccountsQueryRequest,
  DeleteAgencyAccountInput,
  DeleteAgencyAccountMutationRequest
} from "~/types/gen/api";
import { actionTree, mutationTree } from "nuxt-typed-vuex";
import { resetState } from "~/store/utils";

export type AgencyAccountInTable = Pick<
  AgencyAccount,
  "number" | "id" | "email" | "name" | "isOwner"
> & { role?: string };

type AgencyAccountsState = {
  agencyAccounts: AgencyAccountInTable[];
};

export const state = (): AgencyAccountsState => ({
  agencyAccounts: []
});

export const mutations = mutationTree(state, {
  recieveAgencyAccounts(state, agencyAccounts) {
    state.agencyAccounts = agencyAccounts;
  },
  deleteAccount(state, agencyAccountId: string) {
    state.agencyAccounts = state.agencyAccounts.filter(
      agencyAccount => agencyAccount.id !== agencyAccountId
    );
  },
  reset: resetState(state)
});

export const actions = actionTree(
  { state },
  {
    async getAgencyAccounts({ commit }): Promise<void> {
      try {
        this.$accessor.presentation.showLoading(null);
        const req = new AgencyAccountsQueryRequest({});
        const res = await this.$apiClient.query(req);
        commit("recieveAgencyAccounts", res.agencyAccounts);
      } catch (e) {
        this.$accessor.error.showError(e);
      } finally {
        this.$accessor.presentation.dismissLoading();
      }
    },
    async deleteAgencyAccount(
      { commit },
      payload: DeleteAgencyAccountInput
    ): Promise<void> {
      const { agencyAccountId } = payload;
      try {
        this.$accessor.presentation.showLoading(null);
        const res = new DeleteAgencyAccountMutationRequest({
          input: {
            agencyAccountId
          }
        });
        await this.$apiClient.mutate(res);
        commit("deleteAccount", agencyAccountId);
      } catch (e) {
        this.$accessor.error.showError(e);
      } finally {
        this.$accessor.presentation.dismissLoading();
      }
    }
  }
);
