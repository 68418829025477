import { actionTree } from "nuxt-typed-vuex";
import {
  CreateInfluencerEvaluationGroupMutationRequest,
  CreateInfluencerEvaluationGroupMutationVariables
} from "~/types/gen/api";

export const state = (): any => ({});

export const actions = actionTree(
  {
    state
  },
  {
    create(_, payload: CreateInfluencerEvaluationGroupMutationVariables) {
      try {
        this.$accessor.presentation.showLoading(null);
        const req = new CreateInfluencerEvaluationGroupMutationRequest(payload);
        const res = this.$apiClient.mutate(req);
        if (res) {
          res.then(() => {
            const { brand } = this.$router.currentRoute.params;
            this.$router.replace(`/${brand}/influencer-evaluations`);
          });
        }
      } catch (e) {
        throw e;
      } finally {
        this.$accessor.presentation.dismissLoading();
      }
    }
  }
);
