import { Context } from "@nuxt/types";
import { makePath } from "./util";

export default async function requireAuth(context: Context) {
  // tokenがなければログインへリダイレクト
  if (!context.app.$accessor.agencyAccount.hasCredential) {
    context.redirect(makePath("/agency-login", context.route.path));
    return;
  }
  // loadAccountをしてなければloadAccountをする
  if (!context.app.$accessor.agencyAccount.hasAccount) {
    await context.app.$accessor.agencyAccount.loadAccount();
  }
  // BrandNameがセットされてない
  // 又は、不正なBrandName及び、ユーザーが許可されてないブランドネームの場合
  // ブランド一覧へ遷移
  const { brand } = context.route.params;
  if (brand && !context.app.$accessor.agencyAccount.hasBrand(brand)) {
    context.redirect("/agency/brands/");
  }
}
