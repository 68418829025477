import fetch from "node-fetch";
import ApolloClient from "apollo-client";
import { Context } from "@nuxt/types";
import {
  InMemoryCache,
  IntrospectionFragmentMatcher
} from "apollo-cache-inmemory";
import { ApolloLink, from } from "apollo-link";
import { onError } from "apollo-link-error";
import { APIClient } from "../network/api-client";
import introspectionQueryResultData from "../types/gen/introspection-result";
import { APIError } from "../network/api-error";
import { GraphQLError } from "graphql";
//@ts-ignore
import { createUploadLink } from "apollo-upload-client";

function isAgencyRequest(ctx: Context): boolean {
  if (!ctx.route.name) return false;
  return ctx.route.name.split("-")[0] === "agency" ? true : false;
}

//TODO: correct all account store expressions
export default function(
  ctx: Context,
  inject: (key: string, value: any) => void
) {
  // Auth middleware
  const auth = new ApolloLink((operation, forward) => {
    const { clientAccount, agencyAccount } = ctx.app.$accessor;
    let token: string | null = null;
    // agencyへのリクエスト時には、agency
    if (isAgencyRequest(ctx)) {
      token = agencyAccount.token;
    } else {
      token = clientAccount.token;
    }

    if (token) {
      operation.setContext({
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
    }
    return forward!(operation);
  });

  // Setup source
  const link = createUploadLink({
    uri: process.env.API_URL,
    fetch: (fetch as unknown) as WindowOrWorkerGlobalScope["fetch"]
  });

  // Setup fragment matcher for cache
  const fragmentMatcher = new IntrospectionFragmentMatcher({
    introspectionQueryResultData
  });

  // Setup cache
  const cache = new InMemoryCache({
    fragmentMatcher
  });

  // Global Error
  const error = onError(link => {
    const apiError = APIError.fromGraphQLErrors(
      (link.graphQLErrors as GraphQLError[]) || []
    );

    // Token expired
    const { clientAccount, agencyAccount } = ctx.app.$accessor;
    if (apiError.status === 401) {
      if (isAgencyRequest(ctx)) {
        agencyAccount.logout();
      } else {
        clientAccount.logout();
      }
    }

    link.forward(link.operation);
  });

  // Setup client
  const client = new ApolloClient({
    link: from([error, auth, link]),
    cache,
    connectToDevTools: process.env.NODE_ENV === "development"
  });

  const apiClient = new APIClient(client);

  // Enable to access $apollo from vue and vuex
  inject("apiClient", apiClient);
}
