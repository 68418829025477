import { actionTree, mutationTree, getterTree } from "nuxt-typed-vuex";
import {
  Suggestion,
  InfluencersQueryRequest,
  InfluencersQueryVariables,
  AdditionalOrderQueryRequest,
  AdditionalOrderQueryVariables,
  PaginatorInfo,
  DownloadAssignmentInfluencersMutationVariables,
  DownloadAssignmentInfluencersMutationRequest,
  AdditionalAssignInfluencersToCampaignMutationRequest,
  SummaryInfluencerScore,
  AdditionalProposalListQueryRequest,
  AdditionalProposalListQueryVariables,
  ClientBrand,
  AssignmentInfluencerEdge,
  SuggestionInfluencerEdge,
  CurrentTotalQueryRequest,
  CurrentTotalQueryVariables,
  ClientAssignment,
  AssignmentInfluencer,
  SuggestionInfluencer,
  AdditionalAssignInfluencerConsiderationMutationRequest,
  AdditionalAssignInfluencerConsiderationMutationVariables,
  CampaignIndicator,
  AssignmentInfluencerConsiderationStatus,
  ToggleConfirmInfluencersConsiderationMutationVariables,
  ToggleConfirmInfluencersConsiderationMutationRequest,
  AddConsiderationInfluencersToConfirmInfluencersMutationRequest,
  AddConsiderationInfluencersToConfirmInfluencersMutationVariables,
  CampaignAgency,
  ClientCampaign,
  AssignmentInfluencerFilter
} from "~/types/gen/api";
import BrandResponse from "~/store/responses/brandResponse";
import { downloadFileByURL } from "~/utils/downloadFileByURL";
import { resetState } from "~/store/utils";

export const Tabs = {
  influencers: "INFLUENCERS",
  additional: "ADDITIONAL"
};

export type orderState = {
  routeParams: {
    brand?: string;
    id?: string;
    name?: string;
    suggestionNumber?: string;
  };
  canAddFinalConfirmationInfluencerCount: number;
  assignmentInfluencers: AssignmentInfluencer[];
  assignmentInfluencersPager: Pick<
    PaginatorInfo,
    "total" | "count" | "currentPage" | "perPage"
  >;
  suggestionInfluencers: SuggestionInfluencer[];
  suggestionInfluencersPager: Pick<
    PaginatorInfo,
    "total" | "count" | "currentPage" | "perPage"
  >;
  activeTab: string;
  suggestion: Suggestion | null;
  additionalSuggestions: Suggestion[];
  orderSummary: {
    campaignIndicator: CampaignIndicator | null;
    summaryInfluencerScore: SummaryInfluencerScore;
    summaries: SummaryInfluencerScore[];
    winningSummaries: SummaryInfluencerScore[];
    currentTotal: ClientAssignment | null;
  };
  additionalOrderSummary: {
    campaignIndicator: CampaignIndicator | null;
    summaryInfluencerScore: SummaryInfluencerScore;
    summaries: SummaryInfluencerScore[];
    winningSummaries: SummaryInfluencerScore[];
    currentTotal: ClientAssignment | null;
  };
  agency: Maybe<CampaignAgency>;
  campaign: Maybe<ClientCampaign>;
};

export const state = (): orderState => ({
  routeParams: {},
  assignmentInfluencers: [],
  assignmentInfluencersPager: {
    total: 0,
    count: 0,
    currentPage: 1,
    perPage: 10
  },
  suggestion: null,
  suggestionInfluencers: [],
  suggestionInfluencersPager: {
    total: 0,
    count: 0,
    currentPage: 1,
    perPage: 7
  },
  activeTab: Tabs.influencers,
  additionalSuggestions: [],
  canAddFinalConfirmationInfluencerCount: 0,
  orderSummary: {
    campaignIndicator: null,
    summaryInfluencerScore: {
      totalCost: 0,
      totalCommission: 0,
      totalInfluencerCount: 0,
      totalFollower: 0,
      totalFollow: 0,
      averageEngagementRate: 0,
      averageAffinityRate: 0
    },
    summaries: [],
    winningSummaries: [],
    currentTotal: null
  },
  additionalOrderSummary: {
    campaignIndicator: null,
    summaryInfluencerScore: {
      totalCost: 0,
      totalCommission: 0,
      totalInfluencerCount: 0,
      totalFollower: 0,
      totalFollow: 0,
      averageEngagementRate: 0,
      averageAffinityRate: 0
    },
    summaries: [],
    winningSummaries: [],
    currentTotal: null
  },
  agency: null,
  campaign: null
});

export const mutations = mutationTree(state, {
  changeActiveTab(state, payload) {
    state.activeTab = payload;
  },
  changeAllAssignmentInfluencerConsiderationStatus(state, payload) {
    state.assignmentInfluencers.forEach((v: AssignmentInfluencer) => {
      v.considerationStatus = payload.considerationStatus;
    });
  },
  changeAssignmentInfluencerConsiderationStatus(
    state,
    payload: {
      index: number;
      considerationStatus: AssignmentInfluencerConsiderationStatus;
    }
  ) {
    state.assignmentInfluencers[payload.index].considerationStatus =
      payload.considerationStatus;
  },
  receiveAssignmentInfluencers(state, payload) {
    state.assignmentInfluencers = payload.map(
      (v: AssignmentInfluencerEdge) => v.node!.assignmentInfluencer
    );
  },
  receiveAssignmentInfluencersPaginatorInfo(state, payload) {
    state.assignmentInfluencersPager = payload;
  },
  receiveAdditionalOrderSuggestions(state, payload) {
    state.additionalSuggestions = payload;
  },
  receiveSuggestionNumber(state, suggestionNumber) {
    state.routeParams = {
      ...state.routeParams,
      suggestionNumber
    };
  },
  receiveSuggestionInfluencers(state, payload) {
    if (!payload) return;
    state.suggestionInfluencers = payload.map(
      (v: SuggestionInfluencerEdge) => v.node!.suggestionInfluencer
    );
  },
  receiveSuggestionInfluencersPaginatorInfo(state, payload) {
    if (!payload) return;
    state.suggestionInfluencersPager = payload;
  },
  // 発注、インフルエンサータブのスコアデータ
  receiveCampaignIndicator(state, payload) {
    state.orderSummary.campaignIndicator = payload;
  },
  receiveSummaryInfluencerScore(state, payload) {
    state.orderSummary.summaryInfluencerScore = payload;
  },
  receiveSummaries(state, payload) {
    state.orderSummary.summaries = payload;
  },
  receiveWinningSummaries(state, payload) {
    state.orderSummary.winningSummaries = payload;
  },
  receiveCurrentTotal(state, payload) {
    state.orderSummary.currentTotal = payload;
  },
  // 発注、追加発注タブのスコアデータ
  receiveAdditionalCampaignIndicator(state, payload) {
    state.additionalOrderSummary.campaignIndicator = payload;
  },
  receiveAdditionalSummaryInfluencerScore(state, payload) {
    state.additionalOrderSummary.summaryInfluencerScore = payload;
  },
  receiveAdditionalSummaries(state, payload) {
    state.additionalOrderSummary.summaries = payload;
  },
  receiveAdditionalWinningSummaries(state, payload) {
    state.additionalOrderSummary.winningSummaries = payload;
  },
  receiveAdditionalCurrentTotal(state, payload) {
    state.additionalOrderSummary.currentTotal = payload;
  },
  receiveSuggestion(state, payload) {
    state.suggestion = payload;
  },
  receiveAgency(state, payload) {
    state.agency = payload;
  },
  receiveCampaign(state, payload) {
    state.campaign = payload;
  },
  receiveCanAddFinalConfirmationInfluencerCount(state, payload) {
    state.canAddFinalConfirmationInfluencerCount = payload;
  },
  reset: resetState(state)
});

export const getters = getterTree(state, {
  additionalOrderSuggetionNumbers(state) {
    return state.additionalSuggestions.map((v: any) => {
      return {
        label: v.name,
        value: v.number
      };
    });
  }
});

export const actions = actionTree(
  { state },
  {
    async getOrderInfluencers(context, payload: InfluencersQueryVariables) {
      try {
        this.$accessor.presentation.showLoading(null);
        const req = new InfluencersQueryRequest(payload);
        const res = await this.$apiClient.query(req);
        const brandRes = new BrandResponse(res.brand as ClientBrand);
        const { getListState } = brandRes;

        if (res) {
          const { edges, paginatorInfo } = getListState(
            brandRes.agencyAssignmentInfluencers
          );
          // 発注したインフルエンサーのデータをコミット
          context.commit("receiveAssignmentInfluencers", edges);
          // ページャーデータのコミット
          context.commit(
            "receiveAssignmentInfluencersPaginatorInfo",
            paginatorInfo
          );

          if (brandRes.agencyAssignmentSummaries) {
            // スコアサマリーのデータをコミット
            context.commit(
              "receiveSummaryInfluencerScore",
              brandRes.agencyAssignmentSummaries[0]
            );
            context.commit(
              "receiveCanAddFinalConfirmationInfluencerCount",
              brandRes.agencyAssignmentSummaries[0]!.totalInfluencerCount
            );
          }

          context.commit(
            "receiveCampaignIndicator",
            res.brand.campaign!.indicator
          );
          // 代理店情報をコミット
          context.commit("receiveAgency", brandRes.agency);
          // キャンペーン情報をコミット
          context.commit("receiveCampaign", brandRes.campaign);
        }
      } catch (e) {
        this.$accessor.error.showError(e);
      } finally {
        this.$accessor.presentation.dismissLoading();
      }
    },
    // 追加発注用のスコアデータの取得
    async getAdditionalInfluencersCurrentScore(
      context,
      payload: CurrentTotalQueryVariables
    ) {
      try {
        this.$accessor.presentation.showLoading(null);
        const req = new CurrentTotalQueryRequest(payload);
        const res = await this.$apiClient.query(req);
        const brandRes = new BrandResponse(res.brand as ClientBrand);

        if (res) {
          // スコアサマリーのデータをコミット
          context.commit(
            "receiveAdditionalCurrentTotal",
            brandRes.agencyAssignment
          );
          if (brandRes.agencyAssignmentSummaries) {
            context.commit(
              "receiveAdditionalSummaryInfluencerScore",
              brandRes.agencyAssignmentSummaries[0]
            );
            context.commit(
              "receiveCanAddFinalConfirmationInfluencerCount",
              brandRes.agencyAssignmentSummaries[0]!.totalInfluencerCount
            );
          }
          context.commit(
            "receiveAdditionalCampaignIndicator",
            res.brand.campaign!.indicator
          );
          context.commit(
            "receiveAdditionalSummaries",
            brandRes.agencyAssignmentSummaries
          );
          context.commit(
            "receiveAdditionalWinningSummaries",
            brandRes.agencyAssignmentWinningSummaries
          );
        }
      } catch (e) {
        this.$accessor.error.showError(e);
      } finally {
        this.$accessor.presentation.dismissLoading();
      }
    },
    async getCurrentTotal(context, payload: CurrentTotalQueryVariables) {
      try {
        this.$accessor.presentation.showLoading(null);
        const req = new CurrentTotalQueryRequest(payload);
        const res = await this.$apiClient.query(req);
        const brandRes = new BrandResponse(res.brand as ClientBrand);

        if (res) {
          context.commit("receiveCurrentTotal", brandRes.agencyAssignment);
          context.commit(
            "receiveSummaries",
            brandRes.agencyAssignmentSummaries
          );
          context.commit(
            "receiveWinningSummaries",
            brandRes.agencyAssignmentWinningSummaries
          );
          context.commit(
            "receiveCanAddFinalConfirmationInfluencerCount",
            brandRes.agencyAssignment!.totalInfluencerCount
          );
        }
      } catch (e) {
        this.$accessor.error.showError(e);
      } finally {
        this.$accessor.presentation.dismissLoading();
      }
    },
    async getFinalConfirmationInfluencerTotal(
      context,
      payload: CurrentTotalQueryVariables
    ) {
      try {
        this.$accessor.presentation.showLoading(null);
        const req = new CurrentTotalQueryRequest({
          ...payload,
          includeAdditionalConsiderationSummary: false,
          filterBy: {
            availabilityStatus: ["OK"],
            considerationStatus: "CONSIDERED",
            isClientConfirmed: false
          } as AssignmentInfluencerFilter
        });
        const res = await this.$apiClient.query(req);
        const brandRes = new BrandResponse(res.brand as ClientBrand);

        if (res) {
          if (brandRes.agencyAssignmentSummaries) {
            context.commit(
              "receiveCanAddFinalConfirmationInfluencerCount",
              brandRes.agencyAssignmentSummaries[0]!.totalInfluencerCount
            );
          }
        }
      } catch (e) {
        this.$accessor.error.showError(e);
      } finally {
        this.$accessor.presentation.dismissLoading();
      }
    },
    async downloadCsv(
      _,
      payload: DownloadAssignmentInfluencersMutationVariables
    ) {
      try {
        this.$accessor.presentation.showLoading(null);
        const req = new DownloadAssignmentInfluencersMutationRequest(payload);
        const res = await this.$apiClient.mutate(req);
        if (res.downloadAssignmentInfluencers!.url) {
          downloadFileByURL(res.downloadAssignmentInfluencers!.url);
        }
      } catch (e) {
        this.$accessor.error.showError(e);
      } finally {
        this.$accessor.presentation.dismissLoading();
      }
    },
    async getAdditionalproposalList(
      context,
      payload: AdditionalProposalListQueryVariables
    ) {
      try {
        this.$accessor.presentation.showLoading(null);

        // 追加発注の提案リストを取得(プルダウン用)
        const req = new AdditionalProposalListQueryRequest(payload);
        const res = await this.$apiClient.query(req);

        if (res) {
          const brandRes = new BrandResponse(res.brand as ClientBrand);
          // proposalのセレクトに入れる要素を追加
          context.commit(
            "receiveAdditionalOrderSuggestions",
            brandRes.agencySuggestions
          );

          const { suggestionNumber } = this.$router.currentRoute.query;
          const { additionalSuggestions } = this.$accessor.pages.order;
          if (suggestionNumber && typeof suggestionNumber === "string") {
            // クエリにサジェスチョンナンバーがあればセット
            context.commit(
              "receiveSuggestionNumber",
              parseInt(suggestionNumber)
            );
          } else if (additionalSuggestions.length > 0) {
            // なければresからセット
            context.commit(
              "receiveSuggestionNumber",
              additionalSuggestions[0].number
            );
          }
        }
      } catch (e) {
        this.$accessor.error.showError(e);
      } finally {
        this.$accessor.presentation.dismissLoading();
      }
    },
    async getAdditionalOrderInfluencers(
      context,
      payload: AdditionalOrderQueryVariables
    ) {
      try {
        this.$accessor.presentation.showLoading(null);
        const req = new AdditionalOrderQueryRequest(payload);
        const res = await this.$apiClient.query(req);
        if (res) {
          const brandRes = new BrandResponse(res.brand as ClientBrand);
          const { getListState } = brandRes;
          const { edges, paginatorInfo } = getListState(
            brandRes.agencySuggestionInfluencers
          );
          // リストがない場合はコミットしない
          if (edges) {
            // 発注できるインフルエンサーのデータ
            context.commit("receiveSuggestionInfluencers", edges);
            // 提案のサジェスチョン情報をコミット
            context.commit("receiveSuggestion", brandRes.agencySuggestion);
            // ページャーデータのコミット
            context.commit(
              "receiveSuggestionInfluencersPaginatorInfo",
              paginatorInfo
            );
            context.commit(
              "receiveSuggestionNumber",
              brandRes.agencySuggestion!.number
            );
          }
        }
      } catch (e) {
        this.$accessor.error.showError(e);
      } finally {
        this.$accessor.presentation.dismissLoading();
      }
    },
    async toggleSetSuggestionInfluencers(
      context,
      payload: AdditionalAssignInfluencerConsiderationMutationVariables
    ) {
      try {
        this.$accessor.presentation.showLoading(null);
        const req = new AdditionalAssignInfluencerConsiderationMutationRequest(
          payload
        );
        const res = await this.$apiClient.mutate(req);
        if (res) {
          // 提案のサジェスチョン情報をコミット
          context.commit(
            "receiveSuggestion",
            res.additionalAssignInfluencerConsideration!.agency.suggestion
          );
          // 提案のインフルエンサー情報をコミット
          context.commit(
            "receiveSuggestionInfluencers",
            res.additionalAssignInfluencerConsideration!.agency.suggestion!
              .suggestionInfluencers!.edges
          );
          // 提案のインフルエンサーのページング情報をコミット
          context.commit(
            "receiveSuggestionInfluencersPaginatorInfo",
            res.additionalAssignInfluencerConsideration!.agency.suggestion!
              .suggestionInfluencers!.paginatorInfo
          );

          // 追加発注のコスト周りを更新
          const { brand, id, name } = this.$router.currentRoute.params;
          const { suggestionNumber } = context.state.routeParams;
          this.$accessor.pages.order.getAdditionalInfluencersCurrentScore({
            name: brand,
            page: 1,
            number: parseInt(id),
            agencyName: name,
            suggestionNumber: Number(suggestionNumber)
          });
        }
      } catch (e) {
        this.$accessor.error.showError(e);
      } finally {
        this.$accessor.presentation.dismissLoading();
      }
    },
    async orderAdditionalInfluencers({ state }, suggestionId) {
      try {
        this.$accessor.presentation.showLoading(null);

        const req = new AdditionalAssignInfluencersToCampaignMutationRequest({
          assignmentId: state.additionalOrderSummary.currentTotal
            ? state.additionalOrderSummary.currentTotal.id
            : "",
          suggestionId: suggestionId
        });

        const res = await this.$apiClient.mutate(req);
        if (res) {
          const { brand, id, name } = this.$router.currentRoute.params;
          this.$router.replace(`/${brand}/campaigns/${id}/orders/${name}`);
          // リロード
          this.$router.go(0);
        }
      } catch (e) {
        this.$accessor.error.showError(e);
      } finally {
        this.$accessor.presentation.dismissLoading();
      }
    },
    async toggleAllInfluencerCheckbox(
      context,
      payload: {
        nextConsiderationStatus: AssignmentInfluencerConsiderationStatus;
        variables: ToggleConfirmInfluencersConsiderationMutationVariables;
      }
    ) {
      try {
        this.$accessor.presentation.showLoading(null);
        const req = new ToggleConfirmInfluencersConsiderationMutationRequest(
          payload.variables
        );
        const res = await this.$apiClient.mutate(req);
        if (res) {
          context.commit("changeAllAssignmentInfluencerConsiderationStatus", {
            considerationStatus: payload.nextConsiderationStatus
          });
          const { brand, id, name } = this.$router.currentRoute.params;
          // currentTotal用のクエリのフェッチ
          this.$accessor.pages.order.getCurrentTotal({
            name: brand,
            page: 1,
            number: parseInt(id),
            agencyName: name
          });
          // final confirmationに移動できるインフルエンサー数の取得
          this.$accessor.pages.order.getFinalConfirmationInfluencerTotal({
            name: brand,
            page: 1,
            number: parseInt(id),
            agencyName: name
          });
        }
      } catch (e) {
        this.$accessor.error.showError(e);
      } finally {
        this.$accessor.presentation.dismissLoading();
      }
    },
    async toggleRowInfluencerCheckbox(
      context,
      payload: {
        index: Number;
        nextConsiderationStatus: AssignmentInfluencerConsiderationStatus;
        variables: ToggleConfirmInfluencersConsiderationMutationVariables;
      }
    ) {
      try {
        this.$accessor.presentation.showLoading(null);
        const req = new ToggleConfirmInfluencersConsiderationMutationRequest(
          payload.variables
        );
        const res = await this.$apiClient.mutate(req);
        if (res) {
          context.commit("changeAssignmentInfluencerConsiderationStatus", {
            index: payload.index,
            considerationStatus: payload.nextConsiderationStatus
          });
          const { brand, id, name } = this.$router.currentRoute.params;
          // currentTotal用のクエリのフェッチ
          this.$accessor.pages.order.getCurrentTotal({
            name: brand,
            page: 1,
            number: parseInt(id),
            agencyName: name
          });
          // final confirmationに移動できるインフルエンサー数の取得
          this.$accessor.pages.order.getFinalConfirmationInfluencerTotal({
            name: brand,
            page: 1,
            number: parseInt(id),
            agencyName: name
          });
        }
      } catch (e) {
        this.$accessor.error.showError(e);
      } finally {
        this.$accessor.presentation.dismissLoading();
      }
    },
    async confirmOrders(
      _,
      payload: AddConsiderationInfluencersToConfirmInfluencersMutationVariables
    ) {
      try {
        const req = new AddConsiderationInfluencersToConfirmInfluencersMutationRequest(
          payload
        );
        const res = await this.$apiClient.mutate(req);
        if (res && res.addConsiderationInfluencersToConfirmInfluencers) {
          const updatedInBackground =
            res.addConsiderationInfluencersToConfirmInfluencers
              .areConsiderationInfluencersUpdated;
          if (updatedInBackground) {
            return updatedInBackground;
          }
          this.$router.push(`${this.$router.currentRoute.path}/confirm`);
        }
      } catch (e) {
        this.$accessor.error.showError(e);
      } finally {
        this.$accessor.presentation.dismissLoading();
      }
    }
  }
);
