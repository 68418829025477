













import Vue from "vue";
import AgencyHeader from "~/components/the/header/agency-header.vue";
import Content from "~/components/the/content.vue";
import AppLoading from "~/components/the/app-loading.vue";
import ErrorDialog from "~/components/the/error-dialog.vue";

export default Vue.extend({
  components: {
    AgencyHeader,
    Content,
    ErrorDialog,
    AppLoading
  }
});
