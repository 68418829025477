import { actionTree, mutationTree } from "nuxt-typed-vuex";
import {
  SnapInfluencerEvaluationGroup,
  PaginatorInfo,
  SnapshotsQueryRequest,
  SnapshotsQueryVariables,
  SnapInfluencerEvaluationGroupEdge
} from "~/types/gen/api";
import { resetState } from "~/store/utils";

export type snapshotsState = {
  snapshot: SnapInfluencerEvaluationGroup | null;
  pager: Pick<PaginatorInfo, "total" | "count" | "currentPage">;
};

export const state = (): snapshotsState => ({
  snapshot: null,
  pager: {
    total: 0,
    count: 0,
    currentPage: 0
  }
});

export const mutations = mutationTree(state, {
  recieveSnapshots(state, payload) {
    state.snapshot = payload.map(
      (v: SnapInfluencerEvaluationGroupEdge) =>
        v.node.snapInfluencerEvaluationGroup
    );
  },
  recievePaginatorInfo(state, payload) {
    state.pager = payload;
  },
  reset: resetState(state)
});

export const actions = actionTree(
  {
    state,
    mutations
  },
  {
    async init(context, payload: SnapshotsQueryVariables) {
      const req = new SnapshotsQueryRequest(payload);
      const res = await this.$apiClient.query(req);
      context.commit(
        "recieveSnapshots",
        res.brand.influencerEvaluationGroup!.snapInfluencerEvaluationGroups!
          .edges
      );
      context.commit(
        "recievePaginatorInfo",
        res.brand.influencerEvaluationGroup!.snapInfluencerEvaluationGroups!
          .paginatorInfo
      );
    }
  }
);
