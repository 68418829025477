import { actionTree, mutationTree } from "nuxt-typed-vuex";
import {
  ClientCampaignComment,
  ClientCampaignCommentQueryVariables,
  ClientCampaignCommentQueryRequest,
  SendSuggestionCommentMutationVariables,
  SendSuggestionCommentMutationRequest,
  SendAssignmentCommentMutationVariables,
  SendAssignmentCommentMutationRequest,
  SendReportCommentMutationVariables,
  SendReportCommentMutationRequest,
  ReadSuggestionCommentMutationVariables,
  ReadSuggestionCommentMutationRequest,
  ReadAssignmentCommentMutationVariables,
  ReadAssignmentCommentMutationRequest,
  ReadReportCommentMutationVariables,
  ReadReportCommentMutationRequest,
  AgencyCampaignComment,
  AgencyCampaignCommentQueryVariables,
  AgencyCampaignCommentQueryRequest,
  Comment,
  SendDraftSuggestionCommentMutationVariables,
  SendDraftSuggestionCommentMutationRequest,
  SendDraftAssignmentCommentMutationVariables,
  SendDraftAssignmentCommentMutationRequest,
  SendDraftReportCommentMutationVariables,
  SendDraftReportCommentMutationRequest
} from "~/types/gen/api";
import { resetState } from "~/store/utils";

export type commentsState = {
  clientCampaignComment: ClientCampaignComment;
  agencyCampaignComment: AgencyCampaignComment;
  suggestionComment: Comment[];
  assignmentComment: Comment[];
  reportComment: Comment[];
  draftComment: Comment[];
};

export const state = (): commentsState => ({
  clientCampaignComment: {
    campaignId: "",
    totalSuggestionUnreadComment: 0,
    totalAssignmentUnreadComment: 0,
    totalReportUnreadComment: 0,
    suggestionComments: {
      unreadCommentCount: 0,
      comments: []
    },
    assignmentComments: {
      unreadCommentCount: 0,
      comments: []
    },
    reportComments: {
      unreadCommentCount: 0,
      comments: []
    }
  },
  agencyCampaignComment: {
    campaignId: "",
    suggestionComments: {
      unreadCommentCount: 0,
      comments: []
    },
    assignmentComments: {
      unreadCommentCount: 0,
      comments: []
    },
    reportComments: {
      unreadCommentCount: 0,
      comments: []
    }
  },
  suggestionComment: [],
  assignmentComment: [],
  reportComment: [],
  draftComment: []
});

export const mutations = mutationTree(state, {
  receiveClientCampaignComment(state, payload) {
    state.clientCampaignComment = payload;
  },
  receiveAgencyCampaignComment(state, payload) {
    state.agencyCampaignComment = payload;
  },
  receiveSuggestionComment(state, payload) {
    state.suggestionComment = payload;
  },
  receiveAssignmentComment(state, payload) {
    state.assignmentComment = payload;
  },
  receiveReportComment(state, payload) {
    state.reportComment = payload;
  },
  receiveDraftSuggestionComment(state, payload) {
    state.suggestionComment = payload;
  },
  receiveDraftAssignmentComment(state, payload) {
    state.assignmentComment = payload;
  },
  receiveDraftComment(state, payload) {
    state.draftComment = payload;
  },
  reset: resetState(state)
});

export const actions = actionTree(
  { state },
  {
    // Receive Campaign Comment
    async initClient(context, payload: ClientCampaignCommentQueryVariables) {
      try {
        this.$accessor.presentation.showLoading(null);
        const req = new ClientCampaignCommentQueryRequest(payload);
        const res = await this.$apiClient.query(req);
        if (res) {
          context.commit(
            "receiveClientCampaignComment",
            res.clientCampaignComment
          );
        }
      } catch (e) {
        this.$accessor.error.showError(e);
      } finally {
        this.$accessor.presentation.dismissLoading();
      }
    },
    async initAgency(context, payload: AgencyCampaignCommentQueryVariables) {
      try {
        this.$accessor.presentation.showLoading(null);
        const req = new AgencyCampaignCommentQueryRequest(payload);
        const res = await this.$apiClient.query(req);
        if (res) {
          context.commit(
            "receiveAgencyCampaignComment",
            res.agencyCampaignComment
          );
        }
      } catch (e) {
        this.$accessor.error.showError(e);
      } finally {
        this.$accessor.presentation.dismissLoading();
      }
    },
    async sendSuggestionComment(
      _,
      payload: SendSuggestionCommentMutationVariables
    ) {
      try {
        this.$accessor.presentation.showLoading(null);
        const req = new SendSuggestionCommentMutationRequest(payload);
        const res = await this.$apiClient.mutate(req);
        if (res) {
          _.commit(
            "receiveSuggestionComment",
            res.sendSuggestionComment!.comments
          );
        }
      } catch (e) {
        this.$accessor.error.showError(e);
      } finally {
        this.$accessor.presentation.dismissLoading();
      }
    },
    async sendAssignmentComment(
      _,
      payload: SendAssignmentCommentMutationVariables
    ) {
      try {
        this.$accessor.presentation.showLoading(null);
        const req = new SendAssignmentCommentMutationRequest(payload);
        const res = await this.$apiClient.mutate(req);
        if (res) {
          _.commit(
            "receiveAssignmentComment",
            res.sendAssignmentComment!.comments
          );
        }
      } catch (e) {
        this.$accessor.error.showError(e);
      } finally {
        this.$accessor.presentation.dismissLoading();
      }
    },
    async sendReportComment(_, payload: SendReportCommentMutationVariables) {
      try {
        this.$accessor.presentation.showLoading(null);
        const req = new SendReportCommentMutationRequest(payload);
        const res = await this.$apiClient.mutate(req);
        if (res) {
          _.commit("receiveReportComment", res.sendReportComment!.comments);
        }
      } catch (e) {
        this.$accessor.error.showError(e);
      } finally {
        this.$accessor.presentation.dismissLoading();
      }
    },
    async readSuggestionComment(
      _,
      payload: ReadSuggestionCommentMutationVariables
    ) {
      try {
        this.$accessor.presentation.showLoading(null);
        const req = new ReadSuggestionCommentMutationRequest(payload);
        await this.$apiClient.mutate(req);
      } catch (e) {
        this.$accessor.error.showError(e);
      } finally {
        this.$accessor.presentation.dismissLoading();
      }
    },
    async readAssignmentComment(
      _,
      payload: ReadAssignmentCommentMutationVariables
    ) {
      try {
        this.$accessor.presentation.showLoading(null);
        const req = new ReadAssignmentCommentMutationRequest(payload);
        await this.$apiClient.mutate(req);
      } catch (e) {
        this.$accessor.error.showError(e);
      } finally {
        this.$accessor.presentation.dismissLoading();
      }
    },
    async readReportComment(_, payload: ReadReportCommentMutationVariables) {
      try {
        this.$accessor.presentation.showLoading(null);
        const req = new ReadReportCommentMutationRequest(payload);
        await this.$apiClient.mutate(req);
      } catch (e) {
        this.$accessor.error.showError(e);
      } finally {
        this.$accessor.presentation.dismissLoading();
      }
    },
    async sendDraftSuggestionComment(
      _,
      payload: SendDraftSuggestionCommentMutationVariables
    ) {
      try {
        this.$accessor.presentation.showLoading(null);
        const req = new SendDraftSuggestionCommentMutationRequest(payload);
        const res = await this.$apiClient.mutate(req);
        if (res) {
          _.commit(
            "receiveDraftComment",
            res.sendDraftSuggestionComment!.comment
          );
        }
      } catch (e) {
        this.$accessor.error.showError(e);
      } finally {
        this.$accessor.presentation.dismissLoading();
      }
    },
    async sendDraftAssignmentComment(
      _,
      payload: SendDraftAssignmentCommentMutationVariables
    ) {
      try {
        this.$accessor.presentation.showLoading(null);
        const req = new SendDraftAssignmentCommentMutationRequest(payload);
        const res = await this.$apiClient.mutate(req);
        if (res) {
          _.commit(
            "receiveDraftComment",
            res.sendDraftAssignmentComment!.comment
          );
        }
      } catch (e) {
        this.$accessor.error.showError(e);
      } finally {
        this.$accessor.presentation.dismissLoading();
      }
    },
    async sendDraftReportComment(
      _,
      payload: SendDraftReportCommentMutationVariables
    ) {
      try {
        this.$accessor.presentation.showLoading(null);
        const req = new SendDraftReportCommentMutationRequest(payload);
        const res = await this.$apiClient.mutate(req);
        if (res) {
          _.commit("receiveDraftComment", res.sendDraftReportComment);
        }
      } catch (e) {
        this.$accessor.error.showError(e);
      } finally {
        this.$accessor.presentation.dismissLoading();
      }
    }
  }
);
