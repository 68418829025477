import { actionTree, mutationTree } from "nuxt-typed-vuex";
import {
  CampaignReportRequestMutationRequest,
  CampaignReportRequestMutationVariables
} from "~/types/gen/api";
import { Message } from "element-ui";

export type campaignReportRequestState = {
  response: boolean;
};

export const state = (): campaignReportRequestState => ({
  response: false
});

export const mutations = mutationTree(state, {
  receiveRequestResponse(state, response) {
    state.response = response;
  }
});

export const actions = actionTree(
  { state },
  {
    async downloadCsv(
      context,
      payload: CampaignReportRequestMutationVariables
    ) {
      try {
        this.$accessor.presentation.showLoading(null);
        const req = new CampaignReportRequestMutationRequest(payload);
        const res = await this.$apiClient.mutate(req);
        if (res.campaignReportRequest!.response) {
          Message("Request was sent successfully");
          context.commit(
            "receiveRequestResponse",
            res.campaignReportRequest!.response
          );
        }
      } catch (e) {
        this.$accessor.error.showError(e);
      } finally {
        this.$accessor.presentation.dismissLoading();
      }
    }
  }
);
