import { actionTree, mutationTree, getterTree } from "nuxt-typed-vuex";
import {
  TrainingInfluencer,
  InfluencerPostsQueryRequest,
  InfluencerPostsQueryVariables
} from "~/types/gen/api";
import { resetState } from "~/store/utils";

export type influencerPostsState = {
  trainingInfluencer: TrainingInfluencer | null;
};

export const state = (): influencerPostsState => ({
  trainingInfluencer: null
});

export const mutations = mutationTree(state, {
  recieveTrainigInfluencer(state, payload) {
    state.trainingInfluencer = payload;
  },
  reset: resetState(state)
});

export const getters = getterTree(state, {
  influencerName(state) {
    return state.trainingInfluencer && state.trainingInfluencer.name;
  },
  affinityPosts(state) {
    return state.trainingInfluencer && state.trainingInfluencer.affinityPosts;
  },
  recentPosts(state) {
    return state.trainingInfluencer && state.trainingInfluencer.resentPosts;
  },
  likedPosts(state) {
    return state.trainingInfluencer && state.trainingInfluencer.likedPosts;
  }
});

export const actions = actionTree(
  {
    state,
    mutations
  },
  {
    async init(context, payload: InfluencerPostsQueryVariables) {
      try {
        this.$accessor.presentation.showLoading(null);
        const req = new InfluencerPostsQueryRequest(payload);
        const res = await this.$apiClient.query(req);
        if (res) {
          context.commit(
            "recieveTrainigInfluencer",
            res.brand.influencerEvaluationGroup!.influencerEvaluation!
              .trainingInfluencer
          );
        }
      } catch (e) {
        throw e;
      } finally {
        this.$accessor.presentation.dismissLoading();
      }
    }
  }
);
