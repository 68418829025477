export default {
  title: {
    influencer_pyramid: "Influencer Pyramid"
  },
  label: {
    top: "TOP",
    middle: "Middle",
    increase: "Rising(%) in the past 3 months",
    trend_setter: "Trend Setter",
    micro: "Micro"
  },
  placeholder: {
    min: "Minimum value",
    max: "Maximum value",
    increase: "Increase rate (numbers only)"
  }
};
