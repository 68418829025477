export default {
  title: {
    create_influencer_eval: "Create Influencer Evaluation",
    main_post: "{name}を代表する投稿",
    history: "History"
  },
  label: {
    num_of_participants: "# of Evaluated Influencers",
    affinity_post: "表現度の高い投稿",
    recent_post: "最近の投稿",
    likes_post: "いいねが多い投稿",
    explanation: "Explanation",
    all: "ALL",
    unevaluated: "UNEVALUATION",
    not_snapped: "NO History",
    enter_name: "Please enter name here"
  },
  button: {
    create_master: "Create Master",
    update_list: "Update List",
    snap: "History"
  },
  message: {
    is_snap: "Do you take a history of this influencer list？",
    saved_successfully: "The influencer list was saved successfully",
    is_update: "Do you update influencer list？",
    not_update: `
      This may take a little while. <br>
      Influencers cannot be evaluated until this update is completed.
    `,
    send_notification_after_update:
      "You will recieve a email notification after this update is done",
    uploading: "Uploading..",
    take_time_for_upload: "This may take a little while"
  }
};
