import { Message } from "element-ui";
import { actionTree, mutationTree } from "nuxt-typed-vuex";
import {
  PaginatorInfo,
  ClientAssignment,
  ConfirmedInfluencersQueryRequest,
  ConfirmedInfluencersQueryVariables,
  AssignmentStatus,
  SummaryInfluencerScore,
  AssignmentInfluencerEdge,
  AssignmentInfluencer,
  ClientBrand,
  CampaignAgency,
  CampaignIndicator,
  DownloadAssignmentInfluencersMutationRequest,
  DownloadAssignmentInfluencersMutationVariables,
  RemoveConfirmedInfluencersMutationRequest,
  RemoveConfirmedInfluencersMutationVariables,
  ApproveConfirmedInfluencersMutationRequest,
  ApproveConfirmedInfluencersMutationVariables
} from "~/types/gen/api";
import { convertSummaryInfluencerScore } from "~/store/utils";
import BrandResponse from "~/store/responses/brandResponse";
import { downloadFileByURL } from "~/utils/downloadFileByURL.ts";

export type confirmedInfluencersState = {
  influencers: AssignmentInfluencer[];
  pager: Pick<PaginatorInfo, "total" | "count" | "currentPage" | "perPage">;
  agency: Maybe<CampaignAgency>;
  campaignIndicator: CampaignIndicator | null;
  campaignId: string;
  assignment: ClientAssignment | {};
  isApprovedModalClose: boolean;
  isRejectedModalClose: boolean;
  assignmentStatus: AssignmentStatus;
  summaryInfluencerScore: SummaryInfluencerScore;
  summaries: SummaryInfluencerScore[];
  winningSummaries: SummaryInfluencerScore[];
  currentTotal: ClientAssignment | null;
};

export const state = (): confirmedInfluencersState => ({
  influencers: [],
  pager: {
    total: 0,
    count: 0,
    currentPage: 1,
    perPage: 0
  },
  agency: null,
  campaignIndicator: null,
  campaignId: "",
  assignment: {},
  isApprovedModalClose: true,
  isRejectedModalClose: true,
  assignmentStatus: AssignmentStatus.CheckingAvailability,
  summaryInfluencerScore: {
    totalCost: 0,
    totalCommission: 0,
    totalInfluencerCount: 0,
    totalFollower: 0,
    totalFollow: 0,
    averageEngagementRate: 0,
    averageAffinityRate: 0
  },
  summaries: [],
  winningSummaries: [],
  currentTotal: null
});

export const mutations = mutationTree(state, {
  receiveCampaignIndicator(state, payload) {
    state.campaignIndicator = payload;
  },
  receiveInfluencers(state, payload) {
    state.influencers = payload.map(
      (v: AssignmentInfluencerEdge) => v.node!.assignmentInfluencer
    );
  },
  receivePaginatorInfo(state, payload) {
    state.pager = payload;
  },
  receiveCampaign(state, payload) {
    state.agency = payload.agency;
    state.campaignId = payload.id;
    state.assignment = payload.agency.assignment;
  },
  closeApproveModal(state) {
    state.isApprovedModalClose = false;
  },
  closeRejectModal(state) {
    state.isRejectedModalClose = false;
  },
  receiveAssignmentStatus(state, payload) {
    state.assignmentStatus = payload;
  },
  receiveCurrentTotal(state, payload) {
    state.currentTotal = payload;
  },
  receiveSummaryInfluencerScore(state, payload) {
    state.summaryInfluencerScore = convertSummaryInfluencerScore(payload);
  },
  receiveSummaries(state, payload) {
    state.summaries = payload;
  },
  receiveWinningSummaries(state, payload) {
    state.winningSummaries = payload;
  }
});

export const actions = actionTree(
  { state },
  {
    async init(context, payload: ConfirmedInfluencersQueryVariables) {
      try {
        this.$accessor.presentation.showLoading(null);
        const req = new ConfirmedInfluencersQueryRequest(payload);
        const res = await this.$apiClient.query(req);
        const brandRes = new BrandResponse(res.brand as ClientBrand);
        const { getListState } = brandRes;

        if (res) {
          const { edges, paginatorInfo } = getListState(
            brandRes.agencyAssignmentInfluencers
          );
          context.commit("receiveInfluencers", edges);
          context.commit("receivePaginatorInfo", paginatorInfo);
          context.commit("receiveCampaign", brandRes.campaign);
          context.commit("receiveAssignmentStatus", brandRes.agencyStatus);
          if (brandRes.agencyAssignmentSummaries) {
            // スコアサマリーのデータをコミット
            context.commit(
              "receiveSummaryInfluencerScore",
              brandRes.agencyAssignmentSummaries[0]
            );
          }
          context.commit("receiveCurrentTotal", brandRes.agencyAssignment);
          context.commit(
            "receiveCampaignIndicator",
            brandRes.campaign!.indicator
          );
          context.commit(
            "receiveSummaries",
            brandRes.agencyAssignmentSummaries
          );
          context.commit(
            "receiveWinningSummaries",
            brandRes.agencyAssignmentWinningSummaries
          );
        }
      } catch (e) {
        this.$accessor.error.showError(e);
      } finally {
        this.$accessor.presentation.dismissLoading();
      }
    },
    async confirm(
      context,
      payload: ApproveConfirmedInfluencersMutationVariables
    ) {
      try {
        this.$accessor.presentation.showLoading(null);
        const req = new ApproveConfirmedInfluencersMutationRequest(payload);
        const res = await this.$apiClient.mutate(req);
        if (res) {
          context.commit("closeApproveModal");
          // リロード
          this.$router.go(0);
        }
      } catch (e) {
        this.$accessor.error.showError(e);
      } finally {
        this.$accessor.presentation.dismissLoading();
      }
    },
    async remove(
      context,
      payload: RemoveConfirmedInfluencersMutationVariables
    ) {
      try {
        this.$accessor.presentation.showLoading(null);
        const req = new RemoveConfirmedInfluencersMutationRequest(payload);
        const res = await this.$apiClient.mutate(req);
        if (res) {
          const { brand, id, name } = this.$router.currentRoute.params;
          context.commit("closeRejectModal");
          Message("Rejection request has been succeeded!");
          this.$router.replace(`/${brand}/campaigns/${id}/orders/${name}`);
        }
      } catch (e) {
        this.$accessor.error.showError(e);
      } finally {
        this.$accessor.presentation.dismissLoading();
      }
    },
    async downloadCsv(
      _,
      payload: DownloadAssignmentInfluencersMutationVariables
    ) {
      try {
        this.$accessor.presentation.showLoading(null);
        const req = new DownloadAssignmentInfluencersMutationRequest(payload);
        const res = await this.$apiClient.mutate(req);
        if (res.downloadAssignmentInfluencers!.url) {
          downloadFileByURL(res.downloadAssignmentInfluencers!.url);
        }
      } catch (e) {
        this.$accessor.error.showError(e);
      } finally {
        this.$accessor.presentation.dismissLoading();
      }
    }
  }
);
