




















































import Vue from "vue";
import { Brand, ClientCampaign } from "~/types/gen/api";
import Menu from "~/components/ui/menu/menu.vue";

export default Vue.extend({
  components: {
    Menu
  },
  props: {
    currentBrandName: {
      type: String as () => Brand["name"],
      default: true
    }
  },
  computed: {
    campaigns(): Pick<ClientCampaign, "name" | "number">[] {
      return this.$accessor.clientAccount.campaignMenu;
    },
    limitCampaigns(): Pick<ClientCampaign, "name" | "number">[] {
      return this.campaigns.slice(0, 5);
    }
  },
  methods: {
    handleSelect(val: { key: string; keyPath: string }) {
      this.$router.push(val.key);
    }
  }
});
