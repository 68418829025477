export default {
  label: {
    level: "Level",
    influencer_name: "Influencer Name",
    follower_num: "# of Followers",
    affinity: "Affinity",
    incremental_rate: "Increase Rate",
    is_snap: "Snapped",
    eval_date: "Evaluated Date",
    action: "Action",
    evaluation: "Evaluation",
    note: "Note"
  },
  button: {
    check_post: "Check Post"
  }
};
