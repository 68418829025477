import { actionTree, mutationTree, getterTree } from "nuxt-typed-vuex";
import {
  AffinityModel,
  InfluencerEvaluationGroup,
  AffinityModelQueryRequest,
  AffinityModelQueryVariables
} from "~/types/gen/api";
import { resetState } from "~/store/utils";

export type AffinityModelState = {
  affinityModel: AffinityModel | null;
  influencerEvaluationGroup: InfluencerEvaluationGroup | null;
};
export const state = (): AffinityModelState => ({
  affinityModel: null,
  influencerEvaluationGroup: null
});

export const mutations = mutationTree(state, {
  recieveAffinityModel(state, payload: AffinityModel) {
    state.affinityModel = payload;
    state.influencerEvaluationGroup = payload.snapInfluencerEvaluationGroup!.influencerEvaluationGroup;
  },
  reset: resetState(state)
});

export const getters = getterTree(state, {
  title(state) {
    return (
      state.influencerEvaluationGroup && state.influencerEvaluationGroup!.title
    );
  }
});

export const actions = actionTree(
  {
    state
  },
  {
    async init(context, payload: AffinityModelQueryVariables) {
      const req = new AffinityModelQueryRequest({
        name: payload.name,
        number: payload.number
      });
      const res = this.$apiClient.query(req);
      context.commit("recieveAffinityModel", (await res).brand.affinityModel);
    }
  }
);
