export interface IntrospectionResultData {
  __schema: {
    types: {
      kind: string;
      name: string;
      possibleTypes: {
        name: string;
      }[];
    }[];
  };
}
const result: IntrospectionResultData = {
  __schema: {
    types: [
      {
        kind: "INTERFACE",
        name: "Node",
        possibleTypes: [
          {
            name: "AffinityModel"
          },
          {
            name: "SnapInfluencerEvaluationGroup"
          },
          {
            name: "InfluencerEvaluationGroup"
          },
          {
            name: "InfluencerEvaluationNode"
          },
          {
            name: "MasterInfluencerEvaluation"
          },
          {
            name: "TrainingInfluencer"
          },
          {
            name: "Instagram"
          },
          {
            name: "InstagramPost"
          },
          {
            name: "InstagramPostMedia"
          },
          {
            name: "SnapInfluencerEvaluationGroupNode"
          },
          {
            name: "SnapInfluencerEvaluationNode"
          },
          {
            name: "SnapInfluencerEvaluation"
          },
          {
            name: "SnapTrainingInfluencer"
          },
          {
            name: "CoreModel"
          },
          {
            name: "Logic"
          },
          {
            name: "UsedCampaign"
          },
          {
            name: "ProductItem"
          },
          {
            name: "ProductSubCategory"
          },
          {
            name: "AssignmentInfluencerNode"
          },
          {
            name: "AssignmentInfluencer"
          },
          {
            name: "AssignmentInfluencerInsightBySocialMedia"
          },
          {
            name: "Suggestion"
          },
          {
            name: "AgencyInfo"
          },
          {
            name: "SuggestionInfluencerNode"
          },
          {
            name: "SuggestionInfluencer"
          },
          {
            name: "Influencer"
          },
          {
            name: "SuggestionInfluencerInsightBySocialMedia"
          },
          {
            name: "AssignmentInfluencerHistory"
          },
          {
            name: "AssignmentInfluencerInsightBySocialMediaHistory"
          },
          {
            name: "ReportInfluencerNode"
          },
          {
            name: "ReportInfluencer"
          },
          {
            name: "ReportInfluencerInsightBySocialMedia"
          },
          {
            name: "ScoredInfluencerNode"
          },
          {
            name: "ScoredInfluencer"
          },
          {
            name: "ClientAgency"
          },
          {
            name: "AgencyAccountInfo"
          },
          {
            name: "AgencyAccount"
          },
          {
            name: "AgencyBrand"
          },
          {
            name: "AgencyCampaign"
          },
          {
            name: "CampaignIndicator"
          },
          {
            name: "CampaignInstagramIndicator"
          },
          {
            name: "CampaignTwitterIndicator"
          },
          {
            name: "CampaignYoutubeIndicator"
          },
          {
            name: "CampaignTiktokIndicator"
          },
          {
            name: "AgencyAssignment"
          },
          {
            name: "CampaignInfo"
          },
          {
            name: "ClientAccount"
          },
          {
            name: "ClientBrand"
          },
          {
            name: "AffinityModelNode"
          },
          {
            name: "InfluencerEvaluationGroupNode"
          },
          {
            name: "ClientCampaign"
          },
          {
            name: "CampaignAgency"
          },
          {
            name: "ClientAssignment"
          },
          {
            name: "Tag"
          },
          {
            name: "ClientCampaignsFilterDropdownOptions"
          },
          {
            name: "DropdownOption"
          },
          {
            name: "InfluencerSegment"
          },
          {
            name: "ProductCategory"
          },
          {
            name: "UserPostVector"
          },
          {
            name: "Client"
          }
        ]
      },
      {
        kind: "INTERFACE",
        name: "Connection",
        possibleTypes: [
          {
            name: "InfluencerEvaluationConnection"
          },
          {
            name: "SnapInfluencerEvaluationGroupConnection"
          },
          {
            name: "SnapInfluencerEvaluationConnection"
          },
          {
            name: "AssignmentInfluencerConnection"
          },
          {
            name: "SuggestionInfluencerConnection"
          },
          {
            name: "ReportInfluencerConnection"
          },
          {
            name: "ScoredInfluencerConnection"
          },
          {
            name: "AffinityModelConnection"
          },
          {
            name: "InfluencerEvaluationGroupConnection"
          }
        ]
      },
      {
        kind: "INTERFACE",
        name: "Edge",
        possibleTypes: [
          {
            name: "InfluencerEvaluationEdge"
          },
          {
            name: "SnapInfluencerEvaluationGroupEdge"
          },
          {
            name: "SnapInfluencerEvaluationEdge"
          },
          {
            name: "AssignmentInfluencerEdge"
          },
          {
            name: "SuggestionInfluencerEdge"
          },
          {
            name: "ReportInfluencerEdge"
          },
          {
            name: "ScoredInfluencerEdge"
          },
          {
            name: "AffinityModelEdge"
          },
          {
            name: "InfluencerEvaluationGroupEdge"
          }
        ]
      },
      {
        kind: "INTERFACE",
        name: "InfluencerEvaluation",
        possibleTypes: [
          {
            name: "MasterInfluencerEvaluation"
          },
          {
            name: "SnapInfluencerEvaluation"
          }
        ]
      },
      {
        kind: "INTERFACE",
        name: "BaseInfluencer",
        possibleTypes: [
          {
            name: "TrainingInfluencer"
          },
          {
            name: "SnapTrainingInfluencer"
          },
          {
            name: "AssignmentInfluencer"
          },
          {
            name: "ReportInfluencer"
          },
          {
            name: "ScoredInfluencer"
          }
        ]
      },
      {
        kind: "INTERFACE",
        name: "SocialMediaId",
        possibleTypes: [
          {
            name: "Instagram"
          }
        ]
      },
      {
        kind: "INTERFACE",
        name: "Campaign",
        possibleTypes: [
          {
            name: "UsedCampaign"
          },
          {
            name: "AgencyCampaign"
          },
          {
            name: "CampaignInfo"
          },
          {
            name: "ClientCampaign"
          }
        ]
      },
      {
        kind: "INTERFACE",
        name: "InfluencerInsightBySocialMedia",
        possibleTypes: [
          {
            name: "AssignmentInfluencerInsightBySocialMedia"
          },
          {
            name: "SuggestionInfluencerInsightBySocialMedia"
          },
          {
            name: "ReportInfluencerInsightBySocialMedia"
          }
        ]
      },
      {
        kind: "UNION",
        name: "SocialMediaInsight",
        possibleTypes: [
          {
            name: "InstagramInsight"
          },
          {
            name: "TwitterInsight"
          },
          {
            name: "YoutubeInsight"
          },
          {
            name: "TiktokInsight"
          }
        ]
      },
      {
        kind: "INTERFACE",
        name: "SummaryInfluencerScore",
        possibleTypes: [
          {
            name: "Suggestion"
          },
          {
            name: "ClientReport"
          },
          {
            name: "ClientAssignment"
          },
          {
            name: "AssignmentInstagramSummary"
          },
          {
            name: "AssignmentTiktokSummary"
          },
          {
            name: "AssignmentTwitterSummary"
          },
          {
            name: "AssignmentWinningSummary"
          },
          {
            name: "AssignmentYoutubeSummary"
          },
          {
            name: "ReportInstagramSummary"
          },
          {
            name: "ReportPlannedTotalSummary"
          },
          {
            name: "ReportTiktokSummary"
          },
          {
            name: "ReportTwitterSummary"
          },
          {
            name: "ReportYoutubeSummary"
          },
          {
            name: "SuggestionInstagramSummary"
          },
          {
            name: "SuggestionTiktokSummary"
          },
          {
            name: "SuggestionTwitterSummary"
          },
          {
            name: "SuggestionYoutubeSummary"
          }
        ]
      },
      {
        kind: "INTERFACE",
        name: "SummaryConsiderationInfluencer",
        possibleTypes: [
          {
            name: "Suggestion"
          },
          {
            name: "ClientAssignment"
          }
        ]
      },
      {
        kind: "INTERFACE",
        name: "Agency",
        possibleTypes: [
          {
            name: "AgencyInfo"
          },
          {
            name: "ClientAgency"
          },
          {
            name: "CampaignAgency"
          }
        ]
      },
      {
        kind: "INTERFACE",
        name: "Report",
        possibleTypes: [
          {
            name: "ClientReport"
          },
          {
            name: "AgencyReport"
          }
        ]
      },
      {
        kind: "UNION",
        name: "SocialMediaPostInsight",
        possibleTypes: [
          {
            name: "InstagramPostInsight"
          },
          {
            name: "TwitterPostInsight"
          },
          {
            name: "YoutubePostInsight"
          },
          {
            name: "TiktokPostInsight"
          }
        ]
      },
      {
        kind: "INTERFACE",
        name: "Account",
        possibleTypes: [
          {
            name: "AgencyAccountInfo"
          },
          {
            name: "AgencyAccount"
          },
          {
            name: "ClientAccount"
          }
        ]
      },
      {
        kind: "INTERFACE",
        name: "Brand",
        possibleTypes: [
          {
            name: "AgencyBrand"
          }
        ]
      },
      {
        kind: "INTERFACE",
        name: "CampaignSocialMediaIndicator",
        possibleTypes: [
          {
            name: "CampaignInstagramIndicator"
          },
          {
            name: "CampaignTwitterIndicator"
          },
          {
            name: "CampaignYoutubeIndicator"
          },
          {
            name: "CampaignTiktokIndicator"
          }
        ]
      },
      {
        kind: "UNION",
        name: "CommentAccount",
        possibleTypes: [
          {
            name: "ClientAccount"
          },
          {
            name: "AgencyAccount"
          }
        ]
      },
      {
        kind: "INTERFACE",
        name: "Assignment",
        possibleTypes: [
          {
            name: "ClientAssignment"
          }
        ]
      },
      {
        kind: "INTERFACE",
        name: "SummaryAssignmentInfluencer",
        possibleTypes: [
          {
            name: "ClientAssignment"
          }
        ]
      }
    ]
  }
};
export default result;
