export default {
  label: {
    created_date: "Created Date",
    description: "Descriptions",
    campaign_list: "List of Used Campaign Titles",
    three_months: "3ヶ月",
    six_months: "6ヶ月",
    twelve_months: "12ヶ月"
  },
  title: {
    create_affinity_model: "Create Affinity Model"
  }
};
