/**
 * @param value
 *
 * 1000 => '1,000'
 * '1000' => '1,000'
 */
export function formatNumber(value: number | string) {
  let num: number;
  if (typeof value === "string" && !isNaN(+value)) {
    num = +value;
  } else if (typeof value !== "number") {
    throw Error(`${value} is not a number`);
  } else {
    num = value;
  }
  return num.toLocaleString();
}

/**
 * @param text
 *
 * '1,000' => '1000'
 */
export function removedComma(text: string): string {
  return text.replace(/,/g, "");
}

/**
 * @param text
 *
 * '111-?a' => 'false'
 * '111' => 'true'
 */
export function checkOnlyNumber(str: string): boolean {
  return /^[0-9]+$/.test(str);
}

/**
 * @param text
 *
 * '0001' => 'true'
 * '1' => 'false'
 */
export function checkStartWithZero(str: string): boolean {
  return /^0+/.test(str);
}

/**
 * Add zero padding before value
 * @param value
 * @param length
 */
export function zeroPadding(value: number | string, length: number = 2) {
  let s = "" + value;
  while (s.length < length) {
    s = "0" + s;
  }
  return s;
}

/**
 * 1000 => '1K'
 * 1000000 => '1M'
 */
export function abbreviateNumber(num: number) {
  let x = ("" + Math.floor(num)).length;
  let p = Math.pow;
  if (x % 3 === 0) {
    x -= 1;
  }
  x -= x % 3;
  return formatDecimalPoint(num / p(10, x)) + " KMBTqQ"[x / 3];
}

/**
 * 0.801 => 80.1
 * @param value
 * @param decimalPoint
 */
export function formatParcent(value: number, decimalPoint: number = 2) {
  return formatDecimalPoint(value * 100, decimalPoint);
}

/**
 * 0.805 => 0.80
 * @param value
 * @param decimalPoint
 */
export function formatDecimalPoint(value: number, decimalPoint: number = 2) {
  const time = Math.pow(10, decimalPoint);
  return Math.floor(value * time) / time;
}

export function removeComma(value: string) {
  return value.replace(/,/g, "");
}
