// [context].[type].[key]
// context: common, domain.[name], page.[name]
// type: label, message, button, warn, format
// key: identifier
// e.g ) module.payouts.button.payout = '入金する'

import common from "./common";
import page from "./page";

export default {
  common,
  page
};
