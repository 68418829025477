import { getterTree, actionTree, mutationTree } from "nuxt-typed-vuex";
import {
  Suggestion,
  AgencySuggestionsQueryRequest,
  UploadSuggestionMutationRequest,
  UploadSuggestionMutationVariables,
  UploadAvailabilityResultsMutationRequest,
  UploadAvailabilityResultsMutationVariables,
  DownloadAssignmentInfluencersToAgencyMutationRequest,
  DownloadAssignmentInfluencersToAgencyMutationVariables,
  AssignmentInfluencer,
  AgencyAssignmentQueryRequest,
  AssignmentStatus,
  AgencyReportInfluencersQueryRequest,
  ReportInfluencer,
  UploadPostedResultsMutationRequest,
  UploadPostedResultsMutationVariables,
  AgencyCampaignDetailQueryRequest,
  Brand,
  AgencyCampaignDetailQueryVariables,
  AgencySuggestionsQueryVariables,
  AgencyAssignmentQueryVariables,
  AgencyReportInfluencersQueryVariables,
  PaginatorInfo,
  AssignmentInfluencerEdge,
  ReportInfluencerEdge,
  DownloadSuggestionInfluencersToAgencyMutationVariables,
  DownloadSuggestionInfluencersToAgencyMutationRequest,
  AgencyAdditionalSuggestionsQueryVariables,
  AgencyAdditionalSuggestionsQueryRequest,
  AgencyCampaignDetailQuery,
  DownloadAgencyReportInfluencersMutationVariables,
  DownloadAgencyReportInfluencersMutationRequest
} from "~/types/gen/api";
import { resetState } from "~/store/utils";
import { Message } from "element-ui";

export type AgencySuggestion = Pick<
  Suggestion,
  "id" | "createdAt" | "note" | "number" | "attachmentFiles"
>;
export type AgencySuggestions = AgencySuggestion[];

export type CompletionInfluencer = Pick<
  ReportInfluencer,
  "id" | "name" | "image" | "status" | "postUrl"
>;

export type AgencyCampaignDetailState = {
  brand: {
    image: string;
  };
  campaignNumber: number | null;
  campaign:
    | AgencyCampaignDetailQuery["agencyAccount"]["brand"]["campaign"]
    | null;
  assignmentInfluencers: AssignmentInfluencer[];
  reportInfluencers: CompletionInfluencer[];
  suggestions: AgencySuggestions;
  assignmentStatus: AssignmentStatus | null;
  tab: string;
  agencyId: string;
  pager: Pick<PaginatorInfo, "total" | "count" | "currentPage">;
  reportPager: Pick<PaginatorInfo, "total" | "count" | "currentPage">;
  suggestionInfluencerUrl: string;
  step: number;
  additionalSuggestions: AgencySuggestions;
  reportInfluencersUrl: string;
  assignmentId: string | null;
  agencyName: string | null;
};

export const state = (): AgencyCampaignDetailState => ({
  brand: {
    image: ""
  },
  campaignNumber: null,
  campaign: null,
  suggestions: [],
  assignmentInfluencers: [],
  reportInfluencers: [],
  assignmentStatus: null,
  tab: "Proposal",
  agencyId: "",
  pager: {
    total: 0,
    count: 0,
    currentPage: 0
  },
  reportPager: {
    total: 0,
    count: 0,
    currentPage: 0
  },
  suggestionInfluencerUrl: "",
  step: 0,
  additionalSuggestions: [],
  reportInfluencersUrl: "",
  assignmentId: "",
  agencyName: ""
});

export const mutations = mutationTree(state, {
  setBrand(state, brand: Pick<Brand, "image">) {
    state.brand.image = brand.image;
  },
  setAgencyName(state, payload) {
    state.agencyName = payload.agencyName;
  },
  setCampaign(
    state,
    campaign: AgencyCampaignDetailQuery["agencyAccount"]["brand"]["campaign"]
  ) {
    state.campaign = campaign;
  },
  setCampaignNumber(state, campaignNumber: number) {
    state.campaignNumber = campaignNumber;
  },
  setSuggestions(state, suggestions) {
    state.suggestions = suggestions;
  },
  addSuggestion(state, suggestion: AgencySuggestion) {
    state.suggestions.unshift(suggestion);
  },
  addAdditionalOrder(state, payload) {
    state.additionalSuggestions.push(payload);
  },
  setAssignmentInfluencers(state, assignmentInfluencers) {
    state.assignmentInfluencers = assignmentInfluencers.map(
      (v: AssignmentInfluencerEdge) => v.node.assignmentInfluencer
    );
  },
  setReportInfluencers(state, reportInfluencers) {
    state.reportInfluencers = reportInfluencers.map(
      (v: ReportInfluencerEdge) => v.node.reportInfluencer
    );
  },
  setAssignmentStatus(state, assignmentStatus: AssignmentStatus) {
    state.assignmentStatus = assignmentStatus;
  },
  setAssignmentId(state, assignmentId: string) {
    state.assignmentId = assignmentId;
  },
  recieveTab(state, payload) {
    state.tab = payload;
  },
  setAgencyAccount(state, payload) {
    state.agencyId = payload.id;
    state.agencyName = payload.agencyName;
  },
  recievePaginatorInfo(state, payload) {
    state.pager = payload;
  },
  recieveReportPaginatorInfo(state, payload) {
    state.reportPager = payload;
  },
  recieveSuggestionAllInfluencerList(state, payload) {
    state.suggestionInfluencerUrl = payload;
  },
  recieveSuggestionConsideringInfluencers(state, payload) {
    state.suggestionInfluencerUrl = payload;
  },
  receiveReportInfluencersUrl(state, payload) {
    state.reportInfluencersUrl = payload;
  },
  changeStep(state, payload) {
    switch (payload) {
      case "Proposal":
        state.step = 0;
        break;
      case "Offer":
        state.step = 1;
        break;
      case "Report":
        state.step = 2;
        break;
    }
  },
  recieveAdditionalSuggestions(state, payload) {
    state.additionalSuggestions = payload;
  },
  reset: resetState(state)
});

export const getters = getterTree(state, {
  isAssignmentCompleted(state): boolean {
    return state.assignmentStatus === AssignmentStatus["Completed"];
  },
  campaignId(state) {
    return state.campaign && state.campaign.id;
  }
});

export const actions = actionTree(
  {
    state,
    mutations
  },
  {
    async getCampaign(
      { state, commit },
      payload: AgencyCampaignDetailQueryVariables
    ): Promise<void> {
      if (state.campaignNumber === null) return;
      try {
        this.$accessor.presentation.showLoading(null);
        const { agencyAccount } = await this.$apiClient.query(
          new AgencyCampaignDetailQueryRequest(payload)
        );
        commit("setBrand", agencyAccount.brand);
        if (agencyAccount) {
          commit("setAgencyName", agencyAccount.agencyName);
        }
        if (agencyAccount.brand.campaign) {
          commit("setCampaign", agencyAccount.brand.campaign);
        }
        const { brandName, campaignNumber } = this.$router.currentRoute.params;
        const { tab } = this.$router.currentRoute.query;
        if (tab === "Offer") {
          this.$router.push({
            path: `/agency/brands/${brandName}/campaigns/${campaignNumber}`,
            query: {
              tab: tab
            }
          });
        }
        if (tab === "Execution") {
          this.$router.push({
            path: `/agency/brands/${brandName}/campaigns/${campaignNumber}`,
            query: {
              tab: tab
            }
          });
        }
      } catch (e) {
        this.$accessor.error.showError(e);
      } finally {
        this.$accessor.presentation.dismissLoading();
      }
    },
    async getSuggestions(
      { state, commit },
      payload: AgencySuggestionsQueryVariables
    ): Promise<void> {
      if (state.campaignNumber === null) return;
      try {
        this.$accessor.presentation.showLoading(null);
        const res = await this.$apiClient.query(
          new AgencySuggestionsQueryRequest(payload)
        );
        if (res.agencyAccount.brand!.campaign) {
          commit(
            "setSuggestions",
            res.agencyAccount.brand!.campaign!.suggestions
          );
        }
        if (res.agencyAccount) {
          commit("setAgencyAccount", res.agencyAccount);
        }
      } catch (e) {
        this.$accessor.error.showError(e);
      } finally {
        this.$accessor.presentation.dismissLoading();
      }
    },
    async uploadSuggestion(
      { commit },
      payload: UploadSuggestionMutationVariables
    ): Promise<void> {
      try {
        this.$accessor.presentation.showLoading(null);
        const { uploadSuggestion } = await this.$apiClient.mutate(
          new UploadSuggestionMutationRequest(payload)
        );
        if (uploadSuggestion) {
          const { suggestion } = uploadSuggestion;
          commit("addSuggestion", suggestion);
          Message("Upload has been successful");
        }
      } catch (e) {
        this.$accessor.error.showError(e);
      } finally {
        this.$accessor.presentation.dismissLoading();
      }
    },
    async uploadAdditionalOrder(
      { commit },
      payload: UploadSuggestionMutationVariables
    ): Promise<void> {
      try {
        this.$accessor.presentation.showLoading(null);
        const req = new UploadSuggestionMutationRequest(payload);
        const res = await this.$apiClient.mutate(req);
        if (res.uploadSuggestion) {
          commit("addAdditionalOrder", res.uploadSuggestion.suggestion);
          Message("Upload has been successful");
        }
      } catch (e) {
        this.$accessor.error.showError(e);
      } finally {
        this.$accessor.presentation.dismissLoading();
      }
    },
    async uploadAvailabilityResult(
      { commit },
      payload: UploadAvailabilityResultsMutationVariables
    ): Promise<void> {
      try {
        this.$accessor.presentation.showLoading(null);
        const res = await this.$apiClient.mutate(
          new UploadAvailabilityResultsMutationRequest(payload)
        );
        if (res) {
          commit(
            "setAssignmentInfluencers",
            res.uploadAvailabilityResults!.assignment.assignmentInfluencers!
              .edges
          );
          commit(
            "recievePaginatorInfo",
            res.uploadAvailabilityResults!.assignment.assignmentInfluencers!
              .paginatorInfo
          );
        }
      } catch (e) {
        this.$accessor.error.showError(e);
      } finally {
        this.$accessor.presentation.dismissLoading();
      }
    },
    async downloadAssignmentInfluencers(
      { state },
      payload: DownloadAssignmentInfluencersToAgencyMutationVariables
    ): Promise<string | null> {
      if (state.campaign && state.campaign.id === null) return null;
      let downloadUrl: string | null = null;
      try {
        this.$accessor.presentation.showLoading(null);
        const {
          downloadAssignmentInfluencersToAgency
        } = await this.$apiClient.mutate(
          new DownloadAssignmentInfluencersToAgencyMutationRequest(payload)
        );
        if (downloadAssignmentInfluencersToAgency) {
          downloadUrl = downloadAssignmentInfluencersToAgency.url;
        }
      } catch (e) {
        this.$accessor.error.showError(e);
      } finally {
        this.$accessor.presentation.dismissLoading();
        return downloadUrl;
      }
    },
    async downloadReportInfluencers(
      context,
      payload: DownloadAgencyReportInfluencersMutationVariables
    ) {
      try {
        this.$accessor.presentation.showLoading(null);
        const req = new DownloadAgencyReportInfluencersMutationRequest(payload);
        const res = await this.$apiClient.mutate(req);
        if (res) {
          context.commit(
            "receiveReportInfluencersUrl",
            res.downloadAgencyReportInfluencers!.url
          );
        }
      } catch (e) {
        this.$accessor.error.showError(e);
      } finally {
        this.$accessor.presentation.dismissLoading();
      }
    },
    async getAssignment(
      { state, commit },
      payload: AgencyAssignmentQueryVariables
    ): Promise<void> {
      if (state.campaignNumber === null) return;
      try {
        this.$accessor.presentation.showLoading(null);
        const res = await this.$apiClient.query(
          new AgencyAssignmentQueryRequest(payload)
        );
        const { assignment } = res.agencyAccount.brand.campaign!;
        if (assignment) {
          commit("setAssignmentStatus", assignment!.status);
          commit("setAssignmentId", assignment!.id);
          commit(
            "setAssignmentInfluencers",
            assignment!.assignmentInfluencers!.edges
          );
          commit(
            "recievePaginatorInfo",
            assignment!.assignmentInfluencers!.paginatorInfo
          );
        }
      } catch (e) {
        this.$accessor.error.showError(e);
      } finally {
        this.$accessor.presentation.dismissLoading();
      }
    },
    async getAgencyReportInfluencers(
      context,
      payload: AgencyReportInfluencersQueryVariables
    ) {
      try {
        this.$accessor.presentation.showLoading(null);
        const res = await this.$apiClient.query(
          new AgencyReportInfluencersQueryRequest(payload)
        );
        if (res) {
          const influencers =
            res.agencyAccount.brand.campaign!.report &&
            res.agencyAccount.brand.campaign!.report!.influencers;
          if (influencers) {
            context.commit("setReportInfluencers", influencers.edges);
            context.commit(
              "recieveReportPaginatorInfo",
              influencers!.paginatorInfo
            );
          }
        }
      } catch (e) {
        this.$accessor.error.showError(e);
      } finally {
        this.$accessor.presentation.dismissLoading();
      }
    },
    async uploadPostedResults(
      context,
      payload: UploadPostedResultsMutationVariables
    ): Promise<void> {
      try {
        this.$accessor.presentation.showLoading(null);
        const res = await this.$apiClient.mutate(
          new UploadPostedResultsMutationRequest(payload)
        );
        if (res) {
          context.commit(
            "setReportInfluencers",
            res.uploadPostedResults!.report!.influencers!.edges
          );
          context.commit(
            "recieveReportPaginatorInfo",
            res.uploadPostedResults!.report!.influencers!.paginatorInfo
          );
          Message("Upload has been successful");
        }
      } catch (e) {
        this.$accessor.error.showError(e);
      } finally {
        this.$accessor.presentation.dismissLoading();
      }
    },
    async changeTab(context, payload: string) {
      context.commit("recieveTab", payload);
      context.commit("changeStep", payload);
    },
    async downloadAllInfluencers(
      context,
      payload: DownloadSuggestionInfluencersToAgencyMutationVariables
    ) {
      try {
        this.$accessor.presentation.showLoading(null);
        const req = new DownloadSuggestionInfluencersToAgencyMutationRequest(
          payload
        );
        const res = await this.$apiClient.mutate(req);
        if (res) {
          context.commit(
            "recieveSuggestionAllInfluencerList",
            res.downloadSuggestionInfluencersToAgency!.url
          );
        }
      } catch (e) {
        this.$accessor.error.showError(e);
      } finally {
        this.$accessor.presentation.dismissLoading();
      }
    },
    async downloadConsideringInfluencers(
      context,
      payload: DownloadSuggestionInfluencersToAgencyMutationVariables
    ) {
      try {
        this.$accessor.presentation.showLoading(null);
        const req = new DownloadSuggestionInfluencersToAgencyMutationRequest(
          payload
        );
        const res = await this.$apiClient.mutate(req);
        if (res) {
          context.commit(
            "recieveSuggestionConsideringInfluencers",
            res.downloadSuggestionInfluencersToAgency!.url
          );
        }
      } catch (e) {
        this.$accessor.error.showError(e);
      } finally {
        this.$accessor.presentation.dismissLoading();
      }
    },
    async getAdditionalSuggestion(
      context,
      payload: AgencyAdditionalSuggestionsQueryVariables
    ) {
      try {
        this.$accessor.presentation.showLoading(null);
        const req = new AgencyAdditionalSuggestionsQueryRequest(payload);
        const res = await this.$apiClient.query(req);
        if (res) {
          context.commit(
            "recieveAdditionalSuggestions",
            res.agencyAccount.brand.campaign!.suggestions
          );
        }
      } catch (e) {
        this.$accessor.error.showError(e);
      } finally {
        this.$accessor.presentation.dismissLoading();
      }
    }
  }
);
