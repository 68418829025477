import { actionTree, mutationTree } from "nuxt-typed-vuex";
import {
  InfluencerSegment,
  SnapInfluencerEvaluation,
  PaginatorInfo,
  SnapTrainingInfluencer,
  SnapshotQueryRequest,
  SnapshotQueryVariables
} from "~/types/gen/api";
import { resetState } from "~/store/utils";

export type snapshotState = {
  snapshot: SnapInfluencerEvaluation | null;
  trainingInfluencer: SnapTrainingInfluencer | null;
  influencerSegment: ([InfluencerSegment] | null)[];
  pager: Pick<PaginatorInfo, "total" | "count" | "currentPage">;
};

export const state = (): snapshotState => ({
  snapshot: null,
  trainingInfluencer: null,
  influencerSegment: [],
  pager: {
    total: 0,
    count: 0,
    currentPage: 1
  }
});

export const mutations = mutationTree(state, {
  recieveSnapshot(state, payload) {
    state.snapshot = payload.map((v: any) => v.node.snapInfluencerEvaluation);
  },
  recieveTrainingInfluencer(state, payload) {
    state.trainingInfluencer = payload.map(
      (v: any) => v.node.snapInfluencerEvaluation.trainingInfluencer
    );
  },
  recieveInfluencerSegment(state, payload) {
    state.influencerSegment = payload.map((v: any) => v);
  },
  recievePaginatorInfo(state, payload) {
    state.pager = payload;
  },
  reset: resetState(state)
});

export const actions = actionTree(
  {
    state,
    mutations
  },
  {
    async init(context, payload: SnapshotQueryVariables) {
      try {
        this.$accessor.presentation.showLoading(null);
        const req = new SnapshotQueryRequest(payload);
        const res = await this.$apiClient.query(req);

        if (res) {
          context.commit(
            "recieveSnapshot",
            res.brand.influencerEvaluationGroup!.snapInfluencerEvaluationGroup!
              .snapInfluencerEvaluations!.edges
          );
          context.commit(
            "recieveTrainingInfluencer",
            res.brand.influencerEvaluationGroup!.snapInfluencerEvaluationGroup!
              .snapInfluencerEvaluations!.edges
          );
          context.commit("recieveInfluencerSegment", res.influencerSegments);
          context.commit(
            "recievePaginatorInfo",
            res.brand.influencerEvaluationGroup!.snapInfluencerEvaluationGroup!
              .snapInfluencerEvaluations!.paginatorInfo
          );
        }
      } catch (e) {
        this.$accessor.error.showError(e);
      } finally {
        this.$accessor.presentation.dismissLoading();
      }
    }
  }
);
