export default {
  label: {
    edit: "ブランドを編集する"
  },
  title: {
    edit_brand: "ブランド編集"
  },
  message: {
    success_edit: "Success! Your brand has been edited."
  }
};
