



















import Vue from "vue";
import Modal from "~/components/ui/modal/modal.vue";
import { APIErrorObject } from "~/network/api-error";
export default Vue.extend({
  components: {
    Modal
  },
  computed: {
    isAgencyPath(): boolean {
      const path = this.$route.path;
      return (
        path.split("/")[1] === "agency-password-reset" ||
        path.split("/")[1] === "agency-login"
      );
    },
    title(): string {
      return this.isAgencyPath
        ? `${this.$t("common.password_expired_ja.title")}`
        : `${this.$t("common.password_expired_en.title")}`;
    },
    message(): string {
      return this.isAgencyPath
        ? `${this.$t("common.password_expired_ja.message")}`
        : `${this.$t("common.password_expired_en.message")}`;
    },
    linkText(): string {
      return this.isAgencyPath
        ? `${this.$t("common.password_expired_ja.link_text")}`
        : `${this.$t("common.password_expired_en.link_text")}`;
    },
    link(): string {
      return this.isAgencyPath
        ? "/agency-password-reset"
        : "/client-password-reset";
    },
    error(): APIErrorObject | null {
      return this.$accessor.error.error;
    },
    shouldShowApiError(): boolean {
      return !!this.error && this.error.name === "APIPasswordExpiredError";
    },
    shouldShowDebugMessage(): boolean {
      return (
        process.env.APP_ENV !== "production" &&
        !!this.error &&
        !!this.error.debugMessage
      );
    }
  },
  methods: {
    handleClose() {
      this.$accessor.error.dismissError();
    },
    test() {
      this.handleClose();
      this.$router.push(this.link);
    }
  }
});
