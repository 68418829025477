export default {
  label: {
    reset_password: "Reset",
    new_password: "New password"
  },
  title: {
    reset_password: "Reset your password"
  },
  button: {
    send_link: "Send link"
  },
  message: {
    change_success: "Password has been successfully changed",
    sent_reset_link:
      "A password reset link has been sent to your email. Please follow the link in the email to complete reset password.",
    sent_failed: "The request has been failed."
  }
};
