

























import Vue from "vue";

export default Vue.extend({
  data() {
    return {
      isCollapse: false
    };
  },
  props: {
    defaultOpeneds: {
      type: Array,
      default: null
    },
    defaultActive: {
      type: String,
      default: ""
    }
  },
  computed: {
    toggleClass(): string {
      return this.isCollapse ? "-close" : "";
    }
  },
  methods: {
    onSelect(selected: string) {
      this.$emit("select", selected);
    }
  }
});
