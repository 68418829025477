export default {
  title: {
    campaign_list: "Campaign List",
    campaign_creation: "Create Campaign",
    campaign_edit: "Edit Campaign",
    affinity_model_selection: "Select Affinity Model",
    product_item_selection: "Select Product Item",
    already_confirmed_capital: "ALREADY FINALIZED",
    campaign_settings: "Campaign Settings",
    agency_settings: "Agency Settings",
    delete_campaign: "Delete Campaign"
  },
  label: {
    create_new_campaign: "Create a new campaign",
    campaign_detail: "Campaign Details",
    pitch_list: "Pitch List",
    order_list: "Order List",
    status: "Status",
    extra_order: "Additional Order",
    additional_influencer: "Additional Influencer",
    proposal_bar_title: "Do you like to select these influencers?",
    influencer: "Influencer",
    influencers: "Influencers",
    is_order: "Do you confirm this proposal?",
    do_you_confirm_this_additional_proposal:
      "Do you confirm this additional proposal?",
    product_category: "プロダクトカテゴリ",
    campaign_start_at: "Campaign Start Date",
    campaign_end_at: "Campaign End Date",
    pitch_start_at: "Pitch Start Date",
    pitch_end_at: "Pitch End Date",
    message_to_agency: "Message to Agency",
    cost: "Cost",
    follower_incremental_rate: "Follower Increase Rate",
    avg_like: "Avg Likes",
    avg_comment: "Avg Comments",
    engagement: "Engagement",
    advocate_score: "Advocate Score",
    assigned_influencers: "Assigned Influencers",
    confirmed_influencers: "Confirmed Influencers",
    approve_influencers: "Do you approve this influencers' list?",
    confirm_again: "Are you sure to confirm?",
    reject_again: "Are you sure to reject?",
    delete_all_influencers_confirm: "Are you sure to delete all influencers?",
    sort_by: "Sort by",
    followers_by_capital: "FOLLOWERS",
    increase_rate_by_capital: "INCREASE RATE",
    affinity_score_by_capital: "AFFINITY SCORE",
    likes_by_capital: "LIKES",
    comments_by_capital: "COMMENTS",
    engagement_by_capital: "ENGAGEMENT",
    cost_by_capital: "COST",
    advocate_score_by_capital: "ADOVOCATE SCORE",
    current_total: "Current Total",
    engagement_rate: "Engagement Rate",
    instagram: "Instagram",
    twitter: "Twitter",
    table: "Table",
    rader_chart: "Radar Chart",
    project_radar_chart: "Project Radar Chart",
    affinity_rate: "Affinity Rate",
    affinity_score: "Affinity Score",
    num_likes: "# of Likes",
    num_comments: "# of Comments",
    num_influencers: "# of Influencers",
    assigned_influencer_planning: "Order",
    order: "Order",
    proposal: "Proposal",
    final_confirmation: "Final Confirmation",
    final_confirmation_capital: "FINAL CONFIRMATION",
    see_all: "See ALL",
    close_agency_modal: "Close Agency Bidding",
    delete_agency_modal: "Delete Agency",
    sucess_agency_delete: "Agency has been deleted",
    instagram_reach_kpi: "Instagram reach KPI",
    twitter_reach_kpi: "Twitter reach KPI",
    youtube_reach_kpi: "Youtube reach KPI",
    campaign_period: "Campaign Period",
    agency_pitch_period: "Agency Pitch Period",
    comment_from_agency: "Comment from Agency",
    influencer_name: "Influencer Name",
    view_count: "View Count",
    max_view_count: "Max View Count",
    skip_pitch: "Skip Pitch",
    objective: "Objective",
    objectives: "Objectives",
    kpi: "KPI",
    attached_files: "Attached files from Agency",
    pitch: "Pitch",
    report: "Report",
    attached_files_from_brand: "Attached files from Brand",
    activity: "Activity",
    campaign_id: "Campaign ID"
  },
  button: {
    add_campaign: "Add Campaign",
    edit_campaign: "Edit Campaign",
    add_agency: "Add Agency",
    close_agency_bidding: "Close Agency Bidding",
    delete_agency: "Delete",
    compare_evaluation: "評価を比較する",
    order: "Order",
    confirm_proposal: "Confirm Proposal",
    confirm_additional_proposal: "Confirm Additional Proposal",
    compare_agencies_with_fism: "FISMと他社を比較する",
    confirm: "Confirm",
    add: "Add",
    ok: "Ok",
    cancel: "Cancel",
    delete: "Delete",
    copy: "Copy",
    delete_campaign: "Delete Campaign",
    confirm_order: "Confirm Order"
  },
  message: {
    no_report: "No report has been submitted yet.",
    no_return_action: "You cannot change agency.",
    notify_action: "{action} will be notified to an agency.",
    no_approval_influencer_list:
      "Completed influencers list has not been submitted yet.",
    agency_will_create_completed_list:
      "Agency will create a completed influencers' list.",
    cost_comparison: `Average Cost: ¥{average}<br>
      Ranking: {ranking}/{agencies}
      `,
    no_proposal: "There is no agency proposal.",
    collecting_data:
      "Still in the process of collecting data. Please wait until it is completed. If data are not proceeded more than a day, please contact customer service.",
    influencer_report_available:
      "The list has been finalized and cannot be changed. The report will be available after campaign ended.",
    checked_influencers_to_confirm_list:
      "Would you like to add checked influencers to final confirmation list?",
    checked_influencers_to_order_list:
      "Do you like to add checked influencers to order list?",
    checked_influencers_to_confirm_list_warning:
      "Only those with an availability status of “○” and account exists are eligible.",
    add_to_order_list_button: "Add to Order List",
    confirm_order:
      "Are you sure to add checked influencers to final confirmation list?",
    confirm_order_message:
      "・Only those with an availability status of “○” and account exists are eligible." +
      '<br>・Availability status will be marked as "○" when updated by the agency.',
    updated_in_background: "Updated a final confirmation list by agency.",
    modal_msg_stop_feature: "The following function cannot be used.",
    modal_msg_delete_agency: "Are you sure you want to delete this Agency?",
    modal_msg_reject_agency_feature:
      "Add bidding agencies<br>Upload proposals by agency<br>",
    modal_msg_reject_agency_notice:
      "**CAUTION** <br> A notification email will not automatically be sent to agencies.",
    modal_skip_to_report_msg:
      "All agencies that are currently registered for the campaign<br>will be able to upload reports.",
    agency_bidding_closed: "Agency bidding is closed.",
    add_agency: "Would you like to add {agency} to the pitch list?",
    message_to_agency: `\
□ Job Scope
e.g.)
・インフルエンサーの投稿
・イベント参加
・Gifting
・レポーティング
□ Target Segment
e.g.)
・Beauty connoisseurs
・Fashion Extroverts
□ Age
e.g.)
・20〜35歳のインフルエンサー
□ Sex
e.g.)
・男：女 = 1:1
□ Report
e.g.)
・キャンペーン終了後1週間以内にアップロードしてください
□ Others
e.g.)
・KPIに合わせて最適な予算配分を一緒にご提案ください
□ Contact
e.g.)
name: John Smith
email: js@email.com
tel: 000-0000-0000
`,
    success_delete: "Success! Campaign has been deleted.",
    confirm_delete: "Are you sure you want to delete the Campaign?"
  },
  validation_error_message: {
    objectives: "The Objectives field is required"
  }
};
