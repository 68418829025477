import { actionTree, mutationTree } from "nuxt-typed-vuex";
import {
  ClientCampaign,
  CampaignsQueryVariables,
  CampaignsQueryRequest,
  DeleteCampaignInput,
  DeleteCampaignMutationRequest
} from "~/types/gen/api";
import { resetState } from "~/store/utils";
import { Message } from "element-ui";

export type campaignsState = {
  campaigns: ClientCampaign[];
};

export const state = (): campaignsState => ({
  campaigns: []
});

export const mutations = mutationTree(state, {
  recieveCampaigns(state, payload) {
    state.campaigns = payload;
  },
  reset: resetState(state)
});

export const actions = actionTree(
  { state },
  {
    async init(context, payload: CampaignsQueryVariables) {
      try {
        this.$accessor.presentation.showLoading(null);
        const req = new CampaignsQueryRequest(payload);
        const res = await this.$apiClient.query(req);
        if (res) {
          context.commit("recieveCampaigns", res.brand.campaigns);
        }
      } catch (e) {
        this.$accessor.error.showError(e);
      } finally {
        this.$accessor.presentation.dismissLoading();
      }
    },
    async deleteCampaign(
      { commit },
      payload: DeleteCampaignInput
    ): Promise<void> {
      const { brandName, number } = payload;
      try {
        this.$accessor.presentation.showLoading(null);
        const res = new DeleteCampaignMutationRequest({
          input: {
            brandName,
            number
          }
        });
        const { deleteCampaign } = await this.$apiClient.mutate(res);
        if (deleteCampaign && deleteCampaign.deleted) {
          commit("deleteCampaign", deleteCampaign.deleted);
          Message(this.$i18n.tc("page.campaign.message.success_delete"));
        }
      } catch (e) {
        this.$accessor.error.showError(e);
      } finally {
        this.$accessor.presentation.dismissLoading();
      }
    }
  }
);
