






















































































import Vue from "vue";
import ClientHeader from "~/components/the/header/client-header.vue";
import Sidebar from "~/components/the/sidebar.vue";
import Content from "~/components/the/content.vue";
import AppLoading from "~/components/the/app-loading.vue";
import ErrorDialog from "~/components/the/error-dialog.vue";
import { CampaignAgency, ClientAssignment } from "~/types/gen/api";

export default Vue.extend({
  components: {
    ClientHeader,
    Sidebar,
    Content,
    AppLoading,
    ErrorDialog
  },
  computed: {
    brandName(): string {
      return this.$route.params.brand;
    },
    campaignId(): string {
      return this.$route.params.id;
    },
    name(): string {
      return this.$route.params.name;
    },
    agencies(): CampaignAgency[] {
      return this.$accessor.clientAccount.agencies;
    },
    clientAssignments(): ClientAssignment[] {
      return this.$accessor.clientAccount.clientAssignments;
    }
  },
  methods: {
    handleClick(name: string): void {
      const { brand, id } = this.$route.params;
      this.$router.push(`/${brand}/campaigns/${id}/agency-proposals/${name}`);
    },
    handleOrderClick(name: string): void {
      const { brand, id } = this.$route.params;
      this.$router.push(`/${brand}/campaigns/${id}/orders/${name}`);
    }
  },
  mounted() {
    const { brand, id } = this.$route.params;
    if (id) {
      this.$accessor.clientAccount.getCampaignAgencies({
        name: brand,
        number: parseInt(id)
      });
    }
  },
  watch: {
    campaignId: {
      handler(to) {
        const { brand } = this.$route.params;
        if (to) {
          this.$accessor.clientAccount.getCampaignAgencies({
            name: brand,
            number: parseInt(to)
          });
        }
      }
    }
  }
});
