/**
 * Must not import this file anywhere, because this is loaded by VeeValidate.
 * https://baianat.github.io/vee-validate/guide/localization.html#vuei18n-integration
 *
 * Should be according to the VeeValidate error messages regulation.
 * https://baianat.github.io/vee-validate/guide/messages.html#message-generators
 */
import eng from "~/plugins/i18n/locales/en/common/index";
import camapignEng from "~/plugins/i18n/locales/en/page/campaign";

const en = require("vee-validate/dist/locale/en");

export default {
  attributes: {
    text: eng.label.text,
    email: eng.label.email,
    password: eng.label.password,
    title: eng.label.title,
    evaluationOptions: eng.label.evaluation_options,
    updateDate: eng.label.influencer_posting_period,
    range: eng.label.range,
    logic: eng.label.logic,
    name: eng.label.name,
    brandName: eng.label.brand_name,
    productItemId: eng.label.product_name,
    startAt: camapignEng.label.campaign_start_at,
    endAt: camapignEng.label.campaign_end_at,
    instagramReachKpi: camapignEng.label.instagram_reach_kpi,
    twitterReachKpi: camapignEng.label.twitter_reach_kpi,
    youtubeReachKpi: camapignEng.label.youtube_reach_kpi,
    suggestionStartAt: camapignEng.label.pitch_start_at,
    suggestionEndAt: camapignEng.label.pitch_end_at,
    campaignName: eng.label.campaign_name,
    keywordAndHashtags: eng.label.keyword_and_hashtags,
    objectives: eng.label.objectives
  },
  messages: {
    ...en.messages,
    _default: (field: string) => `${field} is invalid value.`,
    email: (field: string) => `${field} is invalid.`
  }
};
