
































import Vue from "vue";

export default Vue.extend({
  props: {
    type: {
      type: String,
      default: "default"
    },
    size: {
      type: String,
      default: "medium"
    },
    disabled: {
      type: Boolean,
      default: false
    },
    autoWidth: {
      type: Boolean,
      default: false
    },
    text: {
      type: String,
      required: false,
      default: ""
    },
    icon: {
      type: String,
      default: ""
    },
    nativeType: {
      type: String,
      default: "button"
    },
    round: {
      type: Boolean,
      default: false
    },
    isIconRight: {
      type: Boolean,
      default: false
    },
    rightIcon: {
      type: String,
      default: ""
    },
    plain: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    handleClick(e: Event): void {
      this.$emit("click", e);
      this.$emit("mousedown", e);
    }
  }
});
