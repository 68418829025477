/**
 * make path with page path redirect from
 * @param path
 * @param from
 */
export function makePath(path: string, from: string | null = null): string {
  if (from) {
    return `${path}?from=${from}`;
  }
  return path;
}
