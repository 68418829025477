import {
  AgencyCampaign,
  AgencyBrandCampaignsQueryRequest,
  AgencyBrandCampaignsQueryVariables
} from "~/types/gen/api";
import { actionTree, mutationTree } from "nuxt-typed-vuex";
import { resetState } from "~/store/utils";

type AgencyBrandCampaignsState = {
  campaigns: AgencyCampaign[];
};

export const state = (): AgencyBrandCampaignsState => ({
  campaigns: []
});

export const mutations = mutationTree(state, {
  recieveAgencyBrandCampaigns(state, campaigns: AgencyCampaign[]) {
    state.campaigns = campaigns;
  },
  reset: resetState(state)
});

export const actions = actionTree(
  { state },
  {
    async getAgencyBrandCampaigns(
      { commit },
      payload: AgencyBrandCampaignsQueryVariables
    ): Promise<void> {
      try {
        this.$accessor.presentation.showLoading(null);
        const req = new AgencyBrandCampaignsQueryRequest(payload);
        const res = await this.$apiClient.query(req);
        commit(
          "recieveAgencyBrandCampaigns",
          res.agencyAccount.brand.campaigns
        );
      } catch (e) {
        this.$accessor.error.showError(e);
      } finally {
        this.$accessor.presentation.dismissLoading();
      }
    }
  }
);
