/**
 * These are collections of Unicode code points for SPAD.
 *
 * Unicode references:
 * Unicode 11.0 Character Code Charts
 * http://www.unicode.org/charts/
 * UAX #44: Unicode Character Database
 * https://www.unicode.org/reports/tr44/
 */

export const hira = "\u3041-\u3094\u309d-\u309e\u30fb\u30fc";
export const kana = "\u30a1-\u30fe\uff66-\uff9f";
