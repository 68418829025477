import { actionTree, mutationTree } from "nuxt-typed-vuex";
import {
  CampaignCreationQueryVariables,
  CampaignCreationQueryRequest,
  ProductItem,
  AffinityModel,
  CreateCampaignMutationVariables,
  CreateCampaignMutationRequest
} from "~/types/gen/api";
import { resetState } from "~/store/utils";

export type campaignCreationState = {
  productItems: ProductItem[];
  affinityModels: AffinityModel[];
};

export const state = (): campaignCreationState => ({
  productItems: [],
  affinityModels: []
});

export const mutations = mutationTree(state, {
  recieveProductItems(state, payload) {
    state.productItems = payload;
  },
  recieveAffinityModel(state, payload) {
    state.affinityModels = payload;
  },
  reset: resetState(state)
});

export const actions = actionTree(
  { state },
  {
    async init(context, payload: CampaignCreationQueryVariables) {
      try {
        this.$accessor.presentation.showLoading(null);
        const req = new CampaignCreationQueryRequest(payload);
        const res = await this.$apiClient.query(req);
        if (res) {
          context.commit("recieveProductItems", res.brand.productItems);
          context.commit("recieveAffinityModel", res.affinityModels);
        }
      } catch (e) {
        this.$accessor.error.showError(e);
      } finally {
        this.$accessor.presentation.dismissLoading();
      }
    },
    async createCampaign(_, payload: CreateCampaignMutationVariables) {
      try {
        this.$accessor.presentation.showLoading(null);
        const req = new CreateCampaignMutationRequest(payload);
        const res = await this.$apiClient.mutate(req);
        if (res) {
          const { brand } = this.$router.currentRoute.params;
          this.$router.replace(`/${brand}/campaigns`);
        }
      } catch (e) {
        this.$accessor.error.showError(e);
      } finally {
        this.$accessor.presentation.dismissLoading();
      }
    }
  }
);
