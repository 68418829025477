import { NetworkClient } from "./network-client";
import ApolloClient, { ApolloError } from "apollo-client";
import { NormalizedCacheObject } from "apollo-cache-inmemory";
import { QueryRequest, MutationRequest } from "../types/gen/api";
import { graphQLResultHasError } from "apollo-utilities";
import { APIError } from "./api-error";

export class APIClient implements NetworkClient {
  constructor(private backend: ApolloClient<NormalizedCacheObject>) {}

  query<Request, Response>(
    request: QueryRequest<Response, Request>
  ): Promise<Response> {
    return new Promise<Response>((resolve, reject) => {
      return this.backend
        .query<Response, Request>({
          ...request.options,
          ...{
            fetchPolicy: "no-cache"
          }
        })
        .then(v => resolve(v.data))
        .catch(error => reject(this.makeError(error)));
    });
  }

  mutate<Request, Response>(
    request: MutationRequest<Response, Request>
  ): Promise<Response> {
    return new Promise<Response>((resolve, reject) => {
      this.backend
        .mutate(request.options)
        .then(res => {
          if (graphQLResultHasError(res)) {
            throw new ApolloError({
              graphQLErrors: res.errors
            });
          }
          if (!res.data) {
            throw new Error(
              "If there is no error, the response data cannot be empty."
            );
          }
          resolve(res.data);
        })
        .catch(error => {
          reject(this.makeError(error));
        });
    });
  }

  makeError(error: ApolloError) {
    console.error(error);
    return APIError.fromApolloError(error);
  }
}
