import { actionTree, mutationTree } from "nuxt-typed-vuex";
import {
  ReportQueryRequest,
  ReportQueryVariables,
  PaginatorInfo,
  SummaryInfluencerScore,
  DownloadClientReportMutationVariables,
  DownloadClientReportMutationRequest,
  ClientBrand,
  ReportInfluencerEdge,
  ReportInfluencer,
  CampaignIndicator,
  ClientCampaign
} from "~/types/gen/api";
import {
  resetState,
  convertSummaryInfluencerScore,
  getReport
} from "~/store/utils";
import { downloadFileByURL } from "~/utils/downloadFileByURL";
import BrandResponse from "~/store/responses/brandResponse";

export type reportState = {
  campaign: ClientCampaign | null;
  influencers: ReportInfluencer[] | null;
  campaignIndicator: CampaignIndicator | null;
  summaryInfluencerScore: SummaryInfluencerScore;
  currentTotal: SummaryInfluencerScore;
  pager: Pick<PaginatorInfo, "total" | "count" | "currentPage" | "perPage">;
  campaignId: string;
  summaries: SummaryInfluencerScore[];
  winningSummaries: SummaryInfluencerScore[];
  reportDataCount: Object | null;
};

export const state = (): reportState => ({
  campaign: null,
  influencers: null,
  pager: {
    total: 0,
    count: 0,
    currentPage: 1,
    perPage: 7
  },
  campaignIndicator: null,
  summaryInfluencerScore: {
    totalCost: 0,
    totalCommission: 0,
    totalInfluencerCount: 0,
    totalFollower: 0,
    totalFollow: 0,
    averageEngagementRate: 0,
    averageAffinityRate: 0
  },
  currentTotal: {
    totalCost: 0,
    totalCommission: 0,
    totalInfluencerCount: 0,
    totalFollower: 0,
    totalFollow: 0,
    averageEngagementRate: 0
  },
  campaignId: "",
  summaries: [],
  winningSummaries: [],
  reportDataCount: null
});

export const mutations = mutationTree(state, {
  receiveCampaign(state, payload) {
    state.campaign = payload;
  },
  receiveInfluencers(state, payload) {
    state.influencers = payload
      ? payload.map((v: ReportInfluencerEdge) => v.node!.reportInfluencer)
      : [];
  },
  receiveCampaignIndicator(state, payload) {
    state.campaignIndicator = payload;
  },
  receivePaginatorInfo(state, payload) {
    state.pager = payload;
  },
  receiveSummaryInfluencerScore(state, payload) {
    state.summaryInfluencerScore = payload
      ? convertSummaryInfluencerScore(payload)
      : {
          totalCost: 0,
          totalCommission: 0,
          totalInfluencerCount: 0,
          totalFollower: 0,
          totalFollow: 0,
          averageEngagementRate: 0,
          averageAffinityRate: 0
        };
  },
  receiveCurrentTotal(state, payload) {
    state.currentTotal = payload
      ? convertSummaryInfluencerScore(payload)
      : {
          totalCost: 0,
          totalCommission: 0,
          totalInfluencerCount: 0,
          totalFollower: 0,
          totalFollow: 0,
          averageEngagementRate: 0,
          averageAffinityRate: 0
        };
  },
  receiveCampaignId(state, payload) {
    state.campaignId = payload;
  },
  receiveSummaries(state, payload) {
    state.summaries = payload;
  },
  receiveWinningSummaries(state, payload) {
    state.winningSummaries = payload;
  },
  receiveReportDataCount(state, payload) {
    state.reportDataCount = getReport(payload);
  },

  reset: resetState(state)
});

export const actions = actionTree(
  { state },
  {
    async init(context, payload: ReportQueryVariables) {
      try {
        this.$accessor.presentation.showLoading(null);
        const req = new ReportQueryRequest(payload);
        const res = await this.$apiClient.query(req);
        const brandRes = new BrandResponse(res.brand as ClientBrand);
        const { getListState } = brandRes;
        if (res) {
          const { edges, paginatorInfo } = getListState(
            brandRes.reportInfluencers
          );
          context.commit("receiveInfluencers", edges);
          context.commit("receivePaginatorInfo", paginatorInfo);
          if (brandRes.reportSummaries) {
            context.commit(
              "receiveSummaryInfluencerScore",
              brandRes.reportSummaries[0]
            );
          }
          context.commit("receiveCurrentTotal", brandRes.report);
          context.commit(
            "receiveCampaignIndicator",
            res.brand.campaign!.indicator
          );
          context.commit("receiveCampaignId", res.brand.campaign!.id);
          context.commit("receiveSummaries", brandRes.reportSummaries);
          context.commit(
            "receiveWinningSummaries",
            brandRes.reportWinningSummaries
          );
          context.commit("receiveReportDataCount", brandRes.report);
        }
      } catch (e) {
        this.$accessor.error.showError(e);
      } finally {
        this.$accessor.presentation.dismissLoading();
      }
    },
    async downloadCsv(_, payload: DownloadClientReportMutationVariables) {
      try {
        this.$accessor.presentation.showLoading(null);
        const req = new DownloadClientReportMutationRequest(payload);
        const res = await this.$apiClient.mutate(req);
        if (res.downloadClientReport!.url) {
          downloadFileByURL(res.downloadClientReport!.url);
        }
      } catch (e) {
        this.$accessor.error.showError(e);
      } finally {
        this.$accessor.presentation.dismissLoading();
      }
    }
  }
);
