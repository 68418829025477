





























import Vue from "vue";
export default Vue.extend({
  props: {
    isVisible: {
      type: Boolean,
      required: true
    },
    title: {
      type: String,
      required: false,
      default: ""
    },
    width: {
      type: String,
      required: false,
      default: "40%"
    },
    canClickOutside: {
      type: Boolean,
      default: false
    },
    center: {
      type: Boolean,
      default: false
    },
    showClose: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      isShown: false
    };
  },
  watch: {
    isVisible: {
      handler(value) {
        this.isShown = value;
      },
      immediate: true
    }
  },
  created() {
    this.setVisible();
  },
  methods: {
    onClose() {
      this.$emit("close");
    },
    setVisible() {
      this.isShown = this.isVisible;
    }
  }
});
