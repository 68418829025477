// Vuex module
import * as clientAccount from "./clientAccount";
import * as agencyAccount from "./agencyAccount";
import * as clientResetPassword from "./clientResetPassword";
import * as agencyResetPassword from "./agencyResetPassword";

// Page module
import * as affinityModels from "./pages/affinityModels";
import * as affinityModel from "./pages/affinityModel";
import * as affinityModelCreation from "./pages/affinityModelCreation";
import * as influencerEvaluations from "./pages/influencerEvaluations";
import * as influencerEvaluation from "./pages/influencerEvaluation";
import * as agencyAccountIndex from "./pages/agencyAccountIndex";
import * as agencyAccountCreation from "./pages/agencyAccountCreation";
import * as agencyAccountUpdate from "./pages/agencyAccountUpdate";
import * as brandCreate from "./pages/brandCreate";
import * as brandEdit from "./pages/brandEdit";
import * as snapshots from "./pages/snapshots";
import * as agencyCampaignDetail from "./pages/agencyCampaignDetail";
import * as agencyAccounts from "./pages/agencyAccounts";
import * as agencyBrandCampaigns from "./pages/agencyBrandCampaigns";
import * as snapshot from "./pages/snapshot";
import * as campaigns from "./pages/campaigns";
import * as campaign from "./pages/campaign";
import * as clientCampaigns from "./pages/clientCampaigns";
import * as comments from "./pages/comments";
import * as influencerEvaluationGroupCreation from "./pages/influencerEvaluationGroupCreation";
import * as influencerPosts from "./pages/influencerPosts";
import * as proposals from "./pages/proposals";
import * as campaignCreation from "./pages/campaignCreation";
import * as report from "./pages/report";
import * as proposal from "./pages/proposal";
import * as orders from "./pages/orders";
import * as agencyCreate from "./pages/agencyCreate";
import * as agencyEdit from "./pages/agencyEdit";
import * as agencyList from "./pages/agencyList";
import * as adminClientAccounts from "./pages/adminClientAccounts";
import * as adminClientAccountCreate from "./pages/adminClientAccountCreate";
import * as adminClientAccountUpdate from "./pages/adminClientAccountUpdate";
import * as adminInfluencerPyramid from "./pages/adminInfluencerPyramid";
import * as order from "./pages/order";
import * as confirmedInfluencers from "./pages/confirmedInfluencers";
import * as campaignReportRequest from "./pages/campaignReportRequest";
import * as clientCampaignsFilterDropdownOptions from "./pages/clientCampaignsFilterDropdownOptions";
import * as campaignEdit from "./pages/campaignEdit";
import * as error from "./error";
import * as toast from "./toast";

// Libs
import { getAccessorType } from "nuxt-typed-vuex";
import { Context } from "@nuxt/types";
import { ActionContext } from "vuex";

export const actions = {
  async nuxtClientInit(context: ActionContext<{}, {}>, nuxtContext: Context) {
    const clientToken: string = nuxtContext.app.$cookies.get(
      clientAccount.TOKEN_KEY
    );
    const agencyToken = nuxtContext.app.$cookies.get(agencyAccount.TOKEN_KEY);
    // TODO ディレクトリ名をbrand、brandNameに統一した方が良い??
    const { brand, brandName } = nuxtContext.route.params;
    const queryBrandName = brand || brandName;
    // Load token from cookie
    if (clientToken) {
      context.commit("clientAccount/loggedIn", clientToken);
      await context.dispatch("clientAccount/loadAccount");
      if (queryBrandName) {
        context.commit("clientAccount/setCurrentBrandName", queryBrandName);
      }
    }

    if (agencyToken) {
      context.commit("agencyAccount/loggedIn", agencyToken);
      await context.dispatch("agencyAccount/loadAccount");
      if (queryBrandName) {
        context.commit("agencyAccount/setCurrentBrandName", queryBrandName);
      }
    }
  }
};

/**
 * Setup accessor
 */
export const accessorType = getAccessorType({
  modules: {
    pages: {
      modules: {
        affinityModels,
        affinityModel,
        affinityModelCreation,
        brandCreate,
        brandEdit,
        influencerEvaluations,
        agencyAccountIndex,
        agencyAccountCreation,
        agencyAccountUpdate,
        influencerEvaluation,
        snapshots,
        agencyCampaignDetail,
        agencyAccounts,
        agencyBrandCampaigns,
        snapshot,
        campaigns,
        campaign,
        clientCampaigns,
        influencerEvaluationGroupCreation,
        influencerPosts,
        campaignCreation,
        report,
        proposal,
        orders,
        proposals,
        agencyCreate,
        agencyEdit,
        agencyList,
        adminClientAccounts,
        adminClientAccountCreate,
        adminClientAccountUpdate,
        adminInfluencerPyramid,
        order,
        confirmedInfluencers,
        campaignReportRequest,
        error,
        campaignEdit,
        comments,
        clientCampaignsFilterDropdownOptions
      }
    },
    clientAccount,
    agencyAccount,
    toast,
    clientResetPassword,
    agencyResetPassword
  }
});
