import { actionTree, mutationTree } from "nuxt-typed-vuex";
import {
  ClientCampaignsFilterDropdownOptions,
  ClientCampaignsFilterDropdownOptionsQueryRequest
} from "~/types/gen/api";
import { resetState } from "~/store/utils";

export type clientCampaignsFilterDropdownOptionsState = {
  options: Pick<
    ClientCampaignsFilterDropdownOptions,
    | "divisionNames"
    | "brandNames"
    | "campaignNames"
    | "agencyNames"
    | "categoryNames"
    | "snsNames"
  >;
};

export const state = (): clientCampaignsFilterDropdownOptionsState => ({
  options: {
    divisionNames: [],
    brandNames: [],
    campaignNames: [],
    agencyNames: [],
    categoryNames: [],
    snsNames: []
  }
});

export const mutations = mutationTree(state, {
  receiveOptions(state, payload) {
    state.options = payload;
  },
  reset: resetState(state)
});

export const actions = actionTree(
  { state },
  {
    async init(context) {
      try {
        this.$accessor.presentation.showLoading(null);
        const req = new ClientCampaignsFilterDropdownOptionsQueryRequest({});
        const res = await this.$apiClient.query(req);
        if (res) {
          context.commit(
            "receiveOptions",
            res.clientCampaignsFilterDropdownOptions
          );
        }
      } catch (e) {
        this.$accessor.error.showError(e);
      } finally {
        this.$accessor.presentation.dismissLoading();
      }
    }
  }
);
