export default {
  title: {
    edit_account: "Edit Account"
  },
  label: {
    edit_account: "Edit"
  },
  message: {
    success_edit: "The account has been successfully edited."
  }
};
