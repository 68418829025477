import { Context } from "@nuxt/types";
import { makePath } from "./util";

export default async function requireAuth(context: Context) {
  // tokenがなければログインへリダイレクト
  if (!context.app.$accessor.clientAccount.hasCredential) {
    context.redirect(makePath("/login", context.route.path));
    return;
  }
  // loadAccountをしてなければloadAccountをする
  if (!context.app.$accessor.clientAccount.hasAccount) {
    await context.app.$accessor.clientAccount.loadAccount();
  }
  // BrandNameがセットされてない
  // 又は、不正なBrandName及び、ユーザーが許可されてないブランドネームの場合
  // ブランド一覧へ遷移
  const { brand } = context.route.params;
  if (brand && !context.app.$accessor.clientAccount.hasBrand(brand)) {
    context.redirect("/brands");
  } else if (brand && context.app.$accessor.clientAccount.hasBrand(brand)) {
    context.app.$accessor.clientAccount.getCampaignMenu({ name: brand });
  }
}
