












import Vue from "vue";

export default Vue.extend({
  methods: {
    handleSelect(key: string, keyPath: string) {
      this.$emit("select", {
        key,
        keyPath
      });
    }
  }
});
