import { actionTree, mutationTree, getterTree } from "nuxt-typed-vuex";
import {
  AffinityModel,
  AffinityModelCreationQueryRequest,
  AffinityModelCreationQueryVariables,
  CreateAffinityModelMutationRequest,
  CreateAffinityModelMutationVariables,
  InfluencerEvaluationGroup,
  AffinityModelEdge,
  SnapInfluencerEvaluationGroup,
  Logic,
  UserPostVector
} from "~/types/gen/api";
import { formatSlashDate } from "~/utils/date";
import { resetState } from "~/store/utils";

export type EvaluationOptions = {
  label: Pick<InfluencerEvaluationGroup, "title">;
  value: Pick<InfluencerEvaluationGroup, "id">;
  children: {
    label: Pick<SnapInfluencerEvaluationGroup, "title">;
    value: Pick<SnapInfluencerEvaluationGroup, "id">;
  }[];
};
export type Options = {
  label: string;
  value: string;
};

export type AffinityModelCreationState = {
  affinityModels: AffinityModel | null;
  evaluationOptions: EvaluationOptions[];
  updateDate: Options[];
  logic: Options[];
};

export const state = (): AffinityModelCreationState => ({
  affinityModels: null,
  evaluationOptions: [],
  updateDate: [],
  logic: []
});

export const mutations = mutationTree(state, {
  recieveAffinityModelCreationList(state, payload) {
    state.affinityModels = payload.map(
      (v: AffinityModelEdge) => v.node.affinityModel
    );
  },
  recieveLogic(state, payload) {
    state.logic = payload.map((v: Logic) => {
      return {
        label: v.name,
        value: v.id
      };
    });
  },
  recieveInfluencerEvaluationGroups(state, payload) {
    state.evaluationOptions = payload.map((v: any) => {
      const children = v.snapInfluencerEvaluationGroups.edges.map((v: any) => {
        const { snapInfluencerEvaluationGroup } = v.node;
        return {
          label: snapInfluencerEvaluationGroup.title,
          value: snapInfluencerEvaluationGroup.id
        };
      });
      return {
        label: v.title,
        value: v.id,
        children: children
      };
    });
  },
  recieveDate(state, payload) {
    state.updateDate = payload.map((v: UserPostVector) => {
      return {
        label: v.to,
        value: v.to
      };
    });
  },
  reset: resetState(state)
});

export const getters = getterTree(state, {
  modelCreatedAt(state) {
    return formatSlashDate(state.affinityModels!.createdAt);
  }
});

export const actions = actionTree(
  { state },
  {
    async init(context, payload: AffinityModelCreationQueryVariables) {
      try {
        this.$accessor.presentation.showLoading(null);
        const req = new AffinityModelCreationQueryRequest(payload);
        const res = await this.$apiClient.query(req);
        context.commit(
          "recieveAffinityModelCreationList",
          res.brand.affinityModels!.edges
        );
        context.commit("recieveLogic", res.logics);
        context.commit(
          "recieveInfluencerEvaluationGroups",
          res.influencerEvaluationGroups
        );
        context.commit("recieveDate", res.userPostVectors);
      } catch (e) {
        this.$accessor.error.showError(e);
      } finally {
        this.$accessor.presentation.dismissLoading();
      }
    },
    async createAffinityModel(
      _,
      payload: CreateAffinityModelMutationVariables
    ) {
      try {
        this.$accessor.presentation.showLoading(null);
        const req = new CreateAffinityModelMutationRequest(payload);
        const res = await this.$apiClient.mutate(req);
        const { brand } = this.$router.currentRoute.params;
        if (res) {
          const affinityModelId = res.createAffinityModel!.affinityModel.number;
          this.$router.replace(`/${brand}/affinity-models/${affinityModelId}`);
        }
      } catch (e) {
        this.$accessor.error.showError(e);
      } finally {
        this.$accessor.presentation.dismissLoading();
      }
    }
  }
);
