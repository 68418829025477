
















import Vue from "vue";
import { FormItem } from "~/components/ui/forms/form-item";

//Todo: typeフォルダーのshared.d.tsファイルから引っ張ってくるようにする。
export type SelectOption = {
  value: string | number;
  label: string;
};

export default Vue.extend({
  mixins: [FormItem],
  props: {
    value: {
      type: [String, Number],
      default: ""
    },
    options: {
      type: Array as () => SelectOption[],
      default: []
    },
    placeholder: {
      type: String,
      default: "-"
    }
  },
  model: {
    event: "change"
  },
  methods: {
    onClickChange(value: any) {
      this.$emit("click", value);
    }
  }
});
