














import Vue from "vue";
import { LoadingPayload } from "../../store/presentation";
import Loading from "~/components/ui/loading/loading.vue";

export default Vue.extend({
  components: {
    Loading
  },
  computed: {
    isLoading(): boolean {
      return this.$accessor.presentation.isLoading;
    },
    current(): LoadingPayload | null {
      return this.$accessor.presentation.currentLoading;
    }
  }
});
