import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const VUEX_PROPERTIES = ['state', 'getters', 'actions', 'mutations']

let store = {}

void (function updateModules () {
  store = normalizeRoot(require('../src/store/index.ts'), 'store/index.ts')

  // If store is an exported method = classic mode (deprecated)

  // Enforce store modules
  store.modules = store.modules || {}

  resolveStoreModules(require('../src/store/utils.ts'), 'utils.ts')
  resolveStoreModules(require('../src/store/toast/index.ts'), 'toast/index.ts')
  resolveStoreModules(require('../src/store/presentation/index.ts'), 'presentation/index.ts')
  resolveStoreModules(require('../src/store/error/index.ts'), 'error/index.ts')
  resolveStoreModules(require('../src/store/clientResetPassword/index.ts'), 'clientResetPassword/index.ts')
  resolveStoreModules(require('../src/store/clientAccount/index.ts'), 'clientAccount/index.ts')
  resolveStoreModules(require('../src/store/agencyResetPassword/index.ts'), 'agencyResetPassword/index.ts')
  resolveStoreModules(require('../src/store/agencyAccount/index.ts'), 'agencyAccount/index.ts')
  resolveStoreModules(require('../src/store/responses/brandResponse.ts'), 'responses/brandResponse.ts')
  resolveStoreModules(require('../src/store/pages/snapshots/index.ts'), 'pages/snapshots/index.ts')
  resolveStoreModules(require('../src/store/pages/snapshot/index.ts'), 'pages/snapshot/index.ts')
  resolveStoreModules(require('../src/store/pages/report/index.ts'), 'pages/report/index.ts')
  resolveStoreModules(require('../src/store/pages/proposals/index.ts'), 'pages/proposals/index.ts')
  resolveStoreModules(require('../src/store/pages/proposal/index.ts'), 'pages/proposal/index.ts')
  resolveStoreModules(require('../src/store/pages/orders/index.ts'), 'pages/orders/index.ts')
  resolveStoreModules(require('../src/store/pages/order/index.ts'), 'pages/order/index.ts')
  resolveStoreModules(require('../src/store/pages/influencerPosts/index.ts'), 'pages/influencerPosts/index.ts')
  resolveStoreModules(require('../src/store/pages/influencerEvaluations/index.ts'), 'pages/influencerEvaluations/index.ts')
  resolveStoreModules(require('../src/store/pages/influencerEvaluationGroupCreation/index.ts'), 'pages/influencerEvaluationGroupCreation/index.ts')
  resolveStoreModules(require('../src/store/pages/influencerEvaluation/index.ts'), 'pages/influencerEvaluation/index.ts')
  resolveStoreModules(require('../src/store/pages/confirmedInfluencers/index.ts'), 'pages/confirmedInfluencers/index.ts')
  resolveStoreModules(require('../src/store/pages/comments/index.ts'), 'pages/comments/index.ts')
  resolveStoreModules(require('../src/store/pages/clientCampaignsFilterDropdownOptions/index.ts'), 'pages/clientCampaignsFilterDropdownOptions/index.ts')
  resolveStoreModules(require('../src/store/pages/clientCampaigns/index.ts'), 'pages/clientCampaigns/index.ts')
  resolveStoreModules(require('../src/store/pages/campaigns/index.ts'), 'pages/campaigns/index.ts')
  resolveStoreModules(require('../src/store/pages/campaignReportRequest/index.ts'), 'pages/campaignReportRequest/index.ts')
  resolveStoreModules(require('../src/store/pages/campaignEdit/index.ts'), 'pages/campaignEdit/index.ts')
  resolveStoreModules(require('../src/store/pages/campaignCreation/index.ts'), 'pages/campaignCreation/index.ts')
  resolveStoreModules(require('../src/store/pages/campaign/index.ts'), 'pages/campaign/index.ts')
  resolveStoreModules(require('../src/store/pages/brandEdit/index.ts'), 'pages/brandEdit/index.ts')
  resolveStoreModules(require('../src/store/pages/brandCreate/index.ts'), 'pages/brandCreate/index.ts')
  resolveStoreModules(require('../src/store/pages/agencyList/index.ts'), 'pages/agencyList/index.ts')
  resolveStoreModules(require('../src/store/pages/agencyEdit/index.ts'), 'pages/agencyEdit/index.ts')
  resolveStoreModules(require('../src/store/pages/agencyCreate/index.ts'), 'pages/agencyCreate/index.ts')
  resolveStoreModules(require('../src/store/pages/agencyCampaignDetail/index.ts'), 'pages/agencyCampaignDetail/index.ts')
  resolveStoreModules(require('../src/store/pages/agencyBrandCampaigns/index.ts'), 'pages/agencyBrandCampaigns/index.ts')
  resolveStoreModules(require('../src/store/pages/agencyAccountUpdate/index.ts'), 'pages/agencyAccountUpdate/index.ts')
  resolveStoreModules(require('../src/store/pages/agencyAccounts/index.ts'), 'pages/agencyAccounts/index.ts')
  resolveStoreModules(require('../src/store/pages/agencyAccountIndex/index.ts'), 'pages/agencyAccountIndex/index.ts')
  resolveStoreModules(require('../src/store/pages/agencyAccountCreation/index.ts'), 'pages/agencyAccountCreation/index.ts')
  resolveStoreModules(require('../src/store/pages/affinityModels/index.ts'), 'pages/affinityModels/index.ts')
  resolveStoreModules(require('../src/store/pages/affinityModelCreation/index.ts'), 'pages/affinityModelCreation/index.ts')
  resolveStoreModules(require('../src/store/pages/affinityModel/index.ts'), 'pages/affinityModel/index.ts')
  resolveStoreModules(require('../src/store/pages/adminInfluencerPyramid/index.ts'), 'pages/adminInfluencerPyramid/index.ts')
  resolveStoreModules(require('../src/store/pages/adminClientAccountUpdate/index.ts'), 'pages/adminClientAccountUpdate/index.ts')
  resolveStoreModules(require('../src/store/pages/adminClientAccounts/index.ts'), 'pages/adminClientAccounts/index.ts')
  resolveStoreModules(require('../src/store/pages/adminClientAccountCreate/index.ts'), 'pages/adminClientAccountCreate/index.ts')

  // If the environment supports hot reloading...
})()

// createStore
export const createStore = store instanceof Function ? store : () => {
  return new Vuex.Store(Object.assign({
    strict: (process.env.NODE_ENV !== 'production')
  }, store))
}

function normalizeRoot (moduleData, filePath) {
  moduleData = moduleData.default || moduleData

  if (moduleData.commit) {
    throw new Error(`[nuxt] ${filePath} should export a method that returns a Vuex instance.`)
  }

  if (typeof moduleData !== 'function') {
    // Avoid TypeError: setting a property that has only a getter when overwriting top level keys
    moduleData = Object.assign({}, moduleData)
  }
  return normalizeModule(moduleData, filePath)
}

function normalizeModule (moduleData, filePath) {
  if (moduleData.state && typeof moduleData.state !== 'function') {
    console.warn(`'state' should be a method that returns an object in ${filePath}`)

    const state = Object.assign({}, moduleData.state)
    // Avoid TypeError: setting a property that has only a getter when overwriting top level keys
    moduleData = Object.assign({}, moduleData, { state: () => state })
  }
  return moduleData
}

function resolveStoreModules (moduleData, filename) {
  moduleData = moduleData.default || moduleData
  // Remove store src + extension (./foo/index.js -> foo/index)
  const namespace = filename.replace(/\.(js|mjs|ts)$/, '')
  const namespaces = namespace.split('/')
  let moduleName = namespaces[namespaces.length - 1]
  const filePath = `store/${filename}`

  moduleData = moduleName === 'state'
    ? normalizeState(moduleData, filePath)
    : normalizeModule(moduleData, filePath)

  // If src is a known Vuex property
  if (VUEX_PROPERTIES.includes(moduleName)) {
    const property = moduleName
    const storeModule = getStoreModule(store, namespaces, { isProperty: true })

    // Replace state since it's a function
    mergeProperty(storeModule, moduleData, property)
    return
  }

  // If file is foo/index.js, it should be saved as foo
  const isIndexModule = (moduleName === 'index')
  if (isIndexModule) {
    namespaces.pop()
    moduleName = namespaces[namespaces.length - 1]
  }

  const storeModule = getStoreModule(store, namespaces)

  for (const property of VUEX_PROPERTIES) {
    mergeProperty(storeModule, moduleData[property], property)
  }

  if (moduleData.namespaced === false) {
    delete storeModule.namespaced
  }
}

function normalizeState (moduleData, filePath) {
  if (typeof moduleData !== 'function') {
    console.warn(`${filePath} should export a method that returns an object`)
    const state = Object.assign({}, moduleData)
    return () => state
  }
  return normalizeModule(moduleData, filePath)
}

function getStoreModule (storeModule, namespaces, { isProperty = false } = {}) {
  // If ./mutations.js
  if (!namespaces.length || (isProperty && namespaces.length === 1)) {
    return storeModule
  }

  const namespace = namespaces.shift()

  storeModule.modules[namespace] = storeModule.modules[namespace] || {}
  storeModule.modules[namespace].namespaced = true
  storeModule.modules[namespace].modules = storeModule.modules[namespace].modules || {}

  return getStoreModule(storeModule.modules[namespace], namespaces, { isProperty })
}

function mergeProperty (storeModule, moduleData, property) {
  if (!moduleData) {
    return
  }

  if (property === 'state') {
    storeModule.state = moduleData || storeModule.state
  } else {
    storeModule[property] = Object.assign({}, storeModule[property], moduleData)
  }
}
