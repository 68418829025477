import { actionTree, mutationTree } from "nuxt-typed-vuex";
import {
  AgenciesQueryRequest,
  ClientAgency,
  DeactivateAgencyInput,
  DeactivateAgencyMutationRequest
} from "~/types/gen/api";
import { resetState } from "~/store/utils";

export type agencyListState = {
  agencyList: ClientAgency[];
};

export const state = (): agencyListState => ({
  agencyList: []
});

export const mutations = mutationTree(state, {
  recieveAgencies(state, payload) {
    state.agencyList = payload.map((v: ClientAgency) => {
      return {
        id: v.id,
        name: v.name,
        contact_person: v.ownerAgencyAccount.name,
        email: v.ownerAgencyAccount.email
      };
    });
  },
  deactivateAgency(state, agencyId) {
    state.agencyList = state.agencyList.filter(
      agency => agency.id !== agencyId
    );
  },
  reset: resetState(state)
});

export const actions = actionTree(
  { state },
  {
    async getAgencyList(context): Promise<void> {
      try {
        this.$accessor.presentation.showLoading(null);
        const req = new AgenciesQueryRequest({});
        const res = await this.$apiClient.query(req);
        if (res) {
          context.commit("recieveAgencies", res.agencies);
        }
      } catch (e) {
        this.$accessor.error.showError(e);
      } finally {
        this.$accessor.presentation.dismissLoading();
      }
    },
    async deactivateAgency(
      context,
      payload: DeactivateAgencyInput
    ): Promise<void> {
      try {
        this.$accessor.presentation.showLoading(null);
        const req = new DeactivateAgencyMutationRequest({
          input: {
            agencyId: payload.agencyId
          }
        });
        const res = await this.$apiClient.mutate(req);
        if (res) {
          context.commit("deactivateAgency", payload.agencyId);
        }
      } catch (e) {
        this.$accessor.error.showError(e);
      } finally {
        this.$accessor.presentation.dismissLoading();
      }
    }
  }
);
