import { actionTree, getterTree, mutationTree } from "nuxt-typed-vuex";
import { DateTime } from "luxon";
import {
  AgencyCredentialPayload,
  AgencyAccountQueryRequest,
  AgencyAccountQuery,
  Brand,
  AgencyLoginMutationRequest,
  AgencyLoginMutationVariables
} from "~/types/gen/api";

export const TOKEN_KEY = "AGENCY_TOKEN_KEY";

export type AgencyAccountState = {
  token: string | null;
  currentBrandName: string;
} & AgencyAccountQuery["agencyAccount"];

export type resetPasswordPayload = {
  email: string;
  password: string;
};

export const state = (): AgencyAccountState => ({
  token: null,
  currentBrandName: "",
  id: "",
  agencyId: "",
  name: "",
  agencyName: "",
  email: "",
  isOwner: false,
  brands: []
});

export const mutations = mutationTree(state, {
  loggedIn(state, value: string) {
    state.token = value;
  },
  loggedOut(state) {
    state.token = null;
  },
  receiveAccount(
    state,
    {
      id,
      name,
      agencyId,
      agencyName,
      email,
      isOwner,
      brands
    }: AgencyAccountQuery["agencyAccount"]
  ) {
    state.id = id;
    state.agencyId = agencyId;
    state.name = name;
    state.agencyName = agencyName;
    state.email = email;
    state.isOwner = isOwner;
    state.brands = brands;
  },
  setCurrentBrandName(state, name: Brand["name"]) {
    state.currentBrandName = name;
  }
});

export const getters = getterTree(state, {
  hasCredential(state) {
    return !!state.token;
  },
  hasAccount(state) {
    return !!state.id;
  },
  hasBrand(state) {
    return (brandName: string) => {
      let flg = false;
      state.brands.forEach(elm => {
        if (elm.name === brandName) {
          flg = true;
        }
      });
      return flg;
    };
  }
});

export const actions = actionTree(
  { state, getters, mutations },
  {
    async login(_, payload: AgencyLoginMutationVariables): Promise<void> {
      try {
        this.$accessor.presentation.showLoading(null);
        const res = await this.$apiClient.mutate(
          new AgencyLoginMutationRequest(payload)
        );
        if (res.agencyLogin && res.agencyLogin.token) {
          this.$accessor.agencyAccount.saveCredential(res.agencyLogin);
          await this.$accessor.agencyAccount.loadAccount();
        }
      } catch (e) {
        this.$accessor.error.showError(e);
      } finally {
        this.$accessor.presentation.dismissLoading();
      }
    },
    async loadAccount(context): Promise<void> {
      try {
        this.$accessor.presentation.showLoading(null);
        const { agencyAccount } = await this.$apiClient.query(
          new AgencyAccountQueryRequest({})
        );
        context.commit("receiveAccount", agencyAccount);
      } catch (e) {
        this.$accessor.agencyAccount.logout();
        this.$accessor.error.showError(e);
      } finally {
        this.$accessor.presentation.dismissLoading();
      }
    },
    saveCredential(context, payload: AgencyCredentialPayload): void {
      const token = payload.token;
      const expiredAt = DateTime.local().plus({ year: 100 });
      // Persist token
      this.$cookies.set(TOKEN_KEY, token, {
        path: "/",
        expires: expiredAt.toJSDate()
      });
      context.commit("loggedIn", token);
    },
    logout(context): void {
      this.$cookies.remove(TOKEN_KEY);
      context.commit("loggedOut");
      this.$router.push("/agency-login");
    }
  }
);
