export default {
  title: {
    proposal_list_csv_download: "Please download a csv template for proposal.",
    offer_list_csv_download: "Please download a csv template for offer.",
    completion_list_csv_download: "Please download a csv templete for report.",
    final_confirmation:
      "After checking all the influencers' availability, please click the confirm button.",
    confirm: "Are you sure to confirm?",
    confirmation_check: "Checklist"
  },
  label: {
    uploaded_files: "Uploaded Files",
    uploaded_influencers: "Uploaded influencers",
    confirm: "Confirm",
    availability_status: "Availability status"
  },
  message: {
    proposal_list_csv_upload:
      "Please upload an influencer list proposal in csv format.",
    offer_list_csv_upload:
      "Please fill out influencers' availability status in the template and upload it in csv format.",
    completion_list_csv_upload:
      "Please upload a final report in csv format after the campaign ends.",
    allow_multiple_upload: "You can upload a file multiple times.",
    cannot_upload_after_confimration:
      "Once you click the confirm button, you will not be able to upload a file since your client will check your uploaded files.",
    client_checking: "Your client is checking..",
    assign_influencer_when_approved:
      "If you get approved for your offer, you can assign influencers to the campaign.",
    reupload_list_when_declined:
      "If your client decline your offer, you will need to upload a list again.",
    approved: "Approved",
    client_approved: "Your client has approved your influencer offer list.",
    report_offer:
      "Please make sure to submit a final report after the campaign ends.",
    waiting_offer: "Waiting for a client offer..",
    upload_report_after_campaign:
      "Please upload an influencer list report after the campaign ends.",
    available_upload_after_confirmed:
      "** You can upload a report after your client has approved your influencer list."
  }
};
