













import Vue from "vue";
import ClientHeader from "~/components/the/header/client-header.vue";
import AppLoading from "~/components/the/app-loading.vue";
import ErrorDialog from "~/components/the/error-dialog.vue";

export default Vue.extend({
  components: {
    ClientHeader,
    AppLoading,
    ErrorDialog
  }
});
