import { actionTree, mutationTree } from "nuxt-typed-vuex";
import {
  OrdersQueryRequest,
  OrdersQueryVariables,
  ClientBrand,
  ClientCampaign
} from "~/types/gen/api";
import { resetState } from "~/store/utils";
import BrandResponse from "~/store/responses/brandResponse";

export type orders = {
  campaign: ClientBrand["campaign"];
  orders: ClientCampaign["assignments"];
};

export const state = (): orders => ({
  campaign: null,
  orders: null
});

export const mutations = mutationTree(state, {
  recieveCampaign(state, payload) {
    state.campaign = payload;
  },
  recieveOrders(state, payload) {
    state.orders = payload;
  },
  reset: resetState(state)
});

export const actions = actionTree(
  { state },
  {
    async init(context, payload: OrdersQueryVariables) {
      try {
        this.$accessor.presentation.showLoading(null);
        const req = new OrdersQueryRequest(payload);
        const res = await this.$apiClient.query(req);
        if (res) {
          const brandRes = new BrandResponse(res.brand as ClientBrand);
          context.commit("recieveCampaign", brandRes.campaign);
          context.commit("recieveOrders", brandRes.campaignAssignments);
        }
      } catch (e) {
        this.$accessor.error.showError(e);
      } finally {
        this.$accessor.presentation.dismissLoading();
      }
    }
  }
);
