















import Vue from "vue";
import Header from "~/components/the/header/header.vue";
import { Brand } from "~/types/gen/api";
import { makeNewBrandPath } from "~/utils/path.ts";

export default Vue.extend({
  components: {
    Header
  },
  computed: {
    brandName(): string {
      return this.$route.params.brand;
    },
    brands(): Brand[] {
      return this.$accessor.agencyAccount.brands;
    },
    currentBrandName(): string {
      return this.$accessor.agencyAccount.currentBrandName;
    },
    clientAccount() {
      return this.$accessor.agencyAccount;
    },
    isBrandSelectable() {
      return !!this.$route.params.brandName;
    }
  },
  methods: {
    onChangeBrand({ name }: { name: string }) {
      this.$accessor.agencyAccount.setCurrentBrandName(name);
      const newPath = makeNewBrandPath({
        currentPath: this.$route.path,
        currentBrand: this.$route.params.brandName,
        newBrand: name
      });
      if (newPath) {
        this.$router.replace(newPath);
      }
    },
    onLogout() {
      this.$accessor.agencyAccount.logout();
    }
  }
});
