




















































import Vue from "vue";
import { ClientAccount, AgencyAccount } from "~/types/gen/api";
import Menu from "~/components/ui/menu/menu.vue";

export default Vue.extend({
  components: {
    Menu
  },
  props: {
    account: {
      type: Object as () => ClientAccount | AgencyAccount | null,
      default: null
    },
    isOwner: {
      type: Boolean,
      default: false
    },
    isAgency: {
      type: Boolean,
      default: false
    },
    clientGuidelineUrl: {
      type: String,
      default: process.env.CLIENT_GUIDELINE_URL
    },
    agencyGuidelineUrl: {
      type: String,
      default: process.env.AGENCY_GUIDELINE_URL
    },
    supportEmail: {
      type: String,
      default: process.env.SUPPORT_MAIL
    },
    supportLink: {
      type: String,
      default: process.env.SUPPORT_LINK
    },
    purchaseManualUrl: {
      type: String,
      default: process.env.PURCHASE_MANUAL_URL
    }
  },
  methods: {
    onLogout() {
      this.$emit("logout");
    },
    onGuideLine() {
      let guideline = this.clientGuidelineUrl;
      if (this.isAgency) {
        guideline = this.agencyGuidelineUrl;
      }
      window.open(guideline, "_blank");
    },
    onPurchaseManual() {
      let url = this.purchaseManualUrl;
      window.open(url, "_blank");
    },
    onSupportEmail() {
      if (this.isAgency) {
        window.open("mailto:" + this.supportEmail, "_blank");
      }
      window.open(this.supportLink, "_blank");
    }
  }
});
