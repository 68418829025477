export function makeNewBrandPath({
  currentPath,
  currentBrand,
  newBrand
}: {
  currentPath: string;
  currentBrand: string | undefined;
  newBrand: string;
}): string | null {
  // URLの/_brand/~~~の_brandの部分を変更する。
  if (currentBrand) {
    const newPath = currentPath.replace(currentBrand, newBrand);
    return newPath;
  }
  return null;
}
