














import Vue from "vue";
import Header from "~/components/the/header/header.vue";
import { Brand } from "~/types/gen/api";
import { makeNewBrandPath } from "~/utils/path.ts";

export default Vue.extend({
  components: {
    Header
  },
  props: {
    isBrandSelectable: {
      type: Boolean,
      default: true
    },
    isScoreSettingLinkVisile: {
      type: Boolean,
      default: true
    },
    isCampaignLinkVisile: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    brandName(): string {
      return this.$route.params.brand;
    },
    brands(): Brand[] {
      return this.$accessor.clientAccount.brands;
    },
    currentBrandName(): string {
      return this.$accessor.clientAccount.currentBrandName;
    },
    clientAccount() {
      return this.$accessor.clientAccount;
    }
  },
  methods: {
    onChangeBrand({ name }: { name: string }) {
      this.$accessor.clientAccount.setCurrentBrandName(name);
      const newPath = makeNewBrandPath({
        currentPath: this.$route.path,
        currentBrand: this.$route.params.brand,
        newBrand: name
      });
      if (newPath) {
        this.$router.replace(newPath);
      }
    },
    onLogout() {
      this.$accessor.clientAccount.logout();
    }
  }
});
