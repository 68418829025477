import {
  AffinityModel,
  PaginatorInfo,
  AffinityModelsQueryRequest,
  AffinityModelsQueryVariables
} from "~/types/gen/api";
import { actionTree, mutationTree } from "nuxt-typed-vuex";
import { resetState } from "~/store/utils";

export type AffinityModelsState = {
  affinityModels: AffinityModel | null;
  pager: Pick<PaginatorInfo, "total" | "count" | "currentPage">;
};

export const state = (): AffinityModelsState => ({
  affinityModels: null,
  pager: {
    total: 0,
    count: 0,
    currentPage: 0
  }
});

export const mutations = mutationTree(state, {
  recieveAffinityModelList(state, payload) {
    state.affinityModels = payload.map((v: any) => v.node.affinityModel);
  },
  recievePaginatorInfo(state, payload) {
    state.pager = payload;
  },
  reset: resetState(state)
});

export const actions = actionTree(
  { state },
  {
    async init(context, payload: AffinityModelsQueryVariables) {
      const req = new AffinityModelsQueryRequest(payload);
      const res = this.$apiClient.query(req);
      context.commit(
        "recieveAffinityModelList",
        (await res).brand.affinityModels!.edges
      );
      context.commit(
        "recievePaginatorInfo",
        (await res).brand.affinityModels!.paginatorInfo
      );
    }
  }
);
