import { actionTree, getterTree, mutationTree } from "nuxt-typed-vuex";
import { DateTime } from "luxon";
import {
  ClientCredentialPayload,
  ClientAccountQueryRequest,
  ClientAccountQuery,
  Brand,
  LoginMutationRequest,
  LoginMutationVariables,
  CampaignMenuQueryVariables,
  CampaignMenuQueryRequest,
  ClientCampaign,
  CampaignSideMenuQueryRequest,
  CampaignSideMenuQueryVariables,
  CampaignAgency,
  ClientBrand,
  ClientAssignment
} from "~/types/gen/api";
import BrandResponse from "~/store/responses/brandResponse";

const LS = window && window.localStorage;
export const TOKEN_KEY = "client-account-token";

export type ClientAccountState = {
  token: string | null;
  currentBrandName: string;
  agencies: CampaignAgency[];
  clientAssignments: ClientAssignment[];
  campaignMenu: Pick<ClientCampaign, "name" | "number">[];
} & ClientAccountQuery["clientAccount"];

export const state = (): ClientAccountState => ({
  token: null,
  id: "",
  name: "",
  email: "",
  isOwner: false,
  number: 0,
  brands: [],
  currentBrandName: "",
  campaignMenu: [],
  agencies: [],
  clientAssignments: []
});

export const mutations = mutationTree(state, {
  loggedIn(state, value: string) {
    state.token = value;
  },
  loggedOut(state) {
    state.token = null;
  },
  receiveAccount(
    state,
    { id, name, email, isOwner, brands }: ClientAccountQuery["clientAccount"]
  ) {
    state.id = id;
    state.name = name;
    state.email = email;
    state.isOwner = isOwner;
    state.brands = brands;
  },
  setCurrentBrandName(state, name: Brand["name"]) {
    state.currentBrandName = name;
  },
  recieveCampaignMenu(state, payload) {
    state.campaignMenu = payload;
  },
  recieveAgencies(state, payload) {
    state.agencies = payload;
  },
  recieveClientAssignments(state, payload) {
    state.clientAssignments = payload;
  }
});

export const getters = getterTree(state, {
  hasCredential(state) {
    return !!state.token;
  },
  hasAccount(state) {
    return !!state.id;
  },
  hasBrand(state) {
    return (brandName: string) => {
      let flg = false;
      state.brands.forEach(elm => {
        if (elm.name === brandName) {
          flg = true;
        }
      });
      return flg;
    };
  }
});

export const actions = actionTree(
  { state, getters, mutations },
  {
    async login(_, payload: LoginMutationVariables): Promise<void> {
      try {
        this.$accessor.presentation.showLoading(null);
        const res = await this.$apiClient.mutate(
          new LoginMutationRequest(payload)
        );
        if (res.clientLogin && res.clientLogin.token) {
          this.$accessor.clientAccount.saveCredential(res.clientLogin);
          await this.$accessor.clientAccount.loadAccount();
        }
      } catch (e) {
        this.$accessor.error.showError(e);
      } finally {
        this.$accessor.presentation.dismissLoading();
      }
    },
    async loadAccount(context): Promise<void> {
      try {
        this.$accessor.presentation.showLoading(null);
        const { clientAccount } = await this.$apiClient.query(
          new ClientAccountQueryRequest({})
        );
        context.commit("receiveAccount", clientAccount);
      } catch (e) {
        this.$accessor.clientAccount.logout();
        this.$accessor.error.showError(e);
      } finally {
        this.$accessor.presentation.dismissLoading();
      }
    },
    saveCredential(context, payload: ClientCredentialPayload): void {
      const token = payload.token;
      const expiredAt = DateTime.local().plus({ year: 100 });
      // Persist token
      this.$cookies.set(TOKEN_KEY, token, {
        path: "/",
        expires: expiredAt.toJSDate()
      });
      context.commit("loggedIn", token);
    },
    logout(context): void {
      this.$cookies.remove(TOKEN_KEY);
      LS.clear();
      context.commit("loggedOut");
      this.$router.replace("/login");
    },
    async getCampaignAgencies(
      context,
      payload: CampaignSideMenuQueryVariables
    ) {
      try {
        this.$accessor.presentation.showLoading(null);
        const req = new CampaignSideMenuQueryRequest(payload);
        const res = await this.$apiClient.query(req);
        if (res) {
          const brandRes = new BrandResponse(res.brand as ClientBrand);
          context.commit("recieveAgencies", brandRes.agencies);
          context.commit(
            "recieveClientAssignments",
            brandRes.campaignAssignments
          );
        }
      } catch (e) {
        this.$accessor.error.showError(e);
      } finally {
        this.$accessor.presentation.dismissLoading();
      }
    },
    async getCampaignMenu(context, payload: CampaignMenuQueryVariables) {
      try {
        this.$accessor.presentation.showLoading(null);
        const req = new CampaignMenuQueryRequest(payload);
        const res = await this.$apiClient.query(req);
        if (res) {
          context.commit("recieveCampaignMenu", res.brand.campaigns);
        }
      } catch (e) {
        this.$accessor.error.showError(e);
      } finally {
        this.$accessor.presentation.dismissLoading();
      }
    }
  }
);
