import { actionTree, mutationTree } from "nuxt-typed-vuex";
import {
  ClientAccountUpdateQueryRequest,
  ClientAccountUpdateQueryVariables,
  ClientAccount,
  UpdateClientAccountMutationRequest,
  UpdateClientAccountMutationVariables
} from "~/types/gen/api";
import { resetState } from "~/store/utils";

export type ClientAccountState = {
  account: Pick<ClientAccount, "id" | "number" | "name" | "email">;
};

export const state = (): ClientAccountState => ({
  account: {
    id: "",
    number: 0,
    name: "",
    email: ""
  }
});

export const mutations = mutationTree(state, {
  recieveClientAccount(state, payload) {
    state.account = payload;
  },
  reset: resetState(state)
});

export const actions = actionTree(
  { state },
  {
    async getClientAccount(
      context,
      clientAccountId: ClientAccountUpdateQueryVariables
    ): Promise<void> {
      try {
        const req = new ClientAccountUpdateQueryRequest(clientAccountId);
        const res = await this.$apiClient.query(req);
        if (res) {
          context.commit("recieveClientAccount", res.clientAccountByNumber);
        }
        this.$accessor.presentation.showLoading(null);
      } catch (e) {
        this.$accessor.error.showError(e);
      } finally {
        this.$accessor.presentation.dismissLoading();
      }
    },
    async updateClientAccount(
      _,
      payload: UpdateClientAccountMutationVariables
    ): Promise<void> {
      try {
        const req = new UpdateClientAccountMutationRequest(payload);
        const res = await this.$apiClient.mutate(req);
        if (res) {
          this.$router.replace("/admin/client-accounts");
        }
        this.$accessor.presentation.showLoading(null);
      } catch (e) {
        this.$accessor.error.showError(e);
      } finally {
        this.$accessor.presentation.dismissLoading();
      }
    }
  }
);
