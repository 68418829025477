import { actionTree, mutationTree } from "nuxt-typed-vuex";
import {
  CampaignCreationQueryVariables,
  CampaignCreationQueryRequest,
  ProductItem,
  AffinityModel,
  CampaignQueryVariables,
  CampaignQueryRequest,
  ClientCampaign,
  UpdateCampaignMutationRequest,
  UpdateCampaignMutationVariables
} from "~/types/gen/api";
import { resetState } from "~/store/utils";

export type campaignCreationState = {
  campaign: ClientCampaign | null;
  productItems: ProductItem[];
  affinityModels: AffinityModel[];
};

export const state = (): campaignCreationState => ({
  campaign: null,
  productItems: [],
  affinityModels: []
});

export const mutations = mutationTree(state, {
  recieveCampaign(state, payload) {
    state.campaign = payload;
  },
  recieveProductItem(state, payload) {
    state.productItems = payload;
  },
  recieveAffinityModel(state, payload) {
    state.affinityModels = payload;
  },
  reset: resetState(state)
});

export const actions = actionTree(
  { state },
  {
    async getCampaign(context, payload: CampaignQueryVariables) {
      try {
        this.$accessor.presentation.showLoading(null);
        const req = new CampaignQueryRequest(payload);
        const res = await this.$apiClient.query(req);
        if (res) {
          context.commit("recieveCampaign", res.brand.campaign);
        }
      } catch (e) {
        this.$accessor.error.showError(e);
      } finally {
        this.$accessor.presentation.dismissLoading();
      }
    },
    async init(context, payload: CampaignCreationQueryVariables) {
      try {
        this.$accessor.presentation.showLoading(null);
        const req = new CampaignCreationQueryRequest(payload);
        const res = await this.$apiClient.query(req);
        if (res) {
          context.commit("recieveProductItem", res.brand.productItems);
          context.commit("recieveAffinityModel", res.affinityModels);
        }
      } catch (e) {
        this.$accessor.error.showError(e);
      } finally {
        this.$accessor.presentation.dismissLoading();
      }
    },
    async updateCampaign(_, payload: UpdateCampaignMutationVariables) {
      try {
        this.$accessor.presentation.showLoading(null);
        const req = new UpdateCampaignMutationRequest(payload);
        const res = await this.$apiClient.mutate(req);
        if (res) {
          const { brand, id } = this.$router.currentRoute.params;
          this.$router.replace(`/${brand}/campaigns/${id}`);
        }
      } catch (e) {
        this.$accessor.error.showError(e);
      } finally {
        this.$accessor.presentation.dismissLoading();
      }
    }
  }
);
