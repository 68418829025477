export default {
  label: {
    periodEndAtSuffix: "まで"
  },
  message: {
    winning1: "貴社の提案が採用されました",
    winning2: "STEP2「発注リスト」の作成をお願いします",
    rejected: "この度はご提案いただきありがとうございました",
    beforeSuggestionPeriod: "提案開始までお待ちください",
    betweenSuggestionPeriod: "提案の締め切り",
    afterSuggestionPeriod1: "提案は締め切られました",
    afterSuggestionPeriod2: "提案を確認しています"
  }
};
