import Vue from "vue";

export const Toast = Vue.extend({
  name: "Toast",
  computed: {
    isToastShow() {
      return this.$accessor.toast.isShow;
    }
  },
  watch: {
    isToastShow: {
      handler(to) {
        if (to) {
          const toastPayload: any = Object.assign(
            {},
            this.$accessor.toast.toastPayload
          );
          this.$message(toastPayload);
          this.$accessor.toast.hide();
        }
      }
    }
  }
});

export default Toast;
