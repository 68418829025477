export default {
  label: {
    add: "Add Brand",
    image: "Image"
  },
  title: {
    create_brand: "Create Brand"
  },
  error: {
    create: "Failed to create a brand"
  },
  message: {
    success_create: "Success! Your brand has been created."
  },
  placeholder: {
    brand_description: "Brand descriptions"
  }
};
