import { actionTree, mutationTree } from "nuxt-typed-vuex";
import { CreateAgencyMutationRequest } from "~/types/gen/api";
import { resetState } from "~/store/utils";

export const state = () => ({});

export const mutations = mutationTree(state, {
  reset: resetState(state)
});

export const actions = actionTree(
  { state },
  {
    async createAgency(
      _context,
      { email, name, representative }
    ): Promise<void> {
      try {
        this.$accessor.presentation.showLoading(null);
        await this.$apiClient.mutate(
          new CreateAgencyMutationRequest({
            input: {
              name,
              accountName: representative,
              accountEmail: email
            }
          })
        );
        this.$router.replace("/admin/agencies");
      } catch (e) {
        // TODO エラーメッセージ
      } finally {
        this.$accessor.presentation.dismissLoading();
      }
    }
  }
);
