import { actionTree, mutationTree } from "nuxt-typed-vuex";
import {
  InfluencerEvaluationGroup,
  PaginatorInfo,
  InfluencerEvaluationGroupsQueryRequest,
  InfluencerEvaluationGroupsQueryVariables,
  CreateSnapMutationVariables,
  CreateSnapMutationRequest
} from "~/types/gen/api";
import { formatSlashDate } from "~/utils/date";
import { resetState } from "~/store/utils";

type evaluation = {
  createdAt: string;
  updatedAt: string;
  evaluationCount: number;
  evaluation: {
    matched: string;
    consider: string;
    unmatched: string;
  };
};

export type influencerEvaluationsState = {
  influencerEvaluations: InfluencerEvaluationGroup | null;
  pager: Pick<PaginatorInfo, "total" | "count" | "currentPage">;
  evaluationData: ([evaluation] | [])[];
  brandId: string;
};

export const state = (): influencerEvaluationsState => ({
  influencerEvaluations: null,
  pager: {
    total: 0,
    count: 0,
    currentPage: 0
  },
  evaluationData: [],
  brandId: ""
});

export const mutations = mutationTree(state, {
  recieveInfluencerEvaluations(state, payload) {
    state.influencerEvaluations = payload.map(
      (v: any) => v.node.influencerEvaluationGroup
    );
    state.evaluationData = (state.influencerEvaluations! as any).map(
      (v: any) => {
        return {
          id: v.id,
          createdAt: formatSlashDate(v.createdAt),
          updatedAt: formatSlashDate(v.updatedAt),
          evaluationCount: v.evaluationCount,
          evaluation: {
            matched: v.evaluationMatchedCount,
            consider: v.evaluationConsideringCount,
            unmatched: v.evaluationUnmatchedCount
          },
          number: v.number
        };
      }
    );
  },
  recievePaginatorInfo(state, payload) {
    state.pager = payload;
  },
  recieveBrandId(state, payload) {
    state.brandId = payload;
  },
  reset: resetState(state)
});

export const actions = actionTree(
  { state },
  {
    async init(context, payload: InfluencerEvaluationGroupsQueryVariables) {
      try {
        const req = new InfluencerEvaluationGroupsQueryRequest(payload);
        const res = await this.$apiClient.query(req);
        if (res) {
          context.commit(
            "recieveInfluencerEvaluations",
            res.brand.influencerEvaluationGroups!.edges
          );
          context.commit(
            "recievePaginatorInfo",
            res.brand.influencerEvaluationGroups!.paginatorInfo
          );
          context.commit("recieveBrandId", res.brand.id);
        }
      } catch (e) {
        throw e;
      }
    },
    async createSnap(_, payload: CreateSnapMutationVariables) {
      try {
        this.$accessor.presentation.showLoading(null);
        const req = new CreateSnapMutationRequest(payload);
        const res = await this.$apiClient.mutate(req);
        if (res) {
          this.$accessor.toast.show({
            message: this.$i18n.tc(
              "page.influencer_eval.message.saved_successfully"
            ),
            type: "success"
          });
        }
      } catch (e) {
        this.$accessor.error.showError(e);
      } finally {
        this.$accessor.presentation.dismissLoading();
      }
    }
  }
);
