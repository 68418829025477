export default {
  label: {
    requirement: "Requirement",
    password: "Password",
    others: "Others",
    text: "Text",
    brand: "Brand",
    score_settings: "Score Settings",
    affinity_models: "Affinity Models",
    influencer_evaluations: "Influencer Evaluations",
    campaign: "Campaign",
    campaigns: "Campaigns",
    title: "Title",
    detail: "Details",
    snapshot: "Snapshot",
    evaluation_options: "Influencer Evaluation Data",
    influencer_posting_period: "Influencer Posting Period",
    post_start_at: "Post Start At",
    post_end_at: "Post End At",
    range: "Range",
    logic: "Logic",
    campaign_name: "Campaign Name",
    budget: "Budget",
    keyword_and_hashtags: "Keyword And Hashtags",
    objectives: "Objectives",
    reach: "Reach",
    sales: "Sales",
    ec_click: "EcClick",
    reachKpi: "Reach KPI",
    product_category: "Product Category",
    budget_with_yen: "Budget(Yen)",
    note: "Note",
    dashboard: "Dashboard",
    proposal_list: "Proposal List",
    order_list: "Order List",
    report: "Report",
    agency: "Agency",
    company_name: "Agency Name",
    last_proposal_date: "Last Proposal Submission Date",
    upload_num: "# of Files",
    email: "Email Address",
    name: "Name",
    brand_name: "Brand Name",
    division_name: "Division Name",
    discriptions: "Descriptions",
    proposal_date: "Proposal Date",
    influencer_num: "# of Influencers",
    total_cost: "Cost",
    proposed_agency: "Agency",
    complete: "COMPLETE",
    no_post: "NO POST",
    monitor: "MONITOR",
    influencer_name: "Influencer Name",
    post_URL: "Post URL",
    proposal: "Proposal",
    pitch: "Pitch",
    influencer: "Influencer",
    status: "Status",
    follower_num: "# of Followers",
    availability_status: "Availability Status",
    order_complete: "Order Complete",
    confirm: "Confirm",
    cost: "Cost",
    alphanumeric: "Half-width alphanumeric characters",
    role: "Role",
    account: "Account",
    account_admin: "User Account Management",
    date: "Date",
    completion_status: "Status",
    completion_list: "Completion List",
    owner: "Owner",
    sign_out: "Sign Out",
    product_name: "Product Name",
    create: "Create",
    agency_name: "Agency Name",
    agency_representative: "Representative",
    agency_email: "Agency Email",
    add_agency: "Add a new agency",
    master_setting: "Master Setting",
    guideline: "Manual",
    purchase_manual: "Influencer 購買マニュアル",
    contact_person: "Contact Person",
    contact_person_email: "Contact Person Email",
    support_mail: "Contact",
    empty_data: "No data found",
    cannot_displayed_data: "No Data",
    order: "Order",
    execution: "Execution",
    suggestion: "Suggestion",
    suggestion_csv: "suggestion",
    order_date: "Order Date",
    engagement_num: "# of Engagement",
    engagement_rate: "Engagement Rate",
    productItem: "Product Item",
    offer: "Offer",
    total: "Total",
    instagram: "Instagram",
    twitter: "Twitter",
    youtube: "YouTube",
    tiktok: "TikTok",
    warning_capital: "WARNING",
    pitch_list: "Pitch List",
    all_capital: "ALL",
    checked: "Checked",
    per_page: "Per Page",
    yen_with_brackets: "(yen)",
    waiting_capital: "WAITING",
    warning_description:
      "Still in the process of collecting data. Please wait until it is completed. If data are not proceeded more than a day, please contact customer service.",
    updated_date: "Updated Date"
  },
  button: {
    check: "Check",
    create: "Create",
    history: "History",
    edit: "Edit",
    upload_image: "Upload Image",
    upload_csv: "Upload CSV",
    export: "Export",
    cancel: "Cancel",
    order: "Order",
    ok: "OK",
    close: "Close",
    save: "Save",
    redact: "Edit",
    delete: "Delete",
    deactivate: "Deactivate",
    remove: "Remove",
    submit: "Submit",
    update: "Update",
    set: "Set",
    reject: "Reject",
    delete_all_influencers: "Delete all influencers",
    approve: "Approve",
    back_top: "Back to Top",
    download_csv: "Download CSV",
    skip_pitch: "Skip Pitch"
  },
  message: {
    delete: "Would you really wish to delete?",
    deactivate: "Would you really wish to deactivate?",
    input_note: "Please enter the contents of note",
    no_post: "Post could not found",
    prompt_file_select: "Please select a file",
    no_return_action: "You cannot undo this action.",
    not_available_now: "Influencer posts are not available now.",
    type_number: "Please type half-width number."
  },
  format: {
    yen: "￥{0}",
    limit_text: "Max {0} characters",
    person: "{0}",
    percent: "{0}%"
  },
  placeholder: {
    email: "sample@gmail.com",
    limit_text: "Max {0} characters",
    person: "{0}",
    type_password_in_alphanumeric:
      "Type password(Half-width alphanumeric characters)",
    type_registered_email: "Type your registered email"
  },
  error: {
    status_code: {
      404: "Not Found",
      500: "Internal Server Error",
      401: "Unauthorized"
    },
    message: "Error Occurred"
  },
  title: {
    sign_in: "Sign In",
    agency_login: "Agency Sign In",
    brands: "Brand List",
    dashboard: "TOP",
    campaign_list: "Report",
    create_agency: "Add Agency",
    edit_agency: "Edit Agency Account",
    agency_admin: "Agency Account Management"
  },
  chart: {
    all: "All",
    exclusion: "Exclude",
    evaluated_influencer: "Evaluated Influencers",
    total: "Total",
    sub_total: "Sub Total",
    current: "Current",
    person: "Person",
    open: "Open",
    close: "Close",
    empty: "No data found",
    planned: "Planned",
    planning: "Planning",
    capital_total: "TOTAL",
    plan_total: "Plan Total",
    current_total: "Current Total",
    initial_total: "Initial Total",
    pitch_total: "Pitch Total",
    order_total: "Order Total",
    delivery_total: "Delivery Total",
    commission: "Commission",
    agency_fee: "Agency Fee"
  },
  image: {
    empty: "Image could not be retrieved.",
    only_url: "Only url"
  },
  tooltip: {
    warning: {
      head: "Warning",
      body: "Report upload data is missing. <br/>" + "Please contact Agency.",
      not_applicable_in_this_social_platform:
        "Not Applicable in this social platform"
    }
  },
  password_expired_en: {
    title: "Your password has expired",
    message:
      "Your password has expired and requires a reset for continued use.<br/>" +
      "To ensure the security of your account, a new password is necessary.<br/>" +
      "Passwords must be updated every three months.<br/>" +
      "Please follow the link below for instructions on resetting your password.",
    link_text: "Reset password"
  },
  password_expired_ja: {
    title: "パスワードの有効期限切れ",
    message:
      "パスワードの有効期限が切れたため、継続して使用するためには再設定が必要です。<br/>" +
      "お客様のアカウントのセキュリティを確保するために、新しいパスワードが必要です。<br/>" +
      "パスワードは3ヶ月ごとに更新する必要があります。<br/>" +
      "パスワードの再設定方法については、以下のリンクを参照してください。",
    link_text: "パスワード再設定"
  }
};
