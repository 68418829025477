import { actionTree, mutationTree } from "nuxt-typed-vuex";
import {
  AgencyChangePasswordMutationRequest,
  AgencyChangePasswordMutationVariables,
  SendAgencyPasswordResetEmailMutationRequest,
  SendAgencyPasswordResetEmailMutationVariables
} from "~/types/gen/api";
import { Message } from "element-ui";

export const state = () => ({});

export const mutations = mutationTree(state, {});

export const actions = actionTree(
  { state, mutations },
  {
    async resetPassword(_, payload: AgencyChangePasswordMutationVariables) {
      try {
        this.$accessor.presentation.showLoading(null);
        const res = await this.$apiClient.mutate(
          new AgencyChangePasswordMutationRequest(payload)
        );
        // パスワードチェンジ後はログアウトする
        if (res) {
          Message(this.$i18n.tc("page.reset_password.message.change_success"));
          this.$accessor.agencyAccount.logout();
          this.$router.push("/agency-login");
        }
      } catch (e) {
        this.$accessor.error.showError(e);
      } finally {
        this.$accessor.presentation.dismissLoading();
      }
    },
    async sendPasswordRestEmail(
      _,
      payload: SendAgencyPasswordResetEmailMutationVariables
    ) {
      try {
        this.$accessor.presentation.showLoading(null);
        const req = new SendAgencyPasswordResetEmailMutationRequest(payload);
        const res = await this.$apiClient.mutate(req);
        if (!!res.agencyChangePasswordRequest!.requested) {
          Message(this.$i18n.tc("page.reset_password.message.sent_reset_link"));
        } else {
          //TODO 後でstoreにtoast作る。
          Message(this.$i18n.tc("page.reset_password.message.sent_failed"));
        }
      } catch (e) {
        this.$accessor.error.showError(e);
      } finally {
        this.$accessor.presentation.dismissLoading();
      }
    }
  }
);
