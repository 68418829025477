const middleware = {}

middleware['agencyExcludeAuth'] = require('../src/middleware/agencyExcludeAuth.ts')
middleware['agencyExcludeAuth'] = middleware['agencyExcludeAuth'].default || middleware['agencyExcludeAuth']

middleware['agencyRequireAuth'] = require('../src/middleware/agencyRequireAuth.ts')
middleware['agencyRequireAuth'] = middleware['agencyRequireAuth'].default || middleware['agencyRequireAuth']

middleware['excludeAuth'] = require('../src/middleware/excludeAuth.ts')
middleware['excludeAuth'] = middleware['excludeAuth'].default || middleware['excludeAuth']

middleware['requireAuth'] = require('../src/middleware/requireAuth.ts')
middleware['requireAuth'] = middleware['requireAuth'].default || middleware['requireAuth']

middleware['supportButton'] = require('../src/middleware/supportButton.ts')
middleware['supportButton'] = middleware['supportButton'].default || middleware['supportButton']

middleware['util'] = require('../src/middleware/util.ts')
middleware['util'] = middleware['util'].default || middleware['util']

export default middleware
