import { actionTree, mutationTree } from "nuxt-typed-vuex";
import {
  ClientCampaign,
  ClientCampaignsQueryVariables,
  ClientCampaignsQueryRequest
} from "~/types/gen/api";
import { resetState } from "~/store/utils";

export type campaignsState = {
  campaigns: ClientCampaign[];
};

export const state = (): campaignsState => ({
  campaigns: []
});

export const mutations = mutationTree(state, {
  receiveCampaigns(state, payload) {
    state.campaigns = payload;
  },
  reset: resetState(state)
});

export const actions = actionTree(
  { state },
  {
    async init(context, payload: ClientCampaignsQueryVariables) {
      try {
        this.$accessor.presentation.showLoading(null);
        const req = new ClientCampaignsQueryRequest(payload);
        const res = await this.$apiClient.query(req);
        if (res) {
          context.commit("receiveCampaigns", res.clientCampaigns);
        }
      } catch (e) {
        this.$accessor.error.showError(e);
      } finally {
        this.$accessor.presentation.dismissLoading();
      }
    }
  }
);
