












































































import Vue from "vue";
import Select from "~/components/ui/forms/select.vue";
import { Brand, ClientAccount, AgencyAccount } from "~/types/gen/api";
import { SelectOption } from "~/components/ui/forms/select.vue";
import Menu from "~/components/ui/menu/menu.vue";
import AccountMenu from "~/components/the/header/account-menu.vue";
import CampaignMenu from "~/components/the/header/campaign-menu.vue";

export default Vue.extend({
  components: {
    Select,
    AccountMenu,
    CampaignMenu,
    Menu
  },
  props: {
    brands: {
      type: Array as () => Brand[],
      default: () => {
        return [];
      }
    },
    logoLink: {
      type: String,
      default: "/"
    },
    logoAllCampaignsLink: {
      type: String,
      default: "/campaigns"
    },
    account: {
      type: Object as () => ClientAccount | AgencyAccount | null,
      default: null
    },
    isBrandSelectable: {
      type: Boolean,
      default: true
    },
    isScoreSettingLinkVisile: {
      type: Boolean,
      default: true
    },
    isCampaignLinkVisile: {
      type: Boolean,
      default: true
    },
    isOwner: {
      type: Boolean,
      default: false
    },
    isAgency: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    brandList(): SelectOption[] {
      if (!this.isBrandSelectable) {
        return [];
      }
      return this.brands.map(brand => {
        return {
          label: brand.name,
          value: brand.name
        };
      });
    },
    brandName() {
      return this.$route.params.brand;
    }
  },
  methods: {
    onBrandChange(brandName: string) {
      if (this.isAgency) {
        this.$router.push(`/agency/brands/${brandName}/campaigns`);
      } else {
        this.$router.push(`/${brandName}/campaigns`);
      }
    },
    onLogout() {
      this.$emit("logout");
    },
    handleSettingMenu() {
      this.$router.push(`/${this.brandName}/affinity-models`);
    },
    handleSettingSubMenu(val: { key: string; keyPath: string }) {
      this.$router.push(val.key);
    },
    handleTopMenu(val: { key: string; keyPath: string }) {
      this.$router.push(val.key);
    },
    handleCampaignClick() {
      this.$router.push(`/${this.brandName}/campaigns`);
    }
  }
});
