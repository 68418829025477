import { actionTree, mutationTree } from "nuxt-typed-vuex";
import {
  AdminClientAccountsQueryRequest,
  ClientAccount
} from "~/types/gen/api";
import { resetState } from "~/store/utils";

export type ClientAccountsState = {
  accounts: ClientAccount[];
};

export const state = (): ClientAccountsState => ({
  accounts: []
});

export const mutations = mutationTree(state, {
  recieveClientAccounts(state, payload) {
    state.accounts = payload;
  },
  reset: resetState(state)
});

export const actions = actionTree(
  { state },
  {
    async getClientAccounts(context): Promise<void> {
      try {
        this.$accessor.presentation.showLoading(null);
        const req = new AdminClientAccountsQueryRequest({});
        const res = await this.$apiClient.query(req);
        if (res) {
          context.commit("recieveClientAccounts", res.clientAccounts);
        }
      } catch (e) {
        this.$accessor.error.showError(e);
      } finally {
        this.$accessor.presentation.dismissLoading();
      }
    }
  }
);
