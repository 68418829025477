import { actionTree, mutationTree, getterTree } from "nuxt-typed-vuex";
import {
  LoadingStatus,
  InfluencerSegment,
  InfluencerEvaluationGroup,
  PaginatorInfo,
  MasterInfluencerEvaluation,
  InfluencerEvaluationQueryRequest,
  InfluencerEvaluationQueryVariables,
  UpdateInfluencerEvaluationNoteMutationVariables,
  UpdateInfluencerEvaluationNoteMutationRequest,
  UpdateInfluencerEvaluationMutationVariables,
  UpdateInfluencerEvaluationMutationRequest,
  UpdateUnevaluatedInfluencerMutationVariables,
  UpdateUnevaluatedInfluencerMutationRequest,
  CreateSnapMutationRequest,
  CreateSnapMutationVariables,
  InfluencerEvaluation
} from "~/types/gen/api";
import { resetState } from "~/store/utils";

export type influencerEvaluationState = {
  influencerEvaluationGroup: InfluencerEvaluationGroup | null;
  influencerSegment: ([InfluencerSegment] | null)[];
  pager: Pick<PaginatorInfo, "total" | "count" | "currentPage">;
  influencerEvaluation: MasterInfluencerEvaluation[];
  postLoadingStatus: LoadingStatus;
};

export const state = (): influencerEvaluationState => ({
  influencerEvaluationGroup: null,
  influencerSegment: [],
  pager: {
    total: 0,
    count: 0,
    currentPage: 0
  },
  influencerEvaluation: [],
  postLoadingStatus: LoadingStatus.Complete
});

export const mutations = mutationTree(state, {
  recieveInfluencerEvaluationGroup(state, payload) {
    state.influencerEvaluationGroup = payload;
    state.influencerEvaluation = payload.influencerEvaluations!.edges!.map(
      (v: any) => {
        return v.node!.influencerEvaluation;
      }
    );
  },
  recieveInfluencerSegment(state, payload) {
    state.influencerSegment = payload.map((v: InfluencerSegment) => v);
  },
  recievePaginatorInfo(state, payload) {
    state.pager = payload;
  },
  recievePostLoadingStatus(state, payload) {
    state.postLoadingStatus = payload;
  },
  recieveUpdateNote(state, payload) {
    state.influencerEvaluation.map((v: InfluencerEvaluation) => {
      if (v.id === payload.id) {
        return (v.note = payload.note);
      }
    });
  },
  updateInfluencerEvaluation(state, payload) {
    state.influencerEvaluation.map((v: MasterInfluencerEvaluation) => {
      if (v.id === payload.id) {
        v.evaluation = payload.evaluation;
        v.evaluatedAt = payload.evaluatedAt;
      }
    });
  },
  reset: resetState(state)
});

export const getters = getterTree(state, {
  evaluationCount(state) {
    return (
      state.influencerEvaluationGroup &&
      state.influencerEvaluationGroup.evaluationCount
    );
  },
  groupId(state) {
    return (
      state.influencerEvaluationGroup && state.influencerEvaluationGroup.id
    );
  }
});

export const actions = actionTree(
  { state },
  {
    async init(context, payload: InfluencerEvaluationQueryVariables) {
      try {
        this.$accessor.presentation.showLoading(null);

        const req = new InfluencerEvaluationQueryRequest(payload);
        const res = await this.$apiClient.query(req);
        if (res) {
          context.commit(
            "recieveInfluencerEvaluationGroup",
            res.brand!.influencerEvaluationGroup
          );
          context.commit("recieveInfluencerSegment", res.influencerSegments);
          context.commit(
            "recievePaginatorInfo",
            res.brand.influencerEvaluationGroup!.influencerEvaluations!
              .paginatorInfo
          );
          context.commit(
            "recievePostLoadingStatus",
            res.brand.influencerEvaluationGroup!.postLoadingStatus.status
          );
          const { brand, id } = this.$router.currentRoute.params;
          const {
            page,
            perPage,
            searchBy,
            filterBy
          } = this.$router.currentRoute.query;
          if (
            res.brand.influencerEvaluationGroup!.postLoadingStatus.status ==
            "INCOMPLETE"
          ) {
            this.$router.push(
              `/${brand}/influencer-evaluations/${id}/uploading`
            );
          } else if (page || perPage || searchBy || filterBy) {
            this.$router.push({
              path: `/${brand}/influencer-evaluations/${id}`,
              query: {
                page: page ? page.toString() : null,
                perPage: perPage ? perPage.toString() : null,
                filterBy: filterBy ? filterBy : null,
                searchBy: searchBy ? searchBy : null
              }
            });
          } else {
            this.$router.push(`/${brand}/influencer-evaluations/${id}`);
          }
        }
      } catch (e) {
        this.$accessor.error.showError(e);
      } finally {
        this.$accessor.presentation.dismissLoading();
      }
    },
    async updateNote(
      context,
      payload: UpdateInfluencerEvaluationNoteMutationVariables
    ) {
      try {
        this.$accessor.presentation.showLoading(null);

        const req = new UpdateInfluencerEvaluationNoteMutationRequest(payload);
        const res = await this.$apiClient.mutate(req);
        const { brand, id } = this.$router.currentRoute.params;

        if (res) {
          context.commit(
            "recieveUpdateNote",
            res.updateInfluencerEvaluationNote!.influencerEvaluation
          );
          this.$router.replace(`/${brand}/influencer-evaluations/${id}`);
        }
      } catch (e) {
        this.$accessor.error.showError(e);
      } finally {
        this.$accessor.presentation.dismissLoading();
      }
    },
    async updateInfluencerEvaluation(
      context,
      payload: UpdateInfluencerEvaluationMutationVariables
    ) {
      try {
        this.$accessor.presentation.showLoading(null);

        const req = new UpdateInfluencerEvaluationMutationRequest(payload);
        const res = await this.$apiClient.mutate(req);
        if (res) {
          context.commit(
            "updateInfluencerEvaluation",
            res.updateInfluencerEvaluation!.influencerEvaluation
          );
        }
      } catch (e) {
        this.$accessor.error.showError(e);
      } finally {
        this.$accessor.presentation.dismissLoading();
      }
    },
    async updateUnevaluatedInfluencer(
      context,
      payload: UpdateUnevaluatedInfluencerMutationVariables
    ) {
      try {
        this.$accessor.presentation.showLoading(null);
        const req = new UpdateUnevaluatedInfluencerMutationRequest(payload);
        const res = await this.$apiClient.mutate(req);

        if (res) {
          context.commit(
            "recievePostLoadingStatus",
            res.updateUnevaluatedInfluencer!.postLoadingStatus.status
          );
          const { brand, id } = this.$router.currentRoute.params;
          if (
            res.updateUnevaluatedInfluencer!.postLoadingStatus.status ==
            "INCOMPLETE"
          ) {
            window.location.reload(true);
          } else {
            this.$router.push(`/${brand}/influencer-evaluations/${id}`);
          }
        }
      } catch (e) {
        this.$accessor.error.showError(e);
      } finally {
        this.$accessor.presentation.dismissLoading();
      }
    },
    async createSnap(_, payload: CreateSnapMutationVariables) {
      try {
        this.$accessor.presentation.showLoading(null);
        const req = new CreateSnapMutationRequest(payload);
        const res = await this.$apiClient.mutate(req);
        if (res) {
          const { brand } = this.$router.currentRoute.params;
          this.$router.replace(`/${brand}/influencer-evaluations`);
        }
      } catch (e) {
        this.$accessor.error.showError(e);
      } finally {
        this.$accessor.presentation.dismissLoading();
      }
    }
  }
);
