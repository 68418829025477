import affinity_model from "./affinity-model";
import influencer_eval from "./influencer-eval";
import snapshot from "./snapshot";
import campaign from "./campaign";
import agent_account_index from "~/plugins/i18n/locales/ja/page/agent-account-index.ts";
import agent_account_create from "~/plugins/i18n/locales/ja/page/agent-account-create.ts";
import agent_account_edit from "./agent-account-edit";
import brands_create from "./brands-create";
import brands from "./brands";
import brands_edit from "./brands-edit";
import brands_index from "./brands-index";
import login from "./login";
import reset_password from "./reset-password";
import agent_campaign_detail from "./agent-campaign-detail";
import influencer_pyramid from "./influencer-pyramid";
import agency_campaign_proposal from "./agency-campaign-proposal";
import comments from "./comments";

export default {
  affinity_model,
  influencer_eval,
  snapshot,
  campaign,
  agent_account_index,
  agent_account_create,
  agent_account_edit,
  brands_create,
  brands_edit,
  brands_index,
  login,
  reset_password,
  agent_campaign_detail,
  influencer_pyramid,
  brands,
  agency_campaign_proposal,
  comments
};
