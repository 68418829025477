import { actionTree, mutationTree } from "nuxt-typed-vuex";
import {
  CreateBrandMutationRequest,
  CreateBrandMutationVariables
} from "~/types/gen/api";
import { resetState } from "~/store/utils";
import { Message } from "element-ui";

export const state = () => ({});

export const mutations = mutationTree(state, {
  reset: resetState(state)
});

export const actions = actionTree(
  { state },
  {
    async createbrand(
      _context,
      payload: CreateBrandMutationVariables
    ): Promise<void> {
      try {
        this.$accessor.presentation.showLoading(null);
        const { createBrand } = await this.$apiClient.mutate(
          new CreateBrandMutationRequest(payload)
        );
        if (createBrand) {
          this.$accessor.clientAccount.loadAccount;
          const path = `/brands/${createBrand.brand.name}/edit`;
          location.href = path;
          Message(this.$i18n.tc("page.brands_create.message.success_create"));
        }
      } catch (e) {
        this.$accessor.error.showError(e);
      } finally {
        this.$accessor.presentation.dismissLoading();
      }
    }
  }
);
