import { actionTree, mutationTree } from "nuxt-typed-vuex";
import { CreateClientAccountMutationRequest } from "~/types/gen/api";
import { resetState } from "~/store/utils";

export const state = () => ({});

export const mutations = mutationTree(state, {
  reset: resetState(state)
});

export const actions = actionTree(
  { state },
  {
    async createClientAccount(_context, { name, email }): Promise<void> {
      try {
        this.$accessor.presentation.showLoading(null);
        await this.$apiClient.mutate(
          new CreateClientAccountMutationRequest({
            input: {
              name,
              email
            }
          })
        );
        this.$router.replace("/admin/client-accounts");
      } catch (e) {
        this.$accessor.error.showError(e);
      } finally {
        this.$accessor.presentation.dismissLoading();
      }
    }
  }
);
