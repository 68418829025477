
















import Vue from "vue";
import Modal from "~/components/ui/modal/modal.vue";
import { APIErrorObject } from "~/network/api-error";
export default Vue.extend({
  components: {
    Modal
  },
  computed: {
    error(): APIErrorObject | null {
      return this.$accessor.error.error;
    },
    shouldShowDebugMessage(): boolean {
      return (
        process.env.APP_ENV !== "production" &&
        !!this.error &&
        !!this.error.debugMessage
      );
    }
  },
  methods: {
    handleClose() {
      this.$accessor.error.dismissError();
    }
  }
});
