import { actionTree, mutationTree } from "nuxt-typed-vuex";
import {
  ClientCampaign,
  CampaignQueryVariables,
  CampaignQueryRequest,
  CampaignAgency,
  ClientAgency,
  AddAgenciesToCampaignMutationRequest,
  AddAgenciesToCampaignMutationVariables,
  RejectSuggestionMutationRequest,
  RejectSuggestionMutationVariables,
  ClientAssignment,
  ClientBrand,
  DownloadAssignmentInfluencersMutationVariables,
  DownloadAssignmentInfluencersMutationRequest,
  RemoveAgencyFromCampaignMutationVariables,
  RemoveAgencyFromCampaignMutationRequest,
  SkipToReportMutationVariables,
  SkipToReportMutationRequest
} from "~/types/gen/api";
import { downloadFileByURL } from "~/utils/downloadFileByURL";
import { resetState } from "~/store/utils";
import BrandResponse from "~/store/responses/brandResponse";

export type campaignsState = {
  campaign: ClientCampaign | null;
  agencies: CampaignAgency[];
  clientAssignments: ClientAssignment[];
  registeredAgencies: ClientAgency[];
  isSuccessRemoveAgency: boolean;
};

export const state = (): campaignsState => ({
  campaign: null,
  agencies: [],
  registeredAgencies: [],
  clientAssignments: [],
  isSuccessRemoveAgency: false
});

export const mutations = mutationTree(state, {
  recieveCampaign(state, payload) {
    state.campaign = payload;
  },
  recieveAgencies(state, payload) {
    state.agencies = payload.map((v: any) => v);
  },
  reciveRegisteredAgencies(state, payload) {
    state.registeredAgencies = payload;
  },
  recieveClientAssignments(state, payload) {
    state.clientAssignments = payload;
  },
  recieveSuccessRemoveAgency(state, payload) {
    state.isSuccessRemoveAgency = payload;
  },
  reset: resetState(state)
});

export const actions = actionTree(
  { state },
  {
    async init(context, payload: CampaignQueryVariables) {
      try {
        this.$accessor.presentation.showLoading(null);
        const req = new CampaignQueryRequest(payload);
        const res = await this.$apiClient.query(req);
        if (res) {
          const brandRes = new BrandResponse(res.brand as ClientBrand);
          //TODO あとでclassを使う。
          context.commit("recieveCampaign", brandRes.campaign);
          context.commit("recieveAgencies", brandRes.agencies);
          context.commit("reciveRegisteredAgencies", res.agencies);
          context.commit(
            "recieveClientAssignments",
            brandRes.campaignAssignments
          );
        }
      } catch (e) {
        this.$accessor.error.showError(e);
      } finally {
        this.$accessor.presentation.dismissLoading();
      }
    },
    async addAgencies(
      context,
      payload: AddAgenciesToCampaignMutationVariables
    ) {
      try {
        this.$accessor.presentation.showLoading(null);
        const req = new AddAgenciesToCampaignMutationRequest(payload);
        const res = await this.$apiClient.mutate(req);
        if (res) {
          //TODO あとでclassを使う。
          context.commit(
            "recieveAgencies",
            res.addAgenciesToCampaign!.campaign.agencies
          );
        }
      } catch (e) {
        this.$accessor.error.showError(e);
      } finally {
        this.$accessor.presentation.dismissLoading();
      }
    },
    async rejectAgency(_, payload: RejectSuggestionMutationVariables) {
      try {
        this.$accessor.presentation.showLoading(null);
        const req = new RejectSuggestionMutationRequest(payload);
        const res = await this.$apiClient.mutate(req);
        if (res && res.rejectSuggestion!.campaign.id) {
          const { brand, id } = this.$router.currentRoute.params;
          if (id) {
            await this.$accessor.pages.campaign.init({
              name: brand,
              number: parseInt(id)
            });
          }
        }
      } catch (e) {
        this.$accessor.error.showError(e);
      } finally {
        this.$accessor.presentation.dismissLoading();
      }
    },
    async downloadAssignmentInfluencers(
      _,
      payload: DownloadAssignmentInfluencersMutationVariables
    ) {
      try {
        this.$accessor.presentation.showLoading(null);
        const req = new DownloadAssignmentInfluencersMutationRequest(payload);
        const res = await this.$apiClient.mutate(req);
        if (res.downloadAssignmentInfluencers!.url) {
          downloadFileByURL(res.downloadAssignmentInfluencers!.url);
        }
      } catch (e) {
        this.$accessor.error.showError(e);
      } finally {
        this.$accessor.presentation.dismissLoading();
      }
    },
    async removeAgencyFromCampaign(
      context,
      payload: RemoveAgencyFromCampaignMutationVariables
    ) {
      try {
        this.$accessor.presentation.showLoading(null);
        const req = new RemoveAgencyFromCampaignMutationRequest(payload);
        const res = await this.$apiClient.mutate(req);
        if (res) {
          context.commit("recieveSuccessRemoveAgency", true);
          const { brand, id } = this.$router.currentRoute.params;
          if (parseInt(id)) {
            await this.$accessor.pages.campaign.init({
              name: brand,
              number: parseInt(id)
            });
          }
        }
      } catch (e) {
        this.$accessor.error.showError(e);
      } finally {
        this.$accessor.presentation.dismissLoading();
      }
    },
    async skipToReport(_context, payload: SkipToReportMutationVariables) {
      try {
        this.$accessor.presentation.showLoading(null);
        const req = new SkipToReportMutationRequest(payload);
        await this.$apiClient.mutate(req);
      } catch (e) {
        this.$accessor.error.showError(e);
      } finally {
        this.$accessor.presentation.dismissLoading();
      }
    }
  }
);
