import { actionTree, mutationTree } from "nuxt-typed-vuex";
import {
  InfluencerSegmentsQueryRequest,
  InfluencerSegment,
  UpdateInfluencerSegmentMutationRequest
} from "~/types/gen/api";
import { resetState } from "~/store/utils";

export type InfluencerSegmentsState = {
  influencerSegments: InfluencerSegment[];
};

export const state = (): InfluencerSegmentsState => ({
  influencerSegments: []
});

export const mutations = mutationTree(state, {
  setInfluencerSegments(state, influencerSegments: InfluencerSegment[]) {
    state.influencerSegments = influencerSegments;
  },
  reset: resetState(state)
});

export const actions = actionTree(
  { state },
  {
    async getInfluencerSegments({ commit }): Promise<void> {
      try {
        this.$accessor.presentation.showLoading(null);

        const { influencerSegments } = await this.$apiClient.query(
          new InfluencerSegmentsQueryRequest({})
        );
        if (influencerSegments) {
          commit("setInfluencerSegments", influencerSegments);
        }
      } catch (e) {
        commit("loginFailed", e);
      } finally {
        this.$accessor.presentation.dismissLoading();
      }
    },
    async updateInfluencerSegment(
      { state },
      result: { [k in string]: { min: number; max: number; increase: number } }
    ): Promise<void> {
      for (const segmentId of Object.keys(result)) {
        const influencerSegment = state.influencerSegments.find(
          influencerSegment => influencerSegment.id === segmentId
        );
        if (!influencerSegment) continue;
        try {
          this.$accessor.presentation.showLoading(null);
          await this.$apiClient.mutate(
            new UpdateInfluencerSegmentMutationRequest({
              input: {
                influencerSegmentId: influencerSegment.id,
                name: influencerSegment.name,
                maxFollowerCount: result[influencerSegment.id].max,
                minFollowerCount: result[influencerSegment.id].min,
                increaseRate: result[influencerSegment.id].increase
              }
            })
          );
        } catch (e) {
          this.$accessor.error.showError(e);
        } finally {
          this.$accessor.presentation.dismissLoading();
        }
      }
    }
  }
);
