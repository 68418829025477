























import Vue from "vue";
import Spinner from "./indicator/spinner.vue";
import { LoadingType } from "~/store/presentation/index";

export default Vue.extend({
  components: {
    Spinner
  },
  props: {
    type: {
      type: String as () => LoadingType,
      default: "normal"
    }
  },
  computed: {
    typeClassName() {
      return "-" + this.type;
    }
  }
});
